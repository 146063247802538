import React from 'react';
import PropTypes from 'prop-types';

const TextEntryIcon = ({ size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" width={size} height={size}>
    <path d="M14.5.5H1.4C.8.5.5.9.5 1.4v12.1c0 .6.4.9.9.9h13.1" fill="none" stroke="#3c3c3b" strokeWidth=".933" />
    <text transform="translate(4.233 11.7)" fontSize="12.133" fontFamily="Raleway" fill="#3c3c3b">A
    </text>
  </svg>
);

TextEntryIcon.propTypes = {
  size: PropTypes.string,
};

TextEntryIcon.defaultProps = {
  size: '12px',
};

export default TextEntryIcon;
