import { getIn as get } from 'formik';
import dxConfigData from 'dxConfigData';

export const config = {
  get(path, defaultValue) {
    if (path === null || path === undefined) {
      throw new RangeError('Calling config.get with null or undefined parameter.');
    }
    const value = get(dxConfigData, path);
    if (value === undefined) {
      if (defaultValue === undefined) {
        throw new Error(`Configuration '${path}' is undefined.`);
      }
      else {
        return defaultValue;
      }
    }
    return value;
  },
  has(path) {
    if (path === null || path === undefined) {
      throw new RangeError('Calling config.has with null or undefined parameter.');
    }
    return get(dxConfigData, path) !== undefined;
  },
  data() { return dxConfigData; },
};
