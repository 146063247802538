import {
  CHANGE_INNOBOARD_COLUMNS,
  CHANGE_INNOBOARD_SEGMENT,
  ERROR_MANAGERS,
  ERROR_ONE_ON_ONE_FOR_PROJECT,
  ERROR_PROJECT_BOARDS,
  ERROR_PROJECT_BY_EVALUATION,
  ERROR_PROJECT_BY_PHASE,
  ERROR_PROJECT_REVIEWS_BY_PHASE,
  ERROR_PROJECT_WORKSHOPS,
  ERROR_PROJECTS,
  FETCH_MANAGERS,
  FETCH_ONE_ON_ONE_FOR_PROJECT,
  FETCH_PROJECT_BOARDS,
  FETCH_PROJECT_BY_EVALUATION,
  FETCH_PROJECT_BY_PHASE,
  FETCH_PROJECT_REVIEWS_BY_PHASE,
  FETCH_PROJECT_WORKSHOPS,
  FETCH_PROJECTS,
  RECEIVE_MANAGERS,
  RECEIVE_ONE_ON_ONE_FOR_PROJECT,
  RECEIVE_PROJECT_BOARDS,
  RECEIVE_PROJECT_BY_EVALUATION,
  RECEIVE_PROJECT_BY_PHASE,
  RECEIVE_PROJECT_REVIEWS_BY_PHASE,
  RECEIVE_PROJECT_WORKSHOPS,
  RECEIVE_PROJECTS,
  RESET_INNOBOARD_FILTERS,
  SELECT_FILTER_SEGMENT,
} from '../../constants';

import {
  errorEntities,
  errorEntity,
  fetchEntities,
  fetchEntity,
  receiveEntities,
  receiveEntity,
} from './utils';

const createSelectedSegmentsArray = (amountOfSegments) => {
  if (amountOfSegments) {
    const array = [];
    for (let i = 0; i < amountOfSegments; i++) {
      array.push(i);
    }
    return array;
  }
  else {
    // Default segments
    return [0, 1, 2];
  }
};

const createNewlySelectedInnoboardSegmentArray = (segment, maxSegments) => {
  let i = 0;
  if (segment >= maxSegments) {
    i = segment - maxSegments + 1;
  }
  const result = [];
  for (let j = 0; j < maxSegments; i++, j++) {
    result.push(i);
  }
  return result;
};

const initialState = {
  entities: {},
  isFiltering: false,
  relations: {
    applicationHasManagers: {},
    campaignHasProjects: {},
    projectHasWorkshops: {},
    projectHasTimeslots: {},
  },
  filterSegments: false,
  selectedSegments: createSelectedSegmentsArray(localStorage.getItem('innoBoardColumns')),
};

// function getMaxOrderInnoboardCriteria(project, innoboardId) {
//   if (!project.innoBoards) { return -1; }
//   const innoboard = project.innoBoards[innoboardId];
//   if (!innoboard) { return -1; }
//   const criteria = innoboard.model.version.criteria;
//   let maxArchivedOrder = -1;
//   let maxOrder = -1;
//   for (const critKey in criteria) {
//     const { instances } = criteria[critKey];
//     for (const innoboardCritKey in instances) {
//       const innoboardCriteria = instances[innoboardCritKey];
//       if (maxOrder < innoboardCriteria.order) {
//         maxOrder = innoboardCriteria.order;
//       }
//       if (innoboardCriteria.archivedOn) {
//         if (maxArchivedOrder < innoboardCriteria.order) {
//           maxArchivedOrder = innoboardCriteria.order;
//         }
//       }
//     }
//   }
//   return { maxArchivedOrder, maxOrder };
// }

// function incrementOrderArchivedInnoboardCriteria(project, innoboardId) {
//   const criteria = project.innoBoards[innoboardId].model.version.criteria;
//   const newCriteria = {};

//   for (const critKey in criteria) {
//     const instances = criteria[critKey].instances;
//     // Clone criteria.
//     const newInstances = {};
//     newCriteria[critKey] = { ...criteria[critKey], instances: newInstances };
//     for (const innoboardCritKey in instances) {
//       const innoboardCriteria = instances[innoboardCritKey];
//       newInstances[innoboardCritKey] = { ...innoboardCriteria };
//       if (innoboardCriteria.archivedOn) {
//         newInstances[innoboardCritKey].order = innoboardCriteria.order + 1;
//       }
//     }
//   }
//   return {
//     ...project,
//     innoBoards: {
//       ...project.innoBoards,
//       [innoboardId]: {
//         ...project.innoBoards[innoboardId],
//         model: {
//           ...project.innoBoards[innoboardId].model,
//           version: {
//             ...project.innoBoards[innoboardId].model.version,
//             criteria: newCriteria,
//           },
//         },
//       },
//     },
//   };
// }

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_PROJECT_BY_EVALUATION: return errorEntity(state, action);
    case FETCH_PROJECT_BY_EVALUATION: return fetchEntity(state, action);
    case RECEIVE_PROJECT_BY_EVALUATION: return receiveEntity(state, action);

    case ERROR_PROJECT_BY_PHASE: return errorEntity(state, action);
    case FETCH_PROJECT_BY_PHASE: return fetchEntity(state, action);
    case RECEIVE_PROJECT_BY_PHASE: return receiveEntity(state, action);

    case ERROR_PROJECT_REVIEWS_BY_PHASE: return errorEntity(state, action);
    case FETCH_PROJECT_REVIEWS_BY_PHASE: return fetchEntity(state, action);
    case RECEIVE_PROJECT_REVIEWS_BY_PHASE: return receiveEntity(state, action);

    case ERROR_PROJECT_BOARDS: return errorEntity(state, action);
    case FETCH_PROJECT_BOARDS: return fetchEntity(state, action);
    case RECEIVE_PROJECT_BOARDS: {
      return receiveEntity(state, action);
    }

    case ERROR_PROJECTS: return errorEntities('campaignHasProjects', state, action);
    case FETCH_PROJECTS: return fetchEntities('campaignHasProjects', state, action);
    case RECEIVE_PROJECTS: return receiveEntities('campaignHasProjects', state, action);

    case ERROR_MANAGERS: return errorEntities('applicationHasManagers', state, action);
    case FETCH_MANAGERS: return fetchEntities('applicationHasManagers', state, action);
    case RECEIVE_MANAGERS: return receiveEntities('applicationHasManagers', state, action);

    case ERROR_ONE_ON_ONE_FOR_PROJECT: return errorEntities('projectHasTimeslots', state, action);
    case FETCH_ONE_ON_ONE_FOR_PROJECT: return fetchEntities('projectHasTimeslots', state, action);
    case RECEIVE_ONE_ON_ONE_FOR_PROJECT: return receiveEntities('projectHasTimeslots', state, action);

    case ERROR_PROJECT_WORKSHOPS: return errorEntities('projectHasWorkshops', state, action);
    case FETCH_PROJECT_WORKSHOPS: return fetchEntities('projectHasWorkshops', state, action);
    case RECEIVE_PROJECT_WORKSHOPS: return receiveEntities('projectHasWorkshops', state, action);
    case CHANGE_INNOBOARD_COLUMNS: {
      const { boardColumns } = action;
      // Construct our new array
      const tempArray = [];
      for (let i = 0; i < boardColumns; i++) {
        tempArray.push(i);
      }
      return {
        ...state,
        selectedSegments: tempArray,
      };
    }

    // TODO: fix ordering of archived segments
    // case CHANGE_INNOBOARD_SEGMENT: {
    //   const { innoboardId, projectId, segment, boardColumns: cols } = action;

    //   const key = serialize({ projectId });
    //   let newProject = state.entities[key];

    //   const segmentAmount = newProject.innoBoards[innoboardId].model.version.segmentAmount;
    //   // If add segment.
    //   if (segmentAmount === segment) {
    //     const { maxOrder } = getMaxOrderInnoboardCriteria(newProject, innoboardId);
    //     // There are archived segments.
    //     if (maxOrder !== -1 && maxOrder < segment) {
    //       // Move all archived criteria to the back.
    //       newProject = incrementOrderArchivedInnoboardCriteria(state.entities[key], innoboardId);
    //     }
    //   }

    //   return {
    //     ...state,
    //     entities: {
    //       ...state.entities,
    //       [key]: newProject,
    //     },
    //     isFiltering: false,
    //     selectedSegments: createNewlySelectedInnoboardSegmentArray(segment, cols),
    //   };
    // }

    case CHANGE_INNOBOARD_SEGMENT: {
      const { segment, boardColumns: cols } = action;

      // const segmentAmount = innoBoard.model.version.segmentAmount;
      // If add segment.
      // if (segmentAmount === segment) {
      //   const { maxOrder } = getMaxOrderInnoboardCriteria(innoBoard);
      //   // There are archived segments.
      //   if (maxOrder !== -1 && maxOrder < segment) {
      //     // Move all archived criteria to the back.
      //     newProject = incrementOrderArchivedInnoboardCriteria(state.entities[key], innoboardId);
      //   }
      // }

      return {
        ...state,
        isFiltering: false,
        selectedSegments: createNewlySelectedInnoboardSegmentArray(segment, cols),
      };
    }

    case SELECT_FILTER_SEGMENT: {
      if (state.selectedSegments.includes(action.segment)) {
        return {
          ...state,
          isFiltering: true,
          selectedSegments: state.selectedSegments.filter(s => s !== action.segment),
        };
      }

      if (state.selectedSegments.length === 3) {
        const [, ...restSelectedSegments] = state.selectedSegments;
        restSelectedSegments.push(action.segment);
        return {
          ...state,
          selectedSegments: restSelectedSegments.sort(),
          isFiltering: true,
        };
      }
      return {
        ...state,
        isFiltering: true,
        selectedSegments: ([...state.selectedSegments, action.segment]).sort(),
      };
    }

    case RESET_INNOBOARD_FILTERS: {
      return {
        ...state,
        isFiltering: false,
        selectedSegments: initialState.selectedSegments,
      };
    }

    default: return state;
  }
};
