import { ALL, SELECTED, PROJECT_TYPE_MAPPING } from '../constants';

export const filter = (cards, managerId, type, state) => {
  if (!cards) { return null; }
  const filteredCards = cards.filter(card => (managerId === ALL || card.project.managers.find(({ id }) => id === managerId)) &&
  (type === ALL || card.project.projectTypeId === type) &&
  (!state || card.project.state === state));
  return filteredCards;
};

export const getCategoriesOptions = (data) => {
  const mapping = {};
  data.forEach(el => {
    const { categories } = el.project;
    categories.forEach(v => {
      if (!mapping[v.id]) {
        mapping[v.id] = v.title;
      }
    });
  });
  return Object
    .keys(mapping)
    .map(key => ({ value: key, label: mapping[key] }));
};

export const getMajorDomainsOptions = (data) => {
  const mapping = {};
  data.forEach(el => {
    const { majorDomain } = el.project;
    if (!mapping[majorDomain]) {
      if (majorDomain) {
        mapping[majorDomain] = majorDomain;
      }
      else {
        mapping.NO_DOMAIN = 'No Domain';
      }
    }
  });
  return Object
    .keys(mapping)
    .map(key => ({ value: key, label: mapping[key] }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getStatusOptions = (data) => {
  const mapping = {};
  data.forEach(el => {
    const { status, state } = el.project;
    const projectStatus = status || state;
    if (!mapping[projectStatus]) {
      mapping[projectStatus] = projectStatus;
    }
  });
  return Object
    .keys(mapping)
    .map(key => ({ value: key, label: PROJECT_TYPE_MAPPING[mapping[key]] || mapping[key] }));
};

export const selectInitialDropdownValues = (options, excludedValues) => options.reduce((v, o) => {
  if (excludedValues && excludedValues.includes(o.value)) {
    return v;
  }
  v[o.value] = SELECTED;
  return v;
}, {});

export const filterCategories = (project, selectedCategories) => {
  const categoryIds = project.categories.map(v => v.id);
  let found = false;
  const selectedCategoryIds = Object.keys(selectedCategories);
  selectedCategoryIds.forEach(sId => {
    categoryIds.forEach(id => {
      if (sId === id) {
        found = true;
      }
    });
  });
  return found;
};

export const filterDomains = (project, selectedDomains) => {
  const domain = project.majorDomain || 'NO_DOMAIN';
  let found = false;
  const selectedDomainIds = Object.keys(selectedDomains);
  selectedDomainIds.forEach(id => {
    if (id === domain) {
      found = true;
    }
  });
  return found;
};

export const filterStatuses = (status, selectedStatuses) => {
  let found = false;
  const statusesNames = Object.keys(selectedStatuses);
  statusesNames.forEach(s => {
    if (s === status) {
      found = true;
    }
  });
  return found;
};
