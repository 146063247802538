import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ListItem from '../lists/ListItem';

const StyledListItem = styled(ListItem)`
  box-shadow: ${({ theme }) => theme.boxShadow};
  width: 600px;
`;

const ItemPreview = ({ title }) => <StyledListItem text={title} />;

ItemPreview.propTypes = {
  title: PropTypes.string.isRequired,
};

export default React.memo(ItemPreview);
