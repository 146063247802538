import { UPDATE_SALESFORCE_SYNC_STATUS } from '../constants';

const initialState = {
  status: 'INITIAL',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SALESFORCE_SYNC_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }
    default:
      return state;
  }
};
