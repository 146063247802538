import React, { Component } from 'react';
import PropTypes from 'prop-types';
import useClickOutside from 'react-cool-onclickoutside';

export const NewClickOutside = React.forwardRef((props, ref) => {
  const localRef = React.useRef();
  const componentRef = ref || localRef;

  useClickOutside(
    props.onClickOutside,
    {
      ignoreClass: props.classToIgnore,
      refs: [componentRef],
    },
  );

  return (<div className={props.className} ref={componentRef}>{props.children}</div>);
});

NewClickOutside.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classToIgnore: PropTypes.string,
  onClickOutside: PropTypes.func.isRequired,
};

NewClickOutside.defaultProps = {
  className: '',
  classToIgnore: 'ignore-click',
};

NewClickOutside.displayName = 'ClickOutsideWrapper';

class ClickOutside extends Component {

  // onMount startListening on mousedown so we can simulate our clickOutside
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  // Unbind our mousedown event so we don't crop up our eventListeners
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  /**
   * @function
   * @return {Object} The wrapper ref
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    const { wrapperRef } = this.props;
    if (!wrapperRef) {
      this.wrapperRef = node;
    }
  }

  /**
   * @function
   * @param {Object} event the current event to be handled
   * Will on other than ref target close our container
   */
  handleClickOutside = ({ target }) => {
    const { clickOutside, ignoreClassNames } = this.props;
    // When not given a ref will use the own ref it generates from wrapping the children
    const wrapperRef = this.props.wrapperRef || this.wrapperRef;
    if (wrapperRef && !wrapperRef.contains(target)) {
      if (ignoreClassNames && ignoreClassNames.length > 0 && target.className && target.className.includes && ignoreClassNames.find(ignoreClassName => target.className.includes(ignoreClassName))) {
        return;
      }
      clickOutside();
    }
  }

  render() {
    const { children, styles, className } = this.props;
    return (
      <div ref={this.setWrapperRef} className={className} {...styles}>
        {children}
      </div>
    );
  }

}

ClickOutside.propTypes = {
  // The children that will be passed to our Container
  children: PropTypes.node.isRequired,
  className: PropTypes.string,

  // What happens when the user clicks outside
  clickOutside: PropTypes.func.isRequired,
  // The class names of the element which we want to ignore for clicks outside.
  ignoreClassNames: PropTypes.arrayOf(PropTypes.string),
  styles: PropTypes.any,

  // An optional ref to an outer div
  wrapperRef: PropTypes.any,

};

export default ClickOutside;
