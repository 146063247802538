import React from 'react';
import PropTypes from 'prop-types';
import { prevent } from '@innovatrix/utils';
import styled from '@emotion/styled';
import { withRouter } from 'react-router';

const LIGHT = 'light';
const DARK = 'dark';

const NavItemLi = styled.li`
  display: block;
  float: left;
  margin-right: 32px;
  position: relative;

  > a {
    border-bottom: 2px solid transparent;
    color: ${({ theme, flavor }) => (flavor === LIGHT ? theme.colors.white : theme.colors.secondary)};
    padding: 0 0 10px 0;
    text-decoration: none;

    &::after {
      border-bottom: 1px solid ${({ theme, flavor }) => (flavor === LIGHT ? theme.colors.white : theme.colors.secondary)};
      content: "";
      display: block;
      margin-bottom: -10px;
      padding-bottom: 6px;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  &.action {
    > a {
      color: ${({ theme }) => theme.colors.primary};

      &::after {
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
      }
    }

    &.selected a {
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }

  &.active, &.selected {
    > a {
      border-bottom: 2px solid ${({ theme, flavor }) => (flavor === LIGHT ? theme.colors.primary : theme.colors.secondary)};
      ${({ theme, isIndex, flavor }) => ((flavor === DARK && isIndex) ? `border-bottom: 2px solid ${theme.colors.primary};` : '')}
      font-weight: bold;

      &::after {
        border-bottom: 0;
      }
    }
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 12px;
`;

const Icon = ({ icon }) => <IconWrapper>{icon}</IconWrapper>;

Icon.propTypes = { icon: PropTypes.node.isRequired };

const Anchor = styled.a`
  display: inline-block !important;
`;

const NavItem = withRouter(({ className, active, onClick, href, name, disabled, flavor, url, history, isIndex, icon }) => {
  const HASH_TAG = '#';
  const link = href || url || HASH_TAG;

  const handleClick = React.useCallback((e) => {
    prevent(e);
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick();
    }

    if (link && link !== HASH_TAG) {
      history.push(link);
    }
  }, [disabled, history, link, onClick]);
  return (
    <NavItemLi flavor={flavor} role="presentation" className={`${className} ${active ? '.active' : ''}`} isIndex={isIndex}>
      <Anchor href={link} onClick={handleClick}>{icon && (<Icon icon={icon} />)}{name}</Anchor>
    </NavItemLi>
  );
});

NavItem.defaultProps = {
  active: false,
  className: '',
  disabled: false,
  isIndex: false,
  name: '',
  onClick: undefined,
  url: '',
  icon: null,
};

NavItem.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  flavor: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.node,
  isIndex: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
};

const MenuBarItem = ({ children, ...props }) => (
  <NavItem {...props}>{children}</NavItem>
);

MenuBarItem.defaultProps = { flavor: LIGHT };
MenuBarItem.propTypes = { children: PropTypes.node, flavor: PropTypes.oneOf([LIGHT, DARK]) };

export default React.memo(MenuBarItem);
