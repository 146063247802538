import { keyframes } from '@emotion/react';

import {
  ASSUMPTION_YELLOW,
  CATEGORIES_BG,
  CATEGORIES_OUTLINE,
  DANGER,
  DECISION_GO_LIGHT,
  DECISION_GO,
  FOCUS,
  GREY_1,
  GREY_2,
  GREY_BLUEISH,
  GREY_LIGHT_1,
  GREY_LIGHT_2,
  GREY_LIGHT_3,
  MAIN_BG,
  MAIN_BORDER,
  MAIN_PAGE,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  PRIMARY,
  PURPLE,
  REVIEW_CONDITIONS,
  REVIEW_CONDITIONS_BG,
  REVIEW_GO,
  REVIEW_GO_BG,
  REVIEW_MAYBE,
  REVIEW_MAYBE_BG,
  REVIEW_NOGO,
  REVIEW_NOGO_BG,
  SECONDARY,
  TURQUOISE_DARK,
  TURQUOISE_LIGHT,
  TURQUOISE,
  WARNING_DARK,
  WARNING_LIGHT,
  WARNING,
  WHITE,
} from './colors';

import { FOCUS_TRANSPARENT } from '.';

const FONT = 'Raleway';

export default {
  animation: {
    pulse: keyframes`
      0% {
        box-shadow: 0 0 0 0 rgba(63, 152, 189, 0.4);
      }
      70% {
        box-shadow: 0 0 0 15px rgba(63, 152, 189, 0);
      }
      100% {
        box-shadow: 0 0 0 15px rgba(63, 152, 189, 0);
      }
    `,
    pulseWithColor: (r = 63, g = 152, b = 189) => keyframes`
      0% {
        box-shadow: 0 0 0 0 rgba(${r}, ${g}, ${b}, 0.5);
      }
      70%, 100% {
        box-shadow: 0 0 0 15px rgba(63, 152, 189, 0);
      }
    `,
  },
  block: `
    background-color: ${WHITE};
    border-radius: 3px;
    border: 1px solid ${GREY_1};
  `,
  blockLight: `
    background-color: ${WHITE};
    border-radius: 3px;
    border: 1px solid ${GREY_LIGHT_3};
  `,
  border: {
    color: GREY_LIGHT_3,
    radius: '3px',
  },
  boxShadow: '0 0 10px 0 rgba(0,0,0,0.30)',
  // @supports CSS rule checks if the CSS rule is supported by the browser
  // @supports not CSS rule checks if the CSS rule is not supported by the browser
  clamp: ({ lines, lineHeight }) => `
    @supports (-webkit-line-clamp: ${lines}) {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${lines};
      display: -webkit-box;
      overflow: hidden;
    }

    @supports not (-webkit-line-clamp: ${lines}) {
      line-height: ${lineHeight}px;
      max-height: calc(${lines} * ${lineHeight}px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: no-wrap;
    }
  `,
  colors: {
    assumptionYellow: ASSUMPTION_YELLOW,
    background: MAIN_BG,
    border: MAIN_BORDER,
    categoriesBackground: CATEGORIES_BG,
    categoriesOutline: CATEGORIES_OUTLINE,
    danger: DANGER,
    focus: FOCUS,
    focusTransparent: FOCUS_TRANSPARENT,
    green: DECISION_GO,
    greenLight: DECISION_GO_LIGHT,
    grey1: GREY_1,
    grey2: GREY_2,
    greyBlueish: GREY_BLUEISH,
    greyLight1: GREY_LIGHT_1,
    greyLight2: GREY_LIGHT_2,
    greyLight3: GREY_LIGHT_3,
    page: MAIN_PAGE,
    primary: PRIMARY,
    primaryDark: PRIMARY_DARK,
    primaryLight: PRIMARY_LIGHT,
    primaryLightest: '#C6EDFE',
    purple: PURPLE,
    reviewConditions: REVIEW_CONDITIONS,
    reviewConditionsBackground: REVIEW_CONDITIONS_BG,
    reviewGo: REVIEW_GO,
    reviewGoBackground: REVIEW_GO_BG,
    reviewMaybe: REVIEW_MAYBE,
    reviewMaybeBackground: REVIEW_MAYBE_BG,
    reviewNoGo: REVIEW_NOGO,
    reviewNoGoBackground: REVIEW_NOGO_BG,
    secondary: SECONDARY,
    selectionBlue: '#3A7CFE',
    transparent: 'transparent',
    turquoise: TURQUOISE,
    turquoiseDark: TURQUOISE_DARK,
    turquoiseLight: TURQUOISE_LIGHT,
    warning: WARNING,
    warningDark: WARNING_DARK,
    warningLight: WARNING_LIGHT,
    white: WHITE,
  },
  gradient: {
    blue: `linear-gradient(90deg, ${TURQUOISE_LIGHT} 0%, ${PRIMARY} 100%)`,
    grey: `linear-gradient(90deg, ${GREY_LIGHT_3} 0%, ${GREY_1} 100%)`,
    progression: `linear-gradient(90deg, ${PRIMARY_LIGHT} 0%, #3cf180 100%)`,
    red: `linear-gradient(90deg, ${WARNING_LIGHT} 0%, ${WARNING} 100%)`,
    regression: `linear-gradient(90deg, ${WARNING} 0%, ${PRIMARY_LIGHT} 100%)`,
    whiteBlue: `linear-gradient(to right, ${WHITE} 0%, ${WHITE} 50%, ${GREY_BLUEISH} 50%, ${GREY_BLUEISH} 100%)`,
  },
  overlay: {
    purple: 'rgba(54,51,125,0.5)',
    red: 'rgba(208,2,27,0.5)',
  },
  font: {
    family: FONT,
  },
  rows: (columns) => `
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(${columns}, 1fr)
  `,
  scrollbar: {
    small: `
      ::-webkit-scrollbar {
        width: 10px;
      };
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${SECONDARY};
        border: 4px solid rgba(0, 0, 0, 0);
      };
    `,
    large: `
      ::-webkit-scrollbar {
        width: 12px;
      };
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${SECONDARY};
        border: 10px solid rgba(0, 0, 0, 0);
      };
  `,
  },
  noData: {
    page: `
      margin-bottom: 100px;
      margin-top: 100px;
      text-align: center;
    `,
    component: `
      text-align: center;
    `,
  },
  effect: {
    ripple: `
      overflow: hidden;
      position: relative;

      &::after {
        border-radius: 100%;
        content: "";
        height: 6px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform-origin: 50% 50%;
        transform: scale(1,1) translate(-50%);
        width: 6px;
      }

      &:focus::after {
        animation: ripple 1s ease-out;
      }
    `,
  },
};
