import * as React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const LinkIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 14 13" {...styles} {...props}>
    <path d="M7 1C7.4.6 8 .2 8.6.1s1.2-.2 1.8 0c.6.2 1.2.5 1.6.9s.8 1 .9 1.6c.2.6.2 1.2 0 1.8S12.5 5.6 12 6l-2 2-1-1 2-2c.3-.3.5-.6.6-1s.1-.8 0-1.1-.3-.7-.6-1c-.3-.3-.6-.5-1-.6-.3 0-.7 0-1 .1-.4.1-.7.3-1 .6L6 4 5 3l2-2zM5.5 8.5l-1-1 4-4 1 1-4 4zM3 10.1c.3.3.6.5 1 .6s.8.1 1.1 0c.4-.1.7-.3 1-.6l2-2 1 1-2 2c-.4.4-1 .8-1.6.9-.6.2-1.2.2-1.8 0-.7-.2-1.2-.5-1.7-.9s-.8-1-.9-1.6c-.2-.6-.2-1.2 0-1.8s.4-1.2.9-1.6l2-2 1 1L3 7c-.3.3-.5.6-.6 1-.1.4-.1.8 0 1.1.1.4.3.7.6 1z" />
  </svg>
);

LinkIcon.propTypes = propTypes;
LinkIcon.defaultProps = defaultProps;

export default LinkIcon;
