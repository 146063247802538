import { put, take } from 'redux-saga/effects';

import {
  removeModalAction,
  showErrorModalAction,
} from '../actions/dxModalActions';
import { DX_MODAL_REMOVE } from '../constants';

// -- Modal Helpers --------------- ---  --  -

/**
 * Close the modal popup with the given id.
 * @param {string} id - The ID of the modal popup to remove.
 */
export function* removeModal(id) {
  yield put(removeModalAction(id));
}

/**
 * Show an error modal and return when the modal is closed.
 * @param {DxErrorArgument} error - See `@innovatrix/utils/dx/DxError`.
 * @param {object} [options] - Options injected in the props of the `ErrorModal` component.
 * @param {string} [id] - The modal ID to use, in case you need to close the modal programmatically.
 * @example
 * import { showErrorModal } from 'react-frontend/sagas';
 * function* mySaga() {
 *   yield showErrorModal('Something went wrong.');
 *   console.info('closed');
 * }
 */
export function* showErrorModal(error, options, id) {
  yield put(showErrorModalAction(error, options));
  yield take((action) => action.type === DX_MODAL_REMOVE && id === action.id);
}
