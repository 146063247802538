import { COMPLETE_MUTATION, FETCH_QUERY, RECEIVE_QUERY, SEND_MUTATION } from '../constants';

export const fetchQueryAction = ({ query, namespace, variables, isDxApi, url }) => ({
  isDxApi,
  namespace,
  query,
  type: FETCH_QUERY,
  url,
  variables,
});

export const receiveQueryAction = ({ data, error, query, namespace, variables, url }) => ({
  data,
  error,
  query,
  namespace,
  variables,
  url,
  type: RECEIVE_QUERY,
});

export const completeMutationAction = ({ data, error, mutation, namespace, variables, refetchQueries }) => ({
  data,
  error,
  mutation,
  namespace,
  type: COMPLETE_MUTATION,
  variables,
  refetchQueries,
});

export const sendMutationAction = ({ mutation, namespace, variables, refetchQueries, isDxApi }) => ({
  mutation,
  namespace,
  isDxApi,
  type: SEND_MUTATION,
  variables,
  refetchQueries,
});
