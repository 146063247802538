import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useReduxModule from '@innovatrix/common/utils/useReduxModule';
import { fetchCampaignAction, fetchProjectsAction, fetchManagersAction } from '@innovatrix/actions';

import LoadingScreen from './components/LoadingScreen';
import { cardsReducer, projectsReducer } from './reducers';
import projectsSagas from './sagas';

const CardsWrapper = ({ fetchManagers, fetchProjects, fetchCampaign, phaseId, campaignId, ...props }) => {
  const loader = import(/* webpackChunkName="cards" */'./pages');
  const Module = useReduxModule(
    'cards',
    projectsSagas,
    [{ key: 'cards', reducer: cardsReducer }, { key: 'projects', reducer: projectsReducer }],
    [fetchManagers, campaignId && fetchCampaign.bind(undefined, { campaignId }), fetchProjects.bind(undefined, { campaignId, phaseId })].filter(Boolean),
    loader,
  );
  return Module ? <Module {...props} /> : <LoadingScreen />;
};

CardsWrapper.propTypes = {
  campaignId: PropTypes.string,
  fetchCampaign: PropTypes.func.isRequired,
  fetchManagers: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  phaseId: PropTypes.string,
};

export default connect((state, { match: { params: { campaignId, phaseId } } }) => ({
  campaignId,
  phaseId,
}), {
  fetchCampaign: fetchCampaignAction,
  fetchManagers: fetchManagersAction,
  fetchProjects: fetchProjectsAction,
})(CardsWrapper);
