import { mutationBuilder, queryBuilder } from '@innovatrix/utils/dx';

// -- Queries --------------- --- --  -

export const createInnoBoardCriterionMutation = mutationBuilder(
  {
    variables: {
      innoBoardId: { type: 'String!' },
      innoBoardPhaseId: { type: 'String!' },
      projectId: { type: 'String!' },
      criterionId: { type: 'String!' },
      innoBoardCriterion: { type: 'JSON!' },
    },
    fields: {
      _createInnoBoardCriterion: {
        varArgs: {
          projectId: 'projectId',
          innoBoardId: 'innoBoardId',
          innoBoardPhaseId: 'innoBoardPhaseId',
          criterionId: 'criterionId',
          innoBoardCriterion: 'innoBoardCriterion',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createInnoboardCriterionCommentMutation = mutationBuilder({
  variables: {
    comment: { type: 'String!' },
    innoboardCriterionId: { type: 'String!' },
  },
  fields: {
    _createInnoboardCriterionComment: {
      varArgs: { comment: 'comment', innoboardCriterionId: 'innoboardCriterionId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const getInnoboardCriterionCommentsQuery = queryBuilder({
  variables: {
    innoboardCriterionId: { type: 'String!' },
  },
  fields: {
    innoboardCriterionComments: {
      varArgs: { innoboardCriterionId: 'innoboardCriterionId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateInnoboardCriterionCommentMutation = mutationBuilder({
  variables: {
    comment: { type: 'String!' },
    id: { type: 'String!' },
  },
  fields: {
    _updateInnoboardCriterionComment: {
      varArgs: { comment: 'comment', id: 'id' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const createAssumptionCommentMutation = mutationBuilder({
  variables: {
    comment: { type: 'String!' },
    assumptionId: { type: 'String!' },
  },
  fields: {
    _createAssumptionComment: {
      varArgs: { comment: 'comment', assumptionId: 'assumptionId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const getAssumptionCommentsQuery = queryBuilder({
  variables: {
    assumptionId: { type: 'String!' },
  },
  fields: {
    assumptionComments: {
      varArgs: { assumptionId: 'assumptionId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateAssumptionCommentMutation = mutationBuilder({
  variables: {
    comment: { type: 'String!' },
    id: { type: 'String!' },
  },
  fields: {
    _updateAssumptionComment: {
      varArgs: { comment: 'comment', id: 'id' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateInnoBoardCriterionMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    innoBoardCriterionId: { type: 'String!' },
    innoBoardCriterion: { type: 'JSON!' },
  },
  fields: {
    _updateInnoBoardCriterion: {
      varArgs: { projectId: 'projectId', innoBoardCriterionId: 'innoBoardCriterionId', innoBoardCriterion: 'innoBoardCriterion' },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const createAssumptionMutation = mutationBuilder({
  variables: {
    assumption: { type: 'JSON!' },
    projectId: { type: 'String!' },
    innoBoardCriterionId: { type: 'String!' },
  },
  fields: {
    _createAssumption: {
      varArgs: { projectId: 'projectId', innoBoardCriterionId: 'innoBoardCriterionId', assumption: 'assumption' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const copyAssumptionsMutation = mutationBuilder({
  variables: {
    assumptionIds: { type: 'JSON!' },
    criterionId: { type: 'String!' },
    innoBoardCriterionId: { type: 'String' },
    innoBoardPhaseId: { type: 'String!' },
    order: { type: 'Int!' },
    segmentTitle: { type: 'String' },
  },
  fields: {
    _copyAssumptions: {
      varArgs: {
        assumptionIds: 'assumptionIds',
        criterionId: 'criterionId',
        innoBoardCriterionId: 'innoBoardCriterionId',
        innoBoardPhaseId: 'innoBoardPhaseId',
        order: 'order',
        segmentTitle: 'segmentTitle',
      },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const moveAssumptionsMutation = mutationBuilder({
  variables: {
    assumptionIds: { type: 'JSON!' },
    criterionId: { type: 'String!' },
    innoBoardCriterionId: { type: 'String' },
    innoBoardPhaseId: { type: 'String!' },
    order: { type: 'Int!' },
    segmentTitle: { type: 'String' },
  },
  fields: {
    _moveAssumptions: {
      varArgs: {
        assumptionIds: 'assumptionIds',
        criterionId: 'criterionId',
        innoBoardCriterionId: 'innoBoardCriterionId',
        innoBoardPhaseId: 'innoBoardPhaseId',
        order: 'order',
        segmentTitle: 'segmentTitle',
      },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const deleteSegmentMutation = mutationBuilder({
  variables: {
    innoBoardId: { type: 'String!' },
    innoBoardPhaseId: { type: 'String!' },
    isSoftDelete: { type: 'Boolean!' },
    segmentId: { type: 'String!' },
  },
  fields: {
    _deleteSegment: {
      varArgs: { innoBoardId: 'innoBoardId', innoBoardPhaseId: 'innoBoardPhaseId', isSoftDelete: 'isSoftDelete', segmentId: 'segmentId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const undoArchiveSegmentMutation = mutationBuilder({
  variables: {
    innoBoardId: { type: 'String!' },
    innoBoardPhaseId: { type: 'String!' },
    segmentId: { type: 'String!' },
  },
  fields: {
    _undoArchiveSegment: {
      varArgs: { innoBoardId: 'innoBoardId', innoBoardPhaseId: 'innoBoardPhaseId', segmentId: 'segmentId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const deleteAssumptionsMutation = mutationBuilder({
  variables: {
    assumptions: { type: '[JSON]!' },
    isSoftDelete: { type: 'Boolean' },
  },
  fields: {
    _deleteAssumptions: {
      varArgs: { assumptions: 'assumptions', isSoftDelete: 'isSoftDelete' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const deleteAssumptionMutation = mutationBuilder({
  variables: {
    assumptionId: { type: 'String!' },
    isSoftDelete: { type: 'Boolean!' },
  },
  fields: {
    _deleteAssumption: {
      varArgs: { assumptionId: 'assumptionId', isSoftDelete: 'isSoftDelete' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const undoArchiveAssumptionMutation = mutationBuilder({
  variables: {
    assumptionId: { type: 'String!' },
  },
  fields: {
    _undoArchiveAssumption: {
      varArgs: { assumptionId: 'assumptionId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const duplicateBoardMutation = mutationBuilder({
  variables: {
    innoBoard: { type: 'JSON!' },
    eventIds: { type: '[String]' },
    clarification: { type: 'String' },
  },
  fields: {
    duplicateInnoBoard: {
      varArgs: { innoBoard: 'innoBoard', eventIds: 'eventIds', clarification: 'clarification' },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const updateAssumptionMutation = mutationBuilder({
  variables: {
    assumption: { type: 'JSON!' },
    projectId: { type: 'String!' },
  },
  fields: {
    _updateAssumption: {
      varArgs: { assumption: 'assumption', projectId: 'projectId' },
      fields: {
        error: true,
        result: true,
        success: true,
      },
    },
  },
});

export const updateAssumptionsMutation = mutationBuilder({
  variables: {
    assumptions: { type: '[JSON]!' },
  },
  fields: {
    _updateAssumptions: {
      varArgs: { assumptions: 'assumptions' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const getRelatedAssumptionsQuery = queryBuilder({
  variables: {
    assumptionId: { type: 'String!' },
    innoBoardPhaseId: { type: 'String!' },
  },
  fields: {
    _getRelatedAssumptions: {
      varArgs: {
        assumptionId: 'assumptionId',
        innoBoardPhaseId: 'innoBoardPhaseId',
      },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const addRelatedAssumptionMutation = mutationBuilder({
  variables: {
    assumptionId: { type: 'String!' },
    relatedAssumptionId: { type: 'String!' },
  },
  fields: {
    _addRelatedAssumption: {
      varArgs: { assumptionId: 'assumptionId', relatedAssumptionId: 'relatedAssumptionId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const getCriterionTemplateAssumptionsQuery = queryBuilder({
  variables: {
    criterionId: { type: 'String!' },
  },
  fields: {
    _getCriterionTemplateAssumptions: {
      varArgs: {
        criterionId: 'criterionId',
      },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const deleteRelatedAssumptionMutation = mutationBuilder({
  variables: {
    assumptionId: { type: 'String!' },
    relatedAssumptionId: { type: 'String!' },
  },
  fields: {
    _deleteRelatedAssumption: {
      varArgs: { assumptionId: 'assumptionId', relatedAssumptionId: 'relatedAssumptionId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});
