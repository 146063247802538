import * as React from 'react';
import { fetchQueryAction } from '@innovatrix/actions';
import { getQueryDataFactorySelector } from '@innovatrix/selectors/genericSelectors';
import { connect } from 'react-redux';

// Guidelines for optimal performance
// If you always pass in a fresh object as variables
// You will keep querying new data so DONT
// This can be done through useMemo
// Another option would be to pass an argument just like the second
// argument of useEffect to this Render Props func.
const Query = ({ children, data, fetchQuery, query, namespace, variables, skip, url, isDxApi }) => {
  // A reference to prevent state updates on unmounted components
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  const [counter, setCounter] = React.useState(0);
  React.useEffect(() => {
    if (!skip && isMounted.current) {
      fetchQuery({ query, namespace, variables, url, isDxApi });
    }
    // We do a shallow equal check here.
  }, [...Object.values(variables || {}), counter, skip]); // eslint-disable-line
  const refetch = React.useCallback(() => (isMounted.current ? setCounter(i => i + 1) : null), [setCounter]);
  return React.useMemo(() => (isMounted.current ? children(data, refetch) : null), [children, data, refetch]);
};

// Selector factory for optimal performance.
// This ensures that every Query gets a personalized
// memoized selector.
const makeMapState = () => {
  const selectItemForThisComponent = getQueryDataFactorySelector();
  return (state, { namespace, variables, skip }) => ({
    data: selectItemForThisComponent(state, { namespace, variables, skip }),
  });
};

export default connect(makeMapState, {
  fetchQuery: fetchQueryAction,
})(Query);
