import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import OtherIcon from './OtherIcon';
import { defaultProps, propTypes } from './defaultPropTypes';

const Loader = () => <OtherIcon />;
const DocIcon = loadable(() => import('./DocIcon'), {
  fallback: <Loader />,
});
const ExcelIcon = loadable(() => import('./ExcelIcon'), {
  fallback: <Loader />,
});
const ImageIcon = loadable(() => import('./ImageIcon'), {
  fallback: <Loader />,
});
const PdfIcon = loadable(() => import('./PdfIcon'), {
  fallback: <Loader />,
});
const PowerPointIcon = loadable(() => import('./PowerPointIcon'), {
  fallback: <Loader />,
});

const ICON_MAP = {
  doc: DocIcon,
  'application/vnd.oasis.opendocument.text': DocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocIcon,
  'application/msword': DocIcon,
  docx: DocIcon,
  document: DocIcon,
  'application/vnd.ms-excel': ExcelIcon,
  'application/x-msi': ExcelIcon,
  'application/vnd.oasis.opendocument.spreadsheet': ExcelIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ExcelIcon,
  xls: ExcelIcon,
  xlsx: ExcelIcon,
  numbers: ExcelIcon,
  'image/png': ImageIcon,
  'image/gif': ImageIcon,
  'image/jpeg': ImageIcon,
  png: ImageIcon,
  gif: ImageIcon,
  jpg: ImageIcon,
  jpeg: ImageIcon,
  psd: ImageIcon,
  tiff: ImageIcon,
  ai: ImageIcon,
  'application/pdf': PdfIcon,
  pdf: PdfIcon,
  ppt: PowerPointIcon,
  pptx: PowerPointIcon,
  'application/vnd.ms-powerpoint': PowerPointIcon,
  'application/vnd.oasis.opendocument.presentation': PowerPointIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': PowerPointIcon,
  presentation: PowerPointIcon,
};

const DocumentIcon = ({ fileName, type, ...iconProps }) => {
  const Component = React.useMemo(() => {
    if (type && ICON_MAP[type]) {
      return ICON_MAP[type];
    }

    if (fileName) {
      const chunks = fileName.split('.');
      const extension = chunks[chunks.length - 1];

      if (extension && ICON_MAP[extension]) {
        return ICON_MAP[extension];
      }
    }
    return OtherIcon;
  }, [fileName, type]);

  return <Component {...iconProps} />;
};

DocumentIcon.propTypes = {
  ...propTypes,
  fileName: PropTypes.string,
  type: PropTypes.string,
};

DocumentIcon.defaultProps = defaultProps;

export default DocumentIcon;
