import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import PersistProjectModal from '@innovatrix/common/PersistProjectModal';
import { getApplicationMetaData } from '@innovatrix/selectors';
import graphql from '@innovatrix/common/graphql';
import { dxAuthSignOutAction } from '@innovatrix/react-frontend/actions';
import { Button } from '@innovatrix/common/buttons';
import { Title } from '@innovatrix/common/text';

import { createProjectViaRegistrationFlowMutation } from '../cards/queries';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  margin-left: 32px;
  margin-right: 64px;
  margin-top: 95px;

  @media (max-width: 900px) {
    margin-top: 16px;
  }
`;

const CreateProjectOnRegistrationRoute = ({ applicationMetaData, createProject, signOut }) => {
  const [step, setStep] = React.useState(0);
  const { t } = useTranslation(['main', 'intakes']);

  const { unfinishedRegistrations } = applicationMetaData;
  const [firstUnfinishedRegistration] = unfinishedRegistrations;

  if (!firstUnfinishedRegistration) {
    return <Redirect to="/" />;
  }

  const { campaignId, firstPhaseId } = firstUnfinishedRegistration;

  const onClose = () => {};

  const onSignOut = () => {
    signOut('/');
  };

  const onSuccessCallback = () => {
    setStep(s => s + 1);
  };

  if (step === 1) {
    return (
      <Container>
        <Title size="h3">
          {t('intakes:ui.finalize.text')}
        </Title>
        <Button
          flavor="primary"
          label={t('intakes:ui.finalize.cta')}
          onClick={onSignOut}
        />
      </Container>
    );
  }

  return (
    <PersistProjectModal
      campaignId={campaignId}
      hideCloseButton
      onClose={onClose}
      onSuccessCallback={onSuccessCallback}
      persist={createProject}
      phaseId={firstPhaseId}
    />
  );
};

CreateProjectOnRegistrationRoute.propTypes = {
  applicationMetaData: PropTypes.object.isRequired,
  createProject: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(undefined, {
  signOut: dxAuthSignOutAction,
})(graphql({
  name: 'createProject',
  namespace: '_createProjectViaRegistrationFlow',
  mutation: createProjectViaRegistrationFlowMutation,
})(connect((state) => ({
  applicationMetaData: getApplicationMetaData(state),
}))(CreateProjectOnRegistrationRoute)));
