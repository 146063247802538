import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { push } from 'connected-react-router';
import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchAssessmentVersionsAction, persistAssessmentVersionAction, receiveActiveAssessmentVersionAction, errorActiveAssessmentVersionAction, persistSurveyAction, fetchProjectOverviewAction, fetchProjectByEvaluationAction, errorAssessmentVersionsAction, receiveAssessmentVersionsAction, fetchActiveAssessmentVersionAction, closeModalAction, errorAssessmentVersionAction, receiveAssessmentVersionAction } from '../actions';
import { SCORE_TAB, ASSESSMENT_TAB, FETCH_ACTIVE_ASSESSMENT_VERSION, FETCH_ASSESSMENT_VERSIONS, INTRODUCTION, PERSIST_SURVEY_2, FETCH_ASSESSMENT_VERSION } from '../constants';
import { persistSurveyMutation, createAssessmentVersionMutation, updateAssessmentVersionMutation, getActiveAssessmentVersionQuery, getAssessmentVersionsQuery, getAssessmentVersionQuery } from '../queries';
import { hasAnswer } from '../pages/assessmentModal/_utils';

import * as api from './_api';
import { fetchEntitiesSaga } from './_utils';

function transformQuestionGroup(group) {
  // When the type is new we return it without id and type so our backend knows it's time to create a new entity.
  if (group.type === 'new') { return { title: group.title }; }
  return {
    id: group.value,
    title: group.title,
  };
}

/**
 * @name fetchEvaluationsSaga
 */
export default function* () {
  // TODO: should move
  yield takeEvery(FETCH_ASSESSMENT_VERSIONS, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorAssessmentVersionsAction,
    errorMessage: 'Failed to fetch the assessment versions',
    keyword: '_getAssessmentVersions',
    query: getAssessmentVersionsQuery,
    receiveAction: receiveAssessmentVersionsAction,
  }));

  yield takeEvery(FETCH_ASSESSMENT_VERSION, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorAssessmentVersionAction,
    errorMessage: 'Failed to fetch the assessment version',
    keyword: '_getAssessmentVersion',
    query: getAssessmentVersionQuery,
    receiveAction: receiveAssessmentVersionAction,
  }));

  yield takeEvery(FETCH_ACTIVE_ASSESSMENT_VERSION, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorActiveAssessmentVersionAction,
    errorMessage: 'Failed to fetch the assessment version',
    keyword: '_getActiveAssessmentVersion',
    query: getActiveAssessmentVersionQuery,
    receiveAction: receiveActiveAssessmentVersionAction,
  }));

  yield takeEvery(PERSIST_SURVEY_2, function* ({
    index, assessmentVersionId, evaluationId, projectId, questions, addEvaluationAsReviewer,
    persistAllQuestions, phaseId, setEvaluationAsSubmitted, callback,
  }) {
    try {
      const filledInQuestions = persistAllQuestions
        // In case we want to persist all questions at once, we only remove the INTRODUCTION question types
        ? questions
          .filter(q => q.type !== INTRODUCTION)
        // In case we only want to persist the questions that have been filled in
        : questions.slice(0, index + 1)
          .filter(q => q.type !== INTRODUCTION)
          .filter(hasAnswer);

      const newEvaluationId = yield call(api.fetch, '_persistSurvey', persistSurveyMutation, {
        variables: {
          addEvaluationAsReviewer,
          assessmentVersionId,
          evaluationId,
          phaseId,
          projectId,
          questions: filledInQuestions,
          setEvaluationAsSubmitted: Boolean(setEvaluationAsSubmitted),
        },
      });

      if (callback && typeof callback === 'function') {
        callback(null, newEvaluationId);
      }
    }
    catch (err) {
      if (callback && typeof callback === 'function') {
        callback(err, null);
      }
      yield call(showErrorModal, err, { title: 'Failed to save the survey.' });
    }
  });

  yield takeEvery(persistSurveyAction.REQUEST, function* persistSurvey({
    callback,
    payload: {
      campaignId, index, assessmentVersionId, evaluationId, projectId, questions, addEvaluationAsReviewer,
      persistAllQuestions, phaseId, setEvaluationAsSubmitted,
    },
  }) {
    try {
      const filledInQuestions = persistAllQuestions
        // In case we want to persist all questions at once, we only remove the INTRODUCTION question types
        ? questions
          .filter(q => q.type !== INTRODUCTION)
        // In case we only want to persist the questions that have been filled in
        : questions.slice(0, index + 1)
          .filter(q => q.type !== INTRODUCTION)
          .filter(hasAnswer);
      const newEvaluationId = yield call(api.fetch, '_persistSurvey', persistSurveyMutation, {
        variables: {
          addEvaluationAsReviewer,
          assessmentVersionId,
          evaluationId,
          phaseId,
          projectId,
          questions: filledInQuestions,
          setEvaluationAsSubmitted: Boolean(setEvaluationAsSubmitted),
        },
      });
      if (callback && typeof callback === 'function') {
        callback();
      }
      if (setEvaluationAsSubmitted) {
        yield put(closeModalAction('isSurveyOpen'));
      }
      yield put(fetchActiveAssessmentVersionAction(({ projectId })));
      yield put(fetchProjectOverviewAction({ projectId }));
      // Let's navigate to our newly created evaluation.
      if (evaluationId) {
        yield put(fetchProjectByEvaluationAction({ campaignId, evaluationId, projectId }));
      }
      // Do no redirect when we added a review for a proposal
      else if (newEvaluationId && !addEvaluationAsReviewer) {
        yield put(push(campaignId ?
          `/campaigns/${campaignId}/projects/${projectId}/${SCORE_TAB}/${newEvaluationId}` :
          `/projects/${projectId}/${SCORE_TAB}/${newEvaluationId}`));
      }
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to save the survey.' });
    }
  });
  // TODO: should move
  yield takeEvery(persistAssessmentVersionAction.REQUEST, function* persistAssessmentVersion({ callback, payload }) {
    try {
      const assessmentVersionQuestionGroups = payload.questions
        .filter(q => q.questionGroup)
        .reduce((acc, cur) => {
          const questionGroup = cur.questionGroup;
          // If it exists, do not add it again to the accumulator
          if (acc.find(a => a.questionGroup.id === questionGroup.id)) {
            return acc;
          }
          else {
            acc.push(cur);
            return acc;
          }
        }, [])
        .map(q => transformQuestionGroup(q.questionGroup));
      const newQuestionGroups = payload.questionGroups
        .filter(qg => qg.type === 'new')
        .map(transformQuestionGroup);
      const variables = { assessmentVersion: {
        ...payload,
        questionGroups: [...assessmentVersionQuestionGroups, ...newQuestionGroups],
      } };
      const mutation = payload.id ? updateAssessmentVersionMutation : createAssessmentVersionMutation;
      const keyword = payload.id ? '_updateAssessmentVersion' : '_createAssessmentVersion';
      const assessmentVersionId = yield call(api.fetch, keyword, mutation, { variables });
      callback();
      yield put(fetchAssessmentVersionsAction({ assessmentId: payload.assessmentId }));
      yield put(push(`/manage/${ASSESSMENT_TAB}/${payload.assessmentId}/${payload.id || assessmentVersionId}`));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to create the assessment' });
      callback(err);
    }
  });
}
