import PropTypes from 'prop-types';

import { SECONDARY } from '../../styles';

export const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  styles: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const defaultProps = {
  color: SECONDARY,
  height: 16,
  width: 16,
};
