import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ASSUMPTION_YELLOW, GREY_2, SECONDARY, GREY_LIGHT_3 } from '@innovatrix/styles';
import AssumptionStatusIcon from '@innovatrix/icons/AssumptionStatusIcon';

const assumptionColors = {
  Assumption: ASSUMPTION_YELLOW,
  'New Insight': '#44BDC1',
  Validated: '#94CE58',
  Invalidated: SECONDARY,
  Unclear: GREY_2,
  Unknown: GREY_LIGHT_3,
};

export const getAssumptionColor = status => assumptionColors[status] || assumptionColors.Assumption;

const AssumptionIndicator = styled(AssumptionStatusIcon)`
  background-color: ${({ status }) => getAssumptionColor(status)};
  border-radius: 4px;
  display: inline-block;
  height: 20px;
  min-width: 20px;
  width: 20px;
`;

const AssumptionStatus = ({ prevStatus, status }) => (
  <AssumptionIndicator
    newStatusColor={getAssumptionColor(status)}
    oldStatusColor={getAssumptionColor(prevStatus || status)}
  />
);

AssumptionStatus.propTypes = {
  prevStatus: PropTypes.string,
  status: PropTypes.string,
};

export default AssumptionStatus;
