export const FETCH_WORKSHOPS = 'innovatrix/FETCH_WORKSHOPS';
export const RECEIVE_WORKSHOPS = 'innovatrix/RECEIVE_WORKSHOPS';
export const ERROR_WORKSHOPS = 'innovatrix/ERROR_WORKSHOPS';
export const ERROR_WORKSHOP_TYPES = 'innovatrix/ERROR_WORKSHOP_TYPES';
export const FETCH_WORKSHOP_TYPES = 'innovatrix/FETCH_WORKSHOP_TYPES';
export const RECEIVE_WORKSHOP_TYPES = 'innovatrix/RECEIVE_WORKSHOP_TYPES';

export const CHANGE_TAB = 'innovatrix/CHANGE_TAB';
export const MANAGE_WORKSHOP_TYPES = 'innovatrix/MANAGE_WORKSHOP_TYPES';
export const MOVE_WORKSHOP = 'innovatrix/MOVE_WORKSHOP';
export const DELETE_WORKSHOP_DATE = 'innovatrix/DELETE_WORKSHOP_DATE';
export const DELETE_WORKSHOP = 'innovatrix/DELETE_WORKSHOP';
export const CREATE_WORKSHOP_SUCCESS = 'innovatrix/CREATE_WORKSHOP_SUCCESS';
export const INVITE_PROJECT_TO_WORKSHOP = 'innovatrix/INVITE_PROJECT_TO_WORKSHOP';
export const ASSIGN_PROJECT_TO_TIMESLOT = 'innovatrix/ASSIGN_PROJECT_TO_TIMESLOT';
export const REMOVE_PROJECT_FROM_TIMESLOT = 'innovatrix/REMOVE_PROJECT_FROM_TIMESLOT';

export const ERROR_WORKSHOP_MOMENTS = 'innovatrix/ERROR_WORKSHOP_MOMENTS';
export const FETCH_WORKSHOP_MOMENTS = 'innovatrix/FETCH_WORKSHOP_MOMENTS';
export const RECEIVE_WORKSHOP_MOMENTS = 'innovatrix/RECEIVE_WORKSHOP_MOMENTS';

export const ERROR_WORKSHOP_DATES = 'innovatrix/ERROR_WORKSHOP_DATES';
export const FETCH_WORKSHOP_DATES = 'innovatrix/FETCH_WORKSHOP_DATES';
export const RECEIVE_WORKSHOP_DATES = 'innovatrix/RECEIVE_WORKSHOP_DATES';

export const ERROR_TIMESLOT_DATES = 'innovatrix/ERROR_TIMESLOT_DATES';
export const FETCH_TIMESLOT_DATES = 'innovatrix/FETCH_TIMESLOT_DATES';
export const RECEIVE_TIMESLOT_DATES = 'innovatrix/RECEIVE_TIMESLOT_DATES';

export const ERROR_PROJECTS_TIMESLOTS = 'innovatrix/ERROR_PROJECTS_TIMESLOTS';
export const FETCH_PROJECTS_TIMESLOTS = 'innovatrix/FETCH_PROJECTS_TIMESLOTS';
export const RECEIVE_PROJECTS_TIMESLOTS = 'innovatrix/RECEIVE_PROJECTS_TIMESLOTS';

export const DELETE_TIMESLOT_DATE = 'innovatrix/DELETE_TIMESLOT_DATE';
export const FETCH_PROJECTS_FOR_WORKSHOP = 'innovatrix/FETCH_PROJECTS_FOR_WORKSHOP';
export const RECEIVE_PROJECTS_FOR_WORKSHOP = 'innovatrix/RECEIVE_PROJECTS_FOR_WORKSHOP';
export const ERROR_PROJECTS_FOR_WORKSHOP = 'innovatrix/ERROR_PROJECTS_FOR_WORKSHOP';

export const REQUIRE_PROJECT_TO_WORKSHOP = 'innovatrix/REQUIRE_PROJECT_TO_WORKSHOP';
