import {
  ERROR_CAMPAIGN,
  ERROR_CAMPAIGNS,
  FETCH_CAMPAIGN,
  FETCH_CAMPAIGNS,
  RECEIVE_CAMPAIGN,
  RECEIVE_CAMPAIGNS,
} from '../../constants';

import { fetchEntities, receiveEntities, errorEntities, fetchEntity, receiveEntity, errorEntity } from './utils';

const initialState = {
  entities: {},
  relations: {
    innovatrixHasCampaigns: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_CAMPAIGNS: return errorEntities('innovatrixHasCampaigns', state, action);
    case FETCH_CAMPAIGNS: return fetchEntities('innovatrixHasCampaigns', state, action);
    case RECEIVE_CAMPAIGNS: return receiveEntities('innovatrixHasCampaigns', state, action);

    case ERROR_CAMPAIGN: return errorEntity(state, action);
    case FETCH_CAMPAIGN: return fetchEntity(state, action);
    case RECEIVE_CAMPAIGN: return receiveEntity(state, action);

    default: return state;
  }
};

