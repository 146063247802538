import * as React from 'react';
import { FastField, connect, getIn, Field } from 'formik';
import PropTypes from 'prop-types';

const FormikComponent = ({
  field: { name, ...restField },
  FieldComponent,
  form: { errors = {}, touched = {} },
  onChange,
  ...restProps
}) => {
  const val = getIn(touched, name);
  const isTouched = React.useMemo(
    () => (typeof val === 'number' || Boolean(val)),
    [val],
  );
  return (
    <FieldComponent
      {...restProps}
      {...restField}
      error={React.useMemo(() => getIn(errors, name), [errors, name])}
      fieldId={name}
      onChange={React.useCallback((value) => onChange(name, value), [name, onChange])}
      touched={isTouched}
    />
  );
};

FormikComponent.propTypes = {
  field: PropTypes.object.isRequired,
  FieldComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const formikField = (FieldComponent) => connect(({ fieldId, formik, fast, onChange, ...props }) => {
  const onChangeValue = React.useCallback((name, value) => {
    formik.setFieldValue(name, value);
    if (onChange) {
      onChange(value);
    }
  }, [onChange, formik]);
  return fast ?
    <FastField
      {...props}
      component={FormikComponent}
      FieldComponent={FieldComponent}
      formik={formik}
      name={fieldId}
      onChange={onChangeValue}
      setFieldTouched={formik.setFieldTouched}
    /> : (
      <Field
        {...props}
        component={FormikComponent}
        FieldComponent={FieldComponent}
        formik={formik}
        name={fieldId}
        onChange={onChangeValue}
        setFieldTouched={formik.setFieldTouched}
      />
    );
});

export default formikField;
