import { ERROR_MY_PROJECTS, FETCH_MY_PROJECTS, RECEIVE_MY_PROJECTS, ORDER_MY_CARDS } from './_actionTypes';

export const errorMyProjectsAction = ({ error }) => ({
  error,
  type: ERROR_MY_PROJECTS,
});

export const fetchMyProjectsAction = () => ({
  type: FETCH_MY_PROJECTS,
});

export const receiveMyProjectsAction = ({ data }) => ({
  data,
  type: RECEIVE_MY_PROJECTS,
});

export const orderMyCardsAction = order => ({
  type: ORDER_MY_CARDS,
  order,
});
