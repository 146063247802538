import { createSelector } from 'reselect';

export const getScore = state => state.score;

export const getSelectedEvaluations = createSelector(
  getScore,
  (score) => score.selectedEvaluations,
);

export const getSelectedReviewer = createSelector(
  getScore,
  (score) => score.selectedReviewer,
);
