import styled from '@emotion/styled';

const MainNavBar = styled.nav`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 0;
  border: none;
  display: flex;
  margin-bottom: 0;
  padding: 14px 32px 10px 32px;
  width: 100%;

  > .container-fluid {
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-size: 16px;
    line-height: normal;
    width: inherit;

    > .brand {
      margin-right: 14px;
    }

    > .collapse {
      align-items: flex-start;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      width: 100%;

      > div {
        display: flex;
        align-items: center;

        &.pull-left {
          flex-flow: wrap;

          > li {
            margin: 0 14px;
            list-style-type: none;

            > a {
              color: ${({ theme }) => theme.colors.white};
              line-height: 22px;
              padding: 0 0 6px 0;
              text-decoration: none;
              &:hover {
                transition: all ease 0.3s;
              }
              &::after {
                border-bottom: 1px solid ${({ theme }) => theme.colors.white};
                content: '';
                display: block;
                margin-bottom: -8px;
                padding-bottom: 6px;
                transform: scaleX(0);
                transition: transform 250ms ease-in-out;
              }
            }
            > a:hover::after {
              transform: scaleX(1);
            }
          }
          > .active {
            > a, > a:hover, > a:active, > a:focus {
              background: ${({ theme }) => theme.colors.transparent};
              border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
              opacity: 1;
              &::after {
                border-bottom: 0;
              }
            }
          }
        }

        &.pull-right {
          height: 36px;
          > div {
            color: ${({ theme }) => theme.colors.secondary};
          }
          > li {
            list-style-type: none;
            margin: 0 14px;

            > a {
              color: ${({ theme }) => theme.colors.white};
              text-decoration: none;
            }

            &:last-child {
              padding-top: 6px;
            }
          }
        }
      }

      @media screen and (min-width: 680px) {
        margin-left: 0;
        margin-right: -27px;
      }
    }
  }

  @media screen and (min-width: 1700px) {
    padding-left: calc(32px + 1vw);
    padding-right: calc(32px + 1vw);
  }
`;

export default MainNavBar;
