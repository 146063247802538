/* eslint-disable no-use-before-define */

/**
 * Compares two objects and returns true when they are shallowly equal, i.e. if they have the same
 * properties, compared using strict equality.
 * @param {*} a
 * @param {*} b
 * @return {boolean}
 */
const equalShallow = (a, b) => {
  if (a === b) {
    return true;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  if (typeof a === 'object') {
    if (!a && !b) { return true; }
    if (!a && b || a && !b) { return false; }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    try {
      return keysA.length === keysB.length
        && keysA.every((key) => Object.prototype.hasOwnProperty.call(b, key) && a[key] === b[key])
        && keysB.every((key) => Object.prototype.hasOwnProperty.call(a, key));
    }
    catch (error) {
      console.error('Error in equalShallow:', error);
      throw error;
    }
  }
  if (Array.isArray(a)) {
    return a.length === b.length && a.every((val, idx) => b[idx] === val);
  }
  return false;
};

// -- LoadState ----------- --- --  -

/**
 * The initial load state to use in your Redux state.
 * @type {LoadState}
 */
export const initialLoadState = {
  id: null,
  data: null,
  loaded: false,
  loading: false,
  reloading: false,
  updating: false,
  available: false,
  availableId() { return false; },
  load(xid) { return loading(xid); },
  receive(xid, xdata) { return loaded(xid, xdata); },
  update() { throw new Error('Unexpected `update` call on initial loadState.'); },
  cancel() { throw new Error('Unexpected `cancel` call on initial loadState.'); },
};

const loading = (id) => ({
  id,
  data: null,
  loaded: false,
  loading: true,
  reloading: false,
  updating: false,
  available: false,
  availableId() { return false; },
  load(xid) { return xid === this.id ? this : loading(xid); },
  receive(xid, xData) { return xid === this.id ? loaded(xid, xData) : this; },
  update() { throw new Error('Unexpected `update` call on a `loading` loadState.'); },
  cancel() { return initialLoadState; },
});

const loaded = (id, data) => ({
  id,
  data,
  loaded: true,
  loading: false,
  reloading: false,
  updating: false,
  available: true,
  availableId(xid) { return xid === this.id; },
  load(xid) { return xid === this.id ? reloading(xid, this.data) : loading(xid); },
  receive(xid, xData) { return xid === this.id ? loaded(xid, xData) : this; },
  update(xid) { return xid === this.id ? updating(xid, this.data) : this; },
  cancel() { return this; },
});

const reloading = (id, data) => ({
  id,
  data,
  loaded: false,
  loading: true,
  reloading: true,
  updating: false,
  available: true,
  availableId(xid) { return xid === this.id; },
  load(xid) { return xid === this.id ? this : loading(xid); },
  receive(xid, xData) { return xid === this.id ? loaded(xid, xData) : this; },
  update(xid) { return xid === this.id ? updating(xid, this.data) : this; },
  cancel(xid) { return xid === this.id ? loaded(xid, this.data) : this; },
});

const updating = (id, data) => ({
  id,
  data,
  loaded: false,
  loading: false,
  reloading: false,
  updating: true,
  available: true,
  availableId(xid) { return xid === this.id; },
  load(xid) { return xid === this.id ? reloading(xid, this.data) : loading(xid); },
  receive(xid, xData) { return xid === this.id ? loaded(xid, xData) : this; },
  update() { return this; },
  cancel(xid) { return xid === this.id ? loaded(xid, this.data) : this; },
});

// -- Reducer ----------- --- --  -

/**
 * Create a simple load state reducer.
 * @param {string} loadActionType
 * @param {string} receiveActionType
 * @param {string} updateActionType
 * @param {string} updateFailedType
 * @returns {function(*=, *)}
 */
export function createLoadStateReducer(loadActionType, receiveActionType, updateActionType, updateFailedType) {
  return (state = initialLoadState, action) => {
    let nxtState = state;
    switch (action.type) {
      case loadActionType:
        nxtState = state.load(action.id);
        break;
      case receiveActionType:
        nxtState = state.receive(action.id, action.data);
        break;
      case updateActionType:
        nxtState = state.update(action.id);
        break;
      case updateFailedType:
        nxtState = state.cancel(action.id);
        break;
      default:
        return state;
    }
    return equalShallow(state, nxtState) ? state : nxtState;
  };
}
