import React from 'react';
import PropTypes from 'prop-types';

const Completed = ({ color }) => (
  <svg height="6" viewBox="0 0 8 6" width="8">
    <g>
      <path fill="transparent" d="M-5-6h18v18H-5z" />
      <path fill={color} d="M6.293.293L3 3.585 1.707 2.293A1 1 0 0 0 .293 3.708l2 1.999c.18.181.431.292.707.292a.996.996 0 0 0 .707-.292l4-4A1 1 0 1 0 6.293.293z" />
    </g>
  </svg>
);

Completed.propTypes = {
  color: PropTypes.string,
};

Completed.defaultProps = {
  color: '#17262b',
};

export default Completed;
