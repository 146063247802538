import {
  CLOSE_DECISION_SUBSCRIPTION,
  CREATE_SALESFORCE_SUBSCRIPTION,
  OPEN_DECISION_SUBSCRIPTION,
  REMOVE_SALESFORCE_SUBSCRIPTION,
  UPDATE_SALESFORCE_SYNC_STATUS,
} from '../constants';

export const subscribeToSalesforceWSAction = () => ({
  type: CREATE_SALESFORCE_SUBSCRIPTION,
});
export const unsubscribeFromSalesforceWSAction = () => ({
  type: REMOVE_SALESFORCE_SUBSCRIPTION,
});

export const updateSalesforceSyncStatusAction = ({ status }) => ({
  status,
  type: UPDATE_SALESFORCE_SYNC_STATUS,
});

export const subscribeToSalesforceDecisionWSAction = () => ({
  type: OPEN_DECISION_SUBSCRIPTION,
});

export const unsubscribeFromSalesforceDecisionWSAction = () => ({
  type: CLOSE_DECISION_SUBSCRIPTION,
});
