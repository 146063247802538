import React from 'react';
import PropTypes from 'prop-types';
import { PlainModal } from '@innovatrix/modals';
import styled from '@emotion/styled';
import { Paragraph, Title, Body } from '@innovatrix/common/text';
import { Button } from '@innovatrix/common/buttons';
import ArrowIcon from '@innovatrix/icons/ArrowIcon';
import { noop } from '@innovatrix/utils';

const Modal = styled(PlainModal)`
  > div > form > .body {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 24px 24px 24px 12px;
`;

const StepImage = styled.img`
  padding: 36px 0;
  max-width: 300px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 72px;
  width: 100%;
`;

const StepInformation = styled.div`
  margin-left: 48px;
  max-width: 75%;
`;

const StepCount = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey2};
  font-size: 12px;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

const StepTitle = styled(Title)`
  margin-bottom: 24px;
  overflow: visible;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 26px;
`;

const InformationBlock = styled(Body)`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const TourModal = ({ skip, persistStep, currentStep, steps }) => {
  const [step, setStep] = React.useState(currentStep);

  if (!steps || !Array.isArray(steps) || steps.length === 0) {
    throw new Error('The TourModal requires that you pass an array of steps.');
  }

  const maxSteps = steps.length;
  const visibleStep = steps[step];

  const isMaxStep = React.useMemo(() => step + 1 === maxSteps, [maxSteps, step]);

  const onNext = React.useCallback(() => {
    if (isMaxStep) {
      persistStep(isMaxStep);
    }
    else {
      setStep(s => s + 1);
      persistStep(isMaxStep);
    }
  }, [isMaxStep, persistStep]);

  return (
    <Modal
      isOpen
      onSubmit={noop}
      width={800}
    >
      <Wrapper>
        <StepImage src={visibleStep.image} />
        <Step>
          <StepInformation>
            <StepCount>Step {step + 1}/{maxSteps}</StepCount>
            <StepTitle size="h2" weight="medium">{visibleStep.title}</StepTitle>
            {visibleStep.textBlocks.map((block, i) => (
              <InformationBlock key={`block-${i}`}>{block}</InformationBlock>
            ))}
          </StepInformation>
          <Buttons>
            <Button
              flavor="textButton"
              label="SKIP"
              onClick={skip}
            />
            <Button
              flavor="primary"
              icon={<ArrowIcon />}
              label={isMaxStep ? 'Finish' : 'Next'}
              onClick={onNext}
            />
          </Buttons>
        </Step>
      </Wrapper>
    </Modal>
  );
};

TourModal.propTypes = {
  currentStep: PropTypes.number,
  persistStep: PropTypes.func,
  skip: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    textBlocks: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
};

TourModal.defaultProps = {
  currentStep: 0,
};

export default TourModal;
