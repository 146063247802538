import React from 'react';
import PropTypes from 'prop-types';
import { PRIMARY } from '@innovatrix/styles';

import { propTypes, defaultProps } from './defaultPropTypes';

const CloseIcon = ({ className, color, width, height, strokeColor, styles, ...props }) => (
  <svg className={className} fill={color} width={width} height={height} viewBox="0 0 16 16" {...styles} {...props}>
    <path stroke={color || strokeColor} d="M14,16l-5.9-5.9L2.2,16l-2-2l5.9-5.9L0.2,2.2l2-2l5.9,5.9L14,0.2l2,2l-5.9,5.9L16,14L14,16z" />
  </svg>
);

CloseIcon.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strokeColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CloseIcon.defaultProps = {
  ...defaultProps,
  height: '16px',
  strokeColor: PRIMARY,
  width: '16px',
};

export default CloseIcon;
