import { createFormikAction } from '@innovatrix/actions/utils';

import {
  MANAGE_WORKSHOP_TYPES,
  FETCH_WORKSHOP_DATES,
  FETCH_WORKSHOP_TYPES,
  RECEIVE_WORKSHOP_DATES,
  ERROR_WORKSHOP_DATES,
  RECEIVE_WORKSHOP_TYPES,
  ERROR_WORKSHOP_TYPES,
  FETCH_WORKSHOPS,
  RECEIVE_WORKSHOPS,
  ERROR_WORKSHOPS,
  FETCH_WORKSHOP_MOMENTS,
  RECEIVE_WORKSHOP_MOMENTS,
  ERROR_WORKSHOP_MOMENTS,
  FETCH_PROJECTS_FOR_WORKSHOP,
  RECEIVE_PROJECTS_FOR_WORKSHOP,
  ERROR_PROJECTS_FOR_WORKSHOP,
  FETCH_TIMESLOT_DATES,
  RECEIVE_TIMESLOT_DATES,
  ERROR_TIMESLOT_DATES,
  FETCH_PROJECTS_TIMESLOTS,
  RECEIVE_PROJECTS_TIMESLOTS,
  ERROR_PROJECTS_TIMESLOTS,
  CHANGE_TAB,
  MOVE_WORKSHOP,
  DELETE_WORKSHOP,
  DELETE_WORKSHOP_DATE,
  INVITE_PROJECT_TO_WORKSHOP,
  DELETE_TIMESLOT_DATE,
  ASSIGN_PROJECT_TO_TIMESLOT,
  REMOVE_PROJECT_FROM_TIMESLOT,
  REQUIRE_PROJECT_TO_WORKSHOP,
} from './_actionTypes';

export const updateWorkshopMomentAction = createFormikAction('UPDATE_WORKSHOP_MOMENT');
export const persistTimeslotDateAction = createFormikAction('PERSIST_TIMESLOT_DATE');
export const persistTimeslotTimeRangeAction = createFormikAction('PERSIST_TIMESLOT_TIME_RANGE');
export const createWorkshopTypeAction = createFormikAction('CREATE_WORKSHOP_TYPE');
export const manageWorkshopMomentsAction = createFormikAction('MANAGE_WORKSHOP_MOMENTS');
export const persistMomentAction = createFormikAction('PERSIST_MOMENT');
export const persistWorkshopAction = createFormikAction('PERSIST_WORKSHOP');
export const persistWorkshopDateAction = createFormikAction('PERSIST_WORKSHOP_DATE');

export const deleteWorkshopAction = ({ workshopId, workshopTypeId }) => ({
  type: DELETE_WORKSHOP,
  workshopId,
  workshopTypeId,
});

export const deleteTimeslotDateAction = ({ id, workshopMomentId }) => ({
  id,
  type: DELETE_TIMESLOT_DATE,
  workshopMomentId,
});

export const deleteWorkshopDateAction = ({ workshopDateId, workshopMomentId }) => ({
  type: DELETE_WORKSHOP_DATE,
  workshopDateId,
  workshopMomentId,
});

export const inviteProjectToWorkshopAction = ({ projectIds, workshopMomentId }) => ({
  type: INVITE_PROJECT_TO_WORKSHOP,
  projectIds,
  workshopMomentId,
});

export const removeProjectFromTimeslotAction = ({ projectTimeslotId }, { timeslotRangeId, callback }) => ({
  callback,
  projectTimeslotId,
  timeslotTimeRangeId: timeslotRangeId,
  type: REMOVE_PROJECT_FROM_TIMESLOT,
});

export const assignProjectToTimeslotAction = ({ projectId, timeslotRangeId, start, end, callback }) => ({
  callback,
  end,
  projectId,
  start,
  timeslotRangeId,
  type: ASSIGN_PROJECT_TO_TIMESLOT,
});

export const moveWorkshopAction = ({ originalWorkshopTypeId, workshopId, workshopTypeId }) => ({
  originalWorkshopTypeId,
  type: MOVE_WORKSHOP,
  workshopId,
  workshopTypeId,
});

export const fetchWorkshopDatesAction = ({ workshopMomentId }) => ({
  type: FETCH_WORKSHOP_DATES,
  workshopMomentId,
});

export const manageWorkshopTypesAction = createFormikAction(MANAGE_WORKSHOP_TYPES);

export const changeTabAction = ({ tab }) => ({
  tab,
  type: CHANGE_TAB,
});

export const fetchWorkshopTypesAction = () => ({
  type: FETCH_WORKSHOP_TYPES,
});

export const receiveWorkshopDatesAction = ({ data, workshopMomentId }) => ({
  data,
  type: RECEIVE_WORKSHOP_DATES,
  workshopMomentId,
});

export const receiveWorkshopTypesAction = ({ data }) => ({
  data,
  type: RECEIVE_WORKSHOP_TYPES,
});

export const errorWorkshopTypesAction = ({ error }) => ({
  error,
  type: ERROR_WORKSHOP_TYPES,
});

export const fetchWorkshopsAction = ({ workshopTypeId }) => ({
  type: FETCH_WORKSHOPS,
  workshopTypeId,
});

export const receiveWorkshopsAction = ({ data, workshopTypeId }) => ({
  data,
  type: RECEIVE_WORKSHOPS,
  workshopTypeId,
});

export const errorWorkshopsAction = ({ error, workshopTypeId }) => ({
  error,
  type: ERROR_WORKSHOPS,
  workshopTypeId,
});

export const fetchWorkshopMomentsAction = ({ workshopId }) => ({
  type: FETCH_WORKSHOP_MOMENTS,
  workshopId,
});

export const fetchProjectsForWorkshopAction = ({ workshopMomentId }) => ({
  type: FETCH_PROJECTS_FOR_WORKSHOP,
  workshopMomentId,
});

export const receiveProjectsForWorkshopAction = ({ data, workshopMomentId }) => ({
  data,
  type: RECEIVE_PROJECTS_FOR_WORKSHOP,
  workshopMomentId,
});

export const errorProjectsForWorkshopAction = ({ error, workshopMomentId }) => ({
  error,
  type: ERROR_PROJECTS_FOR_WORKSHOP,
  workshopMomentId,
});

export const fetchTimeslotDatesAction = ({ workshopMomentId }) => ({
  type: FETCH_TIMESLOT_DATES,
  workshopMomentId,
});

export const receiveTimeslotDatesAction = ({ data, workshopMomentId }) => ({
  data,
  type: RECEIVE_TIMESLOT_DATES,
  workshopMomentId,
});

export const getProjectsTimeslotsAction = ({ timeslotTimeRangeId }) => ({
  timeslotTimeRangeId,
  type: FETCH_PROJECTS_TIMESLOTS,
});

export const receiveProjectsTimeslotsAction = ({ data, timeslotTimeRangeId }) => ({
  data,
  timeslotTimeRangeId,
  type: RECEIVE_PROJECTS_TIMESLOTS,
});

export const errorProjectsTimeslotsAction = ({ error, timeslotTimeRangeId }) => ({
  timeslotTimeRangeId,
  error,
  type: ERROR_PROJECTS_TIMESLOTS,
});

export const errorTimeslotDatesAction = ({ error, workshopMomentId }) => ({
  error,
  type: ERROR_TIMESLOT_DATES,
  workshopMomentId,
});

export const receiveWorkshopMomentsAction = ({ data, workshopId }) => ({
  data,
  type: RECEIVE_WORKSHOP_MOMENTS,
  workshopId,
});

export const errorWorkshopMomentsAction = ({ error, workshopId }) => ({
  error,
  type: ERROR_WORKSHOP_MOMENTS,
  workshopId,
});

export const errorWorkshopDatesAction = ({ error, workshopMomentId }) => ({
  error,
  type: ERROR_WORKSHOP_DATES,
  workshopMomentId,
});

export const requireProjectToWorkshopAction = ({ campaignId, projectId, required, workshopMomentId }) => ({
  campaignId,
  required,
  type: REQUIRE_PROJECT_TO_WORKSHOP,
  projectId,
  workshopMomentId,
});
