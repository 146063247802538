import { queryBuilder, mutationBuilder } from '@innovatrix/utils/dx';

export const getProjectsQuery = queryBuilder(
  {
    variables: {
      campaignId: { type: 'String' },
      phaseId: { type: 'String' },
    },
    fields: {
      _getProjects: {
        varArgs: {
          campaignId: 'campaignId',
          phaseId: 'phaseId',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectReviewersQuery = queryBuilder({
  variables: {
    campaignId: { type: 'String!' },
    phaseId: { type: 'String!' },
  },
  fields: {
    _getProjectReviewers: {
      varArgs: {
        campaignId: 'campaignId',
        phaseId: 'phaseId',
      },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const getCampaignReviewersQuery = queryBuilder({
  variables: {
    campaignId: { type: 'String! ' },
  },
  fields: {
    _getCampaignReviewers: {
      varArgs: {
        campaignId: 'campaignId',
      },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const getCampaignReviewerGroupsQuery = queryBuilder({
  fields: {
    _getCampaignReviewerGroups: {
      fields: {
        error: true,
        result: true,
        success: true,
      },
    },
  },
});

export const getAllPossibleReviewersQuery = queryBuilder({
  fields: {
    _getAllPossibleReviewers: {
      fields: {
        error: true,
        result: true,
        success: true,
      },
    },
  },
});

export const updateDecisionMutation = mutationBuilder({
  variables: {
    campaignId: { type: 'String!' },
    conditions: { type: 'String' },
    decision: { type: 'String!' },
    phaseId: { type: 'String!' },
    projectId: { type: 'String!' },
  },
  fields: {
    updateDecision: {
      varArgs: {
        campaignId: 'campaignId',
        conditions: 'conditions',
        decision: 'decision',
        phaseId: 'phaseId',
        projectId: 'projectId',
      },
      fields: {
        error: true,
        result: true,
        success: true,
      },
    },
  },
});

export const createProjectMutation = mutationBuilder(
  {
    variables: {
      name: { type: 'String!' },
      description: { type: 'String' },
      majorDomain: { type: 'String' },
      pictureUrl: { type: 'String' },
      projectTypeId: { type: 'String' },
      campaignId: { type: 'String' },
    },
    fields: {
      _createProject: {
        varArgs: {
          campaignId: 'campaignId',
          name: 'name',
          description: 'description',
          majorDomain: 'majorDomain',
          pictureUrl: 'pictureUrl',
          projectTypeId: 'projectTypeId',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createProjectViaRegistrationFlowMutation = mutationBuilder(
  {
    variables: {
      campaignId: { type: 'String' },
      description: { type: 'String' },
      majorDomain: { type: 'String' },
      name: { type: 'String!' },
      pictureUrl: { type: 'String' },
      profile: { type: 'JSON' },
      projectTypeId: { type: 'String' },
    },
    fields: {
      _createProjectViaRegistrationFlow: {
        varArgs: {
          campaignId: 'campaignId',
          description: 'description',
          majorDomain: 'majorDomain',
          name: 'name',
          pictureUrl: 'pictureUrl',
          profile: 'profile',
          projectTypeId: 'projectTypeId',
        },
        fields: {
          error: true,
          result: true,
          success: true,
        },
      },
    },
  },
);

export const persistCampaignReviewersMutation = mutationBuilder({
  variables: {
    campaignId: { type: 'String!' },
    groupIds: { type: 'JSON' },
    reviewerIds: { type: 'JSON' },
  },
  fields: {
    _persistCampaignReviewers: {
      varArgs: {
        campaignId: 'campaignId',
        groupIds: 'groupIds',
        reviewerIds: 'reviewerIds',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const persistProjectReviewersMutation = mutationBuilder({
  variables: {
    campaignId: { type: 'String!' },
    deletedReviewers: { type: 'JSON' },
    phaseId: { type: 'String!' },
    projectIds: { type: '[String]' },
    reviewers: { type: 'JSON' },
  },
  fields: {
    _persistProjectReviewers: {
      varArgs: {
        campaignId: 'campaignId',
        deletedReviewers: 'deletedReviewers',
        phaseId: 'phaseId',
        projectIds: 'projectIds',
        reviewers: 'reviewers',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const toggleReviewerPriorityMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    reviewerId: { type: 'String!' },
    phaseId: { type: 'String!' },
  },
  fields: {
    _toggleReviewerPriority: {
      varArgs: {
        projectId: 'projectId',
        reviewerId: 'reviewerId',
        phaseId: 'phaseId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const removeReviewerMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    reviewerId: { type: 'String!' },
    phaseId: { type: 'String!' },
  },
  fields: {
    _removeProjectReviewer: {
      varArgs: {
        projectId: 'projectId',
        reviewerId: 'reviewerId',
        phaseId: 'phaseId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const persistCampaignReviewerGroupsMutation = mutationBuilder({
  variables: {
    groups: { type: 'JSON!' },
  },
  fields: {
    _persistCampaignReviewerGroups: {
      varArgs: {
        groups: 'groups',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const persistNewReviewerMutation = mutationBuilder({
  variables: {
    user: { type: 'JSON!' },
  },
  fields: {
    _createCampaignReviewer: {
      varArgs: {
        user: 'user',
      },
      fields: {
        error: true,
        result: true,
        success: true,
      },
    },
  },
});
