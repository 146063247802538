import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import {
  PROJECT_TAB, CAMPAIGNS_ENABLED, CAMPAIGN_TITLE_PLURAL, OVERVIEW_TAB, SALESFORCE_ENABLED, INNOBOARDS_ENABLED, MODELS_ENABLED,
  WORKSHOPS_ENABLED, EXTERNAL_LINK_ENABLED, EXTERNAL_LINK_NAME, EXTERNAL_LINK_URL, PHASES_ENABLED,
  DEALS_ENABLED, DEALS_NAME, IS_PRODUCTION, UMS_URL, SUPPORT_LINK, INNOBOARD_TAB, IS_OPEN_ENVIRONMENT,
} from '@innovatrix/constants';
import { getApplicationMetaData } from '@innovatrix/selectors';
import { getUrlPrefix } from '@innovatrix/utils/urlPrefix';
import { isAuthorized } from '@innovatrix/selectors/authSelectors';
import { WHITE } from '@innovatrix/styles';

import Logo from './InnovatrixLogo';
import InnovatrixNavigationBar from './InnovatrixNavigationBar';
import SearchProjects from './SearchProjects';
import UserMenu from './user/read';

const landingPage = IS_OPEN_ENVIRONMENT ? INNOBOARD_TAB : OVERVIEW_TAB;

// -- Component --------------- --- --  -

const propTypes = {
  applicationMetaData: PropTypes.shape({
    isEvaluator: PropTypes.bool.isRequired,
    myProjects: PropTypes.array.isRequired,
  }),
  canAccessApplications: PropTypes.bool.isRequired,
  canAccessCampaigns: PropTypes.bool.isRequired,
  canAccessDeals: PropTypes.bool.isRequired,
  canAccessManage: PropTypes.bool.isRequired,
  canAccessProjects: PropTypes.bool.isRequired,
  canAccessProposals: PropTypes.bool.isRequired,
  canAccessSearch: PropTypes.bool.isRequired,
  canAccessUMS: PropTypes.bool.isRequired,
  canEditCampaigns: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
};

const CustomNavLink = ({ startsWith, ...props }) => {
  const location = useLocation();
  const active = location.pathname.startsWith(startsWith || props.to);
  return (
    <li className={active ? 'active' : ''}>
      <NavLink {...props} to={props.to}>{props.name || props.to}</NavLink>
    </li>
  );
};

CustomNavLink.propTypes = {
  exact: PropTypes.bool,
  name: PropTypes.string,
  startsWith: PropTypes.string,
  to: PropTypes.string.isRequired,
};

const NavigationBar = ({
  applicationMetaData,
  canAccessApplications,
  canAccessCampaigns,
  canAccessDeals,
  canAccessManage,
  canAccessProjects,
  canAccessProposals,
  canAccessSearch,
  canAccessUMS,
  canEditCampaigns,
  signOut,
}) => (
  <InnovatrixNavigationBar className="dx-app-navbar navbar">
    <div className="container-fluid">
      <div className="brand">
        <Link to="/">
          <Logo color={WHITE} />
        </Link>
      </div>
      <div className="collapse">
        <div className="pull-left">
          {SALESFORCE_ENABLED || CAMPAIGNS_ENABLED
            ? canAccessCampaigns &&
              <CustomNavLink name={CAMPAIGN_TITLE_PLURAL} to="/campaigns" exact />
            : canAccessProjects &&
              <CustomNavLink startsWith="/cards" to="/cards/ACTIVE" name="Projects" exact />}
          {(SALESFORCE_ENABLED || CAMPAIGNS_ENABLED) && PHASES_ENABLED && canAccessProposals ? (
            <CustomNavLink startsWith="/proposals" to="/proposals" name="Proposals" exact />
          ) : null}
          {(SALESFORCE_ENABLED || CAMPAIGNS_ENABLED) && canAccessProjects ?
            <CustomNavLink startsWith="/projects" to="/projects" name="Projects" exact /> : null}
          {!canAccessProjects && applicationMetaData.myProjects.map(({ campaignId, id, name }) => (
            <CustomNavLink
              exact
              key={id}
              name={name}
              startsWith={`${getUrlPrefix(campaignId, id)}`}
              // TODO: phaseId === PHASE_PROJECT ? DASHBOARD_TAB :
              to={`${getUrlPrefix(campaignId, id)}/${landingPage}`}
            />
          ))}
          {(MODELS_ENABLED || INNOBOARDS_ENABLED || WORKSHOPS_ENABLED) && canAccessManage && canEditCampaigns && (
            <CustomNavLink startsWith="/manage" to={`/manage/${PROJECT_TAB}`} name="Manage" />
          )}
          {canAccessUMS && !IS_PRODUCTION && (
            <CustomNavLink startsWith="/components" to="/components/Titles" name="Components" />
          )}
          {canAccessUMS ? (
            <li>
              <a href={UMS_URL} target="blank">Users</a>
            </li>
          ) : null}
          {(DEALS_ENABLED && canAccessDeals) ? <CustomNavLink startsWith="/deals" to="/deals" name={DEALS_NAME} exact /> : null}
          {(EXTERNAL_LINK_ENABLED && !applicationMetaData.isEvaluator) ? (
            <li>
              <a href={EXTERNAL_LINK_URL} target="blank">{EXTERNAL_LINK_NAME}</a>
            </li>
          ) : null}
          <CustomNavLink to="/about" exact name="About" />
        </div>
        <div className="pull-right">
          {canAccessSearch && <SearchProjects />}
          <li>
            <a href={SUPPORT_LINK} target="blank">Support</a>
          </li>
          <li>
            <UserMenu signOut={React.useCallback(() => signOut('/'), [signOut])} hideApplicationsLink={!canAccessApplications} />
          </li>
        </div>
      </div>
    </div>
  </InnovatrixNavigationBar>
);

NavigationBar.propTypes = propTypes;

export default connect((state) => ({
  applicationMetaData: getApplicationMetaData(state),
  canAccessApplications: isAuthorized(state, 'applications/view'),
  canAccessCampaigns: isAuthorized(state, 'access/campaigns'),
  canAccessDeals: isAuthorized(state, 'deals/view'),
  canAccessManage: isAuthorized(state, 'access/manage'),
  canAccessProjects: isAuthorized(state, 'access/projects'),
  canAccessProposals: isAuthorized(state, 'access/proposals'),
  canAccessSearch: isAuthorized(state, 'access/search'),
  canAccessUMS: isAuthorized(state, 'access/ums'),
  canEditCampaigns: isAuthorized(state, 'campaigns/update'),
}))(React.memo(NavigationBar));
