import * as React from 'react';
import PropTypes from 'prop-types';
import TourModal from '@innovatrix/common/TourModal';
import graphql from '@innovatrix/common/graphql';
import * as toast from '@innovatrix/utils/toast';
import {
  DOCUMENTS_ENABLED,
  INNOBOARDS_ENABLED,
  INNOBOARDS_PROJECT_NAME,
  INNOVATRIX_INTRODUCTION_TOUR,
  MODELS_ENABLED,
  MODELS_SCORES_NAME,
  OBJECTIVES_ENABLED,
  OBJECTIVES_NAME,
  WORKSHOPS_ENABLED,
  WORKSHOPS_NAME,
} from '@innovatrix/constants';

import tour1 from '../../assets/tours/innovatrix/tour-1.png';
import tour2 from '../../assets/tours/innovatrix/tour-2.png';
import tour3 from '../../assets/tours/innovatrix/tour-3.png';
import tour4 from '../../assets/tours/innovatrix/tour-4.png';
import tour5 from '../../assets/tours/innovatrix/tour-5.png';
import tour6 from '../../assets/tours/innovatrix/tour-6.png';
import { skipTourMutation, persistStepMutation } from '../_queries';

import { ApplicationContext } from './context';

const createSteps = () => {
  const list = [];
  list.push({
    image: tour1,
    title: 'Improve the way you innovate',
    textBlocks: [
      'Innovatrix is all about innovation. Take these few steps to discover what we offer.',
    ],
  });
  if (INNOBOARDS_ENABLED) {
    const name = INNOBOARDS_PROJECT_NAME.toLowerCase();
    list.push({
      image: tour2,
      title: 'Structure your idea',
      textBlocks: [
        `Map your idea in assumptions on the ${name}.`,
        'After mapping, set your focus, research and then update your board.',
      ],
    });
  }
  if (MODELS_ENABLED) {
    const modelScoreName = MODELS_SCORES_NAME.toLowerCase();
    list.push({
      image: tour4,
      title: 'Rate and follow your progress',
      textBlocks: [
        `Evaluate your idea via ${modelScoreName}.`,
        'Grow your understanding and focus your next steps on the most relevant parameters.',
      ],
    });
  }
  if (OBJECTIVES_ENABLED) {
    const name = OBJECTIVES_NAME.toLowerCase();
    list.push({
      image: tour5,
      title: `Set ${name}`,
      textBlocks: [
        'Indicate your most important goals and when you would like to achieve them.',
        'If possible, quantify them.',
      ],
    });
  }
  if (WORKSHOPS_ENABLED) {
    const name = WORKSHOPS_NAME.toLowerCase();
    list.push({
      image: tour3,
      title: `Subscribe for ${name}`,
      textBlocks: [
        'Dive into the most important topics.',
        'Learn tips and tricks from experts to progress with your idea.',
      ],
    });
  }
  if (DOCUMENTS_ENABLED) {
    list.push({
      image: tour6,
      title: 'Document your work',
      textBlocks: [
        'Save all your files on one secure location.',
      ],
    });
  }
  return list;
};

const steps = createSteps();

const InnovatrixIntroductionTour = ({ persistStep, skipTour }) => {
  const { tours, user, refetchTours: refetch } = React.useContext(ApplicationContext);
  const tour = (tours || []).find(t => t.name === INNOVATRIX_INTRODUCTION_TOUR);

  // Tour not found? Do not display the Modal.
  // Tour was already completed or skipped/cancelled, don't show the modal again
  if (!tour || tour.completedOn || tour.cancelledOn) return null;

  const onSkipTour = () => {
    skipTour({
      userId: user.id,
      tourName: tour && tour.name,
      callback: (err) => {
        if (err) {
          console.error(err);
          toast.error('Unable to skip the current tour.');
        }
        else {
          refetch();
        }
      },
    });
  };

  const onPersistStep = (isFinished) => {
    persistStep({
      finished: isFinished,
      userId: user.id,
      tourName: tour && tour.name,
      callback: (err) => {
        if (err) {
          console.error(err);
          toast.error('Unable to persist the current step.');
        }
        else {
          refetch();
        }
      },
    });
  };

  return (
    <TourModal
      currentStep={tour.step}
      persistStep={onPersistStep}
      skip={onSkipTour}
      steps={steps}
    />
  );
};

InnovatrixIntroductionTour.propTypes = {
  persistStep: PropTypes.func.isRequired,
  skipTour: PropTypes.func.isRequired,
};

export default graphql({
  mutation: persistStepMutation,
  name: 'persistStep',
  namespace: '_persistStep',
  type: 'Mutation',
})(graphql({
  mutation: skipTourMutation,
  name: 'skipTour',
  namespace: '_skipTour',
  type: 'Mutation',
})(React.memo(InnovatrixIntroductionTour)));
