import { ANIMATE_WORKSHOP, COMPLETE_ANIMATE_WORKSHOP } from '../constants';

const initialState = {
  animatedWorkshopId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ANIMATE_WORKSHOP: {
      return {
        ...state,
        animatedWorkshopId: action.workshopMomentId,
      };
    }
    case COMPLETE_ANIMATE_WORKSHOP: {
      return {
        ...state,
        animatedWorkshopId: null,
      };
    }
    default:
      return state;
  }
};
