import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { prevent } from '@innovatrix/utils';
import CaretIcon from '@innovatrix/icons/CaretIcon';
import { SECONDARY } from '@innovatrix/styles';
import { IS_PRODUCTION } from '@innovatrix/constants';
import MoreIcon from '@innovatrix/icons/MoreIcon';

import Paragraph from '../text/Paragraph';
import { LabelGroup } from '../fields/_helpers';

import Item from './Item';

const SIZES = {
  small: '28px',
  large: '36px',
};

const PADDING_FOR_SIZES = {
  small: '6px 16px',
  large: '10px 16px',
  onlyIconSmall: '0 7px',
  onlyIconLarge: '0 11px',
};

const ITEM_HEIGHT = 30;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: inline-flex;
  position: relative;
`;

const calculateTogglePadding = (small, icon, label) => {
  if (small) {
    return (icon && !label) ? PADDING_FOR_SIZES.onlyIconSmall : PADDING_FOR_SIZES.small;
  }
  return (icon && !label) ? PADDING_FOR_SIZES.onlyIconLarge : PADDING_FOR_SIZES.large;
};

const Toggle = styled.button`
  align-items: center;
  background: ${({ disabled, theme }) => (disabled ? theme.colors.greyLight1 : theme.colors.white)};
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid ${({ theme, error, touched }) => ((error && touched) ? theme.colors.warning : theme.colors.grey1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: ${({ small }) => (small ? SIZES.small : SIZES.large)};
  padding-right: ${({ hasCaret }) => (hasCaret ? '32px' : '16px')};
  padding: ${({ small, hasIcon, hasLabel }) => calculateTogglePadding(small, hasIcon, hasLabel)};
  position: relative;
  text-align: start;
  width: ${({ width }) => (width || 'auto')};
  &:hover {
    background: ${({ disabled, theme }) => (disabled ? undefined : theme.colors.greyBlueish)};
  }
  &:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.focus};
    outline: none;
  }
  &:after {
    background: ${({ disabled, theme }) => (disabled ? 'unset' : theme.colors.greyBlueish)};
  }
  ${({ theme }) => theme.effect.ripple}
  ${({ hasIcon }) => (hasIcon ? `
    > svg:first-of-type {
      height: 12px;
      width: 12px;
    }
  ` : '')}
`;

const Menu = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: flex;
  flex-direction: ${({ dropup }) => (dropup ? 'column-reverse' : 'column')};
  margin: 0;
  padding: 4px 0;
  position: ${({ position }) => position};
  width: ${({ width }) => (width || 'auto')};
  height: ${({ height }) => (height || 'auto')};
  z-index: 5;
  ${({ left }) => (left ? 'right: 0' : 'left: 0')};
  ${({ dropup, small }) => (dropup ? '' : `top: calc(${(small ? SIZES.small : SIZES.large)} + 4px);`)}
  ${({ dropup, small }) => (dropup ? `bottom: calc(${(small ? SIZES.small : SIZES.large)} + 4px);` : '')}
  ${({ left, leftOffset, rightOffset, dropup, small, topOffset, position }) => ((position === 'fixed') ? `
    ${dropup ? '' : `top: calc(${topOffset}px + ${small ? SIZES.small : SIZES.large} + 4px);`}
    ${left ? `right: calc(100% - ${rightOffset}px)` : `left: ${leftOffset}px`};
  ` : '')};
`;

const ButtonParagraph = styled(Paragraph)`
  margin-bottom: 0px;
  margin-right: 12px;
  margin-left: ${({ hasIcon }) => (hasIcon ? '12px' : '0')};
`;

const StyledCaretIcon = styled(CaretIcon)`
  height: 6px;
  position: absolute;
  right: 12px;
  transform: ${({ dropup }) => (dropup ? 'rotate(180deg)' : 'unset')};
  width: 6px;
  > path {
    height: 6px;
  }
`;

const List = styled.ul`
  height: ${({ listHeight }) => (listHeight ? `${listHeight}px` : '')};
  overflow-y: auto;
  margin: 0;
  padding-left: 0;
  ${({ theme }) => theme.scrollbar.small};
`;

const CustomItem = styled(Item)`
  height: auto;
  overflow: visible;
  padding: 4px 4px 0 4px;

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const Dropdown = ({
  blur,
  caret,
  className,
  customAction,
  customNode,
  disabled,
  dropup,
  error,
  groupLabel,
  height,
  icon,
  ignoreClick,
  information,
  label,
  left,
  options,
  position,
  required,
  small,
  touched,
  width,
}) => {
  if (!IS_PRODUCTION) {
    if (!options || !Array.isArray(options)) {
      throw new Error('No options array specified for <Dropdown />.');
    }
    if (!label && !icon) {
      throw new Error('No label/icon specified for <Dropdown />.');
    }
  }
  // The ref four our button
  const buttonRef = React.useRef();
  const timeout = React.useRef();
  // We have to force rerender since emotion uses a forwardRef
  const [rerenders, rerender] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  // When everything is in dom, forwardRef populates
  // That's why we have to force a reflow.
  React.useLayoutEffect(() => {
    if (isOpen) {
      const onResize = () => rerender(s => !s);
      onResize();
      window.addEventListener('resize', onResize);
      window.addEventListener('scroll', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('scroll', onResize);
      };
    }
  }, [isOpen]);

  const toggle = React.useCallback((e) => {
    prevent(e);
    setIsOpen(o => !o);
  }, []);
  const leave = React.useCallback(() => {
    timeout.current = setTimeout(() => {
      setIsOpen(() => false);
      // Simulate onBlur functionality
      if (blur && typeof blur === 'function') {
        blur();
      }
    }, 333);
  }, [blur]);

  const enter = React.useCallback(() => {
    if (timeout.current) { clearTimeout(timeout.current); }
  }, []);
  // Extract relevant information from our buttonRef.
  const { top, left: leftOffset, right: rightOffset } = React.useMemo(() => (buttonRef.current ? buttonRef.current.getBoundingClientRect() : {}),
    [buttonRef.current, rerenders]); // eslint-disable-line
  // Calculate height of our current list.
  const listHeight = React.useMemo(() => {
    if (options && options.length > 0) {
      const fullHeight = (options.length * ITEM_HEIGHT) + 0;
      if (customNode && fullHeight > 240) {
        return 255;
      }
      return fullHeight;
    }
    return 0;
    // return (options && options.length > 0 ? (options.length * ITEM_HEIGHT) + 8 : 0);
  }, [customNode, options]);

  const hasNested = React.useMemo(() => options.some(({ options: opts }) => Boolean(opts)), [options]);

  // When dealing with custom actions.
  const customActionCallback = React.useCallback((e) => {
    prevent(e);
    if (customAction) {
      customAction();
    }
    leave();
  }, [customAction, leave]);

  return (
    <Wrapper className={`${className}${ignoreClick ? ` ${ignoreClick}` : ''}`} onMouseLeave={leave} onMouseEnter={enter}>
      {groupLabel && <LabelGroup touched={touched} error={error} label={groupLabel} information={information} required={required} />}
      <Container>
        <Toggle
          className={ignoreClick ? ` ${ignoreClick}` : ''}
          disabled={disabled}
          error={error}
          hasCaret={Boolean(caret)}
          hasIcon={Boolean(icon)}
          hasLabel={Boolean(label)}
          onClick={toggle}
          ref={buttonRef}
          small={small}
          touched={touched}
          type="button"
          width={width}
        >
          {icon}
          {label && <ButtonParagraph hasIcon={Boolean(icon)}>{label}</ButtonParagraph>}
          {/* Because emotion forwards these variables to the dom node we have to strip them. */}
          {caret && <StyledCaretIcon color={SECONDARY} dropup={dropup ? 'true' : undefined} />}
        </Toggle>
        {isOpen &&
          <Menu
            dropup={dropup}
            className={ignoreClick ? ` ${ignoreClick}` : ''}
            left={left}
            leftOffset={leftOffset}
            position={position}
            rightOffset={rightOffset}
            small={small}
            topOffset={top}
            width={label ? width : 'auto'}
            height={height || 'auto'}
          >
            <List className={ignoreClick ? ` ${ignoreClick}` : ''} listHeight={listHeight}>
              {options.map(({
                label: itemLabel, value: itemValue, icon: itemIcon,
                action, options: itemOptions, selected, separator,
              }, i) => (
                <Item
                  action={action}
                  className={ignoreClick ? ` ${ignoreClick}` : ''}
                  hasNested={hasNested}
                  icon={itemIcon}
                  ignoreClick={ignoreClick || ''}
                  key={`${itemValue}${i}`}
                  label={itemLabel}
                  left={left}
                  options={itemOptions}
                  selected={selected}
                  separator={separator}
                  small={small}
                  width={width}
                />
              ))}
            </List>
            {customNode && (
              <CustomItem
                action={customActionCallback}
                className={ignoreClick ? ` ${ignoreClick}` : ''}
                key="custom-action"
                left={left}
                small={small}
                width={width}
              >
                {customNode}
              </CustomItem>
            )}
          </Menu>}
      </Container>
    </Wrapper>
  );
};

Dropdown.propTypes = {
  blur: PropTypes.func,
  caret: PropTypes.bool,
  className: PropTypes.string,
  customAction: PropTypes.func,
  customNode: PropTypes.node,
  disabled: PropTypes.bool,
  dropup: PropTypes.bool,
  error: PropTypes.string,
  groupLabel: PropTypes.node,
  height: PropTypes.string,
  icon: PropTypes.node,
  ignoreClick: PropTypes.string,
  information: PropTypes.string,
  label: PropTypes.string,
  left: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func,
    icon: PropTypes.node,
    label: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.bool,
    separator: PropTypes.bool,
  })).isRequired,
  position: PropTypes.string,
  required: PropTypes.bool,
  small: PropTypes.bool,
  touched: PropTypes.bool,
  width: PropTypes.string,
};

Dropdown.defaultProps = {
  caret: true,
  className: '',
  ignoreClick: '',
  position: 'fixed',
  small: false,
};

const StyledMoreDropdown = styled(Dropdown)`
  > div > button {
    background-color: transparent;
    border: 1px solid transparent;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const MoreDropdown = ({ ...props }) => (
  <StyledMoreDropdown
    caret={false}
    icon={<MoreIcon />}
    left
    {...props}
  />
);

export default React.memo(Dropdown);
