import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { PRIMARY } from '@innovatrix/styles';

const Container = styled.div`
  cursor: pointer;
  padding-bottom: 12px;
  padding-top: 12px;
  width: ${({ width }) => width}px;
`;

const SmallBarLine = styled.div`
  background-color: ${PRIMARY};
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 24px;
`;

const BarLine = styled.div`
  background-color: ${PRIMARY};
  height: 1px;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 100%;
`;

const ThresholdBarPreview = React.memo(({ containerRef }) => (
  <Container width={containerRef.current ? containerRef.current.clientWidth : 200}>
    <SmallBarLine />
    <BarLine />
    <SmallBarLine />
  </Container>
));
ThresholdBarPreview.displayName = 'ThresholdBarPreview';
ThresholdBarPreview.propTypes = {
  containerRef: PropTypes.object.isRequired,
};

export default ThresholdBarPreview;
