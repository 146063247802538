import React from 'react';

import Mutation from './Mutation';
import Query from './Query';

export const MUTATION = 'Mutation';
export const QUERY = 'Query';

const GraphQLHOC = ({
  skip, variables,
  isDxApi, namespace, mutation,
  query, name, refetchQueries,
}) => {
  if (mutation && query) {
    throw Error('Specify a mutation or a query, not both.');
  }

  if (!mutation && !query) {
    throw Error('Specify a mutation or a query.');
  }

  return function HOCOuterWrapper(WrappedComponent) {
    return function HOCInnerWrapper(props) {
      const newVariables = typeof variables === 'function' ? variables(props) : variables;
      if (mutation) {
        return (
          <Mutation
            isDxApi={isDxApi}
            namespace={namespace}
            mutation={mutation}
            refetchQueries={typeof refetchQueries === 'function' ? refetchQueries(props) : refetchQueries}
            variables={newVariables}
          >
            {(mutate, data) => <WrappedComponent {...props} {...{ [name || 'mutate']: mutate }} data={data} />}
          </Mutation>
        );
      }
      return (
        <Query
          isDxApi={isDxApi}
          namespace={namespace}
          query={query}
          variables={newVariables}
          skip={skip ? skip(newVariables) : false}
        >
          {(data, refetch) => <WrappedComponent {...props} {...{ [name || 'result']: data }} refetch={refetch} />}
        </Query>
      );
    };
  };
};

export default GraphQLHOC;
