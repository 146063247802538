import { queryBuilder } from '@innovatrix/utils/dx';

export const filterUsersQuery = queryBuilder({
  variables: {
    searchString: { type: 'String' },
  },
  fields: {
    _getManagersAndCoaches: {
      varArgs: { searchString: 'searchString' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});
