import * as React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import IconButton from './IconButton';
import { ICON_BUTTON, TEXT_BUTTON, TOGGLE_TYPES, ALLOWED_FLAVORS } from './_constants';

const ToggleButton = ({
  activeColor,
  activeFlavor,
  activeIcon,
  activeLabel,
  className,
  color,
  disabled,
  flavor,
  icon,
  onClick,
  small,
  label,
  toggleType,
  tooltip,
  type,
  value,
  width,
}) => {
  // Only one real value (true or false) propagated from instance where component is used
  const handleToggle = React.useCallback(() => {
    onClick(!value);
  }, [onClick, value]);

  // Value is seen as wether component is active or not.
  // According to value switch color, icon and label to active variant when provided.
  // Color, icon and label can be seen as the inactive values of the toggle.
  const toggleProps = React.useMemo(() => ({
    color: value ? activeColor : color,
    icon: value ? (activeIcon || icon) : icon,
    label: value ? activeLabel : label,
    flavor: value ? activeFlavor : flavor,
  }), [activeColor, activeIcon, activeLabel, activeFlavor, color, icon, label, flavor, value]);

  const isIconToggle = React.useMemo(() => toggleType === ICON_BUTTON, [toggleType]);
  return (
    <React.Fragment>
      {isIconToggle ?
        <IconButton
          className={className}
          disabled={disabled}
          flavor={flavor}
          onClick={handleToggle}
          small={small}
          tooltip={tooltip}
          type={type}
          {...toggleProps}
        /> :
        <Button
          className={className}
          disabled={disabled}
          flavor={flavor}
          onClick={handleToggle}
          small={small}
          tooltip={tooltip}
          type={type}
          width={width}
          {...toggleProps}
        />}
    </React.Fragment>
  );
};

ToggleButton.defaultProps = { flavor: TEXT_BUTTON, activeFlavor: TEXT_BUTTON };

ToggleButton.propTypes = {
  activeColor: PropTypes.string,
  activeFlavor: PropTypes.oneOf(ALLOWED_FLAVORS),
  activeIcon: PropTypes.node,
  activeLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  flavor: PropTypes.oneOf(ALLOWED_FLAVORS),
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  small: PropTypes.bool,
  toggleType: PropTypes.oneOf(TOGGLE_TYPES).isRequired,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.bool.isRequired,
  width: PropTypes.string,
};

export default React.memo(ToggleButton);
