import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { MAIN_PAGE } from '../../styles';

const SectionWrapper = styled.div`
  background-color: ${MAIN_PAGE};
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  padding: 24px 64px;

  > h2 {
    margin-bottom: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &.title {
      margin: 0 0 12px 0;
    }
  }

  @media screen and (min-width: 1700px) {
    padding-left: calc(64px + 1vw);
    padding-right: calc(64px + 1vw);
  }
`;

const Section = ({ className, children, title }) => (
  <SectionWrapper className={`dx-section ${className}`}>
    {title && <h2 className="dx-title">{title}</h2>}
    {children}
  </SectionWrapper>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

Section.defaultProps = {
  className: '',
  title: '',
};

export default Section;
