import * as React from 'react';
import PropTypes from 'prop-types';
import formikField from '@innovatrix/components/fields/formikField';

import Dropdown from '../dropdown';

const getOptions = (options, onChange) => (
  (options || []).map((o) => {
    if (!o.options) {
      return {
        action: () => (o.optionalValue ? onChange(o.value, o.optionalValue) : onChange(o.value)),
        icon: o.icon || null,
        label: o.label,
        selected: o.selected,
        separator: o.separator,
      };
    }
    return {
      options: getOptions(o.options, onChange),
      label: o.label,
      selected: o.selected,
      separator: o.separator,
    };
  })
);

const getLabel = (options, value) => {
  if (!options || !value) { return null; }
  for (const o of options) {
    if (Array.isArray(o.value) && o.value.includes(value)) {
      return o.label;
    }
    else if (o.value === value) {
      return o.label;
    }
    if (o.options) {
      const label = getLabel(o.options, value);
      if (label) { return label; }
    }
  }
  return null;
};

const SelectFieldComponent = ({
  blur,
  className,
  customAction,
  customNode,
  disabled,
  dropup,
  error,
  groupLabel,
  height,
  icon,
  ignoreClick,
  information,
  left,
  onChange,
  options: _options,
  placeholder,
  position,
  required,
  small,
  touched,
  value,
  width,
}) => {
  const label = React.useMemo(() => getLabel(_options, value) || placeholder || groupLabel, [_options, groupLabel, placeholder, value]);
  const options = React.useMemo(() => getOptions(_options, onChange), [_options, onChange]);
  return (
    <Dropdown
      blur={blur}
      caret={!disabled}
      className={`${className}${ignoreClick ? ` ${ignoreClick}` : ''}`}
      customAction={customAction}
      customNode={customNode}
      disabled={disabled}
      dropup={dropup}
      error={error}
      groupLabel={groupLabel}
      height={height}
      icon={icon}
      information={information}
      label={label}
      left={left}
      options={options}
      position={position}
      required={required}
      small={small}
      touched={touched}
      width={width}
    />
  );
};

SelectFieldComponent.propTypes = {
  blur: PropTypes.func,
  className: PropTypes.string,
  customAction: PropTypes.func,
  customNode: PropTypes.node,
  disabled: PropTypes.bool,
  dropup: PropTypes.bool,
  error: PropTypes.string,
  groupLabel: PropTypes.node,
  height: PropTypes.string,
  icon: PropTypes.any,
  ignoreClick: PropTypes.string,
  information: PropTypes.string,
  left: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  position: PropTypes.string,
  required: PropTypes.bool,
  small: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
};

SelectFieldComponent.defaultProps = {
  className: '',
  ignoreClick: '',
};

export const SelectField = React.memo(SelectFieldComponent);
SelectField.displayName = 'SelectField';

export default formikField(SelectField);
