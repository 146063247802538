import { createSelector } from 'reselect';
import queryString from 'query-string';
import { MULTI_SINGLE_QUANTIFIED, MULTI_SINGLE, MULTI_MULTI, MULTIPLE_TEXT_ENTRY, TEXT_ENTRY, RANKING, MATRIX, INTRODUCTION, OVERALL_ADVICE, ADVICE_COMMENT, REVIEW } from '@innovatrix/constants';

import { validateRankingQuestion, validateMultipleTextEntry, validateMultipleChoiceMultipleSelection, validateMultipleChoiceSingleSelection } from '../../modules/innovatrix/assessments/utils/validators';

import { DEFAULT_ENTITIES, DEFAULT_ENTITY } from './utils';

const getAssessment = state => state.data.assessment;
const ADVICE_QUESTION_GROUP = 'ADVICE_QUESTION_GROUP';

const getAssessmentRelations = createSelector(
  getAssessment,
  (assessments) => assessments.relations,
);

const getAssessmentEntities = createSelector(
  getAssessment,
  (assessment) => assessment.entities,
);

const getAssessmentHasVersionsRelations = createSelector(
  getAssessmentRelations,
  (relations) => relations.assessmentHasVersions,
);

export const getActiveAssessmentVersion = createSelector(
  getAssessment,
  (assessmentData) => assessmentData.entities[queryString.stringify({ id: 'activeAssessmentVersion' })] || DEFAULT_ENTITY,
);

export const getAssessmentVersions = (state, { assessmentId }) => createSelector(
  getAssessmentHasVersionsRelations,
  (relations) => relations[queryString.stringify({ assessmentId })] || DEFAULT_ENTITIES,
)(state);

export const getAssessmentVersion = (assessmentVersions = [], assessmentVersionId) => {
  for (const a of assessmentVersions) {
    if (a.id === assessmentVersionId) {
      return a;
    }
    for (const oldA of a.oldAssessmentVersions) {
      if (oldA.id === assessmentVersionId) {
        return oldA;
      }
    }
  }
  return null;
};

// In read mode we look at questions by questionGroup instead of only their order.
export const getAssessmentVersionReadMode = (state, { assessmentId, assessmentVersionId }) => {
  const assessmentVersions = getAssessmentVersions(state, { assessmentId }).data.assessmentVersions || [];
  const version = getAssessmentVersion(assessmentVersions, assessmentVersionId);
  if (!version) {
    return null;
  }
  version.questions.sort((q1, q2) => q1.order - q2.order);

  // If type is review, then the first 2 questions (advice type and comment) do NOT have badgeLabels
  if (version.type === REVIEW) {
    const [advice, comment, ...remainingQuestions] = version.questions;
    // Clear the badges for the advice and comment questions.
    const adviceQuestion = {
      id: 'none',
      title: 'none',
      questions: [{
        ...advice,
        badgeLabel: '',
        quantify: false,
      }],
    };
    const commentQuestion = {
      id: 'none',
      title: 'none',
      questions: [{
        ...comment,
        badgeLabel: '',
        quantify: false,
      }],
    };
    // Create the remaining question objects
    const result = remainingQuestions.reduce((acc, q, i) => {
      const question = {
        ...q,
        badgeLabel: `Q${i + 1}`,
        quantify: q.type === MULTI_SINGLE_QUANTIFIED,
      };
      if (question.deletedOn) {
        return acc;
      }
      if (question.questionGroup) {
        return {
          ...acc,
          [question.questionGroup.id]: {
            id: question.questionGroup.id,
            title: question.questionGroup.title,
            questions: [...(acc[question.questionGroup.id] && acc[question.questionGroup.id].questions || []), question],
          },
        };
      }
      else {
        return {
          ...acc,
          [`none-${question.order}`]: {
            id: 'none',
            title: 'None',
            questions: [...(acc.none && acc.none.questions || []), question],
          },
        };
      }
    }, {});
    // Sort the result
    const sortedResult = Object.values(result).sort((a, b) => a.order - b.order);
    // Add all questions back together in the correct order
    const questionsArray = [adviceQuestion, commentQuestion, ...sortedResult];
    return {
      active: version.active,
      questionGroups: questionsArray,
    };
  }

  const result = version.questions
    .filter(q => !q.deletedOn)
    .reduce((acc, q) => {
      const question = {
        ...q,
        badgeLabel: `Q${q.order + 1}`,
        quantify: q.type === MULTI_SINGLE_QUANTIFIED,
      };
      if (question.deletedOn) {
        return acc;
      }
      if (question.questionGroup) {
        return {
          ...acc,
          [question.questionGroup.id]: {
            id: question.questionGroup.id,
            title: question.questionGroup.title,
            questions: [...(acc[question.questionGroup.id] && acc[question.questionGroup.id].questions || []), question],
          },
        };
      }
      else {
        return {
          ...acc,
          [`none-${question.order}`]: {
            id: 'none',
            title: 'None',
            questions: [...(acc.none && acc.none.questions || []), question],
          },
        };
      }
    }, {});

  // let sortedResult = Object.values(result).sort(((a, b) => (a.title || '').localeCompare(b.title || '')));
  const sortedResult = Object.values(result).sort((a, b) => a.order - b.order);
  // if (noneGroup) {
  //   sortedResult = sortedResult.filter(res => res.id !== 'none');
  //   sortedResult = [...sortedResult, noneGroup];
  // }

  return {
    active: version.active,
    questionGroups: sortedResult,
  };
};

// Used for filtering wrongly defined questions.
function filterWronglyDefinedQuestion(question) {
  if (!question.title || question.deletedOn) {
    return false;
  }
  switch (question.type) {
    case MULTI_SINGLE:
    case MULTI_SINGLE_QUANTIFIED:
      return !validateMultipleChoiceSingleSelection(question.options);
    case MULTI_MULTI:
      return !validateMultipleChoiceMultipleSelection(question.options);
    case MULTIPLE_TEXT_ENTRY:
      return !validateMultipleTextEntry(question.options);
    case INTRODUCTION:
    case TEXT_ENTRY:
    case OVERALL_ADVICE:
    case ADVICE_COMMENT:
      return true;
    case RANKING:
      return !validateRankingQuestion(question.options);
    case MATRIX: // TODOs
    default:
      return false;
  }
}

export const getAssessmentVersionQuestionGroups = (state, { assessmentVersionId }) => {
  const assessmentEntities = getAssessmentEntities(state);
  const assessmentVersion = assessmentEntities[queryString.stringify({ assessmentVersionId })];
  if (!assessmentVersion || !assessmentVersion.questionGroups) {
    return [];
  }

  // Check if current assessment version is of type review
  const hasReviewQuestions = assessmentVersion.questionGroups
    .map(qg => qg.questions)
    .reduce((acc, val) => acc.concat(val), [])
    .find(q => q.type === OVERALL_ADVICE);
  if (hasReviewQuestions) {
    // The last questionGroup entry should be the Advice and General comment
    // Let's add a default title for this
    assessmentVersion.questionGroups[assessmentVersion.questionGroups.length - 1] = {
      ...assessmentVersion.questionGroups[assessmentVersion.questionGroups.length - 1],
      id: ADVICE_QUESTION_GROUP,
      title: 'Advice & General comment',
    };
    assessmentVersion.questions.forEach(question => {
      if (question.type === OVERALL_ADVICE || question.type === ADVICE_COMMENT) {
        question.questionGroupId = ADVICE_QUESTION_GROUP;
      }
    });
  }
  return assessmentVersion.questionGroups;
};

export const getAssessmentVersionQuestions = (state, { assessmentVersionId }) => {
  const assessmentEntities = getAssessmentEntities(state);
  const assessmentVersion = assessmentEntities[queryString.stringify({ assessmentVersionId })];
  if (!assessmentVersion || !assessmentVersion.questionGroups) {
    return [];
  }
  let result = [];
  assessmentVersion.questionGroups.forEach(({ questions }) => {
    result = result.concat(questions.filter(filterWronglyDefinedQuestion));
  });
  const sortedQuestions = result.sort((q1, q2) => q1.order - q2.order);

  // Place advice at the end.
  if (sortedQuestions[0] && sortedQuestions[0].type === OVERALL_ADVICE && sortedQuestions[1] && sortedQuestions[1].type === ADVICE_COMMENT) {
    sortedQuestions[0].questionGroupId = ADVICE_QUESTION_GROUP;
    sortedQuestions[1].questionGroupId = ADVICE_QUESTION_GROUP;
    const [overallAdviceQuestion, adviceCommentQuestion, ...restSortedQuestions] = sortedQuestions;
    return [...restSortedQuestions, overallAdviceQuestion, adviceCommentQuestion];
  }

  return sortedQuestions;
};
