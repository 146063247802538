import {
  CHANGE_INNOBOARD_CRITERIA,
  CHANGE_INNOBOARD_FILTER,
  CLOSE_ASSUMPTION_ACTION_MODAL,
  COPY_ASSUMPTIONS,
  FOCUS_ASSUMPTION_COMPLETED,
  FOCUS_ASSUMPTION,
  MOVE_ASSUMPTIONS,
  OPEN_ASSUMPTION_ACTION_MODAL,
  RESET_INNOBOARD_FILTERS,
  SCROLL_TO_LAST_CRITERION_COMPLETED,
  SCROLL_TO_LAST_CRITERION,
  SELECT_SEGMENT,
  UPDATE_ASSUMPTION_ERROR,
  UPDATE_ASSUMPTION_SUCCESS,
  UPDATE_ASSUMPTION,
} from '../constants';

const initialFilters = {
  criteria: [],
  onlyShowDuplicates: false,
  onlyShowKeyUncertainties: false,
  onlyShowRelations: false,
  onlyShowValidations: false,
};

const initialState = {
  filters: initialFilters,
  list: {
    selectedSegment: 'Overview',
  },
  updating: false,
  focusAssumptionIndex: null,
  scrollToLast: false,
  assumptionAction: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FOCUS_ASSUMPTION: {
      return {
        ...state,
        focusAssumptionIndex: action.assumptionIndex,
      };
    }
    case FOCUS_ASSUMPTION_COMPLETED: {
      return {
        ...state,
        focusAssumptionIndex: null,
      };
    }
    case OPEN_ASSUMPTION_ACTION_MODAL: {
      const { type, ...restAction } = action;
      return {
        ...state,
        assumptionAction: restAction,
      };
    }
    case COPY_ASSUMPTIONS:
    case MOVE_ASSUMPTIONS:
    case CLOSE_ASSUMPTION_ACTION_MODAL: {
      return {
        ...state,
        assumptionAction: null,
      };
    }
    case SCROLL_TO_LAST_CRITERION: {
      return {
        ...state,
        scrollToLast: true,
      };
    }
    case SCROLL_TO_LAST_CRITERION_COMPLETED: {
      return {
        ...state,
        scrollToLast: false,
      };
    }

    /**
     * @deprecated in favor of CHANGE_INNOBOARD_FILTER
     */
    case CHANGE_INNOBOARD_FILTER: {
      const [[key, value]] = Object.entries(action.filter);
      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: value,
        },
      };
    }
    case SELECT_SEGMENT: {
      const { segmentId } = action;
      return {
        ...state,
        list: {
          selectedSegment: segmentId,
        },
      };
    }
    case RESET_INNOBOARD_FILTERS: {
      return {
        ...initialState,
        list: state.list || initialState.list,
      };
    }
    case CHANGE_INNOBOARD_CRITERIA: {
      const { value } = action;
      let criteria;
      if (typeof state.filters.criteria === typeof []) {
        if (state.filters.criteria.includes(value)) {
          const index = state.filters.criteria.indexOf(value);
          criteria = [...state.filters.criteria];
          criteria.splice(index, 1);
        }
        else {
          criteria = [...state.filters.criteria, value];
        }
      }
      else {
        criteria = [value];
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          criteria,
        },
      };
    }
    case UPDATE_ASSUMPTION: {
      return {
        ...state,
        updating: true,
      };
    }
    case UPDATE_ASSUMPTION_ERROR:
    case UPDATE_ASSUMPTION_SUCCESS: {
      return {
        ...state,
        updating: false,
      };
    }
    default:
      return state;
  }
};
