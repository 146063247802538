import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { PRIMARY } from '@innovatrix/styles';

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 300px;
  justify-content: center;
  width: 100%;

  > div {
    margin: 0 auto;
  }
`;

// Code taken from here:
// https://github.com/davidhu2000/react-spinners/blob/master/src/PulseLoader.tsx
const PulseComponent = styled.div`
  animation-fill-mode: both;
  animation: ${({ i }) => `loaderpulse 0.75s ${i * 0.12}s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)`};
  background-color: ${({ color }) => color};
  border-radius: 100%;
  display: inline-block;
  height: ${({ size }) => `${size}px`};
  margin: ${({ margin }) => `${margin}px`};
  width: ${({ size }) => `${size}px`};

  @keyframes loaderpulse {
    0% { transform: scale(1); opacity: 1; }
    45% { transform: scale(0.1); opacity: 0.7; }
    80% { transform: scale(1); opacity: 1; }
  }
`;

const Loader = ({ loading, size, margin, color }) => (loading ? (
  <div>
    <PulseComponent size={size} margin={margin} i={1} color={color} />
    <PulseComponent size={size} margin={margin} i={2} color={color} />
    <PulseComponent size={size} margin={margin} i={3} color={color} />
  </div>
) : null);

Loader.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
  margin: PropTypes.number,
  size: PropTypes.number,
};

Loader.defaultProps = {
  color: PRIMARY,
  loading: true,
  margin: 2,
  size: 20,
};

const Spinner = ({ className, size, styles }) => (
  <SpinnerWrapper className={`dx-spinner${className ? ` ${className}` : ''}`} {...styles}>
    <Loader loading size={size} />
  </SpinnerWrapper>
);

Spinner.defaultProps = {
  className: '',
  size: 20,
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  styles: PropTypes.object,
};

export default React.memo(Spinner);
