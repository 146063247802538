import { MULTI_SINGLE, MULTI_SINGLE_QUANTIFIED, RANKING, MULTI_MULTI, TEXT_ENTRY, MATRIX, MULTIPLE_TEXT_ENTRY, ADVICE_COMMENT, OVERALL_ADVICE } from '@innovatrix/constants';

export function validateMultipleChoiceSingleSelection(fields) {
  if (fields && fields.length < 2) {
    return { _error: 'Invalid question - please define at least 2 options to use this question in your model.' };
  }
  return undefined;
}

export function validateMultipleChoiceMultipleSelection(fields) {
  if (fields && fields.length < 2) {
    return { _error: 'Invalid question - please define at least 2 options to use this question in your model.' };
  }
  return undefined;
}

export function validateRankingQuestion(fields) {
  if (fields && fields.length < 2) {
    return { _error: 'Invalid question - please define at least 2 options to use this question in your model.' };
  }
  return undefined;
}

export function validateMultipleTextEntry(fields) {
  const errors = {};

  if (fields) {
    if (fields.length < 2) {
      errors._error = 'Invalid question - please define at least 2 answers or revert back to the single field question.';
    }
    fields.forEach(({ title }, i) => {
      if (!title) {
        if (!errors.answers) {
          errors.answers = [];
        }
        errors.answers[i] = { title: { _error: 'Please provide a question.' } };
      }
    });
  }

  return errors._error || errors.answers ? errors : undefined;
}

export function validateAssessmentVersion({ title, questions }, { focusedQuestionIndex }) {
  const errors = {};
  if (!title) {
    errors.title = 'Version title is required.';
  }

  // if (!displayName) {
  //   errors.displayName = 'Display name is required.';
  // }

  const questionsArrayErrors = [];
  if (!Array.isArray(questions)) {
    return errors;
  }
  questions.forEach(({ options, title: questionTitle, type }, questionIndex) => {
    const questionErrors = {};
    if (!questionTitle && focusedQuestionIndex !== questionIndex) {
      questionErrors.title = 'Question title is required.';
    }
    switch (type) {
      case MULTI_SINGLE:
      case MULTI_SINGLE_QUANTIFIED:
        questionErrors.options = validateMultipleChoiceSingleSelection(options);
        break;
      case MULTI_MULTI:
        questionErrors.options = validateMultipleChoiceMultipleSelection(options);
        break;
      case MULTIPLE_TEXT_ENTRY:
        questionErrors.options = validateMultipleTextEntry(options);
        break;
      case ADVICE_COMMENT:
      case TEXT_ENTRY: break;
      case MATRIX: break;
      case RANKING:
        questionErrors.options = validateRankingQuestion(options);
        break;
      default: break;
    }
    if (questionErrors.options || questionErrors.title) {
      questionsArrayErrors[questionIndex] = questionErrors;
    }
  });
  if (questionsArrayErrors.length > 0) {
    errors.questions = questionsArrayErrors;
  }
  return errors;
}

export function validateAssessmentVersionReadMode({ questionGroups }) {
  const errors = {};

  const questionGroupErrors = [];
  if (!Array.isArray(questionGroups)) {
    return errors;
  }
  questionGroups.forEach(({ questions }, questionGroupIndex) => {
    questionGroupErrors[questionGroupIndex] = questionGroupErrors[questionGroupIndex] || [];
    if (!Array.isArray(questions)) {
      return errors;
    }
    questions.forEach(({ options, title: questionTitle, type }, questionIndex) => {
      const questionErrors = {};
      if (!questionTitle) {
        questionErrors.title = { _error: 'Question title is required.' };
      }
      switch (type) {
        case OVERALL_ADVICE:
        case MULTI_SINGLE:
        case MULTI_SINGLE_QUANTIFIED:
          questionErrors.options = validateMultipleChoiceSingleSelection(options);
          break;
        case MULTI_MULTI:
          questionErrors.options = validateMultipleChoiceMultipleSelection(options);
          break;
        case MULTIPLE_TEXT_ENTRY:
          questionErrors.options = validateMultipleTextEntry(options);
          break;
        case TEXT_ENTRY: break;
        case MATRIX: break;
        case RANKING: break;
        default: break;
      }
      questionGroupErrors[questionGroupIndex][questionIndex] = questionErrors;
    });
  });

  errors.questionGroups = questionGroupErrors;
  return errors;
}
