import PropTypes from 'prop-types';

export const projectPropType = PropTypes.shape({
  averagePerCriterion: PropTypes.object,
  averagePerDomain: PropTypes.object,
  id: PropTypes.string,
  innovationManager: PropTypes.string,
  keywords: PropTypes.object,
  label: PropTypes.string,
  majorDomain: PropTypes.string,
  maxPerCriterion: PropTypes.object,
  meanPerCriterion: PropTypes.object,
  meanPerDomain: PropTypes.object,
  minPerCriterion: PropTypes.object,
  missionStatement: PropTypes.string,
  pitch: PropTypes.string,
  primMember: PropTypes.string,
  reviews: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.string,
});
