import {
  ERROR_APPLICATION_META_DATA,
  FETCH_APPLICATION_META_DATA,
  RECEIVE_APPLICATION_META_DATA,
  ERROR_PROJECT_TYPES,
  FETCH_PROJECT_TYPES,
  RECEIVE_PROJECT_TYPES,
  FETCH_USER_PROFILES,
  ERROR_USER_PROFILES,
  RECEIVE_USER_PROFILES,
} from '../../constants';

import { errorEntity, fetchEntity, receiveEntity, errorEntities, fetchEntities, receiveEntities } from './utils';

const initialState = {
  entities: {},
  relations: {
    projectTypes: {},
    userProfiles: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_APPLICATION_META_DATA: return errorEntity(state, { id: 'application' });
    case FETCH_APPLICATION_META_DATA: return fetchEntity(state, { id: 'application' });
    case RECEIVE_APPLICATION_META_DATA: return receiveEntity(state, { data: action.data, id: 'application' });

    case ERROR_PROJECT_TYPES: return errorEntities('projectTypes', state, action);
    case FETCH_PROJECT_TYPES: return fetchEntities('projectTypes', state, action);
    case RECEIVE_PROJECT_TYPES: return receiveEntities('projectTypes', state, action);

    case RECEIVE_USER_PROFILES: return receiveEntities('userProfiles', state, action);
    case ERROR_USER_PROFILES: return errorEntities('userProfiles', state, action);
    case FETCH_USER_PROFILES: return fetchEntities('userProfiles', state, action);

    default: return state;
  }
};

