import { mutationBuilder } from '@innovatrix/utils/dx';

export const decisonSettingMutation = mutationBuilder({
  variables: {
    willSync: { type: 'Boolean!' },
  },
  fields: {
    syncDecisions: {
      varArgs: { willSync: 'willSync' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});
