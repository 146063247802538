import { createSelector } from 'reselect';

import { LOADING } from '../constants';

import { getProjectBoards } from './data/projectSelectors';

const getProject = (state) => state.data.project;

export const getInnoboardUi = (state) => state.innoBoardUi;

export const getInnoBoardFilters = createSelector(
  getInnoboardUi,
  (state) => state.filters,
);

export const getSelectedSegments = createSelector(
  getProject,
  (state) => state.selectedSegments,
);

export const getIsFiltering = createSelector(
  getProject,
  (state) => state.isFiltering,
);

export const getScrollToLast = createSelector(
  getInnoboardUi,
  (state) => state.scrollToLast,
);

export const getAssumptionAction = createSelector(
  getInnoboardUi,
  (state) => state.assumptionAction,
);

const getInnoUpdateIteration = (innoBoards, innoBoardOrder) => innoBoards.reduce((acc, board) => {
  if (board.order < innoBoardOrder) {
    acc[board.order] = { order: board.order };
  }
  return acc;
}, []);

export const getCriteriaOverviewList = (state, { innoBoards, innoBoardOrder }) => {
  const result = {};
  // What criteria should we show?
  const { criteria: criteriaFilter, onlyShowKeyUncertainties } = getInnoBoardFilters(state);
  const sortedInnoBoards = innoBoards.map(x => x).sort((i1, i2) => i2.order - i1.order);
  const innoUpdateIteration = getInnoUpdateIteration(sortedInnoBoards, innoBoardOrder);
  const currentBoard = sortedInnoBoards.find(i => i.order === innoBoardOrder);
  const sortedCriteria = Object.values(currentBoard.model.version.criteria).sort((c1, c2) => c1.order - c2.order);
  const segmentCriterion = Object.values(sortedCriteria[0].instances);
  let lastUpdate = {};

  for (const { title: segmentName, segmentId } of segmentCriterion) {
    // eslint-disable-next-line
    result[`${segmentName}-${segmentId}`] = sortedCriteria.reduce((acc, { instances, ...rest }) => {
      // When it's all should be shown so when it's not ALL and it includes the list off filtered items it shouldn't be shown
      if (criteriaFilter !== 'ALL' && criteriaFilter.includes(rest.order - 1)) {
        return acc;
      }
      const instance = Object.values(instances).find(ins => ins.segmentId === segmentId);
      if (!instance) {
        return acc;
      }

      if (lastUpdate.on < instance.updatedOn || !lastUpdate.on) {
        lastUpdate = {
          by: instance.updatedBy,
          on: instance.updatedOn,
        };
      }

      // We sort our assumptions through order and in next Reduce we'll make our innoBoard correspond to that
      const sortedAssumptions = instance.assumptions.map(a => a).sort((item1, item2) => item1.order - item2.order);

      let filteredAssumptions;
      // We filter out the assumptions we don't need
      if (onlyShowKeyUncertainties) {
        if (onlyShowKeyUncertainties) {
          filteredAssumptions = sortedAssumptions.filter(assumption => assumption.keyUncertainty);
        }
      }
      else {
        filteredAssumptions = sortedAssumptions;
      }

      const previousAssumptions = sortedInnoBoards.reduce((ac, innoBoard) => {
        // Boards with a higher order and current order don't matter so we ignore them
        if (innoBoard.order >= innoBoardOrder) {
          return ac;
        }

        const criterion = innoBoard.model.version.criteria[rest.id];
        // We get the instance we need with the unique segmentId in case it's been moved
        const innoInstance = Object.values(criterion.instances).find(ins => ins.segmentId === segmentId);

        let normalizedAssumptions;
        // When we're at the board before current one than we need to look at current board to construct our chain
        // Mapping with find will always make the previousAssumptions order correspond to the one off the assumption we're looking at
        // This way we can avoid over complicated hashing structures and take in account moved assumptions
        if (innoBoard.order === innoBoardOrder - 1) {
          // When there's no duplicatedFrom it's a new one
          normalizedAssumptions = filteredAssumptions.map(assumption => assumption.duplicatedFrom && innoInstance.assumptions.find(({ id }) => id === assumption.duplicatedFrom));
        }
        else if (innoBoard.order >= 0) {
          // When we're not the previous board in comparison to our current we'll look at the previously normalized one
          // When there's no duplicatedFrom it's a new one
          normalizedAssumptions = (ac[innoBoard.order + 1] || []).map(assumption => assumption && assumption.duplicatedFrom && innoInstance.assumptions.find(({ id }) => id === assumption.duplicatedFrom));
        }

        return {
          ...ac,
          [innoBoard.order]: normalizedAssumptions,
        };
      }, {});
      // We don't need the instances but just the details for the tableRowheaders
      return [...acc, {
        ...rest,
        // Needed for edit
        instance: {
          ...instance,
          assumptions: filteredAssumptions.filter(a => !a.archivedOn),
          archivedAssumptions: filteredAssumptions.filter(a => a.archivedOn),
          previousAssumptions,
        },
      }];
    }, []);
  }

  return {
    innoUpdateIteration,
    lastUpdate,
    list: result,
  };
};

export const getCriteriaList = (state, { innoBoards, criteria, innoBoardOrder, segmentId }) => {
  // What criteria should we show?
  const { criteria: criteriaFilter, onlyShowKeyUncertainties } = getInnoBoardFilters(state);
  // Reverse sorting since our logic goes from top to bottom
  const sortedInnoBoards = innoBoards.map(x => x).sort((i1, i2) => i2.order - i1.order);
  const innoUpdateIteration = getInnoUpdateIteration(sortedInnoBoards, innoBoardOrder);

  // Reduce function to show it
  return criteria.reduce((acc, { instances, ...rest }) => {
    // When it's all should be shown so when it's not ALL and it includes the list off filtered items it shouldn't be shown
    if (criteriaFilter !== 'ALL' && criteriaFilter.includes(rest.order - 1)) {
      return acc;
    }

    const instance = Object.values(instances).find(i => i.segmentId === segmentId);

    // When this criterion isn't filled in for this segment but is in others we should continue
    if (!instance) {
      return acc;
    }

    // We sort our assumptions through order and in next Reduce we'll make our innoBoard correspond to that
    const sortedAssumptions = instance.assumptions.map(a => a).sort((item1, item2) => item1.order - item2.order);

    let filteredAssumptions;
    // We filter out the assumptions we don't need
    if (onlyShowKeyUncertainties) {
      if (onlyShowKeyUncertainties) {
        filteredAssumptions = sortedAssumptions.filter(assumption => assumption.keyUncertainty);
      }
    }
    else {
      filteredAssumptions = sortedAssumptions;
    }

    const previousAssumptions = sortedInnoBoards.reduce((ac, innoBoard) => {
      // Boards with a higher order and current order don't matter so we ignore them
      if (innoBoard.order >= innoBoardOrder) {
        return ac;
      }

      const criterion = innoBoard.model.version.criteria[rest.id];
      // We get the instance we need with the unique segmentId in case it's been moved
      const innoInstance = Object.values(criterion.instances).find(i => i.segmentId === segmentId);

      let normalizedAssumptions;
      // When we're at the board before current one than we need to look at current board to construct our chain
      // Mapping with find will always make the previousAssumptions order correspond to the one off the assumption we're looking at
      // This way we can avoid over complicated hashing structures and take in account moved assumptions
      if (innoBoard.order === innoBoardOrder - 1) {
        // When there's no duplicatedFrom it's a new one
        normalizedAssumptions = filteredAssumptions.map(assumption => assumption.duplicatedFrom && innoInstance.assumptions.find(({ id }) => id === assumption.duplicatedFrom));
      }
      // When we're not the previous board in comparison to our current we'll look at the previously normalized one
      else if (innoBoard.order >= 0) {
        // When there's no duplicatedFrom it's a new one
        normalizedAssumptions = (ac[innoBoard.order + 1] || []).map(assumption => assumption && assumption.duplicatedFrom && innoInstance.assumptions.find(({ id }) => id === assumption.duplicatedFrom));
      }
      return {
        ...ac,
        [innoBoard.order]: normalizedAssumptions,
      };
    }, {});

    // We don't need the instances but just the details for the tableRowheaders
    return {
      ...acc,
      lastUpdate: {
        by: (acc.lastUpdate.on < instance.updatedOn) || !acc.lastUpdate.on ? instance.updatedBy : acc.lastUpdate.by,
        on: (acc.lastUpdate.on < instance.updatedOn) || !acc.lastUpdate.on ? instance.updatedOn : acc.lastUpdate.on,
      },
      list: [
        ...acc.list,
        {
          ...rest,
          // Needed for edit
          instance: {
            ...instance,
            assumptions: filteredAssumptions.filter(a => !a.archivedOn),
            archivedAssumptions: filteredAssumptions.filter(a => a.archivedOn),
            previousAssumptions,
          },
        },
      ],
      innoUpdateIteration,
    };
  }, { list: [], lastUpdate: {} });
};

export const getInnoBoardData = (state, { projectId, innoboardId }) => {
  const projectData = getProjectBoards(state, { projectId });
  if (!projectData || projectData._status === LOADING) { return []; }
  const currentBoard = projectData.innoBoards[innoboardId];
  if (!currentBoard) { return []; }
  const { criteria } = currentBoard.model.version;
  const orderedCriteria = Object.values(criteria).sort((c1, c2) => c1.order - c2.order);
  const segments = [];
  orderedCriteria.forEach(({ instances }) => {
    const orderedInstances = Object.values(instances).sort((i1, i2) => i1.order - i2.order);
    orderedInstances.forEach((instance) => {
      segments[instance.order] = [...(segments[instance.order] || []), instance];
    });
  });
  return segments;
};
