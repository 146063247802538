import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { GREY_2, GREY_LIGHT_3 } from '@innovatrix/styles';

const translateNumberToClass = (input) => {
  switch (input) {
    case 0: return 'zero';
    case 1: return 'one';
    case 2: return 'two';
    case 3: return 'three';
    default: throw new Error('Impossible amount of breadcrumbs');
  }
};

const breadCrumbsStyles = css({
  alignItems: 'center',
  color: GREY_2,
  display: 'flex',
  fontSize: 12,
  maxWidth: 160,
  marginRight: 6,
  // minWidth: 160,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  '> p:first-child': {
    fontWeight: 'bold',
  },
  '.one > *': {
    maxWidth: '100%',
  },
  '.two > *': {
    maxWidth: '50%',
  },
  '.three > *': {
    maxWidth: '33%',
  },
  '> *': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    margin: 0,
  },
});

const textStyles = css({
  borderRight: `1px solid ${GREY_LIGHT_3}`,
  '&:(last-of-type)': {

  },
  '&:not(last-of-type)': {
    paddingRight: 4,
  },
  '&:not(first-of-type)': {
    paddingLeft: 4,
  },
});

const BreadCrumbs = ({ breadCrumbs = [], styles }) => (
  <span {...css(breadCrumbsStyles, styles)} className={`${translateNumberToClass(breadCrumbs.length)}`}>
    {breadCrumbs.map((text, i) => (
      <p key={i} {...textStyles} title={text}>
        &nbsp;{text}&nbsp;
      </p>
    ))}
  </span>
);

BreadCrumbs.propTypes = {
  breadCrumbs: PropTypes.arrayOf(PropTypes.string),
  styles: PropTypes.any,
};

export default BreadCrumbs;
