import React from 'react';
import PropTypes from 'prop-types';

const TurnIcon = ({ color, size, styles, ...props }) => (
  <svg viewBox="0 0 20 20" width={size} height={size} {...styles} {...props}>
    <path
      d="M10,15.4v-2.7l3.6,3.6L10,20v-2.7c-1.3,0-2.5-0.3-3.7-1c-1.1-0.6-2-1.5-2.6-2.6c-0.7-1.1-1-2.4-1-3.7
	c0-0.7,0.1-1.4,0.3-2c0.2-0.7,0.5-1.3,0.8-1.8l1.3,1.3C4.8,8.2,4.5,9.1,4.6,10c0,1,0.2,1.9,0.7,2.7c0.5,0.8,1.2,1.5,2,2
	C8.1,15.2,9,15.4,10,15.4z M10,2.7c1.3,0,2.5,0.3,3.7,1c1.1,0.6,2,1.5,2.6,2.6c0.7,1.1,1,2.4,1,3.7c0,0.7-0.1,1.4-0.3,2
	c-0.2,0.7-0.5,1.3-0.9,1.8l-1.3-1.3c0.4-0.8,0.6-1.6,0.6-2.5c0-1-0.2-1.9-0.7-2.7c-0.5-0.8-1.2-1.5-2-2C11.9,4.8,11,4.5,10,4.6v2.7
    L6.4,3.7L10,0V2.7z"
      fill={color}
    />
  </svg>
);

TurnIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  styles: PropTypes.any,
};

TurnIcon.defaultProps = {
  color: '#3C3C3B',
  size: '20px',
};

export default TurnIcon;
