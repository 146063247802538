import * as React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import styled from '@emotion/styled';
import { SelectField, StringField, TextField } from '@innovatrix/common/fields';
import { Paragraph } from '@innovatrix/common/text';
import ModalFooter from '@innovatrix/common/ModalFooter';
import { FormModal } from '@innovatrix/modals';
import { Spinner } from '@innovatrix/components';
import { getProjectTypesMetaData, getProjectOverview, getProjectMetaData } from '@innovatrix/selectors';
import { fetchProjectTypesAction } from '@innovatrix/actions';
import { prevent } from '@innovatrix/utils/noop';

const ImageField = loadable(() => import('@innovatrix/components/fields/ImageField'), {
  fallback: <Spinner />,
});

const ProjectIntroduction = styled(Paragraph)`
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
`;

const ProjectNameField = styled(StringField)`
  flex-grow: 2;
`;

const ProjectDescriptionField = styled(TextField)`
  margin-top: 12px;
  margin-bottom: 24px;
`;

const ProjectCategoryField = styled(SelectField)`
  margin-left: 24px;

  > div > button {
    height: 40px;
    text-align: left;
  }
  > div > ul {
    margin-top: 4px;
  }
`;

const PersistProjectModal = ({
  completed,
  fetchProjectTypes,
  handleSubmit,
  hideCloseButton,
  initialValues: { projectTypeId: initialProjectTypeId },
  isEdit,
  isSubmitting,
  onClose,
  projectMetaData,
  projectTypes,
}) => {
  React.useEffect(() => {
    fetchProjectTypes();
  }, [fetchProjectTypes]);

  const onClickCancel = React.useCallback((e) => {
    prevent(e);
    onClose();
  }, [onClose]);

  const projectTypeOptions = React.useMemo(() => projectTypes
    .filter(p => !p.deletedOn)
    .map(({ id, name }) => ({ label: name, value: id })), [projectTypes]);

  const currentProjectTypeName = React.useMemo(() => (projectTypes.find(x => x.id === initialProjectTypeId) || {}).name, [initialProjectTypeId, projectTypes]);

  const buttons = React.useMemo(() => [
    ...(hideCloseButton ? [] : [{ flavor: 'tertiary', type: 'button', onClick: onClickCancel, label: 'Cancel' }]),
    { flavor: 'primary', type: 'submit', label: isEdit ? 'Save' : 'Create Project', disabled: isSubmitting, loading: isSubmitting },
  ], [hideCloseButton, onClickCancel, isEdit, isSubmitting]);

  return (
    <FormModal
      footerContent={<ModalFooter buttons={buttons} />}
      hideCloseButton={hideCloseButton}
      onClose={onClose}
      onSubmit={handleSubmit}
      subTitle="PROJECT"
      title={isEdit ? 'Edit project summary' : 'Create new project'}
    >
      {projectMetaData.introduction && <ProjectIntroduction>{projectMetaData.introduction}</ProjectIntroduction>}
      <Row>
        <ProjectNameField
          fieldId="name"
          label={projectMetaData.name || 'Project Name'}
          placeholder={projectMetaData.name || 'Project Name'}
          required
          size="h2"
        />
        <ProjectCategoryField
          disabled={completed}
          fieldId="projectTypeId"
          groupLabel={projectMetaData.type || 'Project Category'}
          information={(isEdit && completed) ? `${currentProjectTypeName} can't be changed, because the project filled in an assessment.` : null}
          options={projectTypeOptions}
          required
          width="240px"
        />
      </Row>
      <ProjectDescriptionField
        fieldId="description"
        label={projectMetaData.description || 'Project Description'}
        placeholder={projectMetaData.description || 'Project Description'}
      />
      <ImageField fieldId="pictureUrl" />
    </FormModal>
  );
};

PersistProjectModal.propTypes = {
  completed: PropTypes.bool,
  fetchProjectTypes: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideCloseButton: PropTypes.bool,
  initialValues: PropTypes.any,
  isEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectMetaData: PropTypes.shape({
    description: PropTypes.string,
    introduction: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  projectTypes: PropTypes.array.isRequired,
};

const PersistProjectModalForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues = {}, campaignId, phaseId, projectTypes }) => {
    const { id: defaultProjectTypeId } = projectTypes && projectTypes.find(type => !type.canModify) || {};
    return {
      campaignId,
      description: '',
      name: '',
      phaseId,
      pictureUrl: '',
      projectTypeId: defaultProjectTypeId || null,
      ...initialValues,
    };
  },
  handleSubmit: (
    values,
    { props: { onClose, onErrorCallback, onSuccessCallback, persist }, setSubmitting },
  ) => {
    setSubmitting(true);
    // This should be used together with our graphql HoC
    if (onSuccessCallback || onErrorCallback) {
      persist({
        ...values,
        callback: (err, data) => {
          setSubmitting(false);
          if (err) {
            console.error(err);
            if (onErrorCallback) onErrorCallback(err);
          }
          else {
            if (onSuccessCallback) onSuccessCallback(data);
            onClose();
          }
        },
      });
    }
    else {
      // This should be used with the legacy redux sagas implementation
      persist(values, (err) => {
        setSubmitting(false);
        if (!err) onClose();
      });
    }
  },
  validationSchema: yup.object().shape({
    description: yup.string(),
    name: yup.string().max(255, 'Project name is too long.').required('Project name is required.'),
    pictureUrl: yup.string(),
    projectTypeId: yup.string(),
  }),
})(PersistProjectModal);

export default connect((state, { initialValues = {} }) => {
  const projectOverview = initialValues.projectId
    ? getProjectOverview(state, { projectId: initialValues.projectId })
    : {};
  return {
    completed: projectOverview.evaluations && projectOverview.evaluations.some((e) => e.completed),
    projectMetaData: getProjectMetaData(state),
    projectTypes: getProjectTypesMetaData(state).data,
  };
}, {
  fetchProjectTypes: fetchProjectTypesAction,
})(PersistProjectModalForm);
