import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const MoreIcon = ({ color, styles, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 4" {...styles} {...props}>
    <path fill={color} d="M7 .3c.5 0 .8.2 1.2.5.3.3.5.7.5 1.2s-.2.8-.5 1.2c-.3.3-.7.5-1.2.5s-.8-.2-1.2-.5c-.3-.3-.5-.7-.5-1.2s.2-.8.5-1.2C6.2.5 6.5.3 7 .3zm5 0c.5 0 .8.2 1.2.5.3.3.5.7.5 1.2s-.2.8-.5 1.2c-.3.3-.7.5-1.2.5s-.8-.2-1.2-.5c-.3-.3-.5-.7-.5-1.2s.2-.8.5-1.2c.4-.3.7-.5 1.2-.5zM2 .3c.5 0 .8.2 1.2.5.3.3.5.7.5 1.2s-.2.8-.5 1.2c-.3.3-.7.5-1.2.5s-.8-.2-1.2-.5C.5 2.9.3 2.5.3 2S.5 1.2.8.8C1.2.5 1.5.3 2 .3z" />
  </svg>
);

// PRIMAY
MoreIcon.propTypes = propTypes;
MoreIcon.defaultProps = defaultProps;

export default MoreIcon;
