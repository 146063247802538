import { queryBuilder, mutationBuilder } from '@innovatrix/utils/dx';

// -- Queries --------------- --- --  -

export const getApplicationMetaDataQuery = queryBuilder({
  variables: { userId: { type: 'String' } },
  fields: {
    _getApplicationMetaData: {
      varArgs: { userId: 'userId' },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const getUserProfilesQuery = queryBuilder({
  fields: {
    _userProfiles: {
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const updateProfileMutation = mutationBuilder({
  variables: {
    profile: { type: 'JSON' },
    userId: { type: 'String' },
  },
  fields: {
    _updateProfile: {
      varArgs: { profile: 'profile', userId: 'userId' },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const getProjectTypesQuery = queryBuilder({
  fields: {
    _getProjectTypes: {
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const updateApplicationConfigurationMutation = mutationBuilder({
  variables: {
    data: { type: 'JSON' },
  },
  fields: {
    _updateApplicationConfiguration: {
      varArgs: { data: 'data' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});
