import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const CaretIcon = ({ color, styles, ...props }) => (
  <svg viewBox="0 0 20 20" fill={color} {...styles} {...props}>
    <path d="M0 5h20L10 15z" />
  </svg>
);

CaretIcon.propTypes = propTypes;
CaretIcon.defaultProps = defaultProps;

export default CaretIcon;

