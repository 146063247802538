import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ChapterContainer = styled.div`
  border: none;
  box-sizing: border-box;
  margin: 0;
  box-shadow: none;
`;

const Chapter = ({ children, className }) => (
  <ChapterContainer className={className}>
    {children}
  </ChapterContainer>
);

Chapter.defaultProps = { className: '' };

Chapter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default React.memo(Chapter);
