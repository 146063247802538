import React from 'react';
import { css, Global } from '@emotion/react';
import { SECONDARY, PRIMARY } from '@innovatrix/styles';

const fontSrc = (basePath) => [
  `url('${basePath}.woff2') format('woff2')`,
  `url('${basePath}.woff') format('woff')`,
  `url('${basePath}.ttf') format('truetype')`,
].join(', ');

const GlobalStyles = () => (
  <Global
    styles={
      css`
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 300;
          font-display: fallback;
          src: ${fontSrc('/dx-static/raleway/Raleway-Light')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        @font-face {
          font-family: 'Raleway';
          font-weight: 300;
          font-display: fallback;
          font-style: italic;
          src: ${fontSrc('/dx-static/raleway/Raleway-LightItalic')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: fallback;
          font-weight: 500;
          src: ${fontSrc('/dx-static/raleway/Raleway-Medium')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        @font-face {
          font-family: 'Raleway';
          font-weight: 500;
          font-display: fallback;
          font-style: italic;
          src: ${fontSrc('/dx-static/raleway/Raleway-MediumItalic')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        @font-face {
          font-family: 'Raleway';
          font-weight: 400;
          font-display: fallback;
          font-style: normal;
          src: ${fontSrc('/dx-static/raleway/Raleway-Regular')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        @font-face {
          font-family: 'Raleway';
          font-weight: 400;
          font-display: fallback;
          font-style: italic;
          src: ${fontSrc('/dx-static/raleway/Raleway-Italic')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        @font-face {
          font-family: 'Raleway';
          font-weight: 700;
          font-display: fallback;
          font-style: normal;
          src: ${fontSrc('/dx-static/raleway/Raleway-Bold')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        @font-face {
          font-family: 'Raleway';
          font-weight: 700;
          font-display: fallback;
          font-style: italic;
          src: ${fontSrc('/dx-static/raleway/Raleway-BoldItalic')};
          text-rendering: optimizeLegibility;
          unicode-range: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000';
        }

        *, *:before, *:after {
          box-sizing: inherit;
        }

        html, body, #app {
          height: 100%;
          width: 100%; /* necessary for the tours to behave correctly */
        }

        body {
          -webkit-font-smoothing: antialiased;
          background-color: rgb(236, 237, 238);
          box-sizing: border-box !important;
          color: ${SECONDARY};
          font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          margin: 0;
          overflow-x: hidden;
          padding: 0;
        }

        a {
          color: ${PRIMARY};
          cursor: pointer;
          a:active { box-shadow: none };
          text-decoration: none;
          text-decoration-color: ${PRIMARY} !important;

          &:hover {
            text-decoration: underline;
          }
        }

        blockquote {
          border-left-color: '#E1E1DF';
        }

        dd {
          font-size: 14px;
        }

        ul, ol {
          padding-left: 32px;
        }

        p {
          margin-top: 0;
        }

        b, strong {
          font-family: Raleway;
          font-style: normal;
          font-weight: 500;
        }

        i, em {
          font-style: italic;
          font-weight: normal;
        }

        label, .label {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }

        textarea {
          font-size: 14px;
          line-height: 20px;
          resize: vertical;
        }

        input {
          border: 1px solid #CACAC7;
          border-radius: 0;
          box-shadow: none;
          font-size: 14px;
          line-height: 20px;
        }

        tbody, table, th, td {
          text-align: left;
        }

        @keyframes ripple {
          from {
            transform: scale(0, 0);
            opacity: 1;
          } to {
            opacity: 0;
            transform: scale(75, 75);
          }
        }
      `
    }
  />
);

export default GlobalStyles;
