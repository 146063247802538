import { createSelector } from 'reselect';
import queryString from 'query-string';

import { getDxAuthUser } from '../authSelectors';

import { DEFAULT_ENTITY, DEFAULT_ENTITIES } from './utils';

const getApplication = state => state.data.application;

export const getApplicationMetaData = createSelector(
  getApplication,
  (application) => application.entities[queryString.stringify({ id: 'application' })] || DEFAULT_ENTITY,
);

const getApplicationRelations = createSelector(
  getApplication,
  (application) => application.relations,
);

const getProjectTypesRelations = createSelector(
  getApplicationRelations,
  (relations) => relations.projectTypes,
);

const getUserProfilesRelations = createSelector(
  getApplicationRelations,
  (relations) => relations.userProfiles,
);

export const getProjectMetaData = createSelector(
  getApplicationMetaData,
  (applicationMetaData) => applicationMetaData.PROJECT || {},
);

export const getCurrentUser = createSelector(
  getApplicationMetaData, getDxAuthUser,
  (meta, user) => ({ email: user.payload.name, ...meta.user }) || {},
);

export const getProjectTypesMetaData = createSelector(
  getProjectTypesRelations,
  (relations) => relations[''] || DEFAULT_ENTITIES,
);

export const getUserProfiles = createSelector(
  getUserProfilesRelations,
  (relations) => relations[''] || DEFAULT_ENTITIES,
);
