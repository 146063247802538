import {
  ERROR_ASSUMPTION_COMMENTS,
  ERROR_CRITERION_COMMENTS,
  ERROR_RELATED_ASSUMPTIONS,
  FETCH_ASSUMPTION_COMMENTS,
  FETCH_CRITERION_COMMENTS,
  FETCH_RELATED_ASSUMPTIONS,
  RECEIVE_ASSUMPTION_COMMENTS,
  RECEIVE_CRITERION_COMMENTS,
  RECEIVE_RELATED_ASSUMPTIONS,
} from '../../constants';

import { fetchEntities, receiveEntities, errorEntities } from './utils';

const initialState = {
  entities: {},
  relations: {
    assumptionHasRelatedAssumptions: {},
    assumptionHasComments: {},
    criterionHasComments: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_RELATED_ASSUMPTIONS:
      return receiveEntities('assumptionHasRelatedAssumptions', state, action);
    case FETCH_RELATED_ASSUMPTIONS:
      return fetchEntities('assumptionHasRelatedAssumptions', state, action);
    case ERROR_RELATED_ASSUMPTIONS:
      return errorEntities('assumptionHasRelatedAssumptions', state, action);

    case RECEIVE_ASSUMPTION_COMMENTS:
      return receiveEntities('assumptionHasComments', state, action);
    case FETCH_ASSUMPTION_COMMENTS:
      return fetchEntities('assumptionHasComments', state, action);
    case ERROR_ASSUMPTION_COMMENTS:
      return errorEntities('assumptionHasComments', state, action);

    case RECEIVE_CRITERION_COMMENTS:
      return receiveEntities('criterionHasComments', state, action);
    case FETCH_CRITERION_COMMENTS:
      return fetchEntities('criterionHasComments', state, action);
    case ERROR_CRITERION_COMMENTS:
      return errorEntities('criterionHasComments', state, action);

    default: return state;
  }
};

