import { createSelector } from 'reselect';

export const getSettings = state => state.settings;

export const getLastProcessSetting = createSelector(
  getSettings,
  (settings) => settings.lastProcess,
);

export const getLastSyncSetting = createSelector(
  getSettings,
  (settings) => settings.lastSync,
);

export const getSyncDecisionsSetting = createSelector(
  getSettings,
  (settings) => settings.syncDecisions,
);
