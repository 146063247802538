import React from 'react';
import { css } from 'glamor';
import PropTypes from 'prop-types';

import { SECONDARY, WHITE, GREY_LIGHT_2, MAIN_PAGE, GREY_1 } from '../../styles';

const badgeStyles = css({
  alignItems: 'center',
  backgroundColor: MAIN_PAGE,
  border: `1px solid ${GREY_1}`,
  borderRadius: 2,
  color: SECONDARY,
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  height: 24,
  justifyContent: 'center',
  minWidth: 22,
  padding: '4px 8px',
  width: 24,
});

const flavors = {
  black: css(badgeStyles, {
    backgroundColor: SECONDARY,
    border: 0,
    color: WHITE,
  }),
  grey: css(badgeStyles, {
    backgroundColor: GREY_LIGHT_2,
    border: 0,
    color: SECONDARY,
  }),
  white: css(badgeStyles, {
    backgroundColor: WHITE,
    border: 0,
    color: SECONDARY,
  }),
};

class Badge extends React.PureComponent {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { eventKey, onClick } = this.props;
    if (eventKey !== undefined || eventKey !== null && onClick) {
      onClick(eventKey);
    }
    else if (onClick) {
      onClick();
    }
  }

  render() {
    const { className, flavor, styles, text } = this.props;
    return (
      <span
        {...(styles || {})}
        className={`${flavor ? `${flavors[flavor].toString()}` : `${badgeStyles.toString()}`} ${className}`}
        onClick={this.onClick}
      >{text}
      </span>
    );
  }

}

Badge.propTypes = {
  className: PropTypes.string,
  eventKey: PropTypes.any,
  flavor: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.any,
  text: PropTypes.string.isRequired,
};

export default Badge;
