
import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const CommentIcon = ({ color, styles, ...props }) => (
  <svg viewBox="0 0 24 24" {...styles} {...props}>
    <path fill={color} d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

CommentIcon.propTypes = propTypes;
CommentIcon.defaultProps = defaultProps;

export default CommentIcon;
