import { queryBuilder, mutationBuilder } from '@innovatrix/utils/dx';

import { MODELS_ENABLED, SALESFORCE_ENABLED } from '../constants';

// -- Queries --------------- --- --  -

export const inviteMemberToProjectMutation = mutationBuilder({
  variables: { id: { type: 'String!' }, projectId: { type: 'String!' } },
  fields: {
    _inviteMemberToProject: {
      varArgs: { id: 'id', projectId: 'projectId' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const getProjectsQuery = queryBuilder(
  {
    variables: {
      campaignId: { type: 'String' },
      phaseId: { type: 'String' },
    },
    fields: {
      _getProjects: {
        varArgs: {
          campaignId: 'campaignId',
          phaseId: 'phaseId',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectInnoboardsQuery = queryBuilder(
  {
    variables: { projectId: { type: 'String!' } },
    fields: {
      _getProjectInnoBoards: {
        varArgs: { projectId: 'projectId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getBriefProjectsQuery = queryBuilder(
  {
    fields: {
      _getBriefProjects: {
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectWorkshopsQuery = queryBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
      status: { type: 'String!' },
    },
    fields: {
      _getProjectWorkshops: {
        varArgs: { projectId: 'projectId', status: 'status' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectByPhaseQuery = queryBuilder(
  {
    variables: {
      campaignId: { type: 'String!' },
      isCompare: { type: 'Boolean' },
      projectId: { type: 'String!' },
      phaseId: { type: 'String!' },
    },
    fields: {
      _getProjectByPhase: {
        varArgs: {
          campaignId: 'campaignId',
          isCompare: 'isCompare',
          projectId: 'projectId',
          phaseId: 'phaseId',
        },
        fields: {
          result: {
            fields: {
              project: {
                fields: {
                  average: true,
                  averagePerCriterion: true,
                  averagePerDomain: true,
                  averagePerQuestion: true,
                  averagePerQuestionGroup: true,
                  averagePreSelectionPerCriterion: true,
                  averageSelectionPerCriterion: true,
                  campaignId: true,
                  conditions: true,
                  description: true,
                  minorDomain: true,
                  ratingsSum: true,
                  ratingsTotalMax: true,
                  scaledAggregateSqrtPreSelection: true,
                  scaledAggregateSqrtSelection: true,
                  scaledThresholdFailPreSelection: true,
                  scaledThresholdFailSelection: true,
                  scorePreSelection: true,
                  scoreSelection: true,
                  teamMembers: true,
                  thresholdFailPreSelection: true,
                  thresholdFailPreSelectionPerCriterion: true,
                  thresholdFailSelection: true,
                  thresholdFailSelectionPerCriterion: true,
                  reviews: {
                    fields: {
                      average: true,
                      conditions: true,
                      creationDate: true,
                      generalComments: true,
                      id: true,
                      maxPossible: true,
                      mean: true,
                      overallAdvice: true,
                      phaseId: true,
                      ratings: true,
                      reviewer: {
                        fields: {
                          id: true,
                          name: true,
                        },
                      },
                      sum: true,
                    },
                  },
                  evaluations: {
                    fields: {
                      id: true,
                      name: true,
                      created: true,
                    },
                  },
                  reviewers: {
                    fields: {
                      id: true,
                      name: true,
                      reviews: true,
                    },
                  },
                  majorDomain: true,
                  missionStatement: true,
                  id: true,
                  innovationManager: true,
                  isResubmission: true,
                  keywords: true,
                  label: true,
                  phaseId: true,
                  meanPerCriterion: true,
                  meanPerDomain: true,
                  meanPerQuestion: true,
                  meanPerQuestionGroup: true,
                  maxPerCriterion: true,
                  minPerCriterion: true,
                  maxPerQuestion: true,
                  maxScorePerQuestion: true,
                  minPerQuestion: true,
                  predictionCertaintyPreSelection: true,
                  pitch: true,
                  predictionCertaintySelection: true,
                  priorPrograms: true,
                  status: true,
                },
              },
              domains: true,
              domainsOrdered: {
                fields: {
                  average: true,
                  id: true,
                  label: true,
                  mean: true,
                  hasRatings: true,
                  criteria: true,
                },
              },
              questionGroups: true,
              questionGroupsOrdered: {
                fields: {
                  average: true,
                  id: true,
                  label: true,
                  mean: true,
                  order: true,
                  questions: true,
                },
              },
            },
          },
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectReviewsByPhaseQuery = queryBuilder(
  {
    variables: {
      campaignId: { type: 'String!' },
      projectId: { type: 'String!' },
      phaseId: { type: 'String!' },
    },
    fields: {
      _getProjectReviewsByPhase: {
        varArgs: {
          campaignId: 'campaignId',
          projectId: 'projectId',
          phaseId: 'phaseId',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectByEvaluationQuery = queryBuilder(
  {
    variables: {
      evaluationId: { type: 'String!' },
      projectId: { type: 'String!' },
      isCompare: { type: 'Boolean' },
    },
    fields: {
      _getProjectByEvaluation: {
        varArgs: {
          evaluationId: 'evaluationId',
          projectId: 'projectId',
          isCompare: 'isCompare',
        },
        fields: {
          result: {
            fields: {
              project: {
                fields: {
                  average: true,
                  averagePerQuestion: true,
                  averagePerQuestionGroup: true,
                  documents: true,
                  teamMembers: true,
                  minorDomain: true,
                  majorDomain: true,
                  missionStatement: true,
                  id: true,
                  innovationManager: true,
                  isResubmission: true,
                  keywords: true,
                  label: true,
                  phaseId: true,
                  mean: true,
                  meanPerQuestion: true,
                  meanPerQuestionGroup: true,
                  pitch: true,
                  priorPrograms: true,
                  status: true,
                  reviews: {
                    fields: {
                      average: true,
                      conditions: true,
                      creationDate: true,
                      generalComments: true,
                      id: true,
                      name: true,
                      order: true,
                      maxPossible: true,
                      mean: true,
                      overallAdvice: true,
                      phaseId: true,
                      ratings: true,
                      sum: true,
                      version: true,
                    },
                  },
                  evaluations: {
                    fields: {
                      completed: true,
                      created: true,
                      id: true,
                      name: true,
                      order: true,
                      questionCount: true,
                      submitted: true,
                    },
                  },
                },
              },
              questionGroups: true,
              questionGroupsOrdered: {
                fields: {
                  average: true,
                  id: true,
                  label: true,
                  mean: true,
                  order: true,
                  questions: true,
                },
              },
            },
          },
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectOverviewQuery = queryBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
    },
    fields: {
      _getProjectOverview: {
        varArgs: { projectId: 'projectId' },
        fields: {
          result: {
            fields: {
              applicationType: true,
              id: true,
              categories: {
                fields: {
                  id: true,
                  title: true,
                },
              },
              description: true,
              documents: true,
              ...(MODELS_ENABLED ? {
                bankAccount: true,
                videoPitch: true,
                kboBtw: true,
                end: true,
                bic: true,
                start: true,
                coreActivities: true,
                evaluations: {
                  fields: {
                    id: true,
                    completed: true,
                    groupId: true,
                    displayName: true,
                    submitted: true,
                    name: true,
                  },
                },
              } : {}),
              innovationManager: true,
              projectTypeId: true,
              isResubmission: true,
              label: true,
              majorDomain: true,
              minorDomain: true,
              missionStatement: true,
              phaseId: true,
              pictureUrl: true,
              pitch: true,
              priorPrograms: true,
              reviews: true,
              reviewsPerPhase: {
                fields: {
                  phaseId: true,
                  phase: true,
                  reviews: true,
                },
              },
              status: true,
              state: true,
              ...(SALESFORCE_ENABLED ? {
                salesforceStatus: true,
              } : {}),
              teamMembers: {
                fields: {
                  abbreviation: true,
                  authId: true,
                  email: true,
                  firstName: true,
                  id: true,
                  isReporter: true,
                  lastName: true,
                  name: true,
                  phoneNumber: true,
                  role: true,
                  roles: true,
                  type: true,
                },
              },
              coaches: {
                fields: {
                  id: true,
                  authId: true,
                  email: true,
                  firstName: true,
                  lastName: true,
                  name: true,
                },
              },
              managers: {
                fields: {
                  email: true,
                  firstName: true,
                  id: true,
                  lastName: true,
                  phoneNumber: true,
                  type: true,
                },
              },
            },
          },
          success: true,
          error: true,
        },
      },
    },
  },
);

export const deleteProjectQuery = mutationBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
    },
    fields: {
      _deleteProject: {
        varArgs: {
          projectId: 'projectId',
        },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getManagersQuery = queryBuilder(
  {
    fields: {
      _getManagers: {
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const updateProjectMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    project: { type: 'JSON!' },
  },
  fields: {
    _updateProject: {
      varArgs: {
        projectId: 'projectId',
        project: 'project',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const persistTeamMembersMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    teamMembers: { type: 'JSON!' },
  },
  fields: {
    persistTeamMembers: {
      varArgs: {
        projectId: 'projectId',
        teamMembers: 'teamMembers',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const createTeamMemberMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    teamMember: { type: 'JSON!' },
  },
  fields: {
    createTeamMember: {
      varArgs: {
        projectId: 'projectId',
        teamMember: 'teamMember',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const addManagerMutation = mutationBuilder({
  variables: {
    manager: { type: 'JSON!' },
    projectId: { type: 'String!' },
  },
  fields: {
    addManager: {
      varArgs: {
        manager: 'manager',
        projectId: 'projectId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const persistManagersMutation = mutationBuilder({
  variables: {
    coachIds: { type: '[String]!' },
    managerIds: { type: '[String]!' },
    projectId: { type: 'String!' },
  },
  fields: {
    persistManagers: {
      varArgs: {
        coachIds: 'coachIds',
        managerIds: 'managerIds',
        projectId: 'projectId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const getOneOnOnesForProjectQuery = queryBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
      workshopMomentId: { type: 'String!' },
    },
    fields: {
      _getOneOnOnes: {
        varArgs: {
          projectId: 'projectId',
          workshopMomentId: 'workshopMomentId',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);
