import { createLoadStateReducer } from '../../../reducers/loadState';
import { HIDE_PREDICTIONS } from '../../../constants';
import { errorEntities, fetchEntities, receiveEntities } from '../../../reducers/data/utils';

import {
  ALL,
  FILTER_ALL,
  FILTER_CARDS,
  FILTER_DECISIONS,
  ORDER_ALPHABETICALLY,
  ORDER_CARDS,
  RECEIVE_PROJECTS,
  RECEIVE_UPDATED_PROJECT,
  SHOW_DECISION_DETAILS,
  DELETE_EVALUATION_SUCCESS,
  TOGGLE_PREDICTIONS,
  FILTER_CARDS_BY_MANAGER,
  FETCH_PROJECTS,
  ERROR_PROJECTS,
  ERROR_REVIEWERS,
  FETCH_REVIEWERS,
  RECEIVE_REVIEWERS,
} from './_actionTypes';

const initialState = {
  byId: {},
  statusFilter: ALL,
  decisionsFilter: FILTER_ALL,
  managerFilter: ALL,
  order: ORDER_ALPHABETICALLY,
  ordered: [], // order set of cards
  showPredictions: HIDE_PREDICTIONS,
  relations: {
    reviewers: {},
  },
};

export const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PREDICTIONS: {
      return { ...state, showPredictions: action.value };
    }

    case RECEIVE_PROJECTS: {
      const projects = action.data;
      const byId = projects.reduce((result, project) => ({
        ...result,
        [project.id]: {
          project,
        },
      }), {});
      return { ...state, byId, ordered: projects.map((project) => ({ project })) };
    }
    case DELETE_EVALUATION_SUCCESS: {
      const { projectId, evaluationId } = action;
      const byId = { ...state.byId };
      byId[projectId].project.evaluations = byId[projectId].project.evaluations.filter(({ id }) => id !== evaluationId);
      const ordered = Object.values(byId).map((project) => project);
      return { ...state, byId, ordered };
    }
    case SHOW_DECISION_DETAILS: {
      const { projectId } = action;
      const oldCard = state.byId[projectId];
      const newCard = { ...oldCard, showDecisionDetails: !oldCard.showDecisionDetails };
      return {
        ...state,
        byId: { ...state.byId, [projectId]: newCard },
        ordered: state.ordered.map(card => (card === oldCard ? newCard : card)),
      };
    }

    case RECEIVE_UPDATED_PROJECT: {
      const { project } = action;
      const updatedProject = {
        ...state.byId[project.id],
        project: {
          ...state.byId[project.id].project,
          ...project,
        },
      };
      return {
        ...state,
        byId: { ...state.byId, [project.id]: updatedProject },
        ordered: state.ordered.map(card => (card.project.id === project.id ? updatedProject : card)),
      };
    }

    case ORDER_CARDS: {
      const { order } = action;
      if (order === state.order) { return state; }
      return { ...state, order };
    }

    case FILTER_CARDS_BY_MANAGER: {
      const { managerId } = action;
      return { ...state, managerFilter: managerId };
    }

    case FILTER_CARDS: {
      const { filter } = action;
      return { ...state, statusFilter: filter };
    }

    case FILTER_DECISIONS: {
      const { filter } = action;
      return { ...state, decisionsFilter: filter };
    }

    case ERROR_REVIEWERS: return errorEntities('reviewers', state, action);
    case FETCH_REVIEWERS: return fetchEntities('reviewers', state, action);
    case RECEIVE_REVIEWERS: return receiveEntities('reviewers', state, action);

    default:
      return state;
  }
};

export const projectsReducer = createLoadStateReducer(
  FETCH_PROJECTS,
  RECEIVE_PROJECTS,
  null,
  ERROR_PROJECTS,
);
