import * as React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const CheckIcon = ({ color, className, size, styles, ...props }) => (
  <svg className={className} fill={color} width={size} height={size} viewBox="0 0 24 24" {...styles} {...props}>
    <path stroke={color} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
  </svg>
);

CheckIcon.propTypes = propTypes;
CheckIcon.defaultProps = {
  ...defaultProps,
  size: '12px',
};

export default CheckIcon;
