import {
  DELETE_EVALUATION,
  DELETE_EVALUATION_ERROR,
  DELETE_EVALUATION_SUCCESS,
  ERROR_EVALUATION,
  POST_EVALUATION,
  RECEIVE_EVALUATION,
  FETCH_QUESTION,
  ERROR_QUESTION,
  RECEIVE_QUESTION,
  CLONE_EVALUATION,
  SAVE_ANSWER,
  SUBMIT_EVALUATION,
} from '../constants';

import { createFormikAction } from './utils';

export const saveAnswerAction = createFormikAction(SAVE_ANSWER);

export const postEvaluationAction = (campaignId, projectId, rows, callback) => ({
  callback,
  campaignId,
  projectId,
  type: POST_EVALUATION,
  rows,
});

export const deleteEvaluationSuccessAction = ({ projectId, evaluationId }) => ({
  evaluationId,
  projectId,
  type: DELETE_EVALUATION_SUCCESS,
});

export const submitEvaluationAction = (campaignId, projectId, evaluationId) => ({
  campaignId,
  evaluationId,
  projectId,
  type: SUBMIT_EVALUATION,
});

export const cloneEvaluationAction = ({ campaignId, projectId, evaluationId, callback }) => ({
  callback,
  campaignId,
  evaluationId,
  projectId,
  type: CLONE_EVALUATION,
});

export const receiveEvaluationAction = evaluation => ({
  type: RECEIVE_EVALUATION,
  data: evaluation,
});

export const fetchQuestionAction = questionId => ({
  type: FETCH_QUESTION,
  questionId,
});

export const receiveQuestionAction = (questionId, data) => ({
  data,
  type: RECEIVE_QUESTION,
  questionId,
});

export const errorQuestionAction = (questionId, error) => ({
  error,
  type: ERROR_QUESTION,
  questionId,
});

export const errorEvaluationAction = error => ({
  type: ERROR_EVALUATION,
  error,
});

export const deleteEvaluationAction = ({ idToNavigate, campaignId, projectId, evaluationId, callback }) => ({
  callback,
  campaignId,
  evaluationId,
  idToNavigate,
  projectId,
  type: DELETE_EVALUATION,
});

export const deleteEvaluationErrorAction = ({ campaignId, error, projectId, evaluationId }) => ({
  campaignId,
  error,
  evaluationId,
  projectId,
  type: DELETE_EVALUATION_ERROR,
});
