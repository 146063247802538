/**
 * Moved the creation of the redux store to a separate file to mitigate
 * ending up with a cyclical dependency errors thrown by webpack.
 */
import { createBrowserHistory as createHistory } from 'history';

import reducers from './reducers';
import sagas from './sagas';
import createStore from './utils/createStore';

export const history = createHistory();

export const store = createStore(reducers, sagas, history);
