import * as React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const KeyIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 14 8" {...styles} {...props}>
    <path d="M4.1 5.3c.3 0 .6-.1.8-.4.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.8-.2-.3-.5-.4-.8-.4-.3 0-.6.1-.8.4-.3.2-.4.5-.4.8 0 .3.1.6.3.8.3.3.6.4.9.4zm3.3-2.4h6v2.4h-1.2v2.3H9.9V5.3H7.4C7.1 6 6.7 6.5 6.1 7c-.6.4-1.3.6-2 .6-.6 0-1.2-.2-1.8-.5-.5-.3-.9-.7-1.3-1.2C.7 5.3.6 4.7.6 4.1c0-.6.2-1.2.5-1.8.3-.5.7-1 1.3-1.3C2.9.7 3.5.5 4.2.5c.8 0 1.4.2 2 .6.5.6.9 1.1 1.2 1.8z" />
  </svg>
);

KeyIcon.propTypes = propTypes;
KeyIcon.defaultProps = defaultProps;

// className="attending"
export default KeyIcon;
