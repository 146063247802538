import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Paragraph } from '@innovatrix/common/text';
import DragDropHandlerIcon from '@innovatrix/icons/DragDropHandlerIcon';
import { GREY_1 } from '@innovatrix/styles';

import AssumptionStatus from '../components/AssumptionStatus';

const AssumptionPreviewContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.greyLight3};
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.30);
  display: flex;
  min-height: 30px;
  transform-origin: center;
  width: 320px;

  ${({ withPadding }) => (withPadding ? `
    padding: 8px;
  ` : '')}

  ${({ skewLeft }) => (skewLeft ? `
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-1deg);
  ` : '')}

  ${({ skewRight, theme }) => (skewRight ? `
    border: 2px solid ${theme.colors.focus};
    left: -2px;
    position: absolute;
    top: 0px;
    transform: rotate(1deg);
  ` : '')}
`;

const AssumptionPreviewTitle = styled(Paragraph)`
  margin-left: 8px;
  margin-bottom: 0;
`;

const MultiAssumptionPreviewWrapper = styled.div`
  position: relative;
`;

const DragDropHandler = styled.div`
  background-color: ${({ theme }) => theme.colors.greyBlueish};
  border-right: 1px solid ${({ theme }) => theme.colors.greyLight3};
  padding: 8px;
`;

const MultiAssumptionPreview = ({ title }) => (
  <MultiAssumptionPreviewWrapper>
    <AssumptionPreviewContainer skewLeft>
      <DragDropHandler>
        <DragDropHandlerIcon color={GREY_1} />
      </DragDropHandler>      <AssumptionPreviewTitle>preview</AssumptionPreviewTitle>
    </AssumptionPreviewContainer>
    <AssumptionPreviewContainer skewRight>
      <DragDropHandler>
        <DragDropHandlerIcon color={GREY_1} />
      </DragDropHandler>
      <AssumptionPreviewTitle>{title}</AssumptionPreviewTitle>
    </AssumptionPreviewContainer>
  </MultiAssumptionPreviewWrapper>
);

MultiAssumptionPreview.propTypes = {
  title: PropTypes.string.isRequired,
};

const AssumptionPreview = ({ status, title }) => (
  <AssumptionPreviewContainer withPadding>
    <AssumptionStatus
      hasStatusEvolved={false}
      status={status}
      title={title}
    />
    <AssumptionPreviewTitle>{title}</AssumptionPreviewTitle>
  </AssumptionPreviewContainer>
);

AssumptionPreview.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export const BoardAssumptionPreview = React.memo(AssumptionPreview);
export const MultiBoardAssumptionPreview = React.memo(MultiAssumptionPreview);
