import { findDOMNode } from 'react-dom';

const calcInTopHalf = (monitor, component) => {
  // Determine rectangle on screen.
  const hoverBoundingRect = findDOMNode(component).getBoundingClientRect(); // eslint-disable-line
  // Get vertical middle.
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  // Determine mouse position.
  const clientOffset = monitor.getClientOffset();
  // Get pixels to the top.
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  return hoverClientY < hoverMiddleY;
};

export const moveOnHover = (actualOrder, move, monitor, component) => {
  const dragActualOrder = monitor.getItem().actualOrder;
  // Don't replace items with themselves.
  if (dragActualOrder === actualOrder) {
    return;
  }
  const topHalf = calcInTopHalf(monitor, component);
  const draggingDown = dragActualOrder < actualOrder;
  // Dragging downwards.
  if (draggingDown && topHalf) {
    return;
  }
  // Dragging upwards.
  if (!draggingDown && !topHalf) {
    return;
  }
  move(dragActualOrder, actualOrder);
  // Update the actual order.
  monitor.getItem().actualOrder = actualOrder;
};

