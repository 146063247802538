import { prevent } from './noop';

// To test the copyToClipboard action, make sure that you are in a secure environment (either HTTPS or localhost)
// innovatrix.local or other environments do not expose navigator.clipboard!
// If you want to test this locally, then change the DX_HOST value to 'localhost' in your .env file ;)
export const copyToClipboard = async (e, data) => {
  try {
    prevent(e);
    await navigator.clipboard.writeText(data);
  }
  catch (err) {
    console.warn('Browser does not support navigator.clipboard | unable to copy data:', data);
    throw err;
  }
};
