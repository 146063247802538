import * as React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import MoreIcon from '@innovatrix/icons/MoreIcon';
import { Title, Paragraph } from '@innovatrix/common/text';
import Dropdown from '@innovatrix/common/dropdown';
import { GREY_LIGHT_3, WHITE } from '@innovatrix/styles';
import { noop } from '@innovatrix/utils/noop';

import Cross from './Cross';
import './modalAnimation.scss';
import BreadCrumbs from './BreadCrumbs';

const stopPropagation = (e) => {
  e.stopPropagation();
};

const Wrapper = styled.div`
  height: 100%;
`;

const StyledDropdown = styled(Dropdown)`
  > div > button {
    padding: 10px 11px;
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px 12px;
`;

const Heading = styled.div`
  align-items: center;
  display: flex;
  width: 90%;
`;

const Subtitle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey2};
  margin-right: 8px;
  max-width: 160px;
  text-transform: uppercase;
  width: auto;
`;

const ModalTitle = styled(Title)`
  margin-bottom: 0;
`;

const CloseIcon = styled(Cross)`
  &:hover {
    cursor: pointer;
    > path {
        opacity: 0.6;
        transition: 0.3s;
    }
  }
`;

const Body = styled.div`
  ${({ theme }) => theme.scrollbar.small};
  max-height: 60vh;
  overflow-y: auto;
  padding: 0 32px 24px;
  width: 100%;

  > .form-group {
    margin-bottom: 8px;
  }
`;

const ErrorMessage = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.warning};
  border: 1px solid ${({ theme }) => theme.colors.warning};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 46px;
  justify-content: space-between;
  margin: 0 24px 18px 24px;
  padding: 6px 16px 6px 24px;
`;

const modalStyles = (width, height, minWidth) => ({
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 100,
  },
  content: {
    backgroundColor: WHITE,
    border: `1px solid ${GREY_LIGHT_3}`,
    borderRadius: 3,
    bottom: 0,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
    height: height || 'auto',
    left: 0,
    maxHeight: '90vh',
    maxWidth: width,
    minWidth,
    outline: 0,
    overflow: 'visible',
    padding: 0,
    position: 'relative',
    right: 0,
    top: 0,
    width: '100%',
  },
});

// Make sure to bind modal to your appElement
// See: (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#app');

export const FormModal = ({
  onClose, breadCrumbs, contentRef, contentLabel, isOpen, subTitle,
  title, onSubmit, width, minWidth, className, renderCustomHeader,
  error, moreOptions, moreDisabled, hideCloseButton, height,
  children, footerContent,
}) => {
  const onExplicitClose = React.useCallback(() => {
    onClose(true);
  }, [onClose]);

  const onImplicitClose = React.useCallback((e) => {
    // Pressing escape is an explicit close.
    // Clicking outside the modal is an implicit close.
    const isExplicitClose = e.type === 'keydown';
    onClose(isExplicitClose);
  }, [onClose]);

  // This fixes a bug with ReactModal sometimes not correctly
  // removing the 'ReactModal__Body--open' classname from the body element
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const style = React.useMemo(() => modalStyles(width, height, minWidth), [height, width, minWidth]);

  return (
    <ReactModal
      contentRef={contentRef}
      ariaHideApp={false}
      closeTimeoutMS={500}
      contentLabel={contentLabel}
      isOpen={isOpen}
      onRequestClose={onImplicitClose}
      style={style}
    >
      <Wrapper
        className={className}
        onClick={stopPropagation}
      >
        <form onSubmit={onSubmit}>
          <Header>
            <Heading>
              {subTitle
                ? <Subtitle small>{subTitle}</Subtitle>
                : breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
              <ModalTitle title={title}>{title}</ModalTitle>
            </Heading>
            <React.Fragment>
              {renderCustomHeader && renderCustomHeader()}
              {moreOptions &&
              <StyledDropdown
                left
                options={moreOptions}
                disabled={moreDisabled}
                icon={<MoreIcon />}
                caret={false}
              />}
            </React.Fragment>
            {hideCloseButton ? null : <CloseIcon onClick={onExplicitClose} />}
          </Header>
          {error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : null}
          <Body className="body">
            {children}
          </Body>
          {footerContent}
        </form>
      </Wrapper>
    </ReactModal>
  );
};

FormModal.propTypes = {
  breadCrumbs: PropTypes.arrayOf(PropTypes.string),
  // node for Body Content
  children: PropTypes.node,
  className: PropTypes.any,
  contentLabel: PropTypes.string,
  contentRef: PropTypes.func,
  // error
  error: PropTypes.string,
  // node for Footer Content
  footerContent: PropTypes.node,
  height: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // Function for closing the modal
  moreDisabled: PropTypes.bool,
  moreOptions: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  renderCustomHeader: PropTypes.func,
  subTitle: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  // Main title in Header
  title: PropTypes.string,
  // via "width" property we can change the width of the modal
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FormModal.defaultProps = {
  children: null,
  contentLabel: '',
  footerContent: null,
  hideCloseButton: false,
  isOpen: true,
  minWidth: 450,
  onClose: noop,
  onSubmit: null,
  subTitle: '',
  title: '',
  width: 700,
};

export const PlainModal = ({
  children, className, contentLabel, contentRef,
  isOpen, onSubmit, width, height, onClose,
}) => {
  const style = React.useMemo(() => modalStyles(width, height), [height, width]);

  // This fixes a bug with ReactModal sometimes not correctly
  // removing the 'ReactModal__Body--open' classname from the body element
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const onImplicitClose = React.useCallback((e) => {
    const isExplicitClose = e.type === 'keydown';
    if (onClose && typeof onClose === 'function') {
      onClose(isExplicitClose);
    }
  }, [onClose]);

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={500}
      contentLabel={contentLabel}
      contentRef={contentRef}
      isOpen={isOpen}
      onRequestClose={onImplicitClose}
      style={style}
    >
      <Wrapper className={className} onClick={stopPropagation}>
        <form onSubmit={onSubmit}>
          <Body className="body">
            {children || null}
          </Body>
        </form>
      </Wrapper>
    </ReactModal>
  );
};

PlainModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentLabel: PropTypes.string,
  contentRef: PropTypes.func,
  height: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PlainModal.defaultProps = {
  children: null,
  className: '',
  isOpen: true,
  onClose: noop,
  width: 700,
};
