import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WithTooltip from '@innovatrix/components/WithToolTip';
import InfoIcon from '@innovatrix/icons/InfoIcon';
import CrossIcon from '@innovatrix/icons/CloseIcon';
import AsterixIcon from '@innovatrix/icons/AsterixIcon';
import WarningIcon from '@innovatrix/icons/WarningIcon';
import { DANGER, WARNING, GREY_1 } from '@innovatrix/styles';

import { Paragraph, PreBody } from '../text';

const LabelParagraph = Paragraph.withComponent('label');

const StyledLabelParagraph = styled(LabelParagraph)`
  color: ${({ error, theme, touched }) => (error && touched ? theme.colors.warning : theme.colors.grey2)};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const Label = React.memo(({ className, children, error, touched }) => (
  <StyledLabelParagraph className={className} error={error} title={children} touched={touched} small>
    {children}
  </StyledLabelParagraph>
));

Label.displayName = 'Label';
Label.propTypes = { children: PropTypes.node, className: PropTypes.string, error: PropTypes.string, touched: PropTypes.bool };

const LabelGroupWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 12px;
  justify-content: space-between;
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
`;

const StyledInfo = styled(InfoIcon)`cursor: pointer;`;
const StyledWarning = styled(WarningIcon)`cursor: pointer;`;
const StyledAsterix = styled(AsterixIcon)`margin-top: 2px; margin-left: 4px;`;

const LabelWrapper = styled.div`
  display: flex;
  max-width: 90%;
  overflow: hidden;
`;

const LabelPlaceholder = styled.div`
  height: 20px;
`;

export const LabelGroup = React.memo(({ error, information, label, required, touched }) => {
  const RightComponent = React.useMemo(() => {
    if (error && touched) {
      return (
        <WithTooltip data-effect="solid" label={error}>
          <StyledWarning color={WARNING} width="12" height="12" />
        </WithTooltip>
      );
    }
    else if (information) {
      return (
        <WithTooltip data-effect="solid" label={information}>
          <StyledInfo width="12" height="12" />
        </WithTooltip>
      );
    }
    return <React.Fragment />;
  }, [information, error, touched]);

  if (!label) { return <LabelPlaceholder />; }

  return (
    <LabelGroupWrapper>
      <LabelWrapper>
        <Label touched={touched} error={error}>{label}</Label>
        {required && <StyledAsterix color={WARNING} width="5" height="5" />}
      </LabelWrapper>
      {RightComponent}
    </LabelGroupWrapper>
  );
});

LabelGroup.displayName = 'LabelGroup';
LabelGroup.propTypes = {
  error: PropTypes.string,
  information: PropTypes.string,
  label: PropTypes.node,
  required: PropTypes.bool,
  touched: PropTypes.bool,
};

const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const StyledCross = styled(CrossIcon)`
  cursor: pointer;
  position: absolute;
  // Add some space between clear icon and the field icon when
  // both exist
  right: ${({ move }) => (move ? '19px' : '1px')};
`;

const IconWrapper = styled.span`
  display: inline-flex;
  position: absolute;
  right: 1px;
  > svg {
    width: 10px;
    height: 10px;
  }
`;

export const InputGroup = React.memo(({ className, clear, children, icon }) => (
  <InputWrapper className={className}>
    {children}
    {clear ? <StyledCross move={icon} color={DANGER} onClick={clear} width="10" height="10" /> : null}
    {icon ? <IconWrapper>{icon}</IconWrapper> : <React.Fragment />}
  </InputWrapper>
));

InputGroup.displayName = 'InputGroup';
InputGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  clear: PropTypes.func,
  icon: PropTypes.any,
};

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const Clarification = styled(PreBody)`
  color: ${GREY_1};
`;
