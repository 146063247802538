import invariant from 'invariant';
import isNumber from 'lodash/isNumber';
import { DxError } from '@innovatrix/utils/dx';
import { isString } from '@innovatrix/utils';

import {
  DX_AUTH_AUTHENTICATE,
  DX_AUTH_AUTHENTICATED,
  DX_AUTH_FAILED,
  DX_AUTH_SET_PROVIDER_STATE,
  DX_AUTH_SIGN_OUT,
  DX_AUTH_SIGNED_OUT,
} from '../constants';

// -- Generic dxAuth Action --------------- --- --  -

/**
 * @param {string} providerId
 * @param {string} [targetPath] - The path to redirect to when authenticated.
 */
export const dxAuthenticateAction = (providerId, targetPath = '/') => {
  invariant(isString(providerId),
    `The "providerId" argument must be a string, instead got "${providerId}".`);
  invariant(isString(targetPath),
    `The "targetPath" argument must be a string, instead got "${targetPath}".`);
  return {
    type: DX_AUTH_AUTHENTICATE,
    providerId,
    targetPath,
  };
};

/**
 * Creates action to dispatch when successfully authenticating a user.
 * @param {User} user - The user object.
 * @param {number} expiresAt - Expressed as unix/epoch-time in seconds.
 * @return {{type: string, expiresAt: number, user: User}}
 */
export const dxAuthenticatedAction = (user, expiresAt) => {
  invariant(isNumber(expiresAt), `Expected number as "expiresAt", got "${user}".`);
  return {
    type: DX_AUTH_AUTHENTICATED,
    dxToken: user.dxToken, // deprecated
    expiresAt,
    user,
  };
};

/**
 * @param {DxErrorArgument} errorArgs
 */
export const dxAuthFailedAction = (...errorArgs) => ({
  type: DX_AUTH_FAILED,
  dxError: new DxError(errorArgs),
});

/**
 * Announce intent to sign out the current user.
 * @param {string} [targetPath] - The path to redirect to after being signed out. Defaults to the
 *   value of `duxis.auth.signOutTargetPath` in the setup config.
 */
export const dxAuthSignOutAction = (targetPath) => ({
  type: DX_AUTH_SIGN_OUT,
  targetPath,
});

/**
 * The frontend providers should dispatch this action when the user was successfully signed out.
 */
export const dxAuthSignedOutAction = (providerId) => ({
  type: DX_AUTH_SIGNED_OUT,
  providerId,
});

/**
 * Arbitrary auth state update action creator.
 * @param {string} providerId - The identity provider ID.
 * @param {*} providerState - Arbitrary provider state value.
 */
export const dxAuthSetProviderStateAction = (providerId, providerState) => ({
  type: DX_AUTH_SET_PROVIDER_STATE,
  providerId,
  providerState,
});
