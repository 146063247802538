import React from 'react';
import PropTypes from 'prop-types';

import { errorMessageStyles } from '../../styles';

const ErrorMessage = ({ error, touched }) => (
  error && touched
    ? <div {...errorMessageStyles}>{error}</div>
    : null
);

ErrorMessage.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
};

export default ErrorMessage;
