import { MULTI_MULTI, MULTI_SINGLE, MULTI_SINGLE_QUANTIFIED, RANKING, TEXT_ENTRY, MULTIPLE_TEXT_ENTRY, OVERALL_ADVICE, ADVICE_COMMENT, INTRODUCTION } from '@innovatrix/constants';

export function getDefaultAnswer(q) {
  switch (q.type) {
    case MULTI_SINGLE:
    case MULTI_SINGLE_QUANTIFIED: {
      return { comment: '', otherClarification: '', selection: '' };
    }
    case MULTI_MULTI: {
      return { comment: '', otherClarification: '', selection: q.options.map(() => false) };
    }
    case RANKING: {
      return { comment: '', options: q.options.map(o => o) };
    }
    case TEXT_ENTRY: {
      return { answer: '' };
    }
    case MULTIPLE_TEXT_ENTRY: {
      return { answer: [...q.options.map(() => '')] };
    }
    case OVERALL_ADVICE:
      return { conditions: '', selection: '' };
    case ADVICE_COMMENT:
      return { answer: { argumentation: '' } };
    default: return {};
  }
}

export function hasAnswer(q) {
  if (!q) { return false; }
  switch (q.type) {
    case INTRODUCTION:
      return true;
    case MULTI_SINGLE:
    case MULTI_SINGLE_QUANTIFIED: {
      const selection = q.answer && q.answer.selection;
      return Boolean(selection);
    }
    case MULTI_MULTI: {
      return Boolean(q.answer && q.answer.selection.some(s => s));
    }
    case OVERALL_ADVICE: {
      const selection = q.answer && q.answer.selection;
      const selectedOption = q.options.find((o) => o.id === selection);
      return Boolean(selectedOption && (!selectedOption.requiresCondition || q.answer.conditions));
    }
    case ADVICE_COMMENT: {
      return Boolean(q.answer && q.answer.argumentation);
    }
    case RANKING: {
      return true;
    }
    case TEXT_ENTRY: {
      return Boolean(q.answer && q.answer.answer);
    }
    case MULTIPLE_TEXT_ENTRY: {
      return Boolean(q.answer && q.answer.answer && !q.answer.answer.some(s => !s));
    }
    default:
      throw new Error(`Unknown question type ${q.type}.`);
  }
}

export function getAnswerFromExistingQuestion({ type, comment, answer, options }, followUpQuestion) {
  switch (type) {
    case MULTI_SINGLE:
    case MULTI_SINGLE_QUANTIFIED: {
      if (followUpQuestion) {
        const selection = answer.selection && followUpQuestion.options.find(({ duplicatedFrom }) => duplicatedFrom === answer.selection);
        return { evaluationRatingId: answer.evaluationRatingId, comment, otherClarification: answer.otherClarification, selection: (selection && selection.id) };
      }
      return { evaluationRatingId: answer.evaluationRatingId, comment, otherClarification: answer.otherClarification, selection: answer.selection };
    }
    case MULTI_MULTI: {
      const selection = options.map((({ id: optionId }) => Boolean(answer.selection.find(({ id: answerId }) => answerId === optionId))));
      return { evaluationRatingId: answer.evaluationRatingId, comment, otherClarification: answer.otherClarification, selection };
    }
    case RANKING: {
      return { evaluationRatingId: answer.evaluationRatingId, comment, options: answer.options };
    }
    case TEXT_ENTRY: {
      return answer;
    }
    case MULTIPLE_TEXT_ENTRY: {
      return answer;
    }
    default: return {};
  }
}

export function checkForError(type, errors) {
  switch (type) {
    case MULTI_SINGLE:
    case MULTI_SINGLE_QUANTIFIED:
    case MULTI_MULTI:
    case OVERALL_ADVICE:
    case ADVICE_COMMENT:
    case TEXT_ENTRY: {
      return Boolean(errors.answer);
    }
    case MULTIPLE_TEXT_ENTRY: {
      return errors.answer && (Array.isArray(errors.answer) ? errors.answer.some(a => a) : Boolean(errors.answer));
    }
    default: return false;
  }
}

export function validateSurvey(values) {
  const errors = {};
  values.questions.forEach((q, i) => {
    switch (q.type) {
      case MULTI_SINGLE:
      case MULTI_SINGLE_QUANTIFIED: {
        const selectedOption = q.options.find(({ id }) => q.answer.selection === id);
        const hasOther = selectedOption && selectedOption.title === 'Other';
        if (!selectedOption || (hasOther && !q.answer.otherClarification)) {
          errors.questions = errors.questions || [];
          errors.questions[i] = errors.questions[i] || {};
          errors.questions[i].answer = {};
        }
        break;
      }
      case MULTI_MULTI: {
        const selectedOptions = q.options.filter((o, j) => q.answer.selection[j]);
        const hasOther = selectedOptions.find(({ title }) => title === 'Other');
        if (selectedOptions.length === 0 || (hasOther && !q.answer.otherClarification)) {
          errors.questions = errors.questions || [];
          errors.questions[i] = errors.questions[i] || {};
          errors.questions[i].answer = {};
        }
        break;
      }
      case TEXT_ENTRY: {
        if (!q.answer) {
          errors.questions = errors.questions || [];
          errors.questions[i] = errors.questions[i] || {};
          errors.questions[i].answer = 'You have to give an answer.';
        }
        break;
      }
      case MULTIPLE_TEXT_ENTRY: {
        (q.answer.answer || []).forEach((answer) => {
          if (!answer) {
            errors.questions = errors.questions || [];
            errors.questions[i] = errors.questions[i] || {};
            errors.questions[i].answer = errors.questions[i].answer || [];
            errors.questions[i].answer = {};
          }
        });
        break;
      }
      case OVERALL_ADVICE: {
        const selection = q.answer && q.answer.selection;
        const selectedOption = q.options.find((o) => o.id === selection);
        if (!selectedOption) {
          errors.questions = errors.questions || [];
          errors.questions[i] = errors.questions[i] || {};
          errors.questions[i].answer = errors.questions[i].answer || [];
          errors.questions[i].answer = {};
        }
        else if (selectedOption.requiresCondition && !q.answer.conditions) {
          errors.questions = errors.questions || [];
          errors.questions[i] = errors.questions[i] || {};
          errors.questions[i].answer = errors.questions[i].answer || [];
          errors.questions[i].answer = {
            conditions: 'Fill in a condition.',
          };
        }
        break;
      }
      case ADVICE_COMMENT: {
        if (!q.answer || !q.answer.argumentation) {
          errors.questions = errors.questions || [];
          errors.questions[i] = errors.questions[i] || {};
          errors.questions[i].answer = {
            argumentation: 'You have to give an answer.',
          };
        }
        break;
      }
      default: return {};
    }
  });
  return errors;
}
