import PropTypes from 'prop-types';

export const PRIMARY = 'primary';
export const OUTLINE = 'outline';
export const WARNING = 'warning';
export const SUCCESS = 'success';

export const ALLOWED_DOCUMENT_TYPES = {
  DEFAULT: 'any',
  ALL: ['any', 'pdf', 'excel', 'word', 'powerpoint', 'image'],
  LABELS: ['Any', 'PDF', 'Excel', 'Word', 'Powerpoint', 'Image'],
};
export const ALLOWED_SIZES = ['h1', 'h2', 'h3', 'h4', 'body', 'small', 'paragraph'];
export const ALLOWED_FLAVORS = [PRIMARY, OUTLINE, WARNING, SUCCESS];

export const SIZE_MAPPING = {
  h1: '32px',
  h2: '24px',
  h3: '20px',
  h4: '18px',
  body: '16px',
  paragraph: '14px',
  small: '12px',
};

export const TEXT_FIELD_HEIGHT_MAPPING = {
  h1: 6,
  h2: 6,
  h3: 5,
  h4: 3,
  body: 5,
  paragraph: 7,
  small: 9,
};

export const defaultFieldPropTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  information: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
};

