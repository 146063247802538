import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const DragDropHandlerIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 14 24" {...styles} {...props}>
    <path d="M1.8 3.6C.8 3.6 0 2.8 0 1.8S.8 0 1.8 0s1.8.8 1.8 1.8-.8 1.8-1.8 1.8zm10.4 0c-1 0-1.8-.8-1.8-1.8S11.2 0 12.2 0 14 .8 14 1.8s-.8 1.8-1.8 1.8zM1.8 13.8C.8 13.8 0 13 0 12s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8zm10.4 0c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8S14 11 14 12s-.8 1.8-1.8 1.8zM1.8 24c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8S2.8 24 1.8 24zm10.4 0c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8z" />
  </svg>
);

DragDropHandlerIcon.propTypes = propTypes;
DragDropHandlerIcon.defaultProps = defaultProps;

export default DragDropHandlerIcon;
