import { createSelector } from 'reselect';

const EMPTY_OBJ = {};
export const getOpenedModals = state => state.modals || EMPTY_OBJ;

export const getPersistProjectModalIsOpen = createSelector(
  getOpenedModals,
  (state) => state.persistProjectModal || false,
);

export const isModalOpen = (state, id) => createSelector(
  getOpenedModals,
  (openModals) => Boolean(openModals[id]),
)(state);
