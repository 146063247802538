import { queryBuilder } from '@innovatrix/utils/dx';

// -- Queries --------------- --- --  -

export const getDealsExportQuery = queryBuilder(
  {
    fields: {
      _getDealsExport: {
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);
