import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IS_PRODUCTION } from '@innovatrix/constants';
import WithToolTip from '@innovatrix/components/WithToolTip';

import { Paragraph } from '../text';

import {
  ALLOWED_FLAVORS,
  PRIMARY,
  QUATERNARY,
  SECONDARY,
  TERTIARY,
  TEXT_BUTTON,
  OUTLINE,
  WARNING,
} from './_constants';

const StyledButton = styled.button`
  align-items: center;
  border-radius: ${({ theme }) => theme.border.radius};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  font-size: 14px;
  height: ${({ small }) => (small ? '28px' : '36px')};
  justify-content: center;
  min-width: ${({ width }) => width || 'auto'};
  padding: ${({ small }) => (small ? '6px 10px' : '10px 24px')};
  &:hover {
    transition: 0.3s;
  }
  &:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.focus};
    outline: none;
  }
  ${({ color, disabled, flavor, theme }) => {
    switch (flavor) {
      case PRIMARY:
        return {
          color: color || theme.colors.white,
          background: disabled ? theme.colors.primaryLight : theme.colors.primary,
          border: disabled ? `1px solid ${theme.colors.primaryLight}` : `1px solid ${theme.colors.primary}`,
          ':after': {
            background: theme.colors.primary,
          },
          ':hover': {
            background: !disabled && theme.colors.primaryDark,
          },
          '> span > p': {
            color: color || theme.colors.white,
          },
        };
      case SECONDARY:
        return {
          color: color || theme.colors.white,
          background: disabled ? theme.colors.turquoiseLight : theme.colors.turquoise,
          border: disabled ? `1px solid ${theme.colors.turquoiseLight}` : `1px solid ${theme.colors.turquoise}`,
          ':after': {
            background: theme.colors.turquoise,
          },
          ':hover': {
            background: !disabled && theme.colors.turquoiseDark,
          },
          '> span > p': {
            color: color || theme.colors.white,
          },
        };
      case TERTIARY:
        return {
          color: color || theme.colors.grey2,
          background: theme.colors.white,
          border: disabled ? `1px solid ${theme.colors.greyLight2}` : `1px solid ${theme.colors.grey1}`,
          ':after': {
            background: theme.colors.white,
          },
          ':hover': {
            background: !disabled && theme.colors.greyBlueish,
          },
          '> span > p': {
            color: color || (disabled ? theme.colors.grey1 : theme.colors.grey2),
          },
        };
      case QUATERNARY:
        return {
          color: color || theme.colors.grey2,
          background: theme.colors.greyLight1,
          border: disabled ? `1px solid ${theme.colors.greyLight2}` : `1px solid ${theme.colors.grey1}`,
          ':after': {
            background: theme.colors.greyLight1,
          },
          ':hover': {
            background: !disabled && theme.colors.greyLight2,
          },
          '> span > p': {
            color: color || (disabled ? theme.colors.grey1 : theme.colors.grey2),
          },
        };
      case TEXT_BUTTON:
        return {
          color: color || theme.colors.grey2,
          background: 'none',
          border: '1px solid transparent',
          ':after': {
            background: theme.colors.white,
          },
          ':hover': {
            background: !disabled && theme.colors.greyBlueish,
          },
          '> span > p': {
            color: color || (disabled ? theme.colors.grey1 : theme.colors.grey2),
          },
        };
      case OUTLINE:
        return {
          color: color || theme.colors.grey2,
          background: theme.colors.white,
          border: `1px solid ${color || theme.colors.grey1}`,
          filter: disabled ? 'opacity(0.5)' : 'opacity(1)',
          ':after': {
            background: theme.colors.white,
          },
          ':hover': {
            background: !disabled && theme.colors.greyBlueish,
          },
          '> span > p': {
            color: color || (disabled ? theme.colors.grey1 : theme.colors.secondary),
          },
        };
      case WARNING:
        return {
          color: color || theme.colors.white,
          background: disabled ? theme.colors.warningLight : theme.colors.warning,
          border: disabled ? `1px solid ${theme.colors.warningLight}` : `1px solid ${theme.colors.warning}`,
          ':after': {
            background: theme.colors.warning,
          },
          ':hover': {
            background: !disabled && theme.colors.warningDark,
          },
          '> span > p': {
            color: color || theme.colors.white,
          },
        };
      default:
        return {};
    }
  }};
  ${({ theme }) => theme.effect.ripple}
`;

const StyledParagraph = styled(Paragraph)`
  line-height: 14px;
  margin: 0;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InnerContainer = styled.span`
  align-items: center;
  margin-top: ${({ small }) => (small ? '0' : '-1px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: ${({ small }) => (small ? '12px' : '16px')};
  justify-content: center;
  > * {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

const IconContainer = styled(InnerContainer)`
  margin-right: ${({ noLabel }) => (noLabel ? '0' : '10px')};
  svg:first-of-type {
    height: ${({ small }) => (small ? '10px' : '12px')};
    > *, > g > * {
      fill: ${({ color, disabled, flavor, theme }) => {
    if (!color) {
      if ([PRIMARY, SECONDARY, WARNING].includes(flavor)) {
        return theme.colors.white;
      }
      return (disabled ? theme.colors.grey1 : color);
    }
    return color;
  }}
    };
  }
`;

const LoadingSpinner = styled.div`
  animation: spin 2s infinite;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  height: ${({ small }) => (small ? '10px' : '12px')};
  margin-left: 4px;
  position: relative;
  width: ${({ small }) => (small ? '10px' : '12px')};

  ${({ flavor, theme }) => {
    switch (flavor) {
      case TERTIARY:
      case QUATERNARY:
      case OUTLINE:
        return {
          border: `1px solid ${theme.colors.grey1}`,
        };
      default:
        return {
          border: `1px solid ${theme.colors.white}`,
        };
    }
  }}

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: ${({ small }) => (small ? '5px' : '6px')};
    width: ${({ small }) => (small ? '5px' : '6px')};
    top: -1px;
    left: 2px;

    ${({ flavor, theme }) => {
    switch (flavor) {
      case PRIMARY:
        return {
          background: theme.colors.primaryLight,
        };
      case SECONDARY:
        return {
          background: theme.colors.turquoise,
        };
      case TERTIARY:
        return {
          background: theme.colors.white,
        };
      case QUATERNARY:
        return {
          background: theme.colors.greyLight1,
        };
      case TEXT_BUTTON:
        return {
          background: 'none',
        };
      case OUTLINE:
        return {
          background: theme.colors.white,
        };
      case WARNING:
        return {
          background: theme.colors.warning,
        };
      default:
        return {};
    }
  }}}

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = ({
  className, children, color, disabled, flavor,
  icon, onClick, small, label, type, width, tooltip,
  loading,
}) => {
  if (!IS_PRODUCTION) {
    if (!ALLOWED_FLAVORS.includes(flavor)) {
      throw new Error(
        `Invalid flavor passed to the <Button /> component.
            Got ${flavor} expected one of ${ALLOWED_FLAVORS.join(', ')}.`,
      );
    }
  }

  const noLabel = React.useMemo(() => icon && !label, [icon, label]);

  const button = (
    <StyledButton
      className={className}
      color={color}
      disabled={disabled}
      flavor={flavor}
      small={small}
      onClick={onClick}
      type={type}
      width={width}
    >
      <InnerContainer
        disabled={disabled}
        small={small}
      >
        {icon &&
        <IconContainer
          color={color}
          disabled={disabled}
          flavor={flavor}
          small={small}
          noLabel={noLabel}
        >
          {icon}
        </IconContainer>}
        {label && <StyledParagraph>{label}</StyledParagraph>}
        {loading ? <LoadingSpinner small={small} flavor={flavor} /> : null}
        {children}
      </InnerContainer>
    </StyledButton>
  );

  return (
    tooltip ? (
      <WithToolTip label={tooltip}>
        {button}
      </WithToolTip>
    ) : button
  );
};

Button.defaultProps = { flavor: PRIMARY, type: 'button', loading: false };
Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  flavor: PropTypes.oneOf(ALLOWED_FLAVORS),
  icon: PropTypes.node,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
};

export default React.memo(Button);
