import React from 'react';
import PropTypes from 'prop-types';

const Maybe = ({ color, size, styles, ...props }) => (
  <svg viewBox="0 0 26 26" width={size} height={size} {...styles} {...props}>
    <path
      d="M9 7.494a7.25 7.25 0 0 1 .757-.58 5.435 5.435 0 0 1 1.926-.797A5.908 5.908 0 0 1 12.893 6c.589 0 1.126.081 1.61.244.484.163.9.395 1.245.696.345.302.613.666.803 1.093.19.426.284.904.284 1.432 0 .515-.074.96-.223 1.337a3.68 3.68 0 0 1-.564.98 4.483 4.483 0 0 1-.737.722c-.264.203-.515.395-.752.574-.237.18-.442.356-.615.529a1.044 1.044 0 0 0-.31.584l-.234 1.484h-1.717l-.173-1.657a.452.452 0 0 1-.01-.086v-.086c0-.299.075-.558.224-.778a3.16 3.16 0 0 1 .559-.62c.223-.193.464-.38.721-.564.258-.183.498-.381.722-.594.223-.214.41-.456.559-.727.149-.271.223-.59.223-.955 0-.244-.046-.463-.137-.656a1.482 1.482 0 0 0-.381-.498 1.709 1.709 0 0 0-.585-.32 2.349 2.349 0 0 0-.736-.112 3.31 3.31 0 0 0-.981.127c-.268.085-.495.18-.68.285a8.15 8.15 0 0 0-.473.284.65.65 0 0 1-.346.128c-.244 0-.42-.102-.528-.305L9 7.494zm1.92 11.982c0-.21.04-.41.118-.6.078-.19.186-.352.325-.488a1.6 1.6 0 0 1 .493-.325c.19-.081.393-.122.61-.122a1.544 1.544 0 0 1 1.087.447A1.544 1.544 0 0 1 14 19.476c0 .216-.04.418-.122.604a1.471 1.471 0 0 1-.813.803c-.19.078-.39.117-.6.117a1.59 1.59 0 0 1-1.102-.437 1.445 1.445 0 0 1-.325-.483 1.551 1.551 0 0 1-.117-.604z"
      fill={color}
    />
  </svg>
);

Maybe.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  styles: PropTypes.any,
};

Maybe.defaultProps = {
  color: '#FB6627',
  size: '26px',
};

export default Maybe;
