import { CHANGE_DECISION_SETTING, RECEIVE_SETTINGS } from '../constants';

export const receiveSettingsAction = (settings) => ({
  type: RECEIVE_SETTINGS,
  settings,
});

export const changeDecisionSyncAction = (value) => ({
  type: CHANGE_DECISION_SETTING,
  data: value,
});
