import React from 'react';
import { take, fork, cancel } from 'redux-saga/effects';

import { store } from '../../../_store';
import { sagaMiddleware } from '../../../utils/createStore';

export const CANCEL_SAGA = 'CANCEL_SAGA';

function runSaga(key, saga) {
  const runnableSaga = function* main() {
    const sagaTask = yield fork(saga);
    const { payload } = yield take(CANCEL_SAGA);

    if (payload === key) {
      yield cancel(sagaTask);
    }
  };

  sagaMiddleware.run(runnableSaga);
}

function cancelSaga(key) {
  store.dispatch({
    type: CANCEL_SAGA,
    payload: key,
  });
}

const useReduxModule = (key, saga, reducer, initialAction, loader) => {
  const [Module, setModule] = React.useState();
  const mounted = React.useRef(true);

  React.useEffect(() => {
    // Initialize our arrays for the disposer.
    const reducerKeys = [];
    const sagaKeys = [];

    // Initialize saga(s)
    if (Array.isArray(saga)) {
      saga.forEach(({ key: k, saga: sagaFunc }) => {
        sagaKeys.push(k);
        runSaga(k, sagaFunc);
      });
    }
    else if (saga) {
      sagaKeys.push(key);
      runSaga(key, saga);
    }

    // Inject our reducer(s)
    if (Array.isArray(reducer)) {
      reducer.forEach(({ key: k, reducer: reducerFunc }) => {
        reducerKeys.push(k);
        store.injectReducer(k, reducerFunc);
      });
    }
    else if (reducer) {
      reducerKeys.push(key);
      store.injectReducer(key, reducer);
    }

    // Dispatch the initial fetch action(s).
    if (Array.isArray(initialAction)) {
      initialAction.forEach((action) => action());
    }
    else if (typeof initialAction === 'function' && initialAction) {
      initialAction();
    }

    loader.then((mod) => {
      if (mounted.current) {
        setModule(() => mod.default);
      }
    });

    // Return our disposers to clean the redux state.
    return () => {
      mounted.current = false;
      reducerKeys.forEach(store.removeReducer);
      sagaKeys.forEach(cancelSaga);
    };
  }, []); // eslint-disable-line
  return Module;
};

export default useReduxModule;

