import { ORDER_ALPHABETICALLY } from '../../../constants';
import { errorEntities, fetchEntities, receiveEntities } from '../../../reducers/data/utils';

import {
  ORDER_MY_CARDS,
  ERROR_MY_PROJECTS,
  FETCH_MY_PROJECTS,
  RECEIVE_MY_PROJECTS,
} from './_actionTypes';

const initialState = {
  relations: {
    userHasProjects: {},
  },
  sortOrder: ORDER_ALPHABETICALLY,
  turnedAllCards: false,
  turnedCards: new Set(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_MY_PROJECTS: return errorEntities('userHasProjects', state, action);
    case FETCH_MY_PROJECTS: return fetchEntities('userHasProjects', state, action);
    case RECEIVE_MY_PROJECTS: return receiveEntities('userHasProjects', state, action);
    case ORDER_MY_CARDS: {
      const { order } = action;
      return { ...state, sortOrder: order };
    }
    default:
      return state;
  }
};
