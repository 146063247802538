import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@innovatrix/common/buttons';

const ToggleButton = styled(Button)`
  border-radius: 0;
`;

const ToggleText = ({ onChange, options, value, className }) => {
  const index = options.findIndex(o => o.value === value);
  const selectedOption = options[index];
  const nextValue = options[(index + 1) % options.length];
  return (
    <ToggleButton
      className={className}
      flavor="outline"
      label={selectedOption && selectedOption.label}
      onClick={onChange.bind(null, nextValue.value)}
    />
  );
};

ToggleText.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
};

export default ToggleText;
