import React from 'react';
import PropTypes from 'prop-types';

const Cross = ({ color = '#3c3c3b', onClick, className, styles = {} }) => (
  <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" {...styles}>
    <path fill={color} d="M24 2.4L14.4 12l9.6 9.6-2.4 2.4-9.6-9.6L2.4 24 0 21.6 9.6 12 0 2.4 2.4 0 12 9.6 21.6 0z" opacity=".495" />
  </svg>
);

Cross.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.object,
};

export default Cross;
