import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const DeleteIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 40 40" {...styles} {...props}>
    <path d="M25.2 5.6V4.1c0-.8-.7-1.5-1.5-1.5h-7.4c-.8 0-1.5.7-1.5 1.5v1.4H7.2c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h25.6c.4 0 .8-.3.8-.7 0-.4-.3-.7-.8-.7h-7.6zm-8.9 0V4.1h7.4v1.4h-7.4zM8.9 9.9L10.8 35c0 .5.5.9 1 .9h16.3c.5 0 1-.4 1-.9L31 9.9H8.9zm16 1.5l-1.2 23h-7.3l-1.2-23h9.7zm-14.3 0h4.1l1.2 23h-3.5l-1.8-23zm17.1 23h-3.5l1.2-23h4.1l-1.8 23z" />
  </svg>
);

DeleteIcon.propTypes = propTypes;
DeleteIcon.defaultProps = defaultProps;

export default DeleteIcon;
