import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const LIGHT = 'light';
const DARK = 'dark';

const MenuBarWrapper = styled.ul`
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 20px 64px 0 64px;
  ${({ flavor }) => (flavor === LIGHT ? `
    padding-left: 0;
    padding-right: 0;
    padding-top: 6px;
  ` : '')}

  &::before, &::after {
    display: table;
    content: " ";
  }

  &::after {
    clear: both;
  }

  ${({ flavor }) => (flavor === DARK ? `
  @media screen and (min-width: 1700px) {
    padding-left: calc(64px + 1vw);
    padding-right: calc(64px + 1vw);
  }
  ` : '')}
`;

const MenuBar = ({ className, children, flavor = LIGHT }) => (
  <MenuBarWrapper className={className} flavor={flavor}>
    {children}
  </MenuBarWrapper>
);

MenuBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flavor: PropTypes.oneOf([LIGHT, DARK]),
};

export default React.memo(MenuBar);
