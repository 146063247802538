import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 8px 8px 8px 27px;
  width: 300px;
`;

const SingleSelectionAnswerDragPreview = ({ value }) => (
  <Wrapper>
    {value}
  </Wrapper>
);

SingleSelectionAnswerDragPreview.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SingleSelectionAnswerDragPreview;
