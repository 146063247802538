import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ className, color, onClick, styles, size }) => (
  <svg className={className} styles={styles} height={size} width={size} xmlns="http://www.w3.org/2000/svg" version="1.1" onClick={onClick} viewBox="0 0 14 16">
    <path
      fill={color}
      d="M0.6,15.3c-1-2.6-0.7-4.3,1-5.1c2.6-1.2,7.2-1.4,10.3,0c2,0.9,2.4,2.6,1,5.1H0.6z M6.8,7.4c-2.1,0-3.8-1.7-3.8-3.7S4.8,0,6.8,0s3.8,1.7,3.8,3.7S8.9,7.4,6.8,7.4z"
    />
  </svg>
);

UserIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  styles: PropTypes.any,
};

UserIcon.defaultProps = {
  color: '#FFFFFF',
  size: '14px',
};

export default UserIcon;
