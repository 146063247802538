import queryString from 'query-string';
import moment from 'moment';
import { createSelector } from 'reselect';

import { UNLIMITED, ONCE, LIMITED } from '../../constants';

import { DEFAULT_ENTITY, DEFAULT_ENTITIES } from './utils';
import { getActiveAssessmentVersion } from './assessmentSelectors';

const getProjectState = state => state.data.project;
const getProjectOverviewState = state => state.data.projectOverview;

const getProjectEntities = createSelector(
  getProjectState,
  (projectState) => projectState.entities,
);

const getProjectRelations = createSelector(
  getProjectState,
  (projectState) => projectState.relations,
);

const getProjectOneOnOneWorkshops = createSelector(
  getProjectRelations,
  (relations) => relations.projectHasTimeslots,
);

const getProjectOverviewEntities = createSelector(
  getProjectOverviewState,
  (projectOverviewState) => projectOverviewState.entities,
);

const getApplicationHasManagersRelations = createSelector(
  getProjectRelations,
  (relations) => relations.applicationHasManagers,
);

export const getFilterSegments = createSelector(
  getProjectRelations,
  (relations) => relations.filterSegments,
);

export const getUserHasProjectsRelations = createSelector(
  getProjectRelations,
  (relations) => relations.userHasProjects,
);

export const getProjectHasWorkshopsRelations = createSelector(
  getProjectRelations,
  (relations) => relations.projectHasWorkshops,
);

export const getProjectWorkshops = (state, { projectId, status }) => {
  const workshops = getProjectHasWorkshopsRelations(state)[queryString.stringify({ status, projectId })];
  if (!workshops) { return DEFAULT_ENTITIES; }
  return {
    status: workshops.status,
    data: workshops.data.map(({
      workshopType, attended, workshopMomentId, workshopDescription, workshopTitle, workshopDate, start, end, ...rest
    }) => ({
      attended: attended === undefined ? null : attended,
      description: workshopDescription,
      id: workshopMomentId,
      title: workshopTitle,
      type: workshopType,
      start: start && moment(start),
      end: end && moment(end),
      workshopMomentId,
      ...rest,
    })),
  };
};

export const getProjectBoards = (state, { projectId = '' }) => createSelector(
  getProjectEntities,
  (entities) => entities[queryString.stringify({ projectId })] || DEFAULT_ENTITY,
)(state);

export const getProject = (state, {
  campaignId = '',
  evaluationId,
  isCompare,
  phaseId,
  projectId,
}) => createSelector(
  getProjectEntities,
  (entities) => entities[queryString.stringify({ campaignId, evaluationId, isCompare, phaseId, projectId })] || DEFAULT_ENTITY,
)(state);

export const getProjectOverview = (state, { projectId }) => {
  const overview = getProjectOverviewEntities(state)[queryString.stringify({ projectId })];
  if (!overview) {
    return DEFAULT_ENTITY;
  }

  return {
    ...overview,
    managers: overview.managers && overview.managers.map(manager => ({
      ...manager,
      name: manager.firstName ? `${manager.firstName} ${manager.lastName}` : undefined,
    })),
  };
};

export const getProjectTeamMembers = (state, projectId) => {
  const overview = getProjectOverview(state, { projectId });
  return overview.teamMembers;
};

export const getManagers = (state) => createSelector(
  getApplicationHasManagersRelations,
  (managersRelations) => managersRelations[''] || DEFAULT_ENTITIES,
)(state);

export const getOneOnOnes = (state, { projectId, workshopMomentId }) => (
  getProjectOneOnOneWorkshops(state)[queryString.stringify({ projectId, workshopMomentId })] || DEFAULT_ENTITIES
);

export const getCanEnableFollowUp = (state, { projectId }) => {
  const projectOverview = getProjectOverview(state, { projectId });
  const activeAssessmentVersion = getActiveAssessmentVersion(state);
  const evaluations = (projectOverview.evaluations || []);
  const lastEvaluation = evaluations[evaluations.length - 1];
  if (lastEvaluation && lastEvaluation.groupId !== activeAssessmentVersion.groupId) {
    return false;
  }

  switch (activeAssessmentVersion.limit) {
    case LIMITED: {
      const filledInEvaluations = evaluations.filter((e) => e.groupId === activeAssessmentVersion.groupId);
      return filledInEvaluations.length < activeAssessmentVersion.maxAmount;
    }
    case UNLIMITED: return true;
    case ONCE: return false;
    default: return false;
  }
};
