import {
  ATTACH_DOCUMENT_TEMPLATE,
  ATTACH_FORM_DOCUMENT,
  ATTACH_REVISED_DOCUMENT,
  ATTACH_WORKSHOP_DOCUMENT_FOR_REVISION,
  ATTACH_WORKSHOP_DOCUMENT,
  CREATE_DOCUMENT,
  CREATE_NEW_DOCUMENT_VERSION,
  CREATE_NEW_WORKSHOP_DOCUMENT_VERSION,
  DOWNLOAD_ALL_REVISED_DOCUMENTS,
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_INNOVATRIX_LEGAL_DOCUMENT,
} from '@innovatrix/constants';

export const createDocumentAction = ({ file, projectId, folderId, callback }) => ({
  file,
  projectId,
  folderId,
  type: CREATE_DOCUMENT,
  callback,
});

export const downloadDocumentAction = (params) => ({
  ...params,
  type: DOWNLOAD_DOCUMENT,
});

export const downloadAllRevisedDocumentsAction = ({ workshopMomentId, name }) => ({
  name,
  workshopMomentId,
  type: DOWNLOAD_ALL_REVISED_DOCUMENTS,
});

export const createNewDocumentVersionAction = (params) => ({
  ...params,
  type: CREATE_NEW_DOCUMENT_VERSION,
});

export const createNewWorkshopDocumentVersionAction = ({ documentId, file, workshopMomentId, callback }) => ({
  callback,
  documentId,
  file,
  type: CREATE_NEW_WORKSHOP_DOCUMENT_VERSION,
  workshopMomentId,
});

export const attachWorkshopDocumentAction = ({ workshopMomentId, file, callback }) => ({
  callback,
  file,
  type: ATTACH_WORKSHOP_DOCUMENT,
  workshopMomentId,
});

export const attachWorkshopDocumentForRevisionAction = (params) => ({
  ...params,
  type: ATTACH_WORKSHOP_DOCUMENT_FOR_REVISION,
});

export const attachRevisedDocumentAction = ({ workshopMomentId, projectId, file, documentId, callback }) => ({
  callback,
  projectId,
  file,
  documentId,
  workshopMomentId,
  type: ATTACH_REVISED_DOCUMENT,
});

/**
* Used in intake builder
*/
export const createDocumentTemplateAction = ({ formId, formType, questionId, sectionId, sectionOrder, order, file, callback, label, downloadOnly }) => ({
  callback,
  downloadOnly,
  file,
  formId,
  formType,
  label,
  order,
  questionId,
  sectionId,
  sectionOrder,
  type: ATTACH_DOCUMENT_TEMPLATE,
});

/**
* Used in intake and report form
*/
export const createFormDocumentAction = ({ answerId, questionId, file, formType, isPreview, callback }) => ({
  answerId,
  callback,
  file,
  formType,
  isPreview,
  questionId,
  type: ATTACH_FORM_DOCUMENT,
});

export const downloadInnovatrixPrivacyPolicyAction = (props) => ({
  ...props,
  type: DOWNLOAD_INNOVATRIX_LEGAL_DOCUMENT,
});
