import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ChevronIcon from '@innovatrix/icons/ChevronIcon';

import Paragraph from '../text/Paragraph';

const ITEM_HEIGHT = 30;
const OPTIONS_LIMIT = 15;
const TOTAL_PADDING_MENU = 8;

const DropdownMenuItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${ITEM_HEIGHT}px;
  padding: 8px ${({ hasNested }) => (hasNested ? 16 : 12)}px;
  position: relative;
  ${({ separator, theme }) => (separator ? `
    border-bottom: 1px solid ${theme.colors.grey1};
  ` : '')}

  &:hover {
    background: ${({ theme }) => theme.colors.greyBlueish}
  }
`;

const IconWrapper = styled.span`
  align-items: center;
  display: flex;
  height: 12px;
  margin-right: 12px;
  width: 12px;
  > svg:first-of-type, img:first-of-type {
    height: 12px;
    width: 12px;
  }
`;

const NestedMenu = styled.ul`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  margin: 0;
  padding: 4px 0;
  position: fixed;
  z-index: 6;
  ${({ dropup, topOffset, leftOffset, width, left, rightOffset, totalHeight }) => `
    left: ${left ? leftOffset - 4 : rightOffset}px;
    top: ${dropup ? topOffset - totalHeight : topOffset}px;
    width: ${width}px;
  `}
  ${({ optionsLimitReached }) => (optionsLimitReached ? `
    max-height: ${OPTIONS_LIMIT * ITEM_HEIGHT}px;
    overflow-y: auto;
  ` : '')}
`;

const ItemParagraph = styled(Paragraph)`
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledChevronIcon = styled(ChevronIcon)`
  height: 6px;
  position: absolute;
  width: 6px;
  ${({ left }) => (left ?
    'left: 4px;' :
    'right: 4px; transform: rotate(180deg);'
  )};
`;

const Item = ({ label, icon, action, options, left, small, hasNested, children, className, selected, ignoreClick, separator }) => {
  // Tracks whether or not we are in a timeout frame.
  const timeout = React.useRef();
  const buttonRef = React.useRef();
  // Is our list of items open.
  const [isOpen, setIsOpen] = React.useState(false);
  const [rerenders, rerender] = React.useState(false);
  // Reflow force, same story as Dropdown root.
  React.useLayoutEffect(() => {
    if (isOpen) {
      const onResize = () => rerender(s => !s);
      onResize();
      window.addEventListener('resize', onResize);
      window.addEventListener('scroll', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('scroll', onResize);
      };
    }
  }, [isOpen]);

  const toggle = React.useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      timeout.current = undefined;
      setIsOpen(() => true);
    }, 250);
  }, []);

  const leave = React.useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
    else {
      timeout.current = setTimeout(() => {
        timeout.current = undefined;
        setIsOpen(() => false);
      }, 333);
    }
  }, []);

  const { width: _buttonWidth, left: leftOffset, top, height: buttonHeight } = React.useMemo(() =>
    (buttonRef.current ? buttonRef.current.getBoundingClientRect() : {}), [buttonRef.current, rerenders]); // eslint-disable-line

  const hasNestedOptions = React.useMemo(() => options && options.some(({ options: opts }) => Boolean(opts)), [options]);

  const renderItemOptions = React.useCallback((opts) => {
    const optionsLimitReached = opts.length > OPTIONS_LIMIT;
    const totalHeight = optionsLimitReached
      ? (OPTIONS_LIMIT * ITEM_HEIGHT) + TOTAL_PADDING_MENU - buttonHeight
      : (opts.length * ITEM_HEIGHT) + TOTAL_PADDING_MENU - buttonHeight;
    const shouldDropup = (window.innerHeight) < (top + totalHeight);
    const buttonWidth = _buttonWidth < 150 ? 150 : _buttonWidth;

    return (
      <NestedMenu
        className={ignoreClick ? ` ${ignoreClick}` : ''}
        dropup={shouldDropup}
        left={left}
        leftOffset={leftOffset - buttonWidth}
        optionsLimitReached={optionsLimitReached}
        rightOffset={leftOffset + _buttonWidth}
        topOffset={top}
        totalHeight={totalHeight}
        width={buttonWidth}
      >
        {opts.map(({
          label: itemLabel, icon: itemIcon,
          action: itemAction, options: itemOptions, selected: selectedOption,
          separator: innerSeparator,
        }, i) => (
          <Item
            action={itemAction}
            className={ignoreClick ? ` ${ignoreClick}` : ''}
            hasNested={hasNestedOptions}
            icon={itemIcon}
            key={`${itemLabel}${i}`}
            label={itemLabel}
            left={left}
            options={itemOptions}
            selected={selectedOption}
            separator={innerSeparator}
            small={small}
            width={buttonWidth}
          />
        ))}
      </NestedMenu>
    );
  }, [buttonHeight, top, _buttonWidth, left, leftOffset, ignoreClick, hasNestedOptions, small]);

  return (
    <DropdownMenuItem
      className={`${className}${ignoreClick ? ` ${ignoreClick}` : ''}`}
      hasNested={hasNested}
      onClick={action}
      onMouseEnter={toggle}
      onMouseLeave={leave}
      ref={buttonRef}
      separator={separator}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
      {label && (
        <ItemParagraph selected={selected}>
          {label}
        </ItemParagraph>
      )}
      {/* Because emotion forwards these variables to the dom node we have to strip them. */}
      {options && options.length > 0 && <StyledChevronIcon left={left ? 'true' : undefined} />}
      {options && options.length > 0 && isOpen && renderItemOptions(options)}
    </DropdownMenuItem>
  );
};

const optionShape = PropTypes.shape({
  action: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.bool,
  separator: PropTypes.bool,
});

Item.propTypes = {
  action: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  hasNested: PropTypes.bool,
  icon: PropTypes.node,
  ignoreClick: PropTypes.string,
  label: PropTypes.string,
  left: PropTypes.bool,
  options: PropTypes.arrayOf(optionShape),
  selected: PropTypes.bool,
  separator: PropTypes.bool,
  small: PropTypes.bool,
};

export default React.memo(Item);
