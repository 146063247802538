import {
  RECEIVE_OBJECTIVES, FETCH_OBJECTIVES, ERROR_OBJECTIVES, COMPLETE_OBJECTIVE, COMPLETED_OBJECTIVES, ALL_OBJECTIVES,
} from '../../constants';

import { fetchEntities, receiveEntities, errorEntities, serialize } from './utils';

const initialState = {
  entities: {},
  relations: {
    innovatrixHasObjectives: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_OBJECTIVES: return receiveEntities('innovatrixHasObjectives', state, action);
    case FETCH_OBJECTIVES: return fetchEntities('innovatrixHasObjectives', state, action);
    case ERROR_OBJECTIVES: return errorEntities('innovatrixHasObjectives', state, action);

    case COMPLETE_OBJECTIVE: {
      const { objectiveId, objectiveTypeId, projectId } = action;
      // TODO: make this easier! To complicated! Used in saga, in views, ...
      const fetchObjectiveTypeId = objectiveTypeId === ALL_OBJECTIVES || objectiveTypeId === COMPLETED_OBJECTIVES ? undefined : objectiveTypeId;
      const key = serialize({
        completed: objectiveTypeId === COMPLETED_OBJECTIVES ? true : undefined,
        objectiveTypeId: fetchObjectiveTypeId,
        projectId,
      });

      return {
        ...state,
        relations: {
          ...state.relations,
          innovatrixHasObjectives: {
            ...state.relations.innovatrixHasObjectives,
            [key]: {
              ...state.relations.innovatrixHasObjectives[key],
              data: state.relations.innovatrixHasObjectives[key].data.map(o => {
                if (o.id === objectiveId) {
                  return {
                    ...o,
                    _completed: action.completed,
                  };
                }
                return o;
              }),
            },
          },
        },
      };
    }

    default: return state;
  }
};

