import { spawn } from 'redux-saga/effects';

import applicationSagas from './application';
import assessmentsSagas from './assessments';
import campaignsSagas from './campaigns';
import dealsSagas from './deals';
import documentsSagas from './documents';
import evaluationsSagas from './evaluations';
import exportsSagas from './exports';
import genericSagas from './genericSagas';
import geoSagas from './geo';
import innoBoardSagas from './innoBoards';
import objectiveSagas from './objectives';
import projectSagas from './projects';
import salesforceSagas from './salesforce';
import settingSagas from './settings';
import usersSagas from './users';
import workshopSagas from './workshops';

/**
 * This generator function is run by the redux-saga middleware. Fork or spawn your sagas from this
 * main saga.
 *
 * @example
 * import { spawn } from 'redux-saga/effects';
 *
 * import { fooSaga, barSaga } from './examples';
 *
 * export default function* () {
 *   yield spawn(fooSaga);
 *   yield spawn(barSaga);
 * }
 */
export default function* () {
  yield spawn(applicationSagas);
  yield spawn(assessmentsSagas);
  yield spawn(campaignsSagas);
  yield spawn(dealsSagas);
  yield spawn(documentsSagas);
  yield spawn(evaluationsSagas);
  yield spawn(exportsSagas);
  yield spawn(genericSagas);
  yield spawn(geoSagas);
  yield spawn(innoBoardSagas);
  yield spawn(objectiveSagas);
  yield spawn(projectSagas);
  yield spawn(salesforceSagas);
  yield spawn(settingSagas);
  yield spawn(usersSagas);
  yield spawn(workshopSagas);
}
