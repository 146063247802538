import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import formikField from '@innovatrix/components/fields/formikField';
import { IS_PRODUCTION } from '@innovatrix/constants';
import { PreBody } from '@innovatrix/common/text';

import { FormGroup, LabelGroup, InputGroup, Clarification } from './_helpers';
import { ALLOWED_SIZES, SIZE_MAPPING, defaultFieldPropTypes } from './_constants';

const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: 0;
  border-bottom: 1px solid ${({ error, theme, touched }) => (error && touched ? theme.colors.warning : theme.colors.grey1)};
  color: ${({ theme }) => theme.colors.secondary};
  cursor: text;
  font-size: ${({ size }) => SIZE_MAPPING[size]};
  outline: 0;
  padding: 6px 8px;
  transition: border-bottom 0.3s;
  width: 100%;
  &:placeholder {
    color: ${({ theme }) => theme.colors.grey2};
  }
  &:disabled {
    border-bottom: 1px solid transparent;
    color: ${({ theme, readMode }) => (readMode ? theme.colors.secondary : theme.colors.grey2)};
    cursor: default;
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
  &:hover, &:focus, &:active {
    border-bottom-color: ${({ theme }) => theme.colors.focus};
  }
`;

const Prefix = styled.p`
  margin-bottom: 0;
  margin-right: 24px;
`;

const ALLOWED_TYPES = ['email', 'password', 'text', 'url', 'number'];

const StringFieldComponent = ({
  blur,
  clarification,
  className,
  clearable,
  description,
  disabled,
  error,
  fieldIcon,
  fieldId,
  formatForSalesforce,
  information,
  label,
  max,
  min,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onRemove,
  placeholder,
  prefix,
  readMode,
  required,
  setFieldTouched,
  shouldFocus,
  size,
  touched,
  type,
  value,
}, ref) => {
  if (!IS_PRODUCTION) {
    if (!ALLOWED_TYPES.includes(type)) {
      throw new Error(
        `Invalid type passed to the <StringField /> component.
        Got ${type} expected one of ${ALLOWED_TYPES.join(', ')}.`,
      );
    }

    if (!ALLOWED_SIZES.includes(size)) {
      throw new Error(
        `Invalid size passed to the <StringField /> component.
        Got ${size} expected one of ${ALLOWED_SIZES.join(', ')}.`,
      );
    }
  }

  const textInputRef = React.useRef();

  React.useLayoutEffect(() => {
    if (ref) {
      ref.current = textInputRef.current;
    }
    if (shouldFocus) {
      setTimeout(() => {
        requestAnimationFrame(() => textInputRef.current && textInputRef.current.focus());
      });
    }
  }, [shouldFocus]); // eslint-disable-line

  const formatForSalesforceCallback = React.useCallback((valueToFormat) => {
    /**
     *
     * Commas are not valid...
     * and decimals should be limited to max of 2 values after separator!
     *
     * Example of valid formats:
     * 1000000.34
     * 6.6
     * 1234
     *
     * */
    const valueWithoutCommas = valueToFormat.replace(',', '');
    const disallowExcessiveDecimalSeparators = (valueWithoutCommas.split('.').length - 1) > 1
      ? value
      : valueWithoutCommas;
    const [, decimals] = disallowExcessiveDecimalSeparators.split('.');
    if (decimals && decimals.length > 2) {
      return value;
    }
    return disallowExcessiveDecimalSeparators;
  }, [value]);

  const onChangeCb = React.useCallback((e) => {
    const { value: newValue } = e.currentTarget;
    if (formatForSalesforce) {
      onChange(formatForSalesforceCallback(newValue));
    }
    else {
      onChange(newValue);
    }
  }, [formatForSalesforce, formatForSalesforceCallback, onChange]);

  const clear = React.useCallback(() => {
    onChange('');
    if (onRemove) {
      onRemove();
    }
  }, [onChange, onRemove]);

  const onBlurCb = React.useCallback((e) => {
    const { value: newValue } = e.currentTarget;
    // When wrapped in FormikContext, we set the field to touched on onBlur
    // this way we can display errors to users onBlur
    if (setFieldTouched) {
      // fieldId | touched | shouldValidate
      setFieldTouched(fieldId, true, required);
    }
    if (blur) {
      blur(newValue);
    }
    else if (onBlur) {
      onBlur(newValue);
    }
  }, [blur, fieldId, onBlur, required, setFieldTouched]);

  return (
    <FormGroup className={className}>
      {label && <LabelGroup touched={touched} error={error} label={label} information={information} required={required} />}
      {description && <PreBody>{description}</PreBody>}
      {clarification && <Clarification>{clarification}</Clarification>}
      <InputGroup icon={fieldIcon} clear={disabled || !clearable ? undefined : clear}>
        {prefix ? <Prefix>{prefix}</Prefix> : null}
        <Input
          autoComplete="off"
          disabled={disabled}
          error={error}
          max={max}
          min={min}
          name={fieldId}
          onBlur={onBlurCb}
          onChange={onChangeCb}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder || 'placeholder'}
          readMode={readMode}
          ref={textInputRef}
          size={size}
          touched={touched}
          type={type}
          value={value}
        />
      </InputGroup>
    </FormGroup>
  );
};

StringFieldComponent.propTypes = {
  ...defaultFieldPropTypes,
  blur: PropTypes.func,
  clearable: PropTypes.bool,
  description: PropTypes.string,
  fieldIcon: PropTypes.node,
  formatForSalesforce: PropTypes.bool,
  information: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  onKeyDown: PropTypes.func,
  onRemove: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  readMode: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  shouldFocus: PropTypes.bool,
  size: PropTypes.oneOf(ALLOWED_SIZES),
  touched: PropTypes.bool,
  type: PropTypes.oneOf(ALLOWED_TYPES),
  value: PropTypes.string.isRequired,
};

export const StringField = React.memo(React.forwardRef(StringFieldComponent));
StringField.defaultProps = { size: 'body', type: 'text' };
StringField.displayName = 'StringField';

export default formikField(StringField);
