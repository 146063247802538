import styled from '@emotion/styled';
import DragDropHandlerIcon from '@innovatrix/icons/DragDropHandlerIcon';
import ChevronIcon from '@innovatrix/icons/ChevronIcon';
import AsteriskIcon from '@innovatrix/icons/AsterixIcon';
import { Body, Paragraph } from '@innovatrix/common/text';
import { IconButton } from '@innovatrix/common/buttons';

export const TabContainer = styled.div`
  padding: 0 16px 16px 16px;
`;

export const DragDropHandler = styled(DragDropHandlerIcon)`
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
`;

export const CollapseIcon = styled(ChevronIcon)`
  transform: ${({ collapsed }) => (collapsed ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: all ease 0.3s;
`;

export const DragDropHandlerDisabled = styled.div`
  height: 11px;
  margin-left: 8px;
  margin-right: 8px;
  width: 11px;
`;

export const List = styled.div`
  > div {
    margin-bottom: 8px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;

export const SectionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.greyBlueish};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  padding: 8px;

  ${({ isDragging }) => (isDragging ? `
    border-style: dashed;
  ` : '')}

  ${({ canDrop, theme }) => (canDrop ? `
    border-color: ${theme.colors.focus};
    box-shadow: 0 0 0 2px ${theme.colors.focus};
  ` : '')}

  ${({ isInterSection, theme }) => (isInterSection ? `
    border-color: ${theme.colors.focus};
  ` : '')}
`;

export const SectionDetails = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

export const Fields = styled.div`
  height: ${({ collapsed }) => (collapsed ? 0 : '100%')};
  overflow: ${({ collapsed }) => (collapsed ? 'hidden' : 'visible')};
`;

export const IntegrationContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.greyBlueish};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  margin-bottom: 8px;
  padding: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const IntegrationDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IntegrationFieldEntry = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    width: 100%;

    > div > button {
      width: 100%;
    }
  }
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.grey1};
  height: 1px;
  margin: 16px -16px;
  width: calc(100% + 32px);
`;

export const Title = styled(Body)`
  font-weight: bold;
  margin-bottom: 0;
`;

export const RequiredIcon = styled(AsteriskIcon)`
  vertical-align: top;
  margin-left: 4px;
  margin-top: 1px;
`;

export const QuestionTypeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.transparent};
  box-shadow: none;
  display: ${({ flexInline }) => (flexInline ? 'inline-flex' : 'flex')};
  justify-content: space-between;
  padding: 8px;
  position: relative;
  transition: all 0.3s ease-in-out;

  ${({ inverted, theme }) => (inverted
    ? `
    border: 1px solid ${theme.colors.grey1};
  `
    : '')}

  /* Only show hover state when not dragging and QuestionType isn't disabled */
  ${({ theme, disabled, isDragging, isOver }) => (disabled || isDragging || isOver
    ? ''
    : `
    &:hover {
      border: 1px solid ${theme.colors.grey1};
      box-shadow: ${theme.boxShadow};
    }
  `)}

  /* Container styling onDrag QuestionType */
  ${({ theme, isDragging }) => (isDragging
    ? `
    background-color: ${theme.colors.greyBlueish};
    border: 1px dashed ${theme.colors.grey1};
  `
    : '')}

  /* Add box-shadow and border to DragPreview */
  ${({ theme, flexInline }) => (flexInline
    ? `
    border: 1px solid ${theme.colors.grey1};
    box-shadow: ${theme.boxShadow};
    width: 500px;
  `
    : '')}
`;

export const ExtendedQuestionTypeWrapper = styled(QuestionTypeWrapper)`
  &::before, &::after {
    background-color: ${({ theme }) => theme.colors.transparent};
    content: "";
    display: block;
    height: 5px; /* height is 2px + 3px for the border */
    left: 0;
    position: absolute;
    transition: all 0.3s ease;
    width: 100%;
  }

  /**
   * This got complex...
   * We want to prevent 'flickering' when dragging between items
   * We want the before / after element to be 'connected' to it's main element
   * This means we want a border with a colour (transparent doesn't count)
   * If there is any margin or empty space or transparent border then 'isOver' becomes false for a pixel or two
   * So we use a border of the background colour
   */
  &::after {
    border-top: 3px solid ${({ theme }) => theme.colors.greyBlueish};
    bottom: -6px;
  }

  &::before {
    border-bottom: 3px solid ${({ theme }) => theme.colors.greyBlueish};
    top: -6px;
  }

  ${({ theme, disabled, isDragging, isOver }) => (disabled || isDragging || isOver
    ? ''
    : `
    &:hover {
      /**
       * Because we have a before/after border we need to hide this on hover to avoid conflicting with dropshadow
       */
      &::after {
        border-color: ${theme.colors.transparent};
      }

      &::before {
        border-color: ${theme.colors.transparent};
      }
    }
  `)}

  ${({ isOver, theme, hoveringOnTopHalf }) => (isOver ? `
    ${hoveringOnTopHalf ? `
      &::before {
        background-color: ${theme.colors.selectionBlue};
      }
    ` : `
      &::after {
        background-color: ${theme.colors.selectionBlue};
      }
    `}
  ` : '')}
`;

export const QuestionIcon = styled(IconButton)`
  margin-left: 12px;
  margin-right: 12px;

  > button {
    cursor: default;

    &:hover {
      background-color: ${({ theme }) => theme.colors.greyLight1};
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.grey1};
      box-shadow: none;
      outline: none;
    }

    &:focus::after {
      animation: none; /* removes the ripple-effect */
    }

    > span,
    > span > span,
    > span > span > svg {
      cursor: default; /* remove the mouse cursor when hovering over the IconButton */
    }
  }

  ${({ hide }) => (hide
    ? `
    visibility: hidden;
  `
    : '')}
`;

export const Side = styled.div`
  align-items: center;
  display: flex;
`;

const truncateStrings = `
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1450px) {
    max-width: 300px;
  }

  @media (max-width: 1300px) {
    max-width: 250px;
  }

  @media (max-width: 1200px) {
    max-width: 200px;
  }
`;

export const QuestionTypeTitle = styled(Body)`
  margin-bottom: 0;
  ${truncateStrings}

  ${({ isEmpty, theme }) => (isEmpty
    ? `
    color: ${theme.colors.warning};
    font-style: italic;
  `
    : '')}
`;

export const QuestionTypeDescription = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey1};
  margin-bottom: 0;
  ${truncateStrings}
`;

export const QuestionTypeInformation = styled.div`
  display: flex;
  flex-direction: column;
`;
