import { mutationBuilder, queryBuilder } from '@innovatrix/utils/dx';

// -- Queries --------------- --- --  -

export const persistSurveyMutation = mutationBuilder(
  {
    variables: {
      addEvaluationAsReviewer: { type: 'Boolean' },
      assessmentVersionId: { type: 'String!' },
      evaluationId: { type: 'String' },
      phaseId: { type: 'String' },
      projectId: { type: 'String!' },
      questions: { type: 'JSON!' },
      setEvaluationAsSubmitted: { type: 'Boolean!' },
    },
    fields: {
      _persistSurvey: {
        varArgs: {
          addEvaluationAsReviewer: 'addEvaluationAsReviewer',
          assessmentVersionId: 'assessmentVersionId',
          evaluationId: 'evaluationId',
          phaseId: 'phaseId',
          projectId: 'projectId',
          questions: 'questions',
          setEvaluationAsSubmitted: 'setEvaluationAsSubmitted',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const updateAssessmentVersionMutation = mutationBuilder(
  {
    variables: {
      assessmentVersion: { type: 'JSON!' },
    },
    fields: {
      _updateAssessmentVersion: {
        varArgs: { assessmentVersion: 'assessmentVersion' },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createAssessmentVersionMutation = mutationBuilder(
  {
    variables: {
      assessmentVersion: { type: 'JSON!' },
    },
    fields: {
      _createAssessmentVersion: {
        varArgs: { assessmentVersion: 'assessmentVersion' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getAssessmentVersionQuery = queryBuilder(
  {
    variables: {
      assessmentVersionId: { type: 'String!' },
    },
    fields: {
      _getAssessmentVersion: {
        varArgs: { assessmentVersionId: 'assessmentVersionId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getActiveAssessmentVersionQuery = queryBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
    },
    fields: {
      _getActiveAssessmentVersion: {
        varArgs: { projectId: 'projectId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getAssessmentVersionsQuery = queryBuilder(
  {
    variables: {
      assessmentId: { type: 'String!' },
    },
    fields: {
      _getAssessmentVersions: {
        varArgs: { assessmentId: 'assessmentId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);
