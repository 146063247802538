import {
  ERROR_MANAGERS_AND_COACHES,
  FETCH_MANAGERS_AND_COACHES,
  RECEIVE_MANAGERS_AND_COACHES,
} from '../../constants';

import { fetchEntities, receiveEntities, errorEntities } from './utils';

const initialState = {
  entities: {},
  relations: {
    hasUsers: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_MANAGERS_AND_COACHES: return errorEntities('hasUsers', state, action);
    case FETCH_MANAGERS_AND_COACHES: return fetchEntities('hasUsers', state, action);
    case RECEIVE_MANAGERS_AND_COACHES: return receiveEntities('hasUsers', state, action);

    default: return state;
  }
};
