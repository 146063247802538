import {
  ANIMATE_WORKSHOP,
  COMPLETE_ANIMATE_WORKSHOP,
  DELETE_TIMESLOT_TIME_RANGE,
  DOWNLOAD_ICAL_EVENT,
  DOWNLOAD_WORKSHOPS_EXPORT,
  ERROR_TIMESLOT_TIME_RANGES,
  ERROR_TOGGLE_ATTENDED_WORKSHOP,
  FETCH_TIMESLOT_TIME_RANGES,
  MARK_ATTENDED_WORKSHOP,
  MARK_ATTENDING_WORKSHOP,
  RECEIVE_TIMESLOT_TIME_RANGES,
  REMOVE_EXTERNAL_FROM_WORKSHOP,
  TOGGLE_ATTENDED_WORKSHOP,
} from '../constants';

import { createFormikAction } from './utils';

export const addExternalToWorkshopAction = createFormikAction('ADD_EXTERNAL_TO_WORKSHOP');

export const removeExternalFromWorkshopAction = ({ workshopMomentId, memberId, projectId, status, callback }) => ({
  callback,
  memberId,
  projectId,
  status,
  workshopMomentId,
  type: REMOVE_EXTERNAL_FROM_WORKSHOP,
});

export const deleteTimeslotTimeRangeAction = ({ ids, timeslotDateId, callback }) => ({
  callback,
  ids,
  type: DELETE_TIMESLOT_TIME_RANGE,
  timeslotDateId,
});

export const fetchTimeslotTimeRangesAction = ({ timeslotDateId }) => ({
  type: FETCH_TIMESLOT_TIME_RANGES,
  timeslotDateId,
});

export const animateWorkshopAction = ({ workshopMomentId }) => ({
  type: ANIMATE_WORKSHOP,
  workshopMomentId,
});

export const completeAnimateWorkshopAction = () => ({ type: COMPLETE_ANIMATE_WORKSHOP });

export const receiveTimeslotTimeRangesAction = ({ data, timeslotDateId }) => ({
  data,
  type: RECEIVE_TIMESLOT_TIME_RANGES,
  timeslotDateId,
});

export const errorTimeslotTimeRangesAction = ({ error, timeslotDateId }) => ({
  error,
  type: ERROR_TIMESLOT_TIME_RANGES,
  timeslotDateId,
});

export const markAttendingWorkshopAction = ({ status, campaignId, isExternal, projectId, workshopMomentId, attending, memberId, isInnovatrixScreen, callback }) => ({
  attending,
  callback,
  campaignId,
  isExternal,
  isInnovatrixScreen,
  memberId,
  projectId,
  status,
  type: MARK_ATTENDING_WORKSHOP,
  workshopMomentId,
});

export const markAttendedWorkshopAction = ({ status, campaignId, isInnovatrixScreen, name, projectId, workshopMomentId, attended, memberId }) => ({
  attended,
  campaignId,
  isInnovatrixScreen,
  memberId,
  name,
  projectId,
  status,
  type: MARK_ATTENDED_WORKSHOP,
  workshopMomentId,
});

export const downloadICalEventAction = ({
  workshopMomentId,
  title,
  projectId,
}) => ({
  workshopMomentId,
  title,
  projectId,
  type: DOWNLOAD_ICAL_EVENT,
});

export const toggleAttendedWorkshopAction = ({ shouldAnimate, status, attended, campaignId, projectId, workshopMomentId }) => ({
  attended,
  campaignId,
  projectId,
  status,
  shouldAnimate,
  type: TOGGLE_ATTENDED_WORKSHOP,
  workshopMomentId,
});

export const errorToggleAttendedWorkshopAction = ({ campaignId, error, projectId, workshopMomentId }) => ({
  campaignId,
  error,
  projectId,
  type: ERROR_TOGGLE_ATTENDED_WORKSHOP,
  workshopMomentId,
});

export const downloadWorkshopExportAction = () => ({
  type: DOWNLOAD_WORKSHOPS_EXPORT,
});
