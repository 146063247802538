import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, takeEvery } from 'redux-saga/effects';

import { EXPORT_CSV } from '../constants';

import * as api from './_api';

export default function* () {
  yield takeEvery(EXPORT_CSV, function* exportCsv({ csv, name }) {
    try {
      yield api.downloadCsvFile(csv, name);
    }
    catch (e) {
      yield call(showErrorModal, e, { title: `Failed to export ${name}.` });
    }
  });
}
