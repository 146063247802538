import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ModuleContainer = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  margin: 1px -1px -1px -1px;

  > .dx-section {
    margin: 1px;
  }

  > .ModuleToolbar: {
    margin: 0;
  }
`;

const Module = ({ className, children }) => (
  <ModuleContainer className={`dx-module ${className ? ` ${className}` : ''}`}>
    {children}
  </ModuleContainer>
);

Module.defaultProps = { className: '' };

Module.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Module;
