import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import FormikStringField from '../fields/StringField';

const LinkPropType = PropTypes.shape({
  label: PropTypes.string,
  url: PropTypes.string,
});

const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  margin-bottom: 0;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
`;

const mediaQuery = '@media screen and (max-width: 1050px) ';
const ListChild = styled.div`
  display: flex;
  * + & {
    margin-left: 16px;
  }
  align-items: center;
  flex: 0 0 auto;
`;

const ItemList = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
`;

const ButtonWithSeparator = styled.div`
  display: flex;
  align-items: center;
  * + & {
    margin-left: 16px;
  }
`;

const ListItemContent = styled(ListChild)`
  flex: 1 1 auto;
  > * {
    flex: 1 1 auto;
  }
  ${({ responsiveWrap }) => (responsiveWrap ? `
    ${mediaQuery} {
      flex-wrap: wrap;
    }

  ` : '')}
`;

const Meta = styled(ItemList)`
  flex: 0 0 auto;
  * + & {
    margin-left: 16px;
  }
  ${({ responsiveWrap }) => (responsiveWrap ? `
    ${mediaQuery} {
      flex: 1 1 100%;
      flex-basis: 100%;
      justify-content: space-between;
      margin-left: 0;
    }

  ` : '')}
`;

const StyledListElement = styled.li`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  display: flex;
  ${({ hasChildren }) => (hasChildren ? 'flex-direction: column;' : '')}
  margin-bottom: 8px;
  min-height: 40px;
  padding: 4px 8px 4px 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyBlueish};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Separator = styled.span`
  color: ${({ theme }) => theme.colors.grey1};
  margin-left: -8px;
  margin-right: 8px;
`;

const StyledFormikStringField = styled(FormikStringField)`
  flex-grow: 2;
  > div > input, > div > input:hover {
    background-color: ${({ theme }) => theme.colors.transparent};
  }
  margin: 0;
`;

const Info = styled.span`
  color: ${({ theme }) => theme.colors.grey1};
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ChildrenContainer = styled.div`
  margin-top: 4px;
  width: 100%;
`;

const stopPropagation = (e) => {
  e.stopPropagation();
};

const ListItem = ({
  as,
  children,
  className,
  disabled,
  fieldId,
  placeholder,
  iconButtonRight,
  iconButtonsLeft,
  iconsRight,
  info,
  readMode,
  links,
  text,
  onClick,
}) => (
  <StyledListElement
    as={as}
    className={className}
    onClick={onClick}
    responsiveWrap
    hasChildren={children}
  >
    <Container>
      {iconButtonsLeft && <ListChild>{iconButtonsLeft}</ListChild>}
      <ListItemContent responsiveWrap>
        {fieldId && <StyledFormikStringField readMode={readMode} placeholder={placeholder} disabled={disabled} fieldId={fieldId} />}
        {text && <StyledText title={text} className="list-item-title">{text}</StyledText>}
        {((links && links.length > 0) || info) && (
          <Meta responsiveWrap>
            {links && links.map(link => (
              <StyledLink
                key={link.url} to={link.url} onClick={stopPropagation}
              >{link.label}
              </StyledLink>
            ))}
            {info && (Array.isArray(info) ? info.map(inf => (inf ? <Info key={inf}>{inf}</Info> : null)) : <Info>{info}</Info>)}
          </Meta>)}
      </ListItemContent>
      {(iconsRight || iconButtonRight) && (
        <ListChild>
          {iconsRight && <ItemList>{iconsRight}</ItemList>}
          {iconButtonRight && <ButtonWithSeparator><Separator>|</Separator> {iconButtonRight}</ButtonWithSeparator>}
        </ListChild>
      )}
    </Container>
    {children && (
      <ChildrenContainer>
        {children}
      </ChildrenContainer>
    )}
  </StyledListElement>
);

ListItem.propTypes = {
  as: PropTypes.string, // Render component as another HTMLElement
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  // The icon buttons on the right side.
  iconButtonRight: PropTypes.element,
  // The icon buttons left of the separator.
  iconButtonsLeft: PropTypes.element,
  // The informative icon left from the separator.
  iconsRight: PropTypes.element,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  links: PropTypes.arrayOf(LinkPropType),
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  readMode: PropTypes.bool,
  text: PropTypes.string, // TODO: rename to label, inconsistent...
};

ListItem.defaultProps = {
  children: null,
  disabled: false,
  links: [],
};

export default ListItem;
