import {
  CLOSE_MODAL,
  OPEN_MODAL,
} from '../constants';

export const openModalAction = (id) => ({
  id,
  type: OPEN_MODAL,
});

export const closeModalAction = (id) => ({
  id,
  type: CLOSE_MODAL,
});
