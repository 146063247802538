import { RECEIVE_SETTINGS, CHANGE_DECISION_SETTING } from '../constants';

const initialState = {
  lastProcess: null,
  lastSync: null,
  isLoading: false,
  syncDecisions: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_DECISION_SETTING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RECEIVE_SETTINGS: {
      const { settings } = action;
      return {
        ...state,
        ...settings,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
