import queryString from 'query-string';
import { createSelector } from 'reselect';

import { DEFAULT_ENTITY, DEFAULT_ENTITIES } from './utils';

const getCampaigns = state => state.data.campaign;

const getCampaignEntities = createSelector(
  getCampaigns,
  (campaigns) => campaigns.entities,
);

const getCampaignsRelations = createSelector(
  getCampaigns,
  (campaigns) => campaigns.relations,
);

const getInnovatrixHasCampaignsRelations = createSelector(
  getCampaignsRelations,
  (relations) => relations.innovatrixHasCampaigns,
);

export const getInnovatrixCampaigns = createSelector(
  getInnovatrixHasCampaignsRelations,
  (relations) => relations[''] || DEFAULT_ENTITIES,
);

export const getCampaign = (state, { campaignId }) => createSelector(
  getCampaignEntities,
  (entities) => entities[queryString.stringify({ campaignId })] || DEFAULT_ENTITY,
)(state);

export const getCampaignPhases = (state, { campaignId }) => {
  const phases = getCampaign(state, { campaignId }).phases || [];
  return phases;
};
