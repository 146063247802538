import React from 'react';
import PropTypes from 'prop-types';

const ConditionsIcon = ({ color, size, styles, ...props }) => (
  <svg viewBox="0 0 26 26" width={size} height={size} {...styles} {...props}>
    <path
      d="M9.225 17.86L21.06 6l1.583 1.583L9.225 21 3 14.775l1.556-1.584zm12.101.372L21.067 12h1.393l-.264 6.232h-.87zm.483 2.746a.778.778 0 0 1-.57-.239.778.778 0 0 1-.239-.57c0-.22.08-.41.239-.567a.782.782 0 0 1 .57-.235.78.78 0 0 1 .564.233.765.765 0 0 1 .238.57.77.77 0 0 1-.241.572.779.779 0 0 1-.561.236z"
      fill={color}
    />
  </svg>
);

ConditionsIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  styles: PropTypes.any,
};

ConditionsIcon.defaultProps = {
  color: '#F5C023',
  size: '26px',
};

export default ConditionsIcon;
