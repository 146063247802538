import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const InfoIcon = ({ color, className, size, styles, isShowing, ...props }) => (
  <svg className={className} width={size} height={size} fill={color} viewBox="0 0 14 15" {...styles} {...props}>
    <path d="M6.3 5.4V4.1h1.3v1.3H6.3zm.7 7.3c1 0 1.9-.2 2.7-.7.8-.5 1.5-1.1 1.9-1.9.5-.8.7-1.7.7-2.7s-.2-1.9-.7-2.7c-.5-.8-1.1-1.5-1.9-1.9-.8-.5-1.7-.7-2.7-.7s-1.9.2-2.7.7c-.8.5-1.4 1.1-1.9 1.9-.5.8-.7 1.7-.7 2.7s.2 1.9.7 2.7c.5.8 1.1 1.5 1.9 1.9.8.5 1.7.7 2.7.7zm0-12c1.2 0 2.3.3 3.4.9 1 .6 1.8 1.4 2.4 2.4.6 1 .9 2.2.9 3.4s-.3 2.3-.9 3.4c-.6 1-1.4 1.8-2.4 2.4-1 .6-2.2.9-3.4.9s-2.3-.3-3.4-.9c-1-.6-1.8-1.4-2.4-2.4-.6-1-.9-2.2-.9-3.4s.3-2.3 1-3.4c.6-1 1.4-1.8 2.4-2.4C4.7 1 5.8.7 7 .7zm-.7 10v-4h1.3v4H6.3z" />
  </svg>
);

InfoIcon.propTypes = propTypes;
InfoIcon.defaultProps = {
  ...defaultProps,
  size: '12px',
};

export default InfoIcon;
