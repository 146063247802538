import React from 'react';
import PropTypes from 'prop-types';

import { Chapter, ChapterHeader, Module, Spinner } from '../../../../pages/components';

import MenuBar from './MenuBar';

const LoadingScreen = ({ projectStatus }) => (
  <Chapter>
    <ChapterHeader title="Loading your projects">
      <MenuBar isLoaded={false} projectStatus={projectStatus} />
    </ChapterHeader>
    <Module>
      <Spinner />
    </Module>
  </Chapter>
);

LoadingScreen.propTypes = { projectStatus: PropTypes.string };

export default LoadingScreen;
