import { queryBuilder } from '@innovatrix/utils/dx';

export const getMyProjectsQuery = queryBuilder(
  {
    variables: { id: { type: 'String!' } },
    fields: {
      _getMyProjects: {
        varArgs: { id: 'id' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);
