import { createSelector } from 'reselect';

const getObjectivesUiState = (state) => state.objectives;

export const getObjectivesCreating = createSelector(
  getObjectivesUiState,
  (state) => state.creating,
);

export const getAnimatedObjectiveId = createSelector(
  getObjectivesUiState,
  (state) => state.animatedObjectiveId,
);
