import {
  ERROR_QUESTION,
  FETCH_QUESTION,
  RECEIVE_QUESTION,
} from '../../constants';

import { errorEntity, fetchEntity, receiveEntity } from './utils';

const initialState = {
  entities: {},
  relations: {
    questionHasOptions: {},
    assessmentHasVersions: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_QUESTION: return errorEntity(state, action);
    case FETCH_QUESTION: return fetchEntity(state, action);
    case RECEIVE_QUESTION: return receiveEntity(state, action);

    default: return state;
  }
};

