import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const MinusIcon = ({ color, styles, ...props }) => (
  <svg viewBox="0 0 20 20" {...styles} {...props}>
    <path fill={color} d="M-.031 8.481h20.075v3.058H-.031z" />
    <path d="M21.645 11.559h.024v.024h-.024z" />
  </svg>
);

// PRIMAY
MinusIcon.propTypes = propTypes;
MinusIcon.defaultProps = defaultProps;

export default MinusIcon;
