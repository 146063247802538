import {
  EXPORT_CSV, EXPORT_DEALS_CSV,
} from '@innovatrix/constants';

export const exportCsvAction = ({ csv, name }) => ({
  csv,
  name,
  type: EXPORT_CSV,
});

export const exportDealsCsv = () => ({ type: EXPORT_DEALS_CSV });
