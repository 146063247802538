import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { FastField, getIn, Field } from 'formik';
import { GREY_LIGHT_1, FOCUS, MAIN_PAGE } from '@innovatrix/styles';

import { errorStyles } from '../../../styles';
import { ENTER_KEY, ESCAPE_KEY } from '../../../../constants';
import Label from '../Label';

const groupStyles = css({
  width: '100%',
});

const inputStyles = css({
  border: 0,
  borderBottom: `1px solid ${MAIN_PAGE}`,
  fontSize: 16,
  outline: 0,
  overflowY: 'hidden',
  transition: 'all 0.5s',
  width: '100%',
  padding: '5px 10px',
  resize: 'none',
  ':hover': {
    backgroundColor: GREY_LIGHT_1,
  },
  ':focus': {
    borderBottom: `1px solid ${FOCUS}`,
  },
});

export class TextInput extends PureComponent {

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.autosize = this.autosize.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.textInput) {
        if (this.props.focus) {
          this.focus();
        }
        this.autosize();
      }
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.focus && this.props.focus) {
      setTimeout(() => this.focus());
    }
  }

  onChange(e) {
    const { field } = this.props;
    const { value } = e.currentTarget;
    field.onChange(value);
  }

  autosize() {
    const { autoResize } = this.props;
    if (autoResize) {
      if (this.frame) {
        cancelAnimationFrame(this.frame);
      }
      // Read frame
      this.frame = requestAnimationFrame(() => {
        if (!this.textInput.current) { return; }
        this.textInput.current.style.height = 0;
        const currentScrollHeight = this.textInput.current.scrollHeight;
        // Write frame
        this.textInput.current.style.height = `${currentScrollHeight || 34}px`;
        this.frame = null;
      });
    }
  }

  focus() {
    requestAnimationFrame(() => this.textInput.current && this.textInput.current.focus());
  }

  handleKeyDown = (e) => {
    const { onKeyDown, onPressEnter, onPressEscape } = this.props;
    // Do nothing on "Shift + Enter" to allow carriage return.
    if (e.keyCode === ENTER_KEY && !e.nativeEvent.shiftKey && onPressEnter) {
      e.preventDefault();
      e.stopPropagation();
      onPressEnter();
    }
    if (e.keyCode === ESCAPE_KEY && onPressEscape) {
      e.preventDefault();
      e.stopPropagation();
      onPressEscape();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  render() {
    const {
      className, editMode = true, field: { name, value, onBlur: onBlurField, ...restField }, label, required, form: { errors, touched },
      onBlur, placeholder, styles, blur,
    } = this.props;
    const error = getIn(errors, name);
    return (
      <div {...styles} onDrop={this.props.onDrop} className={`${className} ${groupStyles ? groupStyles.toString() : ''}`}>
        <Label label={label} required={required} />
        <textarea
          ref={this.textInput}
          autoComplete="off"
          disabled={!editMode}
          onDrop={this.props.onDrop}
          name={name}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          value={value || ''}
          onBlur={(e) => {
            onBlurField(e);
            if (onBlur) {
              onBlur(e);
            }
            if (blur) {
              blur(e);
            }
          }}
          onInput={this.autosize}
          {...restField}
          {...inputStyles}
        />
        {error && getIn(touched, name) && editMode && <div {...errorStyles}>{error}</div>}
      </div>

    );
  }

}

TextInput.propTypes = {
  autoResize: PropTypes.bool,
  blur: PropTypes.func,
  className: PropTypes.string,
  editMode: PropTypes.bool,
  field: PropTypes.object,
  fieldId: PropTypes.string,
  focus: PropTypes.bool,
  form: PropTypes.object,
  inputRef: PropTypes.any,
  label: PropTypes.string,
  mode: PropTypes.string,
  onBlur: PropTypes.func,
  onDrop: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPressEnter: PropTypes.func,
  onPressEscape: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  style: PropTypes.object, // overwrites styles
  styles: PropTypes.object,
};

TextInput.defaultProps = {
  autoResize: true,
  className: '',
  editMode: true,
  label: '',
  mode: 'DX/EDIT_MODE',
  rows: 1,
  style: {},
  placeholder: '',
};

const TextField = ({ name, fast, fieldId, ...props }) => (fast ?
  <FastField name={name || fieldId} component={TextInput} key={name || fieldId} {...props} /> :
  <Field name={name || fieldId} component={TextInput} key={name || fieldId} {...props} />);

TextField.propTypes = {
  fast: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default TextField;
