import { mutationBuilder, queryBuilder } from '@innovatrix/utils/dx';

// -- Queries --------------- --- --  -

export const getCampaignsQuery = queryBuilder(
  {
    fields: {
      _getCampaigns: {
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getCampaignQuery = queryBuilder(
  {
    variables: {
      campaignId: { type: 'String!' },
    },
    fields: {
      _getCampaign: {
        varArgs: { campaignId: 'campaignId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const updateCampaignMutation = mutationBuilder(
  {
    variables: {
      assessmentFlowsGroupId: { type: 'String' },
      attendees: { type: 'Int' },
      campaignId: { type: 'String!' },
      hasEvaluativePhases: { type: 'Boolean' },
      intakeEndDate: { type: 'Date' },
      intakeFormId: { type: 'String' },
      intakeStartDate: { type: 'Date' },
      intakeType: { type: 'String' },
      name: { type: 'String' },
      phases: { type: 'JSON' },
    },
    fields: {
      _updateCampaign: {
        varArgs: {
          assessmentFlowsGroupId: 'assessmentFlowsGroupId',
          attendees: 'attendees',
          campaignId: 'campaignId',
          hasEvaluativePhases: 'hasEvaluativePhases',
          intakeEndDate: 'intakeEndDate',
          intakeFormId: 'intakeFormId',
          intakeStartDate: 'intakeStartDate',
          intakeType: 'intakeType',
          name: 'name',
          phases: 'phases',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getPhasesQuery = queryBuilder(
  {
    fields: {
      _getPhases: {
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createPhaseMutation = mutationBuilder(
  {
    variables: {
      title: { type: 'String!' },
    },
    fields: {
      _createPhase: {
        varArgs: {
          title: 'title',
        },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);
