import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { deleteEvaluationSuccessAction, errorQuestionAction, receiveQuestionAction, errorEvaluationAction, receiveEvaluationAction, fetchProjectByEvaluationAction, fetchProjectOverviewAction, deleteEvaluationErrorAction, fetchActiveAssessmentVersionAction } from '../actions';
import { FETCH_QUESTION, CLONE_EVALUATION, EVALUATIONS_CONTEXT, POST_EVALUATION, DELETE_EVALUATION, SCORE_TAB, SAVE_ANSWER, SUBMIT_EVALUATION } from '../constants';
import { submitEvaluationMutation, updateAnswerMutation, fetchQuestionQuery, cloneEvaluationMutation, createEvaluationMutation, deleteEvaluationMutation } from '../queries';
import * as evaluationCsvProcessor from '../utils/evaluationCsvProcessor';
import * as toast from '../utils/toast';

import * as api from './_api';

/**
 * @name uploadEvaluationSaga
 */
export default function* () {
  yield takeEvery(CLONE_EVALUATION, function* ({ campaignId, evaluationId, projectId }) {
    try {
      const newEvaluationId = yield call(api.fetch, 'cloneEvaluation', cloneEvaluationMutation, { variables: { evaluationId, projectId } });
      yield put(fetchProjectOverviewAction({ projectId }));
      yield put(fetchActiveAssessmentVersionAction(({ projectId })));
      // Let's navigate to our newly created evaluation.
      yield put(campaignId ?
        push(`/campaigns/${campaignId}/projects/${projectId}/${SCORE_TAB}/${newEvaluationId}`) :
        push(`/projects/${projectId}/${SCORE_TAB}/${newEvaluationId}`));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err);
    }
  });

  yield takeEvery(POST_EVALUATION, function* uploadEvaluation({ campaignId, projectId, rows, callback }) {
    let ratings = [];
    try {
      ratings = evaluationCsvProcessor.process(rows);
    }
    catch (err) {
      console.error(err);
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed processing evaluations csv.' });
      yield put(errorEvaluationAction(err));
      callback(err);
      return;
    }

    try {
      const context = EVALUATIONS_CONTEXT;
      const evaluation = yield call(api.fetch, 'uploadEvaluationCsv', createEvaluationMutation, {
        context,
        variables: {
          evaluation: {
            ratings,
          },
          projectId,
        },
      });

      // Loading spinner can go!
      yield put(receiveEvaluationAction(evaluation));
      yield put(fetchProjectOverviewAction({ projectId }));
      yield put(fetchActiveAssessmentVersionAction(({ projectId })));
      callback();
      toast.success('Upload successful!');

      // Let's navigate to our newly created evaluation.
      yield put(push(`/campaigns/${campaignId}/projects/${projectId}/${SCORE_TAB}/${evaluation.id}`));
    }
    catch (err) {
      console.error(err);
      if (err.code === 'NOT_COMPLETED') { return; }
      callback(err);
      yield put(errorEvaluationAction(err));
      yield call(showErrorModal, err, { title: 'Failed to post evaluation.' });
    }
  });

  yield takeEvery(DELETE_EVALUATION, function* deleteEvaluation({ campaignId, projectId, evaluationId, idToNavigate, callback }) {
    try {
      const variables = { evaluationId };
      yield call(api.fetch, '_deleteEvaluation', deleteEvaluationMutation, { variables });
      callback();
      yield put(deleteEvaluationSuccessAction({ projectId, evaluationId }));
      yield put(fetchProjectOverviewAction({ projectId }));
      yield put(fetchActiveAssessmentVersionAction({ projectId }));
      yield put(push(`${campaignId ? `/campaigns/${campaignId}` : ''}/projects/${projectId}/${SCORE_TAB}/${idToNavigate}`));
    }
    catch (error) {
      callback(error);
      if (error.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, error, { title: 'Failed to post evaluation.' });
      yield put(deleteEvaluationErrorAction({ error, projectId, campaignId, evaluationId }));
    }
  });

  yield takeEvery(SAVE_ANSWER, function* ({ callback, payload: { operation, campaignId, evaluationId, projectId, questionId, evaluationRatingId, question: { answer } } }) {
    try {
      yield call(api.fetch, '_updateAnswer', updateAnswerMutation, {
        variables: {
          answer,
          evaluationId,
          evaluationRatingId,
          questionId,
        },
      });
      callback();
      if (operation !== 'next' && operation !== 'previous') {
        // Dont fetch overview here or you'll break the scrolling.
        yield put(fetchProjectByEvaluationAction({ campaignId, evaluationId, projectId }));
      }
    }
    catch (err) {
      console.error(err);
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to update the answer.' });
      callback(err);
    }
  });

  yield takeEvery(SUBMIT_EVALUATION, function* ({ campaignId, evaluationId, projectId }) {
    try {
      yield call(api.fetch, 'submitEvaluation', submitEvaluationMutation, { variables: { evaluationId } });
      yield put(fetchProjectOverviewAction({ projectId }));
      yield put(fetchProjectByEvaluationAction({ campaignId, evaluationId, projectId }));
      yield put(fetchActiveAssessmentVersionAction(({ projectId })));
    }
    catch (err) {
      console.error(err);
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to submit the evaluation.' });
    }
  });

  yield takeEvery(FETCH_QUESTION, function* fetchQuestion({ questionId }) {
    try {
      const data = yield call(api.fetch, '_fetchQuestion', fetchQuestionQuery, {
        variables: { questionId },
      });
      yield put(receiveQuestionAction(questionId, data));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to get questions.' });
      yield put(errorQuestionAction(questionId, err));
    }
  });
}
