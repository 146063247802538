import queryString from 'query-string';
import { createSelector } from 'reselect';

const getObjectiveTypeState = state => state.data.objectiveType;

const getObjectiveTypesRelations = createSelector(
  getObjectiveTypeState,
  (objectiveType) => objectiveType.relations,
);

export const getInnovatrixHasObjectiveTypes = createSelector(
  getObjectiveTypesRelations,
  (relations) => relations.innovatrixHasObjectiveTypes,
);

export const getObjectiveTypes = (state, projectId) => getInnovatrixHasObjectiveTypes(state)[queryString.stringify({ projectId })];
