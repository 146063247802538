import { call, takeEvery, put } from 'redux-saga/effects';
import { getDealsExportQuery } from '@innovatrix/queries';
import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';

import { EXPORT_DEALS_CSV, EXPORT_CSV } from '../constants';

import * as api from './_api';

export default function* () {
  yield takeEvery(EXPORT_DEALS_CSV, function* downloadDealsCSV() {
    try {
      const result = yield call(api.fetch, '_getDealsExport', getDealsExportQuery);
      yield put({ type: EXPORT_CSV, csv: result, name: 'deals-export.csv' });
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to update the campaign.' });
    }
  });
}
