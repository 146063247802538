import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';
import forIn from 'lodash/forIn';
import isError from 'lodash/isError';
import isPlainObject from 'lodash/isPlainObject';
import isSet from 'lodash/isSet';
import isMap from 'lodash/isMap';
import isFunction from 'lodash/isFunction';
import { isString } from '@innovatrix/utils';

const trimStringify = (json) => JSON.stringify(json).substr(0, 64);
const capitalize = (term) => `${term.substring(0, 1).toUpperCase()}${term.substring(1)}`;

/**
 * @param {Array} arr
 * @return {Array}
 */
const flatten = (arr) => {
  if (Array.isArray(arr)) {
    return arr.reduce((r, v) => r.concat(Array.isArray(v) ? flatten(v) : v), []);
  }
  return arr;
};

/**
 * Merge the given arguments recursively, non-destructively, and with optional custom behaviour.
 *
 * There are several options for merging objects in one way or another, such as `Object.assign()`,
 * `lodash.defaults()` or `jQuery.extend()`. There are even some tools to recursively or 'deeply'
 * merge objects, such as `lodash.defaultsDeep(). Here the question becomes what one should do with
 * arrays. Should one be replaced by the other or should they be merged? What if one wants to merge
 * aspects conditionally. This function attempts to addresses these requirements with a simple
 * approach: provide reasonable default behaviour, and when a property to merge has a function as
 * value, then the result of calling this function is used.
 *
 * The objects are merged pairwise from left to right. When there are for instance three arguments
 * (A, B, C), then first A & B are pairwise-merged, the result of which is then pairwise-merged with
 * C. The result is always a new object. The given objects are not modified.
 *
 * When pairwise-merging two values (A, B), then A is considered the _target_, while B is considered
 * the _source_. The source and target values are merged according to the following (ordered) rules:
 *
 *  1) When the target is null or undefined, then the source is returned;
 *  2) When the source is undefined, then the target is returned;
 *  3) When the source is null, then the result is null (explicitly null'ed);
 *  4) When both values are arrays, then the result is the concatenation of both arrays;
 *  5) When both values are Map objects, then the result is a new Map that contains the key-value
 *     pairs of both, where for keys that occur in both, the value is merged recursively. Source map
 *     keys for which the merged value is null are not included in the merged map.
 *  6) When both values are Set objects, then the result is a new Set that contains the values from
 *     both;
 *  7) TODO: When the source is a Map and the target is a regular Object,
 *  8) TODO: When the source is a regular Objectd and the target is a Map,
 *  9) TODO: When the source is a Set and the target is an Array,
 * 10) TODO: When the source is an Array and the target is a Set,
 * 11) When the source is a function, then this function is called with the target value as sole
 *     argument, and its return value becomes the merge result.
 * 12) When both values are basic objects, then the result is a new object that contains the
 *     properties of both, merged recursively. Source object properties for which the merged value
 *     is null are not included in the merged object.
 * 13) In all other cases, the source value is the result;
 */

const _mergePairwise = (target, source) => {
  if (target === undefined || target === null || source === null) {
    return source;
  }
  if (source === undefined) {
    return target;
  }
  if (Array.isArray(target) && Array.isArray(source)) {
    return target.concat(source);
    // return target.concat(source.filter((val) => !target.includes(val)));
  }
  if (isMap(target) && isMap(source)) {
    const result = new Map(target.entries());
    source.forEach((value, key) => {
      if (target.has(key)) {
        const mergedVal = _mergePairwise(target.get(key), value);
        if (mergedVal === null) {
          result.delete(key);
        }
        else {
          result.set(key, mergedVal);
        }
      }
      else if (value !== null) {
        result.set(key, value);
      }
    });
    return result;
  }
  if (isSet(target) && isSet(source)) {
    const result = new Set(target.values());
    source.forEach((value) => result.add(value));
    return result;
  }
  if (isFunction(source)) {
    return source(target);
  }
  if (isPlainObject(target) && isPlainObject(source)) {
    const result = { ...target };
    Object.keys(source).forEach((key) => {
      if (result[key] !== undefined) {
        const mergedVal = _mergePairwise(target[key], source[key]);
        if (mergedVal === null) {
          delete result[key];
        }
        else {
          result[key] = mergedVal;
        }
      }
      else if (source[key] !== null) {
        result[key] = source[key];
      }
    });
    return result;
  }
  return source;
};

const merge = (...objects) => {
  if (objects.length < 2) {
    throw new RangeError(`Expected at least two arguments, got ${objects.length}.`);
  }
  if (objects.length === 2) {
    if (objects[0] === undefined || objects[0] === null) { return objects[1]; }
    if (objects[1] === undefined || objects[1] === null) { return objects[0]; }
    return _mergePairwise(objects[0], objects[1]);
  }
  else {
    return merge(_mergePairwise(objects[0], objects[1]), ...objects.slice(2));
  }
};

/// output types:
const DX_QUERY = 'DX_QUERY';
const DX_ITEM = 'DX_ITEM';
const DX_ITEMSET = 'DX_ITEMSET';
const DX_RELATEE = 'DX_RELATEE';
const DX_RELATEES = 'DX_RELATEES';
const DX_PROP = 'DX_PROP';
const DX_MUTATEE = 'DX_MUTATEE';

/**
 * Maps root query field names to field types.
 * @type {Map.<string, {}>}
 */
const queryRootFieldTypes = new Map();

/**
 * Maps root mutation field names to field types.
 * @type {Map.<string, {}>}
 */
const mutationRootFieldTypes = new Map();

const makeObjectDeserializer = (deserializers, log, indent) => {
  if (log) {
    console.trace(`${indent}>> makeObjectDeserializer`);
    return ((dataObj, i) => {
      console.trace(`${i}>> deserialize object: ${trimStringify(dataObj)}`);
      const resultObj = { ...dataObj };
      deserializers.forEach((deserialize, fieldId) => {
        if (dataObj[fieldId] !== undefined) {
          const result = deserialize(dataObj[fieldId], `${i}  `);
          console.trace(`${i}  << ${fieldId}: ${trimStringify(result)}`);
          resultObj[fieldId] = result;
        }
      });
      console.trace(`${indent}<< ${trimStringify(resultObj)}`);
      return resultObj;
    });
  }
  else {
    return ((dataObj) => {
      const resultObj = { ...dataObj };
      deserializers.forEach((deserialize, fieldId) => {
        if (dataObj[fieldId] !== undefined) {
          resultObj[fieldId] = deserialize(dataObj[fieldId]);
        }
      });
      return resultObj;
    });
  }
};

function getDeserializers(fields, log, indent) {
  if (log) { console.trace(`${indent}>> getDeserializers`); }
  let deserializers = null;
  forIn(fields, (field, fid) => {
    // eslint-disable-next-line no-use-before-define
    const deserializer = _getDeserializer(field, log, `${indent}  `);
    if (deserializer) {
      if (!deserializers) { deserializers = new Map(); }
      deserializers.set(fid, deserializer);
    }
  });
  return deserializers;
}

function _getDeserializer(field, log = false, indent = '') {
  if (log) { console.trace(`${indent}>> _getDeserializer - ${field.id}, ${field.output}`); }
  const { output = null } = field;
  if (output === DX_PROP) {
    if (field.deserialize && log) {
      console.trace(`${indent}<< ${field.deserialize}`);
      return (value) => {
        console.trace(`${indent}>> deserialize scalar "${field.id}":`, value);
        const result = field.deserialize(value);
        console.trace(`${indent}   to:`, result);
        console.trace(`${indent}   using: "${field.deserialize}"`);
        return result;
      };
    }
    return field.deserialize;
  }
  else if ([DX_ITEM, DX_MUTATEE, DX_QUERY, DX_RELATEE].includes(output)) {
    const deserializers = getDeserializers(field.fields, log, `${indent}  `);
    if (deserializers && deserializers.size > 0) {
      return makeObjectDeserializer(deserializers, log, indent);
    }
  }
  else if (output === DX_ITEMSET || output === DX_RELATEES) {
    const deserializers = getDeserializers(field.fields, log, `${indent}  `);
    if (deserializers && deserializers.size > 0) {
      const objectDeserializer = makeObjectDeserializer(deserializers, log, indent);
      if (log) {
        return (items, i) => {
          console.trace(`${i}>> deserialize items: ${trimStringify(items)}`);
          return items.map((item) => {
            const result = objectDeserializer(item, `${i}  `);
            console.trace(`${i}<< ${trimStringify(trimStringify(result))}`);
            return result;
          });
        };
      }
      else {
        return (items) => items.map(objectDeserializer);
      }
    }
  }
  return null;
}

/**
 * @private
 * Get the deserializer for the given query.
 * @param {DxQuerySpec} query - The dxQuery specification.
 * @param {boolean} log - Log the deserializer.
 * @return {Function} A function that takes the to be serialized data object as argument and returns
 *   the deserialized data.
 */
function getDeserializer(query, log = false) {
  if (log) {
    console.trace('>> getDeserializer()');
    const _deserializer = _getDeserializer(query, log, '  ');
    return (data) => {
      console.trace(`>> deserialize : ${trimStringify(data)}`);
      if (_deserializer) {
        const result = _deserializer(data, '  ');
        console.trace(`<< ${trimStringify(result)}`);
        return result;
      }
      else {
        console.trace('<< [same as input]');
        return data;
      }
    };
  }
  else {
    const _deserializer = _getDeserializer(query);
    return (data) => (_deserializer ? _deserializer(data, '  ') : data);
  }
}

function preprocess(fields, parentPath, fieldTypes, hasVariables, forDxApi = false) {
  forIn(fields, (field, fid) => {
    const path = parentPath === '' ? fid : `${parentPath}.${fid}`;

    // Provide proper field-specs for scalar field (such as `name: true`):
    if (isBoolean(field)) {
      if (!field) {
        throw new Error(`Unexpected "false" value for "${path}".`);
      }
      // eslint-disable-next-line no-multi-assign
      fields[fid] = field = {};
    }

    field.id = fid;
    field.path = path;

    if (forDxApi) {
      if (!fieldTypes.has(fid)) {
        throw new Error(`Unexpected field "${path}".`);
      }

      // Add additional type metadata in field-spec:
      Object.assign(field, fieldTypes.get(fid));

      // Assert various requirements:
      if (!field.hasFields && field.fields) {
        throw new Error(`Unexpected "fields" for "${path}".`);
      }
      if (field.hasFields && !field.fields) {
        throw new Error(`Expected "fields" for "${path}".`);
      }
      if (!field.acceptsArgs && (field.args || field.varArgs)) {
        throw new Error(`Unexpected arguments for "${path}".`);
      }
      if (field.requiredArgs) {
        field.requiredArgs.forEach((argId) => {
          if (!(field.args && field.args[argId]) && !(field.varArgs && field.varArgs[argId])) {
            throw new Error(`Expected "${argId}" argument for "${path}".`);
          }
        });
      }
      if (!hasVariables && field.varArgs) {
        throw new Error(`Unexpected variable arguments for "${path}".`);
      }
    }

    // Recursively pre-process fields:
    if (field.fields) {
      let nextFieldTypes;

      preprocess(field.fields, path, nextFieldTypes, hasVariables, forDxApi);
    }
  });
}

function getVariableDeclarationString({ default: defaultValue, required = false, type }, name) {
  if (!type) {
    throw new Error(`Expected "type" property for variable "${name}".`);
  }
  let vstr = `$${name}: ${type}`;
  if (required) { vstr = `${vstr}!`; }
  if (defaultValue !== undefined) {
    // TODO: serialize non-trivial default value
    vstr = isString(defaultValue) ? `${vstr} = "${defaultValue}"` : `${vstr} = ${defaultValue}`;
  }
  return vstr;
}

function getVariablesDeclarationString(variables, maxLength) {
  const varStrings = Object.keys(variables).map((name) => getVariableDeclarationString(variables[name], name));
  const slstr = `(${varStrings.join(', ')}) `;
  if (slstr.length <= maxLength) {
    return slstr;
  }
  else {
    return `(\n  ${varStrings.join(',\n  ')}\n)\n`;
  }
}

function getFieldsStr(masterFields, indent) {
  return Object.keys(masterFields).map((fid) => {
    const { args, fields, varArgs } = masterFields[fid];
    const __ = '  '.repeat(indent);
    let qstring = `${__}${fid}`;

    // Add arguments:
    if (args || varArgs) {
      const argStrings = [];
      forIn(args, (value, arg) => {
        const valueStr = isString(value) ? `"${value}"` : value;
        argStrings.push(`${arg}: ${valueStr}`);
      });
      forIn(varArgs, (varId, arg) => {
        argStrings.push(`${arg}: $${varId}`);
      });
      if (argStrings.length > 0) {
        qstring = `${qstring}(${argStrings.join(', ')})`;
      }
    }

    // Recursively include fields:
    if (fields) {
      const fstr = getFieldsStr(fields, indent + 1);
      return `${qstring} {\n${fstr}\n${__}}`;
    }
    else {
      return qstring;
    }
  }).join(',\n');
}

function getQueryString(query, isMutation) {
  const { operation, variables } = query;
  let qstr = isMutation ? 'mutation ' : 'query ';

  // Add operation:
  if (operation) {
    qstr = `${qstr}${operation}`;
  }

  // Add variables declaration:
  if (variables) {
    qstr = `${qstr}${getVariablesDeclarationString(variables, 98 - qstr.length)}`;
  }

  // Add fields:
  return `${qstr}{\n${getFieldsStr(query.fields, 1)}\n}`;
}

/**
 * @private
 * Builds and returns a dxQuery.
 * @param {DxQuerySpec} dxQuery - The dxQuery specification.
 * @param {boolean} isMutation - True when the query is a mutation.
 * @param {Map} rootFieldTypes
 * @param {boolean} forDxApi - True when the query is meant for the standard dxAPI.
 // * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @param {object} [options] - Options.
 * @param {boolean} [options.logDeserializer] - Log the deserializer.
 * @returns {DxQuery}
 */
function buildQuery(dxQuery, isMutation, rootFieldTypes, forDxApi) {
  /** @type DxQueryFieldMetadata */
  const metadata = { ...dxQuery, hasFields: true, output: DX_QUERY, path: '' };
  try {
    preprocess(
      metadata.fields,
      metadata.path,
      forDxApi ? rootFieldTypes : null,
      metadata.variables !== undefined,
      forDxApi,
    );
    return {
      // authRule: authRule || getAuthRule(metadata.fields),
      get authRule() {
        throw new Error('The "dxController.authRule" property is no longer supported');
      },
      get authConstraints() {
        throw new Error('The "dxController.authConstraints" property is no longer supported');
      },
      deserializer: getDeserializer(metadata) || ((x) => x),
      isDxQuery: true,
      isMutation,
      queryString: getQueryString(metadata, isMutation),
      varSpecs: metadata.variables || {},
      variables: {},
      metadata,
    };
  }
  catch (error) {
    const msg = `The queryBuilder failed: ${error.message}`;
    console.error(msg);
    console.error('The given query :', metadata);
    throw new Error(msg);
  }
}

/**
 * Builds a GraphQL query for other APIs using regular javascript objects.
 *
 * ## dxQueryBuilder Functions
 *
 * - _dxQueryBuilder_: build a GraphQL query for dxAPIs.
 * - _queryBuilder_: build a GraphQL query for other APIs.
 * - _dxMutationBuilder_: build a GraphQL mutation for dxAPIs.
 * - _mutationBuilder_: build a GraphQL mutation for other APIs.
 *
 * @param {DxQuerySpec} query
 * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @returns {DxQuery}
 */
export function queryBuilder(query, authRule) {
  if (query.isDxSchema) {
    throw new Error('The queryBuilder method no longer takes a dxSchema as first argument.');
  }
  if (!isObject(query)) {
    throw new Error(`Expected a query declaration object as first argument, instead got "${query}".`);
  }
  return buildQuery(query, false, queryRootFieldTypes, false, authRule);
}

/**
 * Builds a GraphQL mutation for other APIs using regular javascript objects.
 *
 * ## dxQueryBuilder Functions
 *
 * - _dxQueryBuilder_: build a GraphQL query for dxAPIs.
 * - _queryBuilder_: build a GraphQL query for other APIs.
 * - _dxMutationBuilder_: build a GraphQL mutation for dxAPIs.
 * - _mutationBuilder_: build a GraphQL mutation for other APIs.
 *
 * @param {DxQuerySpec} query
 * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @returns {DxQuery}
 */
export function mutationBuilder(query, authRule) {
  if (query.isDxSchema) {
    throw new Error('The mutationBuilder method no longer takes a dxSchema as first argument.');
  }
  if (!isObject(query)) {
    throw new Error(`Expected a query declaration object as first argument, instead got "${query}".`);
  }
  return buildQuery(query, true, mutationRootFieldTypes, false, authRule);
}

// ////////
// DX_ERROR
// ////////

/**
 * Helper function that returns true if the two error objects emitted by the GraphQL.js system, are
 * equal (similar).
 * @param {GraphQLError} errorA
 * @param {GraphQLError} errorB
 * @returns {boolean}
 * @private
 */
function isSameGraphQLError(errorA, errorB) {
  if (errorA.message !== errorB.message) { return false; }
  if (!errorA.locations && !errorB.locations) { return true; }
  if (errorA.locations && !errorB.locations) { return false; }
  if (!errorA.locations && errorB.locations) { return false; }
  if (errorA.locations.length !== errorB.locations.length) { return false; }
  return errorA.locations.every(({ columnA, lineA }, index) => {
    const { columnB, lineB } = errorB.locations[index];
    return (columnA === columnB && lineA === lineB);
  });
}

/**
 * Helper function that prunes the given list of error objects emitted by the GraphQL.js system. It
 * removes duplicates.
 * @param {GraphQLError[]} errors
 * @private
 */
function pruneGraphQLErrors(errors) {
  const prunedErrors = [];
  errors.forEach((incError) => {
    if (!incError.message) {
      console.warn('Got graphql error object without message:', incError);
      return; // ignore unexpected case
    }
    if (prunedErrors.every((pruError) => !isSameGraphQLError(pruError, incError))) {
      prunedErrors.push(incError);
    }
  });
  return prunedErrors;
}

/**
 * @typedef {object} DxErrorArgumentObject
 * @property {Array.<string|DxErrorDetail>} [details] - An array of detailed error descriptions.
 * @property {DxResponse} [dxResponse]
 * @property {Error} [error] - An error object.
 * @property {DxQuery} [query] - A DxQuery object.
 * @property {string} [queryString] - A GraphQL query/mutation string.
 * @property {string} [reason] - A description of the error.
 * @property {string} [status] - The HTTP status code.
 * @property {object} [values] - Arbitrary, to be reported values.
 * @property {object} [variables] - The dxQuery variables object.
 *
 * Additional arbitrary, to be reported values may be provided as properties.
 */

/**
 * A detailed error description.
 * @typedef {object} DxErrorDetail
 * @property {string} message - A detailed error description.
 */

/**
 * An error object emitted by the _GraphQL.js_ system.
 * @typedef {object} GraphQLError
 * @property {Array.<{column: Number, line: Number}>} [locations] - The location(s) of the error in
 *   the GraphQL string.
 * @property {string} message - Description of the error.
 * @property {string} [stack] - Stack trace.
 */

/**
 * Error objects emitted by different systems contain different forms of meta-data that we want
 * to report in error messages. This class attempts to provide a simple solution for dealing with
 * different types of such errors and reporting relevant meta-data provided in them.
 *
 * In particular the errors emitted from the following sources are handled:
 * - graphql.js
 * - axios
 *
 * The constructor takes one or more arguments with a variety of types:
 * - Arrays, the elements of which are interpreted as more arguments, recursively;
 * - Strings are interpreted as error descriptions, multiple of which are concatenated;
 * - Error objects;
 * - DxQuery objects:
 * - Plain objects: The following properties have a special meaning, while the other properties are
 *   interpreted as arbitrary "to be reported" values:
 *   - details - An array of detailed error descriptions or objects with a message property;
 *   - dxResponse - The object returned by the dxRequest functions.
 *   - error - An Error object;
 *   - query - A DxQuery object;
 *   - queryString - A GraphQL query/mutation string;
 *   - reason - A description of the error;
 *   - status - The HTTP status code;
 *   - values - Arbitrary, to be reported values.
 *   - variables - GraphQL query variables object;
 *
 * ## Note on error handling
 *
 * ### Axios
 *
 * When the server responds to a request with a status code that falls out of the range of 2xx, then
 * the returned promise rejects with an Error object that has a `response` property with the Axios
 * response object as value. The following properties might be interesting:
 *
 * - error.response.status : The response status, e.g. 404, 500, etc.
 * - error.response.statusText : Generic error message, e.g. 'Internal Server Error'.
 * - error.response.headers : The headers of the response.
 * - error.response.data.code : The server-side error code.
 * - error.response.data.errno : The server-side error errno.
 * - error.response.data.message : The server-side error message.
 * - error.response.data.stack : The server-side error stack.
 * - error.response.data.type : The server-side error type.
 * - error.response.config.method : The request method.
 * - error.response.config.url : The request url.
 * - error.response.config.data : The request body.
 * - error.response.config.headers : The headers of the request.
 * - error.response.request.path : The request path.
 *
 * ### React
 *
 * The _Component.componentDidCatch_ method is called with two arguments: an error object and an
 * object with the following properties:
 *
 * - componentStack : Textual representation of the React component stack.
 *
 * ### GraphQL.js
 *
 * @todo Document GraphQL.js case
 *
 * @todo Implement the full interface of a standard Error object.
 */
export class DxError {

  /**
   * @param {Array.<DxErrorArgument>} errorArgs
   */
  constructor(...errorArgs) {
    // console.log('>>> new DxError() --', errorArgs);
    this._componentStack = undefined;
    this._details = undefined;
    this._error = undefined;
    this._queryString = undefined;
    this._reason = undefined;
    this._stack = undefined;
    this._values = undefined;
    this._variables = undefined;

    this.isDxError = true;

    this._processArg(flatten(errorArgs));

    this._assert(this._queryString === undefined || isString(this._queryString),
      `The "queryString" should be undefined or a string, instead got "${this._queryString}".`);

    if (!this._reason) {
      this._reason = 'Something went wrong.';
    }
  }

  // -- Accessors --------------- --- --  -

  get componentStack() { return this._componentStack; }

  get details() { return this._details; }

  get error() { return this._error; }

  get message() { return this._reason; }

  get queryString() { return this._queryString; }

  get reason() { return this._reason; }

  get stack() { return this._stack; }

  get values() { return this._values; }

  get variables() { return this._variables; }

  // -- Public Methods --------------- --- --  -

  /**
   * Append a reason string to the current reason string.
   * @param {string} reason
   * @param {boolean} newLine
   * @returns {DxError} This dxError, enabling chaining.
   */
  appendReason(reason, { newLine = false } = {}) {
    this._assert(isString(reason), `Expected a string as reason, instead got "${reason}".`);
    reason = capitalize(reason);
    if (!reason.endsWith('.')) { reason = reason.concat('.'); }
    this._reason = this._reason ? this._reason.concat(newLine ? '\n' : ' ', reason) : reason;
    return this;
  }

  /**
   * Prepend a reason string to the current reason string.
   * @param {string} reason
   * @param {boolean} newLine
   * @returns {DxError} This dxError, enabling chaining.
   */
  prependReason(reason, { newLine = false } = {}) {
    this._assert(isString(reason), `Expected a string as reason, instead got "${reason}".`);
    reason = capitalize(reason);
    if (!reason.endsWith('.')) { reason = reason.concat('.'); }
    this._reason = this._reason ? reason.concat(newLine ? '\n' : ' ', this._reason) : reason;
    return this;
  }

  // Add annotated query string:
  getAnnotatedQuery(indent = '') {
    return this._getMarkedLines(this._queryString, this._details)
      .map((line) => indent.concat(line))
      .join('\n');
  }

  toString() {
    const lines = [this._reason]; // Add error messages:

    // Add detailed errors:
    if (this._details) {
      if (this._details.length === 1) {
        lines.push(this._details[0].message || this._details[0]);
      }
      else if (this._details.length > 1) {
        lines.push('');
        forIn(this._details, (error, errorIndex) => {
          lines.push(`${errorIndex + 1}) ${error.message || error}`);
        });
      }
    }

    // Add annotated GraphQL query:
    if (this.queryString) {
      lines.push('', '# Query:');
      lines.push(this.getAnnotatedQuery('  '));

      // Add GraphQL query variables:
      if (this.variables && Object.keys(this.variables).length > 0) {
        lines.push('', '# Variables:');
        forIn(this.variables, (value, key) => lines.push(`  - ${key}: ${value}`));
      }
    }

    // Add arbitrary values:
    if (this.values && Object.keys(this.values).length > 0) {
      lines.push('', '# Metadata:');
      forIn(this.values, (value, key) => lines.push(`  - ${key}: ${value}`));
    }

    return lines.join('\n');
  }

  // -- Local System Methods --------------- --- --  -

  _processArg(arg) {
    if (arg === undefined) { return; }
    // logValue(arg, 'processing', { maxDepth: 2 });

    if (Array.isArray(arg)) { return arg.forEach((item) => this._processArg(item)); }
    if (isString(arg)) { return this.appendReason(arg); }

    if (isError(arg)) {
      this._error = arg;
      if (arg.message) { this.appendReason(arg.message); }
      if (arg.description) { this.appendReason(arg.description); }
      if (arg.errorDescription) { this.appendReason(arg.description); }
      if (arg.fileName) { this._setValue('File', arg.fileName); }
      if (arg.lineNumber) { this._setValue('Line', arg.lineNumber); }
      if (arg.columnNumber) { this._setValue('Column', arg.columnNumber); }
      if (arg.number) { this._setValue('Error code', arg.number); }
      if (arg.response) { this._processResponse(arg.response); }
      if (arg.status) { this._setValue('HTTP Status', arg.status); }
      if (arg.stack) { this._stack = arg.stack; }
      return;
    }

    if (DxError.isDxError(arg)) {
      this._addDetails(arg._details);
      this._error = arg._error;
      this._queryString = arg._queryString;
      this.appendReason(arg._reason);
      this._stack = arg._stack;
      this._values = arg._values;
      this._variables = arg._variables;
      return;
    }

    if (isObject(arg) && arg.isDxQuery) {
      if (!this._reason) { this._reason = 'The dxQuery failed.'; }
      this._queryString = arg.queryString;
      this._variables = arg.variables;
      return;
    }

    if (isPlainObject(arg)) {
      return forIn(arg, (value, key) => {
        if (key === 'reason') { this.appendReason(value); } // first add the "main" reason
        else if (key === 'componentStack') { this._componentStack = value; } // from second argument of _Component.componentDidCatch_ method
        else if (key === 'errorDescription') { this.appendReason(value); }
        else if (key === 'queryString') { this._queryString = value; } // process before details, dxResponse and error, which main contain detailed graphQl errors
        else if (key === 'details') { this._addDetails(value); }
        else if (key === 'error') { this._processArg(value); }
        else if (key === 'dxResponse') { this._processResponse(value); }
        else if (key === 'response') { this._processResponse(value); }
        else if (key === 'stack') { this._stack = this._stack ? `${this._stack}\n\n${value}` : value; }
        else if (key === 'status') { this._setValue('HTTP Status', value); }
        else if (key === 'values') {
          this._values = this._values ? merge(this._values, value) : value;
        }
        else if (key === 'variables') { this._variables = value; }
        else { this._setValue(key, value); }
      });
    }

    this._assert(false, `Unhandled argument in DxError: "${arg}"`);
  }

  _processResponse(response) {
    if (response.message) { this.appendReason(response.message); }
    if (response.status) { this._setValue('HTTP Status', response.status); }
    if (response.config) {
      if (response.config.method) { this._setValue('method', response.config.method); }
      if (response.config.url) { this._setValue('url', response.config.url); }
    }
    if (response.data) {
      let data = response.data;
      if (Array.isArray(data)) {
        if (data.length > 1) {
          console.error('[DxError] response.data is array with length > 1:', response.data);
        }
        data = data[0];
      }
      if (data.message) { this.appendReason(data.message); }
      if (data.errors) {
        if (data.errors.length === 1 && data.errors[0].stack && !this._stack) {
          this.appendReason(data.errors[0].message);
          this._stack = data.errors[0].stack;
        }
        else if (this._queryString) { // process detailed GraphQL errors:
          this._addDetails(pruneGraphQLErrors(data.errors));
        }
        else {
          this._addDetails(data.errors);
        }
      }
    }
  }

  _addDetails(details) {
    if (!details) { return; }
    if (!Array.isArray(details)) {
      throw new Error(`Expected array, got "${details}".`);
    }
    if (!this._details) { this._details = []; }
    details.forEach((detail) => {
      if (!this._details.includes(detail)) {
        this._details.push(detail);
      }
    });
  }

  _setValue(key, value) {
    if (!this._values) { this._values = {}; }
    this._values[key] = this._values[key] ? merge(this._values[key], value) : value;
  }

  /**
   * This function also logs the error because a DxError is typically created while handling a
   * previous error, in which case the error thrown here cannot be properly handled, and thus not
   * logged.
   * @param {boolean} predicate
   * @param {string} message
   * @private
   */
  _assert(predicate, message) {
    if (!predicate) {
      console.error(message);
      throw new Error(message);
    }
  }

  /**
   * Memoized helper function.
   * @param queryString
   * @param errors
   * @returns {*}
   * @private
   */
  _getMarkedLines(queryString, errors) {
    if (!this._markedLines) {
      const queryLines = queryString.match(/[^\r\n]+/g);
      const markerLines = [];
      forIn(errors, (error, errorIndex) => {
        error.locations.forEach((location) => {
          if (markerLines[location.line]) {
            markerLines[location.line].push([errorIndex + 1, location.column]);
          }
          else {
            markerLines[location.line] = [[errorIndex + 1, location.column]];
          }
        });
      });

      this._markedLines = [];
      queryLines.forEach((queryLine, line) => {
        this._markedLines.push(queryLine);
        const entries = markerLines[line + 1];
        if (entries) {
          entries.sort((e1, e2) => e1.column - e2.column);
          let markedLine = '';
          let freeColumn = 1;
          entries.forEach(([errorIndex, column]) => {
            if (column < freeColumn) {
              this._markedLines.push(markedLine);
              markedLine = '';
              freeColumn = 1;
            }
            if (errors.length === 1) {
              markedLine = `${markedLine}${' '.repeat(column - freeColumn)}^`;
              freeColumn = column + 1;
            }
            else if (errors.length > 1) {
              markedLine = `${markedLine}${' '.repeat(column - freeColumn)}^${errorIndex}`;
              freeColumn = column + 2;
            }
          });
          this._markedLines.push(markedLine);
        }
      });
    }
    return this._markedLines;
  }

}

/**
 * @param {*} obj
 * @returns {boolean} True if the given obj is a {@link DxError} instance.
 */
DxError.isDxError = (obj) => (obj instanceof DxError);

DxError.ERROR_CODE_CONTEXT_BUSY = 'dx/error-code/context-busy';
DxError.ERROR_CODE_DESERIALIZATION_FAILED = 'dx/error-code/deserializaton-failed';
DxError.ERROR_CODE_NOT_AUTHORIZED = 'dx/error-code/not-authorized';
DxError.ERROR_CODE_REQUEST_FAILED = 'dx/error-code/request-failed';
DxError.ERROR_CODE_QUERY_FAILED = 'dx/error-code/query-failed';
