// -- Query Contexts --------------- --- --  -

/** @type {string} */
export const DX_CONTEXT_DEFAULT = 'DX/CONTEXT/DEFAULT';

// -- load-state --------------- --- --  -

/** @type {string} */
export const LOADED = 'DX/LOADED';

/** @type {string} */
export const LOADING = 'DX/LOADING';

/** @type {string} */
export const RELOADING = 'DX/RELOADING';
