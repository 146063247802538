import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const ChevronIcon = ({ color, styles, expanded, isOpen, collapsed, ...props }) => (
  <svg fill={color} viewBox="0 0 8 13" {...styles} {...props}>
    <path d="M6.6 12.6l-6-6 6-6L8 2 3.4 6.6 8 11.2z" />
  </svg>
);

ChevronIcon.propTypes = propTypes;
ChevronIcon.defaultProps = defaultProps;

// TODOX: #8889a0
export default ChevronIcon;
