import styled from '@emotion/styled';

const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ small }) => (small ? '12px' : '14px')};
  font-weight: normal;
  margin: 0;
  margin-bottom: 6px;
  line-height: 1.25;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: visible;
`;

export default Paragraph;
