import { css } from 'glamor';
import { GREY_LIGHT_1, DANGER, FOCUS, WARNING, MAIN_PAGE } from '@innovatrix/styles';

// General
export const BOX_SHADOW = 'rgba(0,0,0,0.3)';

// Styles
export const textInputStyles = css({
  '> input, > textarea': {
    borderWidth: 0,
    borderBottom: `1px solid ${MAIN_PAGE}`,
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    cursor: 'text !important',
    outline: 0,
    overflow: 'hidden',
    paddingBottom: 5,
    transition: 'all 0.5s',
    width: '100%',
    fontSize: 32,
    height: 50,
    ':hover': {
      backgroundColor: GREY_LIGHT_1,
    },
    ':focus': {
      borderBottom: `1px solid ${FOCUS}`,
    },
  },
  '> textarea': {
    // Disable the capability to enlarge the textarea.
    resize: 'none',
  },
  '> .control-label, > label': {
    display: 'none !important',
  },
});

// Objectives / Workshops
// /////////////////////

export const dragDropHandlerStyles = css({
  alignItems: 'center',
  cursor: 'move',
  display: 'flex',
  justifyContent: 'center',
  opacity: 0.5,
  ':hover': {
    opacity: 1,
  },
  transition: 'opacity 0.3s',
  minWidth: 24,
  maxWidth: 24,
});

export const errorStyles = css({
  color: WARNING,
  fontSize: 14,
  marginTop: 4,
});

export const errorMessageStyles = css({
  color: DANGER,
  fontSize: 14,
  marginTop: 4,
});
