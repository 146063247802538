import { mutationBuilder, queryBuilder } from '@innovatrix/utils/dx';

export const deleteProjectTimeslotMutation = mutationBuilder({
  variables: { id: { type: 'String!' } },
  fields: {
    _deleteProjectTimeslot: {
      varArgs: { id: 'id' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const deleteTimeslotTimeRangeMutation = mutationBuilder({
  variables: {
    ids: { type: '[String]!' },
  },
  fields: {
    _deleteTimeslotTimeRange: {
      varArgs: { ids: 'ids' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const getTimeslotTimeRangesQuery = queryBuilder(
  {
    variables: {
      timeslotDateId: { type: 'String!' },
    },
    fields: {
      _getTimeslotTimeRanges: {
        varArgs: { timeslotDateId: 'timeslotDateId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectTimeslotsQuery = queryBuilder({
  variables: {
    timeslotTimeRangeId: { type: 'String!' },
  },
  fields: {
    _getProjectTimeslots: {
      varArgs: { timeslotTimeRangeId: 'timeslotTimeRangeId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const createProjectTimeslotMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    startDatetime: { type: 'Date!' },
    endDatetime: { type: 'Date!' },
    timeslotTimeRangeId: { type: 'String!' },
  },
  fields: {
    _createProjectTimeslot: {
      varArgs: {
        endDatetime: 'endDatetime',
        projectId: 'projectId',
        startDatetime: 'startDatetime',
        timeslotTimeRangeId: 'timeslotTimeRangeId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const addExternalAttenderMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    data: { type: 'JSON!' },
  },
  fields: {
    _addExternalAttender: {
      varArgs: { projectId: 'projectId', data: 'data' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const removeExternalAttenderMutation = mutationBuilder({
  variables: {
    memberId: { type: 'String!' },
    projectId: { type: 'String!' },
    workshopMomentId: { type: 'String!' },
  },
  fields: {
    _removeExternalAttender: {
      varArgs: { projectId: 'projectId', workshopMomentId: 'workshopMomentId', memberId: 'memberId' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const markMemberAttendingMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    data: { type: 'JSON!' },
  },
  fields: {
    _markMemberAttending: {
      varArgs: { projectId: 'projectId', data: 'data' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const markMemberAttendedMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    data: { type: 'JSON!' },
  },
  fields: {
    _markMemberAttended: {
      varArgs: { projectId: 'projectId', data: 'data' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const toggleAttendedWorkshopMutation = mutationBuilder({
  variables: {
    attended: { type: 'Boolean' },
    projectId: { type: 'String!' },
    workshopMomentId: { type: 'String!' },
  },
  fields: {
    _toggleAttendedWorkshop: {
      varArgs: { attended: 'attended', projectId: 'projectId', workshopMomentId: 'workshopMomentId' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});
