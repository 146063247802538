import {
  ACCEPTED_COOKIES,
  ACCEPTED_LEGAL_DOCUMENT,
  FETCH_APPLICATION_META_DATA,
  RECEIVE_APPLICATION_META_DATA,
  ERROR_APPLICATION_META_DATA,
  FETCH_PROJECT_TYPES,
  RECEIVE_PROJECT_TYPES,
  ERROR_PROJECT_TYPES,
  FETCH_USER_PROFILES,
  RECEIVE_USER_PROFILES,
  ERROR_USER_PROFILES,
} from '../constants';

import { createFormikAction } from './utils';

export const acceptedCookiesAction = () => ({
  type: ACCEPTED_COOKIES,
});

export const acceptedLegalDocumentAction = ({ documentId }) => ({
  documentId,
  type: ACCEPTED_LEGAL_DOCUMENT,
});

export const fetchApplicationMetaDataAction = () => ({
  type: FETCH_APPLICATION_META_DATA,
});

export const updateProfileAction = createFormikAction('UPDATE_PROFILE');

export const receiveApplicationMetaDataAction = ({ data }) => ({
  data,
  type: RECEIVE_APPLICATION_META_DATA,
});

export const errorApplicationMetaDataAction = ({ error }) => ({
  error,
  type: ERROR_APPLICATION_META_DATA,
});

export const fetchUserProfilesAction = () => ({ type: FETCH_USER_PROFILES });
export const receiveUserProfilesAction = ({ data }) => ({ data, type: RECEIVE_USER_PROFILES });
export const errorUserProfilesAction = ({ error }) => ({ error, type: ERROR_USER_PROFILES });

export const fetchProjectTypesAction = () => ({
  type: FETCH_PROJECT_TYPES,
});

export const receiveProjectTypesAction = ({ data }) => ({
  data,
  type: RECEIVE_PROJECT_TYPES,
});

export const errorProjectTypesAction = ({ error }) => ({
  error,
  type: ERROR_PROJECT_TYPES,
});
