import { mutationBuilder, queryBuilder } from '@innovatrix/utils/dx';

export const deleteDocumentTemplateMutation = mutationBuilder({
  variables: {
    documentId: { type: 'String!' },
    formType: { type: 'String!' },
    questionId: { type: 'String!' },
  },
  fields: {
    _deleteDocumentTemplate: {
      varArgs: { questionId: 'questionId', documentId: 'documentId', formType: 'formType' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const getProjectDocumentsByFolderQuery = queryBuilder(
  {
    variables: { projectId: { type: 'String!' } },
    fields: {
      _getProjectDocumentsByFolder: {
        varArgs: { projectId: 'projectId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const deleteFormDocumentMutation = mutationBuilder({
  variables: {
    questionId: { type: 'String!' },
    documentId: { type: 'String!' },
    formType: { type: 'String!' },
  },
  fields: {
    _deleteFormDocument: {
      varArgs: { questionId: 'questionId', documentId: 'documentId', formType: 'formType' },
      fields: {
        error: true,
        result: true,
        success: true,
      },
    },
  },
});
