// This integrates with @babel/preset-env in the `react-service`
// See: https://babeljs.io/docs/en/babel-preset-env#usebuiltins
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'normalize.css/normalize.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { initializeQueryFetcher } from '@innovatrix/react-frontend/queries/fetchQuery';
import { Provider } from 'react-redux';
import { ModalManager } from '@innovatrix/react-frontend/components/modals/ModalManager';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@emotion/react';
import { HTML5Backend as Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import ErrorBoundary from './SentryBoundary';
import App from './pages/app';
import { config } from './config';
import GlobalStyles from './styles/globals';
import innovatrixTheme from './styles/theme';
import { store, history } from './_store';

import './i18n';

// Initialize the store:
initializeQueryFetcher({ store, defaultUrl: config.get('innovatrix.hosts.dxApi') });

// -- Render --------------- --- --  -
ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={Backend}>
      <GlobalStyles />
      {/*
          If you want to dark/light this make a MyCustomThemeProvider that exposes a
          supplementary context with darkMode = true. This will change the value
          of the emotion ThemeProvider propagating to all Emotion components.
        */}
      <ThemeProvider theme={innovatrixTheme}>
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ConnectedRouter>
        <ModalManager />
        <ToastContainer
          autoClose={5000}
          closeButton={false}
          closeOnClick
          position="top-center"
          theme="colored"
        />
      </ThemeProvider>
    </DndProvider>
  </Provider>,
  document.getElementById('app'),
);
