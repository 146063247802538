import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { withRouter } from 'react-router';
import { Title, TitleDescription } from '@innovatrix/components';
import { Button } from '@innovatrix/common/buttons';
import { historyPropType } from '@innovatrix/propTypes';

import { config } from './config';
import { IS_PRODUCTION } from './constants';

Sentry.init({
  autoSessionTracking: false,
  blacklistUrls: ['http://innovatrix.local', 'http://localhost'],
  environment: process.env.NODE_ENV,
  dsn: config.get('innovatrix.crashlytics.key'),
});

const errorStyles = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '> button': { marginBottom: 8 },
});

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    this.writeErrorToSentry(error, errorInfo);
  }

  writeErrorToSentry = (error, errorInfo) => {
    if (IS_PRODUCTION) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
    else {
      console.error(this.state);
    }
  }

  return = () => {
    this.setState({ error: null, errorInfo: null });
    this.props.history.push('/');
  }

  render() {
    if (this.state.error) {
      return (
        <div {...errorStyles}>
          <Title>
            Oh no! It seems you have crashed.
          </Title>
          <TitleDescription>
            If you want to provide us with additional information click the link below.
          </TitleDescription>
          <Button label="Report feedback" flavor="primary" onClick={() => Sentry.showReportDialog()} />
          <Button label="Return to the application" flavor="tertiary" onClick={this.return} />
        </div>
      );
    }
    else {
      return this.props.children;
    }
  }

}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  history: historyPropType,
};

export default withRouter(ErrorBoundary);
