import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as api from '../../../sagas/_api'; // TODO: move later.
import * as toast from '../../../utils/toast';
import { receiveProjectsAction, receiveSettingsAction, errorProjectsAction, fetchBriefProjectsAction, closeModalAction, fetchProjectsAction } from '../../../actions';
import { CAMPAIGNS_ENABLED } from '../../../constants';
import { filterUsersQuery } from '../../../queries/users';
import { config } from '../../../config';

import { updateDecisionMutation, getProjectsQuery, createProjectMutation } from './queries';
import { UPDATE_DECISION, FETCH_PROJECTS, FETCH_REVIEWERS } from './_actionTypes';
import { createProjectAction, receiveReviewersAction, errorReviewersAction, receiveDecisionAction, receiveUpdatedProjectAction } from './_actions';

const authUrl = config.get('duxis.auth.apiUrl');

export default function* () {
  function* fetchProjects({ campaignId, phaseId }) {
    try {
      const { syncDecisions, projects } = yield call(api.fetch, '_getProjects', getProjectsQuery,
        {
          variables: {
            campaignId,
            phaseId,
          },
        });
      yield put(receiveProjectsAction({ campaignId, data: projects, phaseId }));
      yield put(receiveSettingsAction({ syncDecisions }));
    }
    catch (err) {
      yield put(errorProjectsAction({ campaignId, error: err, phaseId }));
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to fetch the projects.' });
    }
  }

  yield takeEvery(UPDATE_DECISION, function* updateDecision({ data: { campaignId, conditions, decision, phaseId, projectId } }) {
    try {
      const variables = {
        campaignId,
        conditions,
        decision,
        phaseId,
        projectId,
      };
      yield call(api.fetch, 'updateDecision', updateDecisionMutation, { variables });
      yield put(receiveUpdatedProjectAction({ id: projectId, status: decision, conditions }));
      yield put(receiveDecisionAction(projectId));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to update decision.' });
    }
  });

  yield takeEvery(createProjectAction.REQUEST, function* createProject({ callback, payload: {
    campaignId, description, majorDomain, name, pictureUrl, projectTypeId, createdBy, phaseId,
  } }) {
    try {
      const variables = {
        campaignId,
        createdBy,
        description,
        majorDomain,
        name,
        phaseId,
        pictureUrl,
        projectTypeId,
      };
      yield call(api.fetch, '_createProject', createProjectMutation, { variables });
      yield put(fetchBriefProjectsAction());
      callback();
      yield put(closeModalAction('persistProjectModal'));
      toast.success(`Project "${name}" is created!`);
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to the projects.' });
      callback(err);
    }
    // Refetch projects. LL doesn't have campaigns or phases.
    if (CAMPAIGNS_ENABLED) {
      yield put(fetchProjectsAction({ campaignId, phaseId }));
    }
    else {
      yield put(fetchProjectsAction({}));
    }
  });

  yield takeEvery(FETCH_PROJECTS, fetchProjects);

  yield takeEvery(FETCH_REVIEWERS, function* getReviewers() {
    try {
      const variables = {
        filter: {
          filters: [
            {
              filters: [{
                type: 'hasRelatee',
                field: 'roles',
                id: 'innovatrix/role/coordinator',
              }, {
                type: 'hasRelatee',
                field: 'roles',
                id: 'innovatrix/role/manager',
              },
              {
                type: 'hasRelatee',
                field: 'roles',
                id: 'dx/role/admin',
              }],
              operator: 'or',
            },
          ],
          operator: 'and',
        },
      };
      const result = yield call(api.fetch, 'filterUsers', filterUsersQuery, { variables, url: authUrl }, true);
      yield put(receiveReviewersAction({ data: result.items }));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield put(errorReviewersAction({ error: err }));
      yield call(showErrorModal, err, { title: 'Failed to fetch the reviewers.' });
    }
  });
}
