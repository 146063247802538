import * as React from 'react';
import { noop } from '@innovatrix/utils';

export const ApplicationContext = React.createContext({
  dxToken: null,
  finishedOnboarding: false,
  persistStep: noop,
  refetchMetaData: noop,
  refetchTours: noop,
  skipTour: noop,
  tours: [],
  user: null,
});

export const useApplicationContext = () => React.useContext(ApplicationContext);
