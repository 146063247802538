import styled from '@emotion/styled';

export const PreParagraph = styled.pre`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ small }) => (small ? '12px' : '14px')};
  font-weight: normal;
  line-height: 1.25;
  margin: 0;
  margin-bottom: 6px;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const PreBody = styled.pre`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  margin: 0;
  margin-bottom: 8px;
  word-wrap: break-word;
  white-space: pre-wrap;
`;
