import {
  DELETE_PROJECT,
  ERROR_MANAGERS,
  ERROR_ONE_ON_ONE_FOR_PROJECT,
  ERROR_PROJECT_BOARDS,
  ERROR_PROJECT_BY_EVALUATION,
  ERROR_PROJECT_BY_PHASE,
  ERROR_PROJECT_OVERVIEW,
  ERROR_PROJECT_REVIEWS_BY_PHASE,
  ERROR_PROJECT_WORKSHOPS,
  ERROR_PROJECTS,
  FETCH_BRIEF_PROJECTS,
  FETCH_MANAGERS,
  FETCH_ONE_ON_ONE_FOR_PROJECT,
  FETCH_PROJECT_BOARDS,
  FETCH_PROJECT_BY_EVALUATION,
  FETCH_PROJECT_BY_PHASE,
  FETCH_PROJECT_OVERVIEW,
  FETCH_PROJECT_REVIEWERS,
  FETCH_PROJECT_REVIEWS_BY_PHASE,
  FETCH_PROJECT_WORKSHOPS,
  FETCH_PROJECTS,
  INVITE_MEMBER_TO_PLATFORM,
  RECEIVE_BRIEF_PROJECTS,
  RECEIVE_CRITERION_LEVELS,
  RECEIVE_MANAGERS,
  RECEIVE_ONE_ON_ONE_FOR_PROJECT,
  RECEIVE_PROJECT_BOARDS,
  RECEIVE_PROJECT_BY_EVALUATION,
  RECEIVE_PROJECT_BY_PHASE,
  RECEIVE_PROJECT_OVERVIEW,
  RECEIVE_PROJECT_REVIEWS_BY_PHASE,
  RECEIVE_PROJECT_WORKSHOPS,
  RECEIVE_PROJECTS,
  RESERVE_TIMESLOT,
  TOGGLE_COLLAPSE_CRITERIA,
} from '../constants';

import { createFormikAction } from './utils';

export const errorManagersAction = ({ error }) => ({
  error,
  type: ERROR_MANAGERS,
});

export const fetchManagersAction = () => ({
  type: FETCH_MANAGERS,
});

export const receiveManagersAction = ({ data }) => ({
  data,
  type: RECEIVE_MANAGERS,
});

export const toggleCollapseCriteriaAction = () => ({
  type: TOGGLE_COLLAPSE_CRITERIA,
});

export const inviteMemberToPlatformAction = ({ teamMemberId, projectId, callback }) => ({
  callback,
  id: teamMemberId,
  projectId,
  type: INVITE_MEMBER_TO_PLATFORM,
});

// Fetch project overview
// //////////////////////

export const errorProjectOverviewAction = ({ error, projectId }) => ({
  error,
  projectId,
  type: ERROR_PROJECT_OVERVIEW,
});

export const fetchProjectOverviewAction = ({ projectId }) => ({
  projectId,
  type: FETCH_PROJECT_OVERVIEW,
});

export const receiveProjectOverviewAction = ({ data, projectId }) => ({
  data,
  projectId,
  type: RECEIVE_PROJECT_OVERVIEW,
});

// Fetch Project Boards
// ////////////////////

export const errorProjectBoardsAction = ({ error, projectId }) => ({
  error,
  projectId,
  type: ERROR_PROJECT_BOARDS,
});

export const fetchProjectBoardsAction = ({ projectId }) => ({
  projectId,
  type: FETCH_PROJECT_BOARDS,
});

export const receiveProjectBoardsAction = ({ data, projectId }) => ({
  data,
  projectId,
  type: RECEIVE_PROJECT_BOARDS,
});

// Fetch Project Workshops
// ///////////////////////

export const errorProjectWorkshopsAction = ({ error, projectId, status }) => ({
  error,
  projectId,
  status,
  type: ERROR_PROJECT_WORKSHOPS,
});

export const fetchProjectWorkshopsAction = ({ projectId, status }) => ({
  projectId,
  status,
  type: FETCH_PROJECT_WORKSHOPS,
});

export const receiveProjectWorkshopsAction = ({ data, projectId, status }) => ({
  data,
  projectId,
  status,
  type: RECEIVE_PROJECT_WORKSHOPS,
});

// Fetch projects
// //////////////

// `campaignId` and `phase` is optional of Living Labs.

export const errorProjectsAction = ({ campaignId = '', error, phaseId = '' }) => ({
  campaignId,
  error,
  phaseId,
  type: ERROR_PROJECTS,
});

export const fetchProjectsAction = ({ campaignId = '', phaseId = '' }) => ({
  campaignId,
  id: `${campaignId}-${phaseId}`,
  phaseId,
  type: FETCH_PROJECTS,
});

export const receiveProjectsAction = ({ campaignId = '', data, phaseId = '' }) => ({
  campaignId,
  data,
  id: `${campaignId}-${phaseId}`,
  phaseId,
  type: RECEIVE_PROJECTS,
});

// Fetch project
/// /////////////

export const fetchProjectByPhaseAction = ({ campaignId, projectId, phaseId, isCompare }) => ({
  campaignId,
  isCompare,
  phaseId,
  projectId,
  type: FETCH_PROJECT_BY_PHASE,
});

export const errorProjectByPhaseAction = ({ campaignId, error, projectId, phaseId, isCompare }) => ({
  campaignId,
  error,
  isCompare,
  phaseId,
  projectId,
  type: ERROR_PROJECT_BY_PHASE,
});

export const receiveProjectByPhaseAction = ({ campaignId, data, projectId, phaseId, isCompare }) => ({
  campaignId,
  data,
  isCompare,
  phaseId,
  projectId,
  type: RECEIVE_PROJECT_BY_PHASE,
});

export const fetchProjectReviewsByPhaseAction = ({ campaignId, projectId, phaseId }) => ({
  campaignId,
  phaseId,
  projectId,
  type: FETCH_PROJECT_REVIEWS_BY_PHASE,
});

export const errorProjectReviewsByPhaseAction = ({ campaignId, error, projectId, phaseId }) => ({
  campaignId,
  error,
  phaseId,
  projectId,
  type: ERROR_PROJECT_REVIEWS_BY_PHASE,
});

export const receiveProjectReviewsByPhaseAction = ({ campaignId, data, projectId, phaseId }) => ({
  campaignId,
  data,
  phaseId,
  projectId,
  type: RECEIVE_PROJECT_REVIEWS_BY_PHASE,
});

export const errorProjectByEvaluationAction = ({ campaignId, error, evaluationId, projectId, isCompare }) => ({
  campaignId,
  error,
  evaluationId,
  projectId,
  isCompare,
  type: ERROR_PROJECT_BY_EVALUATION,
});

export const fetchProjectByEvaluationAction = ({ campaignId = '', evaluationId = '', projectId, isCompare }) => ({
  campaignId,
  evaluationId,
  projectId,
  isCompare,
  type: FETCH_PROJECT_BY_EVALUATION,
});

export const receiveProjectByEvaluationAction = ({ campaignId, data, evaluationId, projectId, isCompare }) => ({
  campaignId,
  data,
  evaluationId,
  projectId,
  isCompare,
  type: RECEIVE_PROJECT_BY_EVALUATION,
});

export const receiveCriterionLevelsAction = (criterionId, criterionLevels) => ({
  criterionId,
  type: RECEIVE_CRITERION_LEVELS,
  data: criterionLevels,
});

export const fetchBriefProjectsAction = () => ({
  type: FETCH_BRIEF_PROJECTS,
});

export const receiveBriefProjectsAction = projects => ({
  type: RECEIVE_BRIEF_PROJECTS,
  projects,
});

export const fetchProjectReviewersAction = ({ campaignId, projectId, scoreType }) => ({
  campaignId,
  projectId,
  scoreType,
  type: FETCH_PROJECT_REVIEWERS,
});

export const persistProjectSummaryAction = createFormikAction('PERSIST_PROJECT_SUMMARY');
export const persistTeamMembersAction = createFormikAction('PERSIST_TEAM_MEMBERS');
export const persistManagersAction = createFormikAction('PERSIST_MANAGERS');
export const addManagersAction = createFormikAction('ADD_MANAGERS');
export const createTeamMemberAction = createFormikAction('CREATE_TEAM_MEMBER');

export const deleteProjectAction = ({ projectId, campaignId, phaseId, callback }) => ({
  callback,
  campaignId,
  phaseId,
  projectId,
  type: DELETE_PROJECT,
});

export const fetchOneOnOneForProjectAction = ({ projectId, workshopMomentId, callback }) => ({
  callback,
  projectId,
  type: FETCH_ONE_ON_ONE_FOR_PROJECT,
  workshopMomentId,
});

export const receiveOneOnOneForProjectAction = ({ data, projectId, workshopMomentId }) => ({
  data,
  projectId,
  type: RECEIVE_ONE_ON_ONE_FOR_PROJECT,
  workshopMomentId,
});

export const errorOneOnOneForProjectAction = ({ error, projectId, workshopMomentId }) => ({
  error,
  projectId,
  type: ERROR_ONE_ON_ONE_FOR_PROJECT,
  workshopMomentId,
});

export const reserveTimeslotAction = ({
  id, projectId, workshopMomentId, timeslotRangeId, start, end, callback,
}) => ({
  callback,
  end,
  id,
  projectId,
  start,
  timeslotRangeId,
  type: RESERVE_TIMESLOT,
  workshopMomentId,
});
