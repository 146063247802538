import React from 'react';
import { css } from 'glamor';
import PropTypes from 'prop-types';

import { GREY_1, DANGER } from '../../../styles';

const labelStyles = css({
  color: GREY_1,
  fontSize: 12,
  marginBottom: 4,
  textTransform: 'uppercase',
});

const requiredStyles = css({
  color: DANGER,
  fontSize: 14,
});

const Label = ({ label, required }) => (
  label
    ? (
      <div {...labelStyles}>
        {label}{required && <span {...requiredStyles}>&nbsp;*</span>}
      </div>
    )
    : null
);

Label.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default Label;
