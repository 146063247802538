import {
  RECEIVE_TIMESLOT_TIME_RANGES,
  FETCH_TIMESLOT_TIME_RANGES,
  ERROR_TIMESLOT_TIME_RANGES,
} from '@innovatrix/constants';

import { fetchEntities, receiveEntities, errorEntities } from './utils';

const initialState = {
  entities: {},
  relations: {
    timeslotDateHasTimeslotTimeRanges: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_TIMESLOT_TIME_RANGES: return receiveEntities('timeslotDateHasTimeslotTimeRanges', state, action);
    case FETCH_TIMESLOT_TIME_RANGES: return fetchEntities('timeslotDateHasTimeslotTimeRanges', state, action);
    case ERROR_TIMESLOT_TIME_RANGES: return errorEntities('timeslotDateHasTimeslotTimeRanges', state, action);

    default: return state;
  }
};

