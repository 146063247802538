import qs from 'query-string';
import { call, put } from 'redux-saga/effects';
import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';

import * as api from './_api';

const NOT_COMPLETED = 'NOT_COMPLETED';

// Can handle all sorts of fetching.
export function* fetchEntitiesSaga({ isDxAction, receiveAction, errorAction, query, keyword, errorMessage }, payload) {
  try {
    const variables = { ...payload };
    const context = `${keyword}_${qs.stringify(payload)}`;
    const data = yield call(api.fetch, keyword, query, { variables, context }, Boolean(isDxAction));
    yield put(receiveAction({ data, ...payload }));
  }
  catch (error) {
    if (error.code === NOT_COMPLETED) { return; }
    // Consider automatically making errorMessage and let an entityName be passed inside.
    yield call(showErrorModal, error, { title: errorMessage });
    yield put(errorAction({ error, ...payload }));
  }
}

// These are for example in scenario's of deleting.
export function* mutationSaga(
  { after, errorMessage, keyword, mutation, preparePayload },
  payload,
) {
  try {
    const variables = preparePayload && typeof preparePayload === 'function' ? preparePayload(payload) : { ...payload };
    const data = yield call(api.fetch, keyword, mutation, { variables, context: keyword });
    if (after) { yield after(payload, data); }
  }
  catch (error) {
    if (error.code === NOT_COMPLETED) { return; }
    // Consider automatically making errorMessage and let an entityName be passed inside.
    yield call(showErrorModal, error, { title: errorMessage });
  }
}

// Is for updating and creating in context of our formikAction.
export function* formMutationSaga(
  { preparePayload, keyword, mutation, after, errorMessage },
  { callback, payload },
) {
  try {
    // It is possible to pass in functions for saga's who handle both update and create.
    const variables = preparePayload && typeof preparePayload === 'function' ? preparePayload(payload) : { ...payload };
    const finalKeyword = typeof keyword === 'function' ? keyword(payload) : keyword;
    const finalMutation = typeof mutation === 'function' ? mutation(payload) : mutation;
    // Call the mutation
    const data = yield call(api.fetch, finalKeyword, finalMutation, { variables, context: finalKeyword });
    // Run the afterware.
    if (callback && typeof callback === 'function') { callback(); }
    if (after) { yield after(payload, data); }
  }
  catch (error) {
    if (error.code === NOT_COMPLETED) { return; }
    callback(error);
    // Consider automatically making errorMessage and let an entityName be passed inside.
    yield call(showErrorModal, error, { title: errorMessage });
  }
}
