import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoComplete from '@innovatrix/components/AutoComplete';
import { fetchBriefProjectsAction } from '@innovatrix/actions/projectActions';
import {
  CAMPAIGNS_ENABLED,
  DASHBOARD_TAB,
  INNOBOARD_TAB,
  IS_OPEN_ENVIRONMENT,
  LOADING,
  OVERVIEW_TAB,
  PHASE_PROJECT,
  RELOADING,
  SALESFORCE_ENABLED,
} from '@innovatrix/constants';
import graphql from '@innovatrix/common/graphql';
import { useHistory } from 'react-router';

import { getPhasesQuery } from '../../queries';

const SearchProjects = ({
  isLoading, projects, fetchBriefProjects,
  phases: { data: allPhases, status },
}) => {
  const history = useHistory();
  React.useEffect(() => { fetchBriefProjects(); }, [fetchBriefProjects]);
  const navigate = React.useCallback((id) => {
    const project = projects.find(p => p.value === id);
    if (project) {
      // if not in projectPhase, we should route to Project Info, else we route to dashboard
      if (SALESFORCE_ENABLED || CAMPAIGNS_ENABLED) {
        const { campaignId, phaseId } = project;
        const currentPhase = (allPhases.find(p => p.id === phaseId) || {});
        if (currentPhase.title === PHASE_PROJECT) {
          history.push(`/campaigns/${campaignId}/projects/${id}/${DASHBOARD_TAB}`);
        }
        else {
          const landingPage = IS_OPEN_ENVIRONMENT ? INNOBOARD_TAB : OVERVIEW_TAB;
          history.push(`/campaigns/${campaignId}/projects/${id}/${landingPage}`);
        }
      }
      else {
        history.push(`/projects/${id}/${DASHBOARD_TAB}`);
      }
    }
  }, [allPhases, history, projects]);
  return (
    <AutoComplete
      isLoading={isLoading && (status === LOADING || status === RELOADING)}
      onClick={navigate}
      options={projects}
      placeholder="Search Projects"
      searchField="label"
    />
  );
};

SearchProjects.propTypes = {
  fetchBriefProjects: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  phases: PropTypes.object,
  projects: PropTypes.array,
};

export default graphql({
  name: 'phases',
  namespace: '_getPhases',
  query: getPhasesQuery,
})(connect(
  state => ({
    isLoading: state.briefProjects.isLoading,
    projects: state.briefProjects.projects,
  }), {
    fetchBriefProjects: fetchBriefProjectsAction,
  },
)(React.memo(SearchProjects)));
