import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import { GREY_LIGHT_1, FOCUS, MAIN_PAGE } from '../../../../styles';
import formikField from '../formikField';
import Label from '../Label';
import ErrorMessage from '../ErrorMessage';

const groupStyles = css({ width: '100%' });

const inputStyles = css({
  border: 0,
  borderBottom: `1px solid ${MAIN_PAGE}`,
  fontSize: 16,
  outline: 0,
  transition: 'all 0.5s',
  width: '100%',
  paddingBottom: 6,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 6,
  ':hover': {
    backgroundColor: GREY_LIGHT_1,
  },
  ':focus': {
    borderBottom: `1px solid ${FOCUS}`,
  },
});

export const StringField = ({
  onFocus, focus, blur, className, editMode = true, error, fieldId,
  inputRef, touched, label, placeholder, required, styles, type = 'text',
  value, onChange, onBlur, ...restProps
}) => {
  const textInput = React.useRef();

  const focusInput = React.useCallback(() => {
    requestAnimationFrame(() => textInput.current && textInput.current.focus());
  }, []);

  React.useLayoutEffect(() => {
    if (focus) { focusInput(); }
  }, [focus, focusInput]);

  const onBlurCb = React.useCallback((e) => {
    onBlur(e);
    if (blur) { blur(); }
  }, [blur, onBlur]);

  const onChangeCb = React.useCallback((e) => {
    const { value: newValue } = e.target;
    onChange(newValue);
  }, [onChange]);

  return (
    <div className={`${styles ? `${styles.toString()} ` : ''}${className || ''}`} {...groupStyles}>
      <Label label={label} required={required} />
      <input
        {...restProps}
        {...inputStyles}
        ref={inputRef || textInput}
        autoComplete="off"
        disabled={!editMode}
        onFocus={onFocus}
        type={type}
        name={fieldId}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChangeCb}
        onBlur={onBlurCb}
      />
      <ErrorMessage error={error} touched={touched} />
    </div>

  );
};

StringField.propTypes = {
  blur: PropTypes.func,
  className: PropTypes.string,
  editMode: PropTypes.bool,
  error: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  focus: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  label: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  styles: PropTypes.object,
  touched: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default formikField(
  React.memo(
    StringField,
    (prevProps, nextProps) => {
      if (
        prevProps.className !== nextProps.className ||
        prevProps.editMode !== nextProps.editMode ||
        prevProps.error !== nextProps.error ||
        prevProps.placeholder !== nextProps.placeholder ||
        prevProps.styles !== nextProps.styles ||
        prevProps.touched !== nextProps.touched ||
        prevProps.value !== nextProps.value ||
        prevProps.focus !== nextProps.focus
      ) {
        return false;
      }
      return true;
    },
  ),
);
