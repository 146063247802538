import queryString from 'query-string';

import { FETCH_QUERY, RECEIVE_QUERY, LOADED, RELOADING, LOADING, ERROR, SEND_MUTATION, COMPLETE_MUTATION } from '../constants';

const serialize = queryString.stringify;
const initialState = {};

function fetch(path, state, { variables }) {
  const key = serialize(variables);
  const relations = (state[path] && state[path][key]) || {};
  const prevStatus = relations && relations.status;
  return {
    ...state,
    [path]: {
      ...state[path],
      [key]: prevStatus === LOADED ? { ...relations, status: RELOADING } : { data: null, status: LOADING },
    },
  };
}

function receive(path, state, { data: entities, error, variables }) {
  const key = serialize(variables);
  return {
    ...state,
    [path]: {
      ...state[path],
      [key]: { data: entities, error, status: error ? ERROR : LOADED },
    },
  };
}

function complete(path, state, { data, error, variables }) {
  const key = serialize(variables);
  return {
    ...state,
    [path]: {
      ...state[path],
      [key]: data ? { status: LOADED, data } : { status: ERROR, error },
    },
  };
}

function send(path, state, { variables }) {
  const key = serialize(variables);
  return {
    ...state,
    [path]: {
      ...state[path],
      [key]: { status: LOADING },
    },
  };
}

export default (state = initialState, { namespace, type, isDxApi, ...action }) => {
  switch (type) {
    case FETCH_QUERY: return fetch(namespace, state, action);
    case RECEIVE_QUERY: return receive(namespace, state, action);
    case SEND_MUTATION: return send(namespace, state, action);
    case COMPLETE_MUTATION: return complete(namespace, state, action);
    default: return state;
  }
};

