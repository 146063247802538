import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WithToolTip from '@innovatrix/components/WithToolTip';

import Button from './Button';
import { ALLOWED_FLAVORS, TEXT_BUTTON } from './_constants';

/**
 * Convert boolean props into an object key
 *
 * This is making a small extention to the 'small' boolean property
 * 'small' is used consistently across Innovatrix components
 * However we want an extra-small IconButton
 * So the smallest change possible to the Component interface is to add a 'xsmall' prop
 * This has the problem that you can have small and xsmall at the same time
 * But we handle that by overriding small with xsmall
 * The alternative is to use a 'size' prop
 * but that deprecates all other existing components
 *
 * @param {Object} props emotion component boolean props { small, xsmall }
 * @returns {string} sizes key
 */
const sizeIndex = (props) => {
  const { small, xsmall } = props;
  // xsmall overrides small
  if (xsmall) {
    return 'xsmall';
  }
  if (small) {
    return 'small';
  }
  // default
  return 'default';
};

// convert prop to pixel value
const propsToPixels = (sizes, props) => (sizes[sizeIndex(props)]);

/**
 * Convert an object of sizes into a XXpx string
 *
 * Return a function that accepts emotion props
 * Expected emotion props are { small, xsmall }
 *
 * @param {Object} sizes The values for {xsmall, small, default}
 * @returns {function} emotion modifier function
 */
const px = (sizes) => (
  (props) => `${propsToPixels(sizes, props)}px`
);

// each object specifies {xsmall, small, default} sizes
const CustomButton = styled(Button)`
  height: ${px({ xsmall: 20, small: 28, default: 36 })};
  padding: ${px({ xsmall: 6, small: 8, default: 10 })};
  width: ${px({ xsmall: 20, small: 28, default: 36 })};
  > span > span > svg {
    height: ${px({ xsmall: 8, small: 12, default: 16 })} !important;
    width: ${px({ xsmall: 8, small: 12, default: 16 })};
  }
`;

const IconButton = ({
  color,
  className,
  disabled,
  flavor,
  icon,
  onClick,
  small,
  tooltip,
  type,
  xsmall,
}) => (
  tooltip ? (
    <WithToolTip label={tooltip} className={className}>
      <CustomButton
        color={color}
        disabled={disabled}
        flavor={flavor}
        icon={icon}
        onClick={onClick}
        small={small}
        type={type}
        xsmall={xsmall}
      />
    </WithToolTip>
  ) : (
    <div className={className}>
      <CustomButton
        color={color}
        disabled={disabled}
        flavor={flavor}
        icon={icon}
        onClick={onClick}
        small={small}
        type={type}
        xsmall={xsmall}
      />
    </div>
  )
);

IconButton.defaultProps = { flavor: TEXT_BUTTON };
IconButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  flavor: PropTypes.oneOf(ALLOWED_FLAVORS),
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  xsmall: PropTypes.bool,
};

export default React.memo(IconButton);
