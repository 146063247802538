import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchApplicationMetaDataAction } from '@innovatrix/actions';
import { Spinner, TitleDescription } from '@innovatrix/components';
import { LOADING, ERROR, LOADED, RELOADING, INNOVATRIX_INTRODUCTION_TOUR } from '@innovatrix/constants';
import { getApplicationMetaData } from '@innovatrix/selectors/data/applicationSelectors';
import graphql from '@innovatrix/common/graphql';
import { getDxAuthToken } from '@innovatrix/selectors';

import { getUserToursQuery, persistStepMutation, skipTourMutation } from '../_queries';

import { ApplicationContext } from './context';

const AppWithContext = graphql({
  mutation: persistStepMutation,
  name: 'persistStep',
  namespace: '_persistStep',
})((graphql({
  mutation: skipTourMutation,
  name: 'skipTour',
  namespace: '_skipTour',
})(graphql({
  query: getUserToursQuery,
  name: 'tours',
  namespace: '_getUserTours',
  type: 'Query',
  skip: ({ userId }) => !userId,
  variables: ({ userId }) => ({ userId }),
})(({ children, user, tours: { data }, refetch, refetchMetaData, persistStep, skipTour, dxToken }) => {
  const finishedOnboarding = React.useMemo(() => {
    const onboardingTour = (data || []).find(tour => tour.name === INNOVATRIX_INTRODUCTION_TOUR);
    return Boolean(!onboardingTour || onboardingTour.completedOn || onboardingTour.cancelledOn);
  }, [data]);

  const context = React.useMemo(() => ({
    dxToken,
    finishedOnboarding,
    persistStep,
    refetchMetaData,
    refetchTours: refetch,
    skipTour,
    tours: data || [],
    user,
  }), [data, finishedOnboarding, persistStep, refetch, refetchMetaData, skipTour, user, dxToken]);

  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
}))));

const AppContainer = ({
  applicationMetaData: { _status: status, user }, fetchApplicationMetaData, children, dxToken,
}) => {
  React.useEffect(() => { fetchApplicationMetaData(); }, [fetchApplicationMetaData]);

  return (
    <React.Fragment>
      {status === LOADING || status === RELOADING && <Spinner />}
      {status === ERROR && (
        <TitleDescription>
          An error occurred while loading the application. Try again by refreshing your page.
        </TitleDescription>
      )}
      {status === LOADED && (
        <AppWithContext user={user} userId={user.id} refetchMetaData={fetchApplicationMetaData} dxToken={dxToken}>
          {children}
        </AppWithContext>
      )}
    </React.Fragment>
  );
};

AppContainer.propTypes = {
  applicationMetaData: PropTypes.object.isRequired,
  children: PropTypes.any,
  dxToken: PropTypes.string.isRequired,
  fetchApplicationMetaData: PropTypes.func.isRequired,
};

export default connect((state) => ({
  applicationMetaData: getApplicationMetaData(state),
  dxToken: getDxAuthToken(state),
}), {
  fetchApplicationMetaData: fetchApplicationMetaDataAction,
})(React.memo(AppContainer));
