import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Button } from '@innovatrix/common/buttons';
import { Title, Paragraph } from '@innovatrix/common/text';
import { WHITE, GREY_LIGHT_3 } from '@innovatrix/styles';

import Cross from './Cross';
import './modalAnimation.scss';
import BreadCrumbs from './BreadCrumbs';

const Wrapper = styled.div`
  height: 100%;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px 12px;
`;

const Heading = styled.div`
  align-items: center;
  display: flex;
  width: 90%;
`;

const ModalTitle = styled(Title)`
  margin-bottom: 0;
`;

const Subtitle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey2};
  margin-right: 8px;
  max-width: 160px;
  text-transform: uppercase;
  width: auto;
`;

const CloseIcon = styled(Cross)`
  &:hover {
    cursor: pointer;
    > path {
        opacity: 0.6;
        transition: 0.3s;
    }
  }
`;

const BodyContainer = styled.div`
  ${({ theme }) => theme.scrollbar.large};
  max-height: 500px;
  overflow-y: auto;
  padding: 0 32px 24px;
  width: 100%;
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.greyLight3};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 32px;

  > * {
    margin-left: 12px;
  }
`;

export default class ConfirmationModal extends React.Component {

  onClose(e) {
    e.preventDefault();
    this.props.onClose();
  }

  render() {
    const { body, buttons, breadCrumbs, className, contentLabel, isOpen, subTitle, title, onClose } = this.props;

    const modalStyles = {
      overlay: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 100,
      },
      content: {
        backgroundColor: WHITE,
        border: `1px solid ${GREY_LIGHT_3}`,
        borderRadius: 3,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
        maxWidth: 700,
        minWidth: 450,
        outline: 0,
        overflow: 'visible',
        padding: 0,
        position: 'relative',
        width: '100%',
      },
    };

    return (
      <ReactModal
        ariaHideApp={false}
        className={className}
        closeTimeoutMS={500}
        contentLabel={contentLabel}
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
      >
        <Wrapper>
          <Header>
            <Heading>
              {subTitle
                ? <Subtitle>{subTitle}</Subtitle>
                : breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
              <ModalTitle>{title}</ModalTitle>
            </Heading>
            <CloseIcon onClick={onClose} />
          </Header>
          <BodyContainer>
            {body}
          </BodyContainer>
          <Footer>
            {buttons.map(({ action, flavor, label, loading }, i) => <Button key={i} flavor={flavor} label={label} onClick={action} loading={loading} />)}
          </Footer>
        </Wrapper>
      </ReactModal>
    );
  }

}

ConfirmationModal.propTypes = {
  body: PropTypes.node,
  breadCrumbs: PropTypes.arrayOf(PropTypes.string),
  buttons: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func.isRequired,
    color: PropTypes.string,
    flavor: PropTypes.oneOf(['warning', 'outline', 'primary', 'secondary', 'tertiary', 'quaternary']).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
  contentLabel: PropTypes.string,
  // Function for closing the modal
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  subTitle: PropTypes.string,
  // Main title in Header
  title: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  className: '',
  contentLabel: '',
  isOpen: true,
  subTitle: '',
  title: '',
};
