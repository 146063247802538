import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useReduxModule from '@innovatrix/common/utils/useReduxModule';
import { fetchManagersAction } from '@innovatrix/actions';

import myProjectsReducer from './reducer';
import myProjectsSagas from './sagas';
import LoadingScreen from './components/LoadingScreen';
import { fetchMyProjectsAction } from './_actions';

/**
 *
 * POC implementation for myProjects.
 *
 * Normally should be with React.Suspense and lazy but this can't be done
 * because of outdated deps.
 *
 * We can migrate away from react-frontend in an orderly fashion just by excluding it
 * from the webpack config.
 */
const MyProjectsWrapper = ({ fetchMyProjects, fetchManagers, ...props }) => {
  const loader = import(/* webpackChunkName="myProjects" */'./pages');
  const Module = useReduxModule('myProjects', myProjectsSagas, myProjectsReducer, [fetchManagers, fetchMyProjects], loader);
  return Module ? <Module {...props} /> : <LoadingScreen projectStatus={props.match.params.projectStatus} />;
};

MyProjectsWrapper.propTypes = {
  fetchManagers: PropTypes.func.isRequired,
  fetchMyProjects: PropTypes.func.isRequired,
  match: PropTypes.object,
};

export default connect(null, {
  fetchManagers: fetchManagersAction,
  fetchMyProjects: fetchMyProjectsAction,
})(MyProjectsWrapper);
