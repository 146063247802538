import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import formikField from '@innovatrix/components/fields/formikField';
import { prevent } from '@innovatrix/utils';
import { IS_PRODUCTION } from '@innovatrix/constants';
import { Description } from '@innovatrix/components';

import { Button } from '../buttons';
import { TERTIARY } from '../buttons/_constants';
import { WHITE } from '../../../styles';

import { FormGroup, LabelGroup, InputGroup } from './_helpers';
import { defaultFieldPropTypes } from './_constants';

const StyledButton = styled(Button)`
  border-radius: 18px;
  ${({ disabled, theme }) => (disabled ? `
    background: ${theme.colors.greyLight1};
    border-color: ${theme.colors.grey1};

    :hover {
      background: ${theme.colors.greyLight1};
    }

    > span > p {
      color: ${theme.colors.grey1};
    }
  ` : '')}
  ${({ selected, theme }) => (selected ? `
    background: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    :hover {
      background: ${theme.colors.primaryDark};
    }

    :after {
      background: ${theme.colors.white};
    }

    > span > p {
      color: ${theme.colors.white};
    }
  ` : '')}
`;

const StyledInputGroup = styled(InputGroup)`
  display: grid;
  gap: 16px;
  grid-template-columns: ${({ columns }) => columns};
`;

const displayName = 'GroupSelectField';

const ButtonWrapper = React.memo(({
  disabled,
  label,
  icon,
  multiple,
  onChange,
  selection,
  value,
}) => {
  if (!IS_PRODUCTION) {
    if (value === undefined && value === null) {
      throw new Error(`You did not pas a "value" property to your <${displayName} />.options.`);
    }
    if (!label && !icon) {
      throw new Error(`You did not pas a "label" or "icon" property to your <${displayName} />.options.`);
    }
  }

  const onClick = React.useCallback((e) => {
    prevent(e);
    onChange(value);
  }, [onChange, value]);

  const selected = multiple ? selection.includes(value) : value === selection;

  return (
    <StyledButton
      disabled={disabled}
      color={selected ? WHITE : undefined}
      flavor={TERTIARY}
      selected={selected}
      icon={icon}
      label={label}
      onClick={onClick}
    />
  );
});

ButtonWrapper.displayName = 'ButtonWrapper';
ButtonWrapper.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selection: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
};

export const GroupSelectField = React.memo(({
  className,
  columns,
  description,
  disabled,
  error,
  information,
  label,
  multiple,
  onChange,
  options,
  required,
  touched,
  value: selection,
}) => {
  if (!IS_PRODUCTION) {
    if (!options || !Array.isArray(options)) {
      throw new Error(`You did not pas an "options" array property to your <${displayName} />.`);
    }
    if (!onChange || typeof onChange !== 'function') {
      throw new Error(`You did not pas an "onChange" function property to your <${displayName} />.`);
    }

    if (typeof columns !== 'number' || columns > 12) {
      throw new Error(`You did not pas a "columns" number property or it was bigger than 12 to your <${displayName} />.`);
    }
  }

  const translatedColumns = React.useMemo(() => {
    let cssText = '';
    for (let i = 0; i < columns; i += 1) {
      cssText = `${cssText} 1fr`;
    }
    return cssText.trim();
  }, [columns]);

  const onChangeCb = React.useCallback(val => {
    if (multiple) {
      if (selection.includes(val)) {
        onChange(selection.filter(x => x !== val));
      }
      else {
        onChange([...selection, val]);
      }
    }
    else if (val === selection) {
      onChange(null);
    }
    else {
      onChange(val);
    }
  }, [multiple, onChange, selection]);

  return (
    <FormGroup className={className}>
      {label && <LabelGroup touched={touched} error={error} label={label} information={information} required={required} />}
      {description ? (<Description>{description}</Description>) : null}
      <StyledInputGroup columns={translatedColumns}>
        {options.map(option => (
          <ButtonWrapper
            key={option.value}
            {...option}
            multiple={multiple}
            selection={selection}
            onChange={onChangeCb}
            disabled={disabled || option.disabled}
          />))}
      </StyledInputGroup>
    </FormGroup>
  );
});

GroupSelectField.displayName = displayName;
GroupSelectField.defaultProps = { columns: 6 };
GroupSelectField.propTypes = {
  ...defaultFieldPropTypes,
  columns: PropTypes.number,
  description: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
};

export default formikField(GroupSelectField);
