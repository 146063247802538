import {
  ERROR_OBJECTIVE_TYPES,
  FETCH_OBJECTIVE_TYPES,
  RECEIVE_OBJECTIVE_TYPES,
} from '../../constants';

import { fetchEntities, receiveEntities, errorEntities } from './utils';

const initialState = {
  entities: {},
  relations: {
    innovatrixHasObjectiveTypes: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_OBJECTIVE_TYPES: return receiveEntities('innovatrixHasObjectiveTypes', state, action);
    case FETCH_OBJECTIVE_TYPES: return fetchEntities('innovatrixHasObjectiveTypes', state, action);
    case ERROR_OBJECTIVE_TYPES: return errorEntities('innovatrixHasObjectiveTypes', state, action);

    default: return state;
  }
};

