import { combineReducers } from 'redux';

import {
  ERROR_EVALUATION,
  POST_EVALUATION,
  RECEIVE_EVALUATION,
} from '../constants';

import { createLoadStateReducer } from './loadState';
// UI
// //
import briefProjectsReducer from './briefProjectsReducer';
import innoBoardUiReducer from './innoBoardUiReducer';
import modalsReducer from './modalsReducer';
import objectivesReducer from './objectivesReducer';
import projectInnovatrixReducer from './projectInnovatrixReducer';
import salesforceReducer from './salesforceReducer';
import scoreReducer from './scoreReducer';
import settingsReducer from './settingsReducer';
// DATA
// ////
import dataApplicationReducer from './data/applicationReducer';
import dataAssessmentsRedducer from './data/assessmentsReducer';
import dataCampaignReducer from './data/campaignReducer';
import dataInnoboardReducer from './data/innoboardReducer';
import dataObjectiveReducer from './data/objectiveReducer';
import dataProjectOverviewReducer from './data/projectOverviewReducer';
import dataProjectReducer from './data/projectReducer';
import dataQuestionReducer from './data/questionReducer';
import genericDataReducer from './genericDataReducer';
import geoReducer from './geoReducer';
import managersAndCoachesReducer from './data/managersAndCoachesReducer';
import objectiveTypeReducer from './data/objectiveTypeReducer';
import workshopReducer from './data/workshopReducer';
import workshopUiReducer from './workshopReducer';

// The reducers object to install in the Redux store:
export default {
  briefProjects: briefProjectsReducer,
  data: combineReducers({
    application: dataApplicationReducer,
    assessment: dataAssessmentsRedducer,
    campaign: dataCampaignReducer,
    innoboard: dataInnoboardReducer,
    managersAndCoaches: managersAndCoachesReducer,
    objective: dataObjectiveReducer,
    objectiveType: objectiveTypeReducer,
    project: dataProjectReducer,
    projectOverview: dataProjectOverviewReducer,
    question: dataQuestionReducer,
    workshops: workshopReducer,
  }),
  generic: genericDataReducer,
  geo: geoReducer,
  innoBoardUi: innoBoardUiReducer,
  modals: modalsReducer,
  objectives: objectivesReducer,
  projectInnovatrix: projectInnovatrixReducer,
  salesforce: salesforceReducer,
  score: scoreReducer,
  settings: settingsReducer,
  uploadEvaluation: createLoadStateReducer(POST_EVALUATION, RECEIVE_EVALUATION, null, ERROR_EVALUATION),
  workshopsUi: workshopUiReducer,
};
