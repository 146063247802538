/* global LOG_DX_AUTH */

import { takeEvery } from 'redux-saga/effects';

import { showErrorModal } from '../dxModalSagas';
import { DX_AUTH_FAILED } from '../../constants';

// -- Support --------------- --- --  -

const logAuth = LOG_DX_AUTH ? ((...args) => console.log('[dxAuth.authSagas]', ...args)) : (() => null);

// -- Sagas --------------- --- --  -

function* authFailed({ dxError }) {
  logAuth(`authFailed: ${dxError.toString()}`);
  try {
    yield showErrorModal(dxError.prependReason('Failed to authenticate the user.'));
  }
  catch (error) {
    console.error('Failed to handle DX_AUTH_FAILED.', error);
  }
}

export default function* () {
  logAuth('Installing authSagas...');
  yield takeEvery(DX_AUTH_FAILED, authFailed);
}
