import { config } from '../config';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const INSTANCE_NAME = config.get('innovatrix.instance.name', 'tenant');

export const SALESFORCE_ENABLED = config.get('innovatrix.salesforce.enable', false);
export const CAMPAIGNS_ENABLED = config.get('innovatrix.campaigns.enable', false);
export const PHASES_ENABLED = config.get('innovatrix.phases.enable', false);

export const DEALS_ENABLED = config.get('innovatrix.deals.enable', false);
export const DEALS_NAME = config.get('innovatrix.deals.name', 'Deals');
export const PUBLIC_DEALS_ENABLED = config.get('innovatrix.deals.public', false);

export const CAMPAIGN_TITLE_SINGULAR = config.get('innovatrix.campaigns.nameSingular', 'Campaign');
export const CAMPAIGN_TITLE_PLURAL = config.get('innovatrix.campaigns.namePlural', 'Campaigns');
export const CAMPAIGN_TITLE_SINGULAR_LOWERCASE = CAMPAIGN_TITLE_SINGULAR.toLowerCase();
export const CAMPAIGN_TITLE_PLURAL_LOWERCASE = CAMPAIGN_TITLE_PLURAL.toLowerCase();

export const DOCUMENTS_ENABLED = config.get('innovatrix.documents.enable', false);
export const MAX_FILE_SIZE = config.get('innovatrix.documents.fileSizeLimit', 0);
export const MIN_FILE_SIZE = 1000;

export const INTAKES_ENABLED = config.get('innovatrix.intake.enable', false);

export const OBJECTIVES_ENABLED = config.get('innovatrix.objectives.enable', false);
export const OBJECTIVES_NAME = config.get('innovatrix.objectives.name', 'Objectives');
export const OBJECTIVE_ITEM = config.get('innovatrix.objectives.item', 'objective');

export const WORKSHOPS_ENABLED = config.get('innovatrix.workshops.enable', false);
export const WORKSHOPS_NAME = config.get('innovatrix.workshops.name', 'Workshops');

export const MAILS_ENABLED = config.get('innovatrix.mails.enable', false);
export const IS_ISTART_ENVIRONMENT = INSTANCE_NAME === 'istart';
export const IS_ISTARTNL_ENVIRONMENT = INSTANCE_NAME === 'istartnl';
export const IS_OPEN_ENVIRONMENT = INSTANCE_NAME === 'open';
export const IS_DEMO_ENVIRONMENT = INSTANCE_NAME === 'demo';

export const REPORTS_ENABLED = config.get('innovatrix.reports.enable', false);

export const ASSESSMENTS_V2_ENABLED = config.get('innovatrix.assessments.enable', false);
export const MODELS_ENABLED = config.get('innovatrix.models.enable', false);
export const MODELS_SCORES_NAME = config.get('innovatrix.models.scoresName', 'Assessments');
export const MODELS_MODEL_NAME = config.get('innovatrix.models.modelName', 'Assessment Model');

export const INNOBOARDS_ENABLED = config.get('innovatrix.innoboards.enable', false);
export const INNOBOARDS_MODEL_NAME = config.get('innovatrix.innoboards.modelName', 'Innovation Tools');
export const INNOBOARDS_PROJECT_NAME = config.get('innovatrix.innoboards.projectName', 'Innovation Tools');
export const INNOBOARDS_PUBLIC_SHOW_REGISTER_CTA = config.get('innovatrix.innoboards.public.showRegisterCTA', false);
export const INNOBOARDS_PUBLIC_SHOW_REGISTER_CTA_LINK = config.get('innovatrix.innoboards.public.registerLinkCTA', '/public/apply');

export const EXTERNAL_LINK_ENABLED = config.get('innovatrix.externalLink.enable');
export const EXTERNAL_LINK_NAME = EXTERNAL_LINK_ENABLED ? config.get('innovatrix.externalLink.name') : undefined;
export const EXTERNAL_LINK_URL = EXTERNAL_LINK_ENABLED ? config.get('innovatrix.externalLink.url') : undefined;

export const PROJECT_MANAGERS_LABEL = config.get('innovatrix.projects.projectManagers', 'Project Managers');

export const SHOW_HERO_COMPONENT_PUBLIC_APPLY_PAGE = config.get('innovatrix.public.showPublicApplicationPageHeroComponent', false);
export const INTAKE_WELCOME_PAGE_TITLE = config.get('innovatrix.public.welcomePageTitle', 'Welcome to Innovatrix.');
export const INTAKE_WELCOME_PAGE_DESCRIPTION = config.get('innovatrix.public.welcomePageDescription', 'Please login or register.');
export const INTAKE_WELCOME_PAGE_DISPLAY_LOGO = config.get('innovatrix.public.welcomePageDisplayLogo', true);

export const SUPPORT_LINK = 'https://imec.atlassian.net/servicedesk/customer/portal/1';

export const UMS_URL = config.get('duxis.ums.url', '');

// About
export const DX_HOST = config.get('duxis.dxHost');
export const DX_RELEASE_VERSION = config.get('duxis.dxVersion');
export const INNOVATRIX_BUILD_TIME = process.innovatrix.lastBuildAt;
export const INNOVATRIX_RELEASE_VERSION = config.get('innovatrix.version');

// Microsoft Clarity
export const MICROSOFT_CLARITY_KEY = config.get('innovatrix.microsoftClarity.key', false);
export const ENABLE_MICROSOFT_CLARITY = (IS_DEMO_ENVIRONMENT || IS_OPEN_ENVIRONMENT) && MICROSOFT_CLARITY_KEY;

export const ALL = 'All';
export const MODES = {
  ALL: 'ALL',
  CREATE: 'CREATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  EDIT: 'EDIT',
  PREVIEW: 'PREVIEW',
  VIEW: 'VIEW',
};

// -- dxQuery --------------- --- --  -

export const DX_QUERY_POSTED = 'DX_QUERY_POSTED';
export const DX_QUERY_CANCELLED = 'DX_QUERY_CANCELLED';
export const DX_QUERY_FAILED = 'DX_QUERY_FAILED';
export const DX_QUERY_SUCCESS = 'DX_QUERY_SUCCESS';

export const DX_QUERY_CONTEXT_PENDING = 'DX_QUERY_CONTEXT_PENDING';
export const DX_QUERY_CONTEXT_COMPLETE = 'DX_QUERY_CONTEXT_COMPLETE';

// -- user roles --------------- --- --  -
export const ROLE = {
  APPLICANT: 'innovatrix/role/applicant',
  CLIENT: 'innovatrix/role/client',
  SCOPED_CLIENT: 'innovatrix/role/scoped/client',
  MANAGER: 'innovatrix/role/manager',
};

// -- Timezone ------------------- --- --  -
export const BRUSSELS_TZ = 'Europe/Brussels';

// -- Decisions ------------------- --- --  -

// Salesforce
export const GO = 'Go';
export const NO_GO = 'No go';
// Evaluations
export const APPROVED = 'Approved';
export const REJECTED = 'Rejected';
// Both
export const MAYBE = 'Maybe';
export const CONDITIONS = 'Conditions';
// Are for decisions in cards view
export const ACCEPTED = 'Accepted';
export const UNDECIDED = 'TBD';
export const UNDECIDED_LABEL = 'Undecided';
export const ACCEPTED_WITH_CONDITIONS = 'Accepted with Conditions';
export const WITHDRAWN = 'Withdrawn';
export const STATUSES_OPTIONS = [
  { value: ACCEPTED, label: ACCEPTED },
  { value: ACCEPTED_WITH_CONDITIONS, label: ACCEPTED_WITH_CONDITIONS },
  { value: REJECTED, label: REJECTED },
  { value: UNDECIDED, label: UNDECIDED_LABEL },
  { value: WITHDRAWN, label: WITHDRAWN },
];
export const TEAM_MEMBER = {
  TYPE: {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
  },
};

export const VENTURE_ACCELERATION_MANAGER_TYPES = {
  PRIMARY: 'PRIMARY',
  BACKUP: 'BACKUP',
};

// -- Query Contexts --------------- --- --  -

export const EVALUATIONS_CONTEXT = 'innovatrix/EVALUATIONS_CONTEXT';
export const CAMPAIGNS_CONTEXT = 'innovatrix/CAMPAIGNS_CONTEXT';
export const PROJECTS_CONTEXT = 'innovatrix/PROJECTS_CONTEXT';
export const INNOBOARDS_CONTEXT = 'innovatrix/INNOBOARDS_CONTEXT';

// -- PastCalls ------------------- --- --  -

export const CALL_2017_2 = 'iStart-2017-2';
export const CALL_2017_3 = 'iStart-2017-3';

// -- Phases ---------------------- --- --  -

export const PHASE_PRE_SELECTION = 'Pre-selection';
export const PHASE_SELECTION = 'Selection';
export const PHASE_PROJECT = 'Project';

// -- Cards Order ----------------- --- --  -

export const ORDER_RATINGS_ASC = 'Order by Ratings Asc.';
export const ORDER_RATINGS_DESC = 'Order by Ratings Desc.';
export const ORDER_DECISIONS = 'Order by Decisions';
export const ORDER_VERTICAL = 'Order by Vertical';

// -- Global Order ----------------- --- --  -
export const ORDER_ALPHABETICALLY = 'Order Alphabetically';
export const ORDER_NEWEST_FIRST = 'Order by date (newest first)';
export const ORDER_OLDEST_FIRST = 'Order by date (oldest first)';
export const ORDER_RECENT_FIRST = 'Order by date (most recent first)';
export const ORDER_FUTURE_FIRST = 'Order by date (most future first)';

// -- Filters ------------ --- --  -
export const SELECT_ALL = 'SELECT_ALL';
export const SELECT_ALL_GROUP = 'SELECT_ALL_GROUP';
export const PARTIALLY_SELECTED = 1;
export const SELECTED = 2;

// -- Decisions Filter ------------ --- --  -

export const FILTER_ALL = 'Filter on All';
export const FILTER_ACCEPTED = 'Filter on Accepted';
export const FILTER_REJECTED = 'Filter on Rejected';
export const FILTER_UNDECIDED = 'Filter on Undecided';

// -- Show/Hide predictions ------- --- --  -

export const SHOW_PREDICTIONS = 'SHOW_PREDICTIONS';
export const HIDE_PREDICTIONS = 'HIDE_PREDICTIONS';

// -- Scores Filter --------------- --- --  -

export const ALL_SCORES = 'ALL SCORES';
export const SETTINGS_DELETE_PROJECT = 'DELETE_PROJECT';

// -- Statusses --------------- --- --  -

export const ERROR = Symbol('ERROR');
export const LOADING = Symbol('LOADING');
export const LOADED = Symbol('LOADED');
export const RELOADING = Symbol('RELOADING');

//  -- Assumptions --------------- --- --  -

export const ASSUMPTION = 'Assumption';
export const UNCLEAR = 'Unclear';
export const NEW_INSIGHT = 'New Insight';
export const VALIDATED = 'Validated';
export const INVALIDATED = 'Invalidated';
export const UNKNOWN = 'Unknown';

export const ASSUMPTIONS_STATUSES = {
  ASSUMPTION,
  INVALIDATED,
  NEW_INSIGHT,
  UNCLEAR,
  UNKNOWN,
  VALIDATED,
};

export const ACTIVE_PROJECT_STATUS = 'Active';
export const CLOSED_PROJECT_STATUS = 'Closed';
export const ON_HOLD_PROJECT_STATUS = 'On Hold';
export const ARCHIVED_PROJECT_STATUS = 'Archived';
export const GRADUATED_PROJECT_STATUS = 'Graduated';

export const ACTIVE = 'ACTIVE';
export const ARCHIVED = 'ARCHIVED';
export const ON_HOLD = 'ON_HOLD';
export const GRADUATED = 'GRADUATED';

export const PROJECT_TYPE_MAPPING = {
  ACTIVE: ACTIVE_PROJECT_STATUS,
  GRADUATED: GRADUATED_PROJECT_STATUS,
  ARCHIVED: ARCHIVED_PROJECT_STATUS,
  ON_HOLD: ON_HOLD_PROJECT_STATUS,
};

export const PROJECT_STATUSSES = SALESFORCE_ENABLED ? [
  { label: ACTIVE_PROJECT_STATUS, value: ACTIVE },
  { label: GRADUATED_PROJECT_STATUS, value: GRADUATED },
] : [
  { label: ACTIVE_PROJECT_STATUS, value: ACTIVE },
  { label: ON_HOLD_PROJECT_STATUS, value: ON_HOLD },
  { label: ARCHIVED_PROJECT_STATUS, value: ARCHIVED },
];

export const QUITE_CERTAIN = 'Quite certain';
export const VERY_PROBABLY = 'Very probably';
export const UNCERTAIN = 'Uncertain';

export const EXPAND_ALL = 'EXPAND_ALL';
export const COLLAPSE_ALL = 'COLLAPSE_ALL';

export const DASHBOARD_TAB = 'Dashboard';
export const SCORE_TAB = MODELS_SCORES_NAME;
export const OVERVIEW_TAB = 'Project Info';
export const INNOBOARD_TAB = INNOBOARDS_PROJECT_NAME;
export const WORKSHOPS_TAB = WORKSHOPS_NAME;
export const OBJECTIVES_TAB = OBJECTIVES_NAME; // Objectives or KPI's
export const REPORTS_TAB = 'Reports';
export const DOCUMENTS_TAB = 'Documents';

// manage route only
export const CONFIGURATION_TAB = 'Configuration';

export const COMPARE_TAB = 'Compare';
export const NEW_EVALUATION_TAB = 'New';
export const NEW_ASSESSMENT_TAB = 'Assessment';

// Sub tabs of Score tab.
export const ALL_OBJECTIVES = 'Active';
export const ADD_OBJ_TYPE = 'Add Tab';
export const COMPLETED_OBJECTIVES = 'Completed';
export const MANAGE_OBJECTIVE_TYPES = 'Manage Objective Types';

export const OTHER = 'Other';
export const DONT_KNOW = "Don't know";
export const DONT_KNOW_QUOTE = 'Don’t Know';
export const NOT_APPLICABLE = 'Not applicable';
export const NOT_APPLICABLE_CAPITAL = 'Not Applicable';
export const ARGUMENTATION = 'Argumentation';
export const SKIPPED_OPTION_VALUE = -3;
export const NOT_DRAGGABLE_OPTIONS = [
  DONT_KNOW_QUOTE,
  NOT_APPLICABLE_CAPITAL,
  DONT_KNOW,
  NOT_APPLICABLE,
  OTHER,
  ARGUMENTATION,
];

export const EVENT = 'EVENT';
export const OBJECTIVE = 'OBJECTIVE';
export const MILESTONE = 'MILESTONE';
export const WORKSHOP = 'WORKSHOP';

export const CREATE_ASSUMPTION = 'CREATE_ASSUMPTION';
export const CREATE_EVALUATION = 'CREATE_EVALUATION';
export const CREATE_INNOBOARD = 'CREATE_INNOBOARD';
export const CREATE_INSIGHT = 'CREATE_INSIGHT';
export const CREATE_OBJECTIVE = 'CREATE_OBJECTIVE';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const ATTEND_WORKSHOP = 'ATTEND_WORKSHOP';
export const SKIP_WORKSHOP = 'SKIP_WORKSHOP';
export const DELETE_EVALUATION = 'DELETE_EVALUATION';
export const DELETE_OBJECTIVE = 'DELETE_OBJECTIVE';
export const UPDATE_EVALUATION = 'UPDATE_EVALUATION';
export const UPDATE_INNOBOARD = 'UPDATE_INNOBOARD';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';

export const INNOVATRIX_SCORES = 'INNOVATRIX_SCORES';
export const INNOVATRIX = 'INNOVATRIX';
export const WORKSHOPS = 'WORKSHOPS';
export const ICAL = 'ICAL';
export const NO_ICAL = 'NO_ICAL';
export const OBJECTIVES = 'OBJECTIVES';
export const REPORTS = 'REPORTS';
export const EVENTS = 'EVENTS';
export const PROJECT_INFO = 'PROJECT_INFO';

export const TAB_KEY = 9;
export const ENTER_KEY = 13;
export const ESCAPE_KEY = 27;
export const ARROW_UP_KEY = 38;
export const ARROW_DOWN_KEY = 40;

export const APPLY_TO_ALL = 'ALL';
export const APPLY_TO_SPECIFIC = 'SPECIFIC';

export const WORKSHOP_DETAILS = 'Details';
export const WORKSHOP_PROJECTS = 'Projects';
export const ONE_ON_ONE_TIMESLOTS = '1 on 1 timeslots';
export const ATTENDING_TEAM = 'Attending team';

export const REVIEW = 'review';
export const EVALUATION = 'evaluation';

// Assessment stats
export const DRAFT = 'DRAFT';
// export const ARCHIVED = 'ARCHIVED'; // Commented because this is already once defined above with the same value,
// so no issues here!
export const PUBLISHED = 'PUBLISHED';
export const IN_USE = 'IN_USE';

// Drop zone
export const HIDE_DROP_ZONE = 'HIDE_DROP_ZONE';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_NEW_DOCUMENT_VERSION = 'UPLOAD_NEW_DOCUMENT_VERSION';

// Workshop invitation statuses
export const ATTENDED = 'ATTENDED';
export const ATTENDING = 'ATTENDING';
export const DID_NOT_ATTEND = 'DID_NOT_ATTEND';
export const INVITED = 'INVITED';
export const NOT_ATTENDING = 'NOT_ATTENDING';

// Tours
export const INNOBOARD_ASSUMPTIONS_1_TOUR = 'INNOBOARD_ASSUMPTIONS_1_TOUR';
export const INNOBOARD_ASSUMPTIONS_10_TOUR = 'INNOBOARD_ASSUMPTIONS_10_TOUR';
export const INNOBOARD_ASSUMPTIONS_2_TOUR = 'INNOBOARD_ASSUMPTIONS_2_TOUR';
export const INNOBOARD_ASSUMPTIONS_20_TOUR = 'INNOBOARD_ASSUMPTIONS_20_TOUR';
export const INNOBOARD_BULK_ACTION_TOUR = 'INNOBOARD_BULK_ACTION_TOUR';
export const INNOBOARD_CANVAS_CRITERIA_TOUR = 'INNOBOARD_CANVAS_CRITERIA_TOUR';
export const INNOBOARD_CANVAS_INTRODUCTION_TOUR = 'INNOBOARD_CANVAS_INTRODUCTION_TOUR';
export const INNOBOARD_TOPDOWN_CRITERIA_TOUR = 'INNOBOARD_TOPDOWN_CRITERIA_TOUR';
export const INNOBOARD_TOPDOWN_INTRODUCTION_TOUR = 'INNOBOARD_TOPDOWN_INTRODUCTION_TOUR';
export const INNOBOARD_VALIDATION_ACTIVITY_TOUR = 'INNOBOARD_VALIDATION_ACTIVITY_TOUR';

export const INNOVATRIX_INTRODUCTION_TOUR = 'INNOVATRIX_INTRODUCTION_TOUR';

// Deals
export const DEALS_FILTER_CATEGORY = 'Filter Category';
export const DEALS_ORDER_BY_TYPE = 'Order By Type';
export const DEALS_ORDER_ALPHABETICALLY = 'Order Alphabetically';

// Cards
export const ROW_HEIGHT = 672;

// Workshop Document status
export const DOCUMENT_UPLOADED = 'Uploaded';
export const DOCUMENT_REJECTED = 'Rejected';

// Intakes
export const DRAFT_ID = 'DRAFT_ID';

export const INTAKE_TYPE = {
  APPLICATION: 'APPLICATION',
  CREATION: 'CREATION',
  REGISTRATION: 'REGISTRATION',
};

/**
 * Given 'kind' of file, give specific list of allowed file types
 * This matches up with ICON_MAP in documentTypes/DocumentIcon.jsx
 * Values from this are then used by the acceptedTypes param for FileDropZone
 */
export const ACCEPTED_FILE_TYPES = {
  pdf: [
    'application/pdf',
  ],
  powerpoint: [
    'application/vnd.ms-powerpoint',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  excel: [
    'application/vnd.ms-excel',
    'application/x-msi',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  word: [
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ],
  image: [
    'image/png',
    'image/gif',
    'image/jpeg',
  ],
};

export const LEGAL_DOCUMENTS = {
  INNOVATRIX_PRIVACY_POLICY: 'INNOVATRIX_PRIVACY_POLICY',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
};

// Configuration statuses
export const CONFIGURATION_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED', // Draft status
};

export const DOCUMENT_VARIANTS = {
  APPLICATION_DOCUMENT: 'APPLICATION_DOCUMENT',
  INTAKE_TEMPLATE_DOCUMENT: 'INTAKE_TEMPLATE_DOCUMENT',
  LEGACY_APPLICATION_DOCUMENT: 'LEGACY_APPLICATION_DOCUMENT',
  LEGAL_DOCUMENT_INNOVATRIX: 'LEGAL_DOCUMENT_INNOVATRIX',
  LEGAL_DOCUMENT: 'LEGAL_DOCUMENT',
  PROJECT_DOCUMENT: 'PROJECT_DOCUMENT',
  REPORT_DOCUMENT: 'REPORT_DOCUMENT',
  REPORT_TEMPLATE_DOCUMENT: 'REPORT_TEMPLATE_DOCUMENT',
  WORKSHOP_DOCUMENT_FOR_REVISION: 'WORKSHOP_DOCUMENT_FOR_REVISION',
  WORKSHOP_DOCUMENT_REVISED: 'WORKSHOP_DOCUMENT_REVISED',
  WORKSHOP_DOCUMENT: 'WORKSHOP_DOCUMENT',
};

export const KICK_OFF = 'Kick-off';

export const LAYOUT = {
  CANVAS: 'CANVAS',
  TOPDOWN: 'TOPDOWN',
};

export const MILESTONES = {
  ASSESSMENT_MILESTONE: 'ASSESSMENT_MILESTONE',
  INNOBOARD_MILESTONE: 'INNOBOARD_MILESTONE',
  OBJECTIVE_MILESTONE: 'OBJECTIVE_MILESTONE',
  PROJECT_MILESTONE: 'PROJECT_MILESTONE',
  REPORT_MILESTONE: 'REPORT_MILESTONE',
};

export const REPORT_STATUS = {
  SUBMITTED: 'Submitted',
};

export const PORTFOLIO_STATUS = {
  ACTIVE: 'Active',
  BANKRUPTCY: 'Bankruptcy',
  DE_FACTO_INACTIVE: 'De Facto Inactive',
  EXIT_MBO: 'Exit (MBO)',
  EXIT: 'Exit',
  LIQUIDATION: 'Liquidation',
  STOPPED: 'Stopped',
};
