export function shallowEqual(prev, next) {
  if (prev === null || next === null || prev.length !== next.length) {
    return false;
  }

  const length = prev.length;
  for (let i = 0; i < length; i++) {
    if (prev[i] !== next[i]) {
      return false;
    }
  }

  return true;
}

export function memoize(func) {
  let prevArgs = null;
  let prevResult = null;
  return (...args) => {
    if (!shallowEqual(prevArgs, args)) {
      prevResult = func(...args);
    }
    prevArgs = args;
    return prevResult;
  };
}
