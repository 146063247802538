import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getApplicationMetaData } from '@innovatrix/selectors';

import { ACCEPTED_COOKIES, FETCH_APPLICATION_META_DATA, FETCH_USER_PROFILES, FETCH_PROJECT_TYPES, UPDATE_APPLICATION_CONFIGURATION } from '../constants';
import { updateProfileMutation, getApplicationMetaDataQuery, getUserProfilesQuery, getProjectTypesQuery, updateApplicationConfigurationMutation } from '../queries';
import { receiveApplicationMetaDataAction, errorApplicationMetaDataAction, updateProfileAction, receiveUserProfilesAction, errorUserProfilesAction, errorProjectTypesAction, receiveProjectTypesAction } from '../actions';
import { getUserId } from '../selectors/authSelectors';

import * as api from './_api';
import { fetchEntitiesSaga } from './_utils';

export default function* () {
  // TODO: refactor this to a graphql query
  yield takeEvery(FETCH_APPLICATION_META_DATA, function* fetchApplicationMetaData() {
    try {
      const userId = yield select(getUserId);
      const applicationMetaData = yield call(api.fetch, '_getApplicationMetaData', getApplicationMetaDataQuery, { variables: { userId } });
      if (!applicationMetaData.user.hasLoggedIn) {
        yield put(push('/welcome'));
      }
      yield put(receiveApplicationMetaDataAction({ data: applicationMetaData }));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to fetch the application meta data.' });
      yield put(errorApplicationMetaDataAction({ error: err }));
    }
  });

  yield takeEvery(FETCH_PROJECT_TYPES, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorProjectTypesAction,
    errorMessage: 'Failed to fetch project types.',
    keyword: '_getProjectTypes',
    query: getProjectTypesQuery,
    receiveAction: receiveProjectTypesAction,
  }));

  yield takeEvery(updateProfileAction.REQUEST, function* updateProfile({ payload, callback }) {
    try {
      const userId = yield select(getUserId);
      const newMetaData = yield call(api.fetch, '_updateProfile', updateProfileMutation, {
        variables: { profile: payload, userId },
      });
      callback();
      yield put(receiveApplicationMetaDataAction({ data: newMetaData }));
    }
    catch (err) {
      yield call(showErrorModal, err, { title: 'Failed to update the profile.' });
      callback();
    }
  });

  yield takeEvery(FETCH_USER_PROFILES, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorUserProfilesAction,
    errorMessage: 'Failed to fetch user profiles.',
    keyword: '_userProfiles',
    query: getUserProfilesQuery,
    receiveAction: receiveUserProfilesAction,
  }));

  yield takeEvery(ACCEPTED_COOKIES, function* acceptedCookies() {
    const metaData = yield select(getApplicationMetaData);
    yield put(receiveApplicationMetaDataAction({
      data: {
        ...metaData,
        user: {
          ...metaData.user,
          acceptedCookies: true,
        },
      },
    }));
  });

  yield takeEvery(UPDATE_APPLICATION_CONFIGURATION, function* updateApplicationMetaData({ callback, type, ...updatedApplicationMetaData }) {
    try {
      const metaData = yield select(getApplicationMetaData);
      yield put(receiveApplicationMetaDataAction({
        data: {
          ...metaData,
          configuration: {
            ...metaData.configuration,
            ...updatedApplicationMetaData,
          },
        },
      }));
      yield call(api.fetch, '_updateApplicationConfiguration', updateApplicationConfigurationMutation, {
        variables: {
          data: updatedApplicationMetaData,
        },
      });
      callback();
    }
    catch (err) {
      callback(err);
    }
  });
}
