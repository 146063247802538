import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimeIcon from '@innovatrix/icons/Time';
import { StringField } from '@innovatrix/common/fields/StringField';
import { PRIMARY, GREY_2 } from '@innovatrix/styles';
import { validateTimeInput } from '@innovatrix/utils/validateTimeInput';
import * as toast from '@innovatrix/utils/toast';

import { defaultFieldPropTypes } from './_constants';

const StyledStringField = styled(StringField)`
  > div > input {
    cursor: pointer;
  }
`;

function prefixWhenNeeded(input) {
  return input > 9 ? input : `0${input}`;
}

const placeholder = 'Add Time (12:00 - 13:00)';

const StartEndTimePicker = ({ end, start, date, disabled, showIcon, required, onChange }) => {
  const hasStart = Boolean(start instanceof moment);
  const bothDates = hasStart && Boolean(end instanceof moment);

  const value = bothDates
    ? `${prefixWhenNeeded(start.hours())}:${prefixWhenNeeded(start.minutes())} - ${prefixWhenNeeded(end.hours())}:${prefixWhenNeeded(end.minutes())}`
    : placeholder;

  const [inputValue, setInputValue] = React.useState(value);

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  // If the a date value change we modify the time date
  React.useEffect(() => {
    if (!date || !start || !end) {
      return;
    }
    const dateMonth = date.month();
    const dateYear = date.year();
    const dateDay = date.date();
    const datetimeObject = { year: dateYear, month: dateMonth, date: dateDay, second: 0, millisecond: 0 };
    const newStart = moment().set({ ...datetimeObject, hour: start.hour(), minute: start.minute() });
    const newEnd = moment().set({ ...datetimeObject, hour: end.hour(), minute: end.minute() });
    onChange('start', newStart);
    onChange('end', newEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const hasFormikIntegration = typeof onChange === 'function';

  const onBlur = (valueToValidate) => {
    const validation = validateTimeInput(valueToValidate);
    if (validation.valid) {
      if (hasFormikIntegration) {
        onChange('start', validation.data.momentStartTime);
        onChange('end', validation.data.momentEndTime);
      }
      else {
        console.warn('Missing Formik integration.');
      }
    }
    else {
      toast.error(validation.error);
      setInputValue(value);
    }
  };

  const onChangeCallback = (newValue) => {
    setInputValue(newValue);
  };

  return (
    <div>
      <StyledStringField
        blur={onBlur}
        disabled={disabled}
        fieldIcon={showIcon ? <TimeIcon height={12} width={12} color={disabled ? GREY_2 : PRIMARY} /> : undefined}
        label="Hour"
        onChange={onChangeCallback}
        placeholder={placeholder}
        required={required}
        value={inputValue}
      />
    </div>
  );
};

StartEndTimePicker.displayName = 'StartEndTimePicker';
StartEndTimePicker.defaultProps = { showIcon: true, required: false };

StartEndTimePicker.propTypes = {
  ...defaultFieldPropTypes,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.instanceOf(moment)]),
  end: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showIcon: PropTypes.bool,
  start: PropTypes.any,
};

export default React.memo(StartEndTimePicker);
