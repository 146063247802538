/*
 * Basic inline styles. Each property of this style set is a style declaration created with `glamor`.
 * See https://www.npmjs.com/package/glamor
 *
 * While you can import dxStyles elements where you want to use them, you also need to import
 * `react-frontend/styles/globals` once in your main application chunk, typically in `main.jsx`,
 * as follows `import 'react-frontend/styles/globals';`. Note that importing these globals multiple
 * times may unnecessarily include font-data (of non-trivial size) in multiple chunks.
 */

import { css } from 'glamor';

// -- Page & Section Styles --------------- --- --  -

export const paddedPageElementStyles = css({
  paddingLeft: '4.2%',
  paddingRight: '4.2%',
  '@media screen and (min-width: 680px)': {
    paddingLeft: 32,
    paddingRight: 32,
  },
});

export const sectionPadding = css(paddedPageElementStyles, { padding: 32 });

export const contentSection = css(sectionPadding, { background: 'white' });
