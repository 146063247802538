import sanitize from 'sanitize-filename';
import { normalizeDiacritics } from 'normalize-text';

// convert the constants data structure into one for SelectField options
export const optionsGenerator = (constOptions = []) => (
  Object.keys(constOptions).map((key) => {
    if (typeof constOptions[key] === 'object') {
      const nestedOptions = constOptions[key];
      return {
        label: key,
        options: Object.keys(nestedOptions).map((optionKey) => ({
          label: optionKey,
          value: nestedOptions[optionKey],
        })),
      };
    }
    return {
      label: key,
      value: constOptions[key],
    };
  })
);

export const sanitizeFile = (file) => {
  const sanitizedFileName = sanitize(file.name);
  const normalizedFileName = normalizeDiacritics(sanitizedFileName);
  return new File([file], normalizedFileName);
};
