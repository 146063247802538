import { queryBuilder, mutationBuilder } from '@innovatrix/utils/dx';

export const createEvaluationMutation = mutationBuilder({
  variables: {
    evaluation: { type: 'JSON!' },
    projectId: { type: 'String!' },
  },
  fields: {
    uploadEvaluationCsv: {
      varArgs: { evaluation: 'evaluation', projectId: 'projectId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const cloneEvaluationMutation = mutationBuilder({
  variables: {
    evaluationId: { type: 'String!' },
    projectId: { type: 'String!' },
  },
  fields: {
    cloneEvaluation: {
      varArgs: { evaluationId: 'evaluationId', projectId: 'projectId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateAnswerMutation = mutationBuilder({
  variables: {
    answer: { type: 'JSON!' },
    evaluationId: { type: 'String!' },
    evaluationRatingId: { type: 'String!' },
    questionId: { type: 'String!' },
  },
  fields: {
    _updateAnswer: {
      varArgs: {
        answer: 'answer',
        evaluationId: 'evaluationId',
        evaluationRatingId: 'evaluationRatingId',
        questionId: 'questionId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const fetchQuestionQuery = queryBuilder({
  variables: { questionId: { type: 'String!' } },
  fields: {
    _fetchQuestion: {
      varArgs: { questionId: 'questionId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const submitEvaluationMutation = mutationBuilder({
  variables: {
    evaluationId: { type: 'String!' },
  },
  fields: {
    submitEvaluation: {
      varArgs: { evaluationId: 'evaluationId' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const deleteEvaluationMutation = mutationBuilder({
  variables: {
    evaluationId: { type: 'String!' },
  },
  fields: {
    _deleteEvaluation: {
      varArgs: { evaluationId: 'evaluationId' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});
