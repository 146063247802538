import moment from 'moment';

export const defaultDisabledStart = [22, 23, 0, 1, 2, 3, 4, 5, 6];
export const defaultDisabledEnd = [1, 2, 3, 4, 5, 6];

const regExp = /^\d{0,2}?:?\d{0,2}$/;

// Input should be of the following format:
// 14:00 - 17:30
export const validateTimeInput = (valueToValidate) => {
  // Start and end time should be separated by a 'dash'
  const splittedValues = valueToValidate.split('-');
  // We need at least 2 entries (start AND end time)
  if (splittedValues.length === 2) {
    const [startTime, endTime] = splittedValues;
    const startTimeTrimmed = startTime.trim();
    const endTimeTrimmed = endTime.trim();
    if (!regExp.test(startTimeTrimmed) || !regExp.test(endTimeTrimmed)) {
      return { valid: false, error: 'Invalid format. Should be of format: 12:00 - 14:00.' };
    }

    const momentStartTime = moment(startTimeTrimmed, 'hh:mm');
    const momentEndTime = moment(endTimeTrimmed, 'hh:mm');
    // Test that the times are valid...
    if (!momentStartTime.isValid() || !momentEndTime.isValid()) {
      return { valid: false, error: 'Invalid time was supplied.' };
    }

    // ...and that the start time comes before the end time
    if (momentStartTime.isSameOrAfter(momentEndTime)) {
      return { valid: false, error: 'Starttime should be before endtime.' };
    }

    const startTimeHourValid = !defaultDisabledStart.includes(momentStartTime.get('hour'));
    const endTimeHourValid = !defaultDisabledEnd.includes(momentEndTime.get('hour'));
    if (!startTimeHourValid || !endTimeHourValid) {
      return { valid: false, error: 'Hours should be between 8 and 20.' };
    }

    return { valid: true, data: { momentStartTime, momentEndTime } };
  }
  else {
    return { valid: false, error: 'Invalid format. Should be of format: 12:00 - 14:00.' };
  }
};
