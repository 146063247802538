import { DxError } from '@innovatrix/utils/dx';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@innovatrix/common/buttons';

import { DxErrorDetails } from '../DxErrorDetails';

// -- Component --------------- --- --  -

/**
 * A modal that renders the details of the given error object.
 */
export class ErrorModal extends React.Component {

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  handleClose() {
    if (this.props.onClose) { this.props.onClose(); }
    this.props.removeModal();
  }

  render() {
    return (
      <div className="dx-modal dx-error-modal">
        <DxErrorDetails error={this.props.dxError || this.props.error} />
        <div className="buttons">
          <Button flavor="warning" onClick={this.handleClose} label="Close" />
        </div>
      </div>
    );
  }

}

ErrorModal.propTypes = {
  dxError: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(DxError),
    PropTypes.instanceOf(Error),
    PropTypes.object,
    PropTypes.string,
  ]),
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(DxError),
    PropTypes.instanceOf(Error),
    PropTypes.object,
    PropTypes.string,
  ]),
  onClose: PropTypes.func,
  removeModal: PropTypes.func.isRequired,
};
