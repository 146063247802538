import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Preview = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greyLight1};
  border: 1px solid ${({ theme }) => theme.colors.greyLight3};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: auto;
  margin-bottom: 8px;
  opacity: 1;
  padding: 8px 24px;
  transition: all 0.2s ease-in;
  width: 600px;
`;

const WorkshopTypePreview = ({ title, name }) => <Preview>{title || name}</Preview>;

WorkshopTypePreview.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
};

export default React.memo(WorkshopTypePreview);
