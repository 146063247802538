import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TurnIcon from '@innovatrix/icons/Turn';
import { FloatingActionButton } from '@innovatrix/common/buttons';
import { PROJECT_STATUSSES } from '@innovatrix/constants';
import { MenuBar, MenuBarItem } from '@innovatrix/components';
import { PRIMARY } from '@innovatrix/styles';

const Toolbar = styled.div`
  align-items: center;
  display: flex;
  margin-top: 16px;
  position: absolute;
  right: 0;
  z-index: 1;
`;

const MenuBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  > ul {
    width: 100%;
  }
`;

const MyProjectsMenuBar = ({
  isLoaded, setProjectStatus, projectStatus, turnCards,
}) => (
  <MenuBarWrapper>
    <MenuBar>
      {PROJECT_STATUSSES.map(({ label, value }) => (
        <MenuBarItem
          className={projectStatus === value ? 'selected' : null}
          eventKey={value}
          key={value}
          name={label}
          onClick={() => setProjectStatus(value)}
        />
      ))}
    </MenuBar>
    <Toolbar>
      <FloatingActionButton onClick={turnCards} tooltip="Turn all Cards" flavor="secondary" disabled={!isLoaded}>
        <TurnIcon color={PRIMARY} />
      </FloatingActionButton>
    </Toolbar>
  </MenuBarWrapper>
);

MyProjectsMenuBar.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  projectStatus: PropTypes.string,
  setProjectStatus: PropTypes.func,
  turnCards: PropTypes.func,
};

export default MyProjectsMenuBar;
