import {
  CLOSE_MODAL,
  OPEN_MODAL,
} from '../constants';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_MODAL: {
      const newState = { ...state };
      Reflect.deleteProperty(newState, action.id);
      return newState;
    }
    case OPEN_MODAL: {
      return { ...state, [action.id]: true };
    }
    default:
      return state;
  }
};
