import { createSelector } from 'reselect';

import { config as dxConfig } from '../config';

// -- Config Selectors --------------- --- --  -

const providerConfigMap = dxConfig.get('duxis.auth.identityProviders');

export const getProviderConfig = (name) => {
  const config = providerConfigMap[name];
  if (config) { return config; }
  throw new Error(`There is no '${name}' provider config.`);
};

export const getEnabledProviderNames = () => Object.keys(providerConfigMap).filter((name) => providerConfigMap[name].enable);

// -- State Selectors --------------- --- --  -

export const getDxAuthState = (state) => state.dxAuth;

export const getDxAuthExpired = (state) => !getDxAuthState(state).authenticated ||
    (getDxAuthState(state).expiresAt < (new Date().getTime() / 1000));

export const getDxAuthenticated = (state, checkExpiration = true) => {
  const authState = getDxAuthState(state);
  const expired = checkExpiration ? authState.expiresAt <= (new Date().getTime() / 1000) : false;
  return authState.authenticated && !expired;
};

export const getDxAuthError = (state) => getDxAuthState(state).dxError;
export const getDxAuthFailed = (state) => getDxAuthState(state).failed;
export const getDxAuthProviderId = (state) => getDxAuthState(state).providerId;
export const getDxAuthProviderState = (state) => getDxAuthState(state).providerState;
export const getDxAuthExpiresAt = (state) => (getDxAuthenticated(state) ? getDxAuthState(state).expiresAt : null);
export const getDxAuthTargetPath = (state) => getDxAuthState(state).targetPath || '/';

/**
 * Gets the current user object.
 *
 * When you inject a user object as a prop-type in a React component, you can use the
 * {@link userPropType} as prop-type specification.
 * @see userPropType
 * @param {object} state - The Redux state.
 * @returns {object} The current user identity object.
 */
export const getDxAuthUser = (state) => getDxAuthState(state).user;

/**
 * @param {object} state
 * @returns {string} The dxToken (provided by the dxAuth service).
 */
export const getDxAuthToken = (state) => {
  if (!getDxAuthenticated(state)) {
    const error = new Error('The current user is not (yet) authenticated.');
    error.status = 401;
    throw error;
  }
  return getDxAuthUser(state).dxToken;
};

// -- Helper Selectors --------------- --- --  -
export const getActivities = createSelector(
  getDxAuthUser,
  (user) => (user ? user.genericRights : []),
);

export const isAuthorized = (state, activity) => {
  const activities = getActivities(state);
  return activities.includes(activity);
};

const getAuthorizedAux = createSelector(
  (data) => getDxAuthUser(data[0]),
  (data) => data[1],
  (user, authConstraints) => isAuthorized(user, authConstraints),
);

export const getAuthorized = (state, authConstraints) => getAuthorizedAux([state, authConstraints]);

export const getScopedRights = createSelector(
  getDxAuthUser,
  (user) => (user ? user.scopedRights : null),
);

export const getUserId = createSelector(
  getDxAuthUser,
  (user) => (user && user.id),
);

export const isAuthorizedForScope = (state, activity, scope, topic) => {
  if (isAuthorized(state, activity)) {
    return true;
  }
  const scopedRights = getScopedRights(state);
  if (!scopedRights) { return false; }
  // getScopedRights can return an empty object, so we need to do an
  // additional check so this does not crash
  if (typeof scopedRights === 'object') {
    if (Object.keys(scopedRights).length === 0) { return false; }
  }
  const activities = [
    ...(scopedRights[scope].default || []),
    ...(scopedRights[scope][topic] || []),
  ];
  return activities.includes(activity);
};

export const hasRoleClient = (state) => {
  const scopedRights = getScopedRights(state);
  if (!scopedRights || !scopedRights.projects) { return false; }
  // Non clients only have the default, no specific projects.
  return Object.keys(scopedRights.projects).length > 1;
};
