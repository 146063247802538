import {
  ERROR_ACTIVE_ASSESSMENT_VERSION,
  ERROR_ASSESSMENT_VERSION,
  ERROR_ASSESSMENT_VERSIONS,
  FETCH_ACTIVE_ASSESSMENT_VERSION,
  FETCH_ASSESSMENT_VERSION,
  FETCH_ASSESSMENT_VERSIONS,
  PERSIST_ASSESSMENT_VERSION,
  PERSIST_SURVEY_2,
  PERSIST_SURVEY,
  RECEIVE_ACTIVE_ASSESSMENT_VERSION,
  RECEIVE_ASSESSMENT_VERSION,
  RECEIVE_ASSESSMENT_VERSIONS,
} from '../constants';

import { createFormikAction } from './utils';

export const persistAssessmentVersionAction = createFormikAction(PERSIST_ASSESSMENT_VERSION);

export const persistSurveyAction = createFormikAction(PERSIST_SURVEY);

export const fetchAssessmentVersionAction = ({ assessmentVersionId }) => ({
  assessmentVersionId,
  type: FETCH_ASSESSMENT_VERSION,
});

export const errorAssessmentVersionAction = ({ assessmentVersionId, error }) => ({
  assessmentVersionId,
  error,
  type: ERROR_ASSESSMENT_VERSION,
});

export const receiveAssessmentVersionAction = ({ assessmentVersionId, data }) => ({
  assessmentVersionId,
  data,
  type: RECEIVE_ASSESSMENT_VERSION,
});

export const persistSurveyAction2 = (props) => ({
  ...props,
  type: PERSIST_SURVEY_2,
});

export const fetchActiveAssessmentVersionAction = ({ projectId }) => ({
  projectId,
  type: FETCH_ACTIVE_ASSESSMENT_VERSION,
});

export const errorActiveAssessmentVersionAction = ({ error }) => ({
  error,
  type: ERROR_ACTIVE_ASSESSMENT_VERSION,
});

export const receiveActiveAssessmentVersionAction = ({ data }) => ({
  data,
  type: RECEIVE_ACTIVE_ASSESSMENT_VERSION,
});

export const fetchAssessmentVersionsAction = ({ assessmentId }) => ({
  assessmentId,
  type: FETCH_ASSESSMENT_VERSIONS,
});

export const receiveAssessmentVersionsAction = ({ assessmentId, data }) => ({
  assessmentId,
  data,
  type: RECEIVE_ASSESSMENT_VERSIONS,
});

export const errorAssessmentVersionsAction = ({ assessmentId, error }) => ({
  assessmentId,
  error,
  type: ERROR_ASSESSMENT_VERSIONS,
});
