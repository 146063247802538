import { ERROR_GEO_SUGGESTIONS, FETCH_GEO_SUGGESTIONS, RECEIVE_GEO_SUGGESTIONS } from '../constants';

export const fetchGeoSuggestionsAction = (search) => ({
  search,
  type: FETCH_GEO_SUGGESTIONS,
});

export const errorGeoSuggestionsAction = (error) => ({
  error,
  type: ERROR_GEO_SUGGESTIONS,
});

export const receiveGeoSuggestionsAction = (result) => ({
  result,
  type: RECEIVE_GEO_SUGGESTIONS,
});
