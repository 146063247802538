import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const DuplicateIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 19 22" {...styles} {...props}>
    <path d="M17 20V6H6v14h11zm0-16c.5 0 1 .2 1.4.6s.6.8.6 1.4v14c0 .5-.2 1-.6 1.4-.4.4-.9.6-1.4.6H6c-.5 0-1-.2-1.4-.6-.4-.4-.6-.9-.6-1.4V6c0-.5.2-1 .6-1.4C5 4.2 5.5 4 6 4h11zm-3-4v2H2v14H0V2C0 1.5.2 1 .6.6S1.4 0 2 0h12z" />
  </svg>
);

DuplicateIcon.propTypes = propTypes;
DuplicateIcon.defaultProps = defaultProps;

export default DuplicateIcon;
