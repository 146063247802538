import { ORDER_VERTICAL, ORDER_ALPHABETICALLY, ORDER_DECISIONS, ORDER_RATINGS_ASC, ORDER_RATINGS_DESC } from '../constants';

export const sortCards = (cards, order) => {
  if (!cards) {
    return null;
  }
  switch (order) {
    case ORDER_VERTICAL:
      return cards.sort((verticalA, verticalB) => {
        if (verticalA.project.majorDomain.toLowerCase() < verticalB.project.majorDomain.toLowerCase()) { return -1; }
        if (verticalA.project.majorDomain.toLowerCase() > verticalB.project.majorDomain.toLowerCase()) { return 1; }
        return 0;
      });
    case ORDER_ALPHABETICALLY:
      return cards.sort((cardA, cardB) => {
        if (cardA.project.label.toLowerCase() < cardB.project.label.toLowerCase()) { return -1; }
        if (cardA.project.label.toLowerCase() > cardB.project.label.toLowerCase()) { return 1; }
        return 0;
      });
    case ORDER_DECISIONS:
      return cards.sort((cardA, cardB) => {
        if (cardA.project.decision < cardB.project.decision) { return -1; }
        if (cardA.project.decision > cardB.project.decision) { return 1; }
        return 0;
      });
    case ORDER_RATINGS_ASC:
      return cards.sort((cardA, cardB) => cardA.project.average - cardB.project.average);
    case ORDER_RATINGS_DESC:
      return cards.sort((cardA, cardB) => cardB.project.average - cardA.project.average);
    default:
      throw new Error(`Unknown cards order: '${order}'.`);
  }
};

export const sortProjects = (projects, sortOrder) => {
  const clonedProjects = [].concat(projects);
  switch (sortOrder) {
    case ORDER_VERTICAL:
      return clonedProjects.sort((project1, project2) => {
        if (project1.majorDomain.toLowerCase() < project2.majorDomain.toLowerCase()) { return -1; }
        if (project1.majorDomain.toLowerCase() > project2.majorDomain.toLowerCase()) { return 1; }
        return 0;
      });
    case ORDER_ALPHABETICALLY:
      return clonedProjects.sort((project1, project2) => {
        if (project1.label.toLowerCase() < project2.label.toLowerCase()) { return -1; }
        if (project1.label.toLowerCase() > project2.label.toLowerCase()) { return 1; }
        return 0;
      });
    case ORDER_DECISIONS:
      return clonedProjects.sort((project1, project2) => {
        if (project1.decision < project2.decision) { return -1; }
        if (project1.decision > project2.decision) { return 1; }
        return 0;
      });
    case ORDER_RATINGS_ASC:
      return clonedProjects.sort((project1, project2) => project1.average - project2.average);
    case ORDER_RATINGS_DESC:
      return clonedProjects.sort((project1, project2) => project2.average - project1.average);
    default:
      throw new Error(`Unknown cards order: '${sortOrder}'.`);
  }
};
