import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import DeleteIcon from '@innovatrix/icons/DeleteIcon';
import EditIcon from '@innovatrix/icons/EditIcon';
import CheckIcon from '@innovatrix/icons/CheckIcon';
import { ARCHIVED, DRAFT, IN_USE, PUBLISHED } from '@innovatrix/constants';

import { IconButton } from '../buttons';
import { Paragraph } from '../text';

import StringField from './StringField';
import { ALLOWED_FLAVORS, PRIMARY, WARNING, OUTLINE, SUCCESS } from './_constants';

const STATUS_FLAVOURS = [DRAFT, IN_USE, PUBLISHED, ARCHIVED];

const TagContainer = styled.span`
    width: fit-content;
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: ${({ theme }) => theme.border.radius};
    ${({ outlineColor, flavor, theme }) => {
    switch (flavor) {
      case PRIMARY: return `
        border: 1px solid ${theme.colors.primary};
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        'svg:first-of-type': { fill: ${theme.colors.white}; }
      `;
      case OUTLINE: return `
        border: 1px solid ${outlineColor || 'unset'};
        background-color: ${theme.colors.white};
        color: ${outlineColor || 'unset'};
        'svg:first-of-type': { fill: ${outlineColor}; }
      `;
      case WARNING: return `
        border: 1px solid ${theme.colors.warning};
        background-color: ${theme.colors.reviewNoGoBackground};
        color: ${theme.colors.secondary};
        'svg:first-of-type': { fill: ${theme.colors.white}; }
      `;
      case SUCCESS: return `
        border: 1px solid ${theme.colors.green};
        background-color: ${theme.colors.reviewGoBackground};
        color: ${theme.colors.secondary};
        'svg:first-of-type': { fill: ${theme.colors.white}; }
      `;
      case DRAFT: return `
        color: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.grey1};
        background-color: ${theme.colors.white};
        svg:first-of-type: { fill: ${theme.colors.secondary}; }
      `;
      case PUBLISHED: return `
        color: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.primary};
        background-color: ${theme.colors.white};
        svg:first-of-type: { fill: ${theme.colors.secondary}; }
      `;
      case ARCHIVED: return `
        color: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.grey1};
        background-color: ${theme.colors.greyLight1};
        svg:first-of-type: { fill: ${theme.colors.secondary}; }
      `;
      case IN_USE: return `
        color: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.primary};
        background-color: ${theme.colors.primaryLightest};
        svg:first-of-type: { fill: ${theme.colors.secondary}; }
      `;
      default: return `
        border: 1px solid ${theme.colors.grey1};
        background-color: ${theme.colors.white};
        color: ${theme.colors.secondary};
        'svg:first-of-type': {
          fill: ${theme.colors.secondary};
        }
      `;
    }
  }};
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 0;
  color: ${({ outlineColor, flavor, theme }) => {
    switch (flavor) {
      case OUTLINE: return outlineColor || 'unset';
      case PRIMARY:
        return theme.colors.white;
      case WARNING:
      case SUCCESS:
      default: return theme.colors.secondary;
    }
  }};
`;

const StyledStringField = styled(StringField)`
  margin: 0;
  > div > input, > div > input {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.transparent};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ outlineColor, flavor, theme }) => {
    switch (flavor) {
      case PRIMARY: return theme.colors.white;
      case OUTLINE: return outlineColor || 'unset';
      default: return theme.colors.secondary;
    }
  }} !important;
}
`;

const InteractionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 8px;

  button:hover {
    background-color: transparent;
  }
`;

const TagField = ({ className, disabled, fieldId, flavor, label, onDelete, onEdit, outlineColor, showActions }) => {
  const handleDelete = React.useCallback((e) => {
    e.preventDefault();
    onDelete();
  }, [onDelete]);

  const handleEdit = React.useCallback((e) => {
    e.preventDefault();
    onEdit();
  }, [onEdit]);

  return (
    <TagContainer className={className} outlineColor={outlineColor} flavor={flavor}>
      {!showActions && <StyledParagraph outlineColor={outlineColor} flavor={flavor}>{label}</StyledParagraph>}
      {showActions && onEdit && onDelete &&
        <React.Fragment>
          <StyledStringField fieldId={fieldId} size="body" disabled={disabled} outlineColor={outlineColor} flavor={flavor} />
          <InteractionsContainer>
            {disabled ?
              (
                <React.Fragment>
                  <IconButton icon={<EditIcon />} onClick={handleEdit} small />
                  <IconButton icon={<DeleteIcon />} onClick={handleDelete} small />
                </React.Fragment>
              ) : (
                <IconButton icon={<CheckIcon />} onClick={handleEdit} small />
              )}
          </InteractionsContainer>
        </React.Fragment>}
    </TagContainer>
  );
};

TagField.defaultProps = { disabled: true };
TagField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  flavor: PropTypes.oneOf([...ALLOWED_FLAVORS, ...STATUS_FLAVOURS]),
  label: PropTypes.node,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  outlineColor: PropTypes.string,
  showActions: PropTypes.bool,
};

export default TagField;
