import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import * as toast from '@innovatrix/utils/toast';

import {
  errorCampaignsAction,
  receiveCampaignAction,
  receiveCampaignsAction,
  receiveSettingsAction,
  errorUpdateCampaignAction,
  fetchCampaignsAction,
  errorCampaignAction,
} from '../actions';
import { CAMPAIGNS_CONTEXT, FETCH_CAMPAIGN, FETCH_CAMPAIGNS, UPDATE_CAMPAIGN, SHOW_PREDICTIONS, DOWNLOAD_CAMPAIGNS_EXPORT } from '../constants';
import { getCampaignsQuery, updateCampaignMutation, getCampaignQuery } from '../queries';
import { togglePredictionsAction } from '../modules/projects/cards/_actions'; // TODO: TEMP!
import { getDxAuthToken } from '../selectors/authSelectors';
import { config } from '../config';

import * as api from './_api';

const BASE_URL = config.get('innovatrix.hosts.dxApi');

export default function* () {
  yield takeEvery(FETCH_CAMPAIGNS, function* fetchCampaigns() {
    try {
      const context = CAMPAIGNS_CONTEXT;
      const { campaigns, lastProcess, lastSync } = yield call(api.fetch, '_getCampaigns', getCampaignsQuery, { context });
      yield put(receiveSettingsAction({ lastProcess, lastSync }));
      yield put(receiveCampaignsAction({ data: campaigns }));
    }
    catch (err) {
      yield put(errorCampaignsAction({ error: err }));
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to fetch the campaigns.' });
    }
  });

  yield takeEvery(UPDATE_CAMPAIGN, function* updateCampaign({ callback, ...variables }) {
    try {
      const result = yield call(api.fetch, '_updateCampaign', updateCampaignMutation, { variables });
      yield put(receiveCampaignAction({ campaignId: variables.campaignId, data: result }));
      yield put(togglePredictionsAction(SHOW_PREDICTIONS));
      yield put(fetchCampaignsAction());
      callback();
    }
    catch (err) {
      yield put(errorUpdateCampaignAction({ error: err }));
      callback(err);
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to update the campaign.' });
    }
  });

  yield takeEvery(FETCH_CAMPAIGN, function* fetchCampaign({ campaignId }) {
    try {
      const context = CAMPAIGNS_CONTEXT;
      const variables = { campaignId };
      const result = yield call(api.fetch, '_getCampaign', getCampaignQuery, { context, variables });
      yield put(receiveCampaignAction({ campaignId, data: result }));
    }
    catch (err) {
      yield put(errorCampaignAction({ campaignId, error: err }));
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to fetch the evaluations.' });
    }
  });

  yield takeEvery(DOWNLOAD_CAMPAIGNS_EXPORT, function* downloadCampaignsExport({ id, name }) {
    let toastId;
    try {
      const baseUrl = BASE_URL.replace('/graphql', '');
      const authToken = yield select(getDxAuthToken);
      const fileName = `campaign-${name}-export.csv`;
      toastId = toast.loading('Downloading...');
      const { data } = yield axios({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'POST',
        responseType: 'json',
        url: `${baseUrl}/campaigns/${id}/export`,
      });
      yield api.downloadCsvFile(data, fileName);
      toast.dismiss(toastId);
      toast.success('Download successful!');
    }
    catch (e) {
      toast.dismiss(toastId);
      toast.error('Failed to download the export.');
      console.error(e);
    }
  });
}
