import React from 'react';
import ListItem from '@innovatrix/common/lists/ListItem';
import DocumentIcon from '@innovatrix/icons/documentTypes/DocumentIcon';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Preview = styled(ListItem)`
  display: inline-flex;
  padding-right: 10rem;
`;

const DocumentPreview = ({
  name, text, type,
}) => (
  <Preview
    text={text}
    iconButtonsLeft={<DocumentIcon fileName={name} type={type} />}
  />
);

DocumentPreview.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default DocumentPreview;
