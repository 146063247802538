import React from 'react';
import { Chapter, ChapterHeader, Module, Section } from '@innovatrix/components';
import { Title } from '@innovatrix/common/text';
import styled from '@emotion/styled';

const NotFoundTitle = styled(Title)`
  padding: 100px 0;
  text-align: center;
`;

// -- Component --------------- --- --  -

const NotFound = () => (
  <Chapter>
    <ChapterHeader title="Not Found" />
    <Module>
      <Section>
        <NotFoundTitle size="h1">
          There is nothing here...
        </NotFoundTitle>
      </Section>
    </Module>
  </Chapter>
);

export default React.memo(NotFound);
