import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, takeEvery } from 'redux-saga/effects';

import { openModalAction, closeModalAction, fetchProjectBoardsAction } from '../actions';
import { persistAssumptionCommentAction, persistCriterionAction, undoArchiveSegmentErrorAction, persistAssumptionAction, errorAssumptionCommentsAction, receiveAssumptionCommentsAction, fetchAssumptionCommentsAction, persistInnoboardCriterionCommentAction, fetchCriterionCommentsAction, errorCriterionCommentsAction, receiveCriterionCommentsAction } from '../actions/innoBoardActions';
import {
  DELETE_ASSUMPTION,
  FETCH_ASSUMPTION_COMMENTS,
  FETCH_CRITERION_COMMENTS,
  UNDO_ARCHIVE_SEGMENT,
} from '../constants';
import { createAssumptionCommentMutation, updateAssumptionCommentMutation, createInnoBoardCriterionMutation, updateInnoBoardCriterionMutation, updateAssumptionMutation, undoArchiveSegmentMutation, createAssumptionMutation, deleteAssumptionMutation, getAssumptionCommentsQuery, getInnoboardCriterionCommentsQuery, updateInnoboardCriterionCommentMutation, createInnoboardCriterionCommentMutation } from '../queries';

import * as api from './_api';
import { fetchEntitiesSaga, mutationSaga, formMutationSaga } from './_utils';

/**
 * @name fetchInnoBoardsSaga
 */
export default function* () {
  yield takeEvery(FETCH_CRITERION_COMMENTS, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorCriterionCommentsAction,
    errorMessage: 'Failed to fetch comments.',
    keyword: 'innoboardCriterionComments',
    query: getInnoboardCriterionCommentsQuery,
    receiveAction: receiveCriterionCommentsAction,
  }));

  yield takeEvery(persistInnoboardCriterionCommentAction.REQUEST, formMutationSaga.bind(undefined, {
    * after({ innoboardCriterionId }) { yield put(fetchCriterionCommentsAction({ innoboardCriterionId })); },
    errorMessage: 'Failed to place comment.',
    keyword: ({ id }) => (id ? '_updateInnoboardCriterionComment' : '_createInnoboardCriterionComment'),
    mutation: ({ id }) => (id ? updateInnoboardCriterionCommentMutation : createInnoboardCriterionCommentMutation),
  }));

  yield takeEvery(FETCH_ASSUMPTION_COMMENTS, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorAssumptionCommentsAction,
    errorMessage: 'Failed to fetch comments.',
    keyword: 'assumptionComments',
    query: getAssumptionCommentsQuery,
    receiveAction: receiveAssumptionCommentsAction,
  }));

  yield takeEvery(persistAssumptionCommentAction.REQUEST, formMutationSaga.bind(undefined, {
    * after({ assumptionId }) { yield put(fetchAssumptionCommentsAction({ assumptionId })); },
    errorMessage: 'Failed to place comment.',
    keyword: ({ id }) => (id ? '_updateAssumptionComment' : '_createAssumptionComment'),
    mutation: ({ id }) => (id ? updateAssumptionCommentMutation : createAssumptionCommentMutation),
  }));

  yield takeEvery(persistCriterionAction.REQUEST, function* persistInnoBoardCriterion({ callback, payload: { assumptions, criterionId, description, deletedTemplateAssumptionIds, innoBoardId, innoBoardPhaseId, innoBoardCriterionId, order, projectId, title } }) {
    try {
      const filteredAssumptions = assumptions.filter(({ title: t }) => t);
      filteredAssumptions.forEach((a, i) => {
        const relatedAssumptionIds = a.relatedAssumptions.map(r => r.relatedAssumptionId).filter(r => r);
        filteredAssumptions[i] = {
          ...a,
          relatedAssumptionIds,
        };
        Reflect.deleteProperty(filteredAssumptions[i], 'relatedAssumptions');
      });

      let variables = {
        projectId,
        innoBoardCriterion: {
          assumptions: filteredAssumptions,
          deletedTemplateAssumptionIds,
          description,
          order,
          title,
        },
      };

      if (innoBoardCriterionId) {
        variables = { ...variables, innoBoardCriterionId };
      }
      else {
        variables = { ...variables, innoBoardId, innoBoardPhaseId, criterionId };
      }
      const mutation = innoBoardCriterionId ? updateInnoBoardCriterionMutation : createInnoBoardCriterionMutation;
      const keyword = innoBoardCriterionId ? '_updateInnoBoardCriterion' : '_createInnoBoardCriterion';
      yield call(api.fetch, keyword, mutation, { variables });
      callback();
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to save the changes.' });
      callback(err);
    }
  });

  function* ensureCriteria({ criterionId, criterionTitle, isSegment, segmentCriterionId, innoBoardId, innoBoardPhaseId, innoBoardCriterionId, innoBoardSegmentId, segmentTitle, order, projectId }) {
    // First we need to ensure there is a segment.
    if (!innoBoardSegmentId) {
      const newInnoBoardSegment = yield call(api.fetch, '_createInnoBoardCriterion', createInnoBoardCriterionMutation, {
        variables: {
          criterionId: segmentCriterionId,
          projectId,
          innoBoardCriterion: {
            assumptions: [],
            order,
            title: segmentTitle,
          },
          innoBoardId,
          innoBoardPhaseId,
        },
      });
      if (isSegment) {
        return newInnoBoardSegment.id;
      }
    }

    if (!innoBoardCriterionId) {
      // Second we need to ensure there is a criteria.
      const newInnoBoardCriterion = yield call(api.fetch, '_createInnoBoardCriterion', createInnoBoardCriterionMutation, {
        variables: {
          criterionId,
          projectId,
          innoBoardCriterion: {
            assumptions: [],
            order,
            title: criterionTitle,
          },
          innoBoardId,
          innoBoardPhaseId,
        },
      });
      return newInnoBoardCriterion.id;
    }
    return innoBoardCriterionId;
  }

  yield takeEvery(persistAssumptionAction.REQUEST, function* persistAssumption({
    payload: {
      projectId, duplicatedFrom, duplicateGroupId, hasStatusEvolved, prevStatus,
      innoBoardCriterionId, createdOn, relatedAssumptions, criterionId, innoBoardId,
      isSegment, criterionTitle, critOrder, innoCritTitle,
      ...payload
    },
    callback,
  }) {
    try {
      let shouldOpenModal;
      if (!innoBoardCriterionId) { shouldOpenModal = true; }
      innoBoardCriterionId = yield ensureCriteria({ innoBoardSegmentId: 'notNeeded', criterionTitle: innoCritTitle || criterionTitle, projectId, innoBoardCriterionId, criterionId, innoBoardId, order: critOrder });
      const variables = { assumption: { ...payload, criterionId: innoBoardCriterionId }, innoBoardCriterionId, projectId };
      const keyword = payload.id ? '_updateAssumption' : '_createAssumption';
      const mutation = payload.id ? updateAssumptionMutation : createAssumptionMutation;
      yield call(api.fetch, keyword, mutation, { variables });
      callback();
      if (shouldOpenModal) {
        yield put(closeModalAction(`creatingCriterion-${criterionId}${critOrder}`));
        yield put(openModalAction(`editingCriterion-${innoBoardCriterionId}`));
      }
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to save the assumption.' });
      callback(err);
    }
  });

  yield takeEvery(DELETE_ASSUMPTION, mutationSaga.bind(undefined, {
    * after({ projectId }) { yield put(fetchProjectBoardsAction({ projectId })); },
    errorMessage: 'Failed to delete the assumption.',
    keyword: '_deleteAssumption',
    mutation: deleteAssumptionMutation,
  }));

  yield takeEvery(UNDO_ARCHIVE_SEGMENT, function* undoArchiveSegment({ innoBoardId, innoBoardPhaseId, segmentId }) {
    try {
      const variables = { innoBoardId, segmentId, innoBoardPhaseId };
      yield call(api.fetch, '_undoArchiveSegment', undoArchiveSegmentMutation, { variables });
    }
    catch (error) {
      if (error.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, error, { title: 'Failed to unarchive the segment.' });
      yield put(undoArchiveSegmentErrorAction({ error, innoBoardId, segmentId }));
    }
  });
}
