import React from 'react';
import PropTypes from 'prop-types';
import { StringField } from '@innovatrix/common/fields/StringField';
import styled from '@emotion/styled';

import { DragDropHandlerDisabled } from '../_styles';

const SectionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.greyBlueish};
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  border-radius: 3px;
  max-width: 500px;
  padding: 8px;
`;

const SectionDetails = styled.div`
  align-items: center;
  display: flex;
`;

const SectionDragPreview = ({ title }) => (
  <SectionContainer>
    <SectionDetails>
      <DragDropHandlerDisabled />
      <StringField
        disabled
        placeholder="Section title"
        readMode
        size="body"
        value={title}
      />
    </SectionDetails>
  </SectionContainer>
);

SectionDragPreview.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionDragPreview;
