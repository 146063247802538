const NOT_APPLICABLE = 'Not Applicable';
const NOT_APPLICABLE_OLD = 'Not applicable';
const DONT_KNOW = 'Don’t Know';
const DONT_KNOW_OLD = 'Don’t know';
const DONT_KNOW_WINDOWS = "Don't Know";
const DONT_KNOW_WINDOWS_OLD = "Don't know";

const DONT_KNOW_FIELDS = [
  DONT_KNOW,
  DONT_KNOW_OLD,
  DONT_KNOW_WINDOWS,
  DONT_KNOW_WINDOWS_OLD,
];
const NOT_APPLICABLE_FIELDS = [
  NOT_APPLICABLE,
  NOT_APPLICABLE_OLD,
];
const VERSIONED_COLUMNS = [
  'corresponding criteria',
  "criterion'",
];

export function process(rows) {
  const ratings = [];
  // Last visited domain
  let lastQuestionGroup;
  // Index off lst level
  let lastLevelCount;
  // Is this an old model
  let oldModel;
  // Skip header row.
  let startRow;
  // Are we versioning?
  let versioning;

  if (rows[1][1] === 'Domain') {
    oldModel = true;
    const [number, dom, crit, desc, clar, levText, ...rest] = rows[1]; // eslint-disable-line
    lastLevelCount = rest.length;
    startRow = 2;
  }
  else if (rows[0][1] === 'Domain') {
    oldModel = true;
    const [number, dom, crit, desc, clar, levText, ...rest] = rows[0]; // eslint-disable-line
    lastLevelCount = rest.length;
    startRow = 1;
  }
  else {
    oldModel = false;
    startRow = 1;
  }

  // Check if versioning for both csv types
  if (
    VERSIONED_COLUMNS.includes(rows[0][2].toLowerCase()) ||
    VERSIONED_COLUMNS.includes(rows[1][3].toLowerCase()) ||
    VERSIONED_COLUMNS.includes(rows[0][3].toLowerCase())
  ) {
    versioning = true;
  }

  for (let i = startRow; i < rows.length; i++) {
    const row = rows[i];
    try {
      let nr;
      let question;
      let description;
      let clarification;
      let levelText;
      let levelDescriptionsAndComment;
      let versionedName;

      if (oldModel) {
        // Format old model
        if (versioning) {
          [nr, lastQuestionGroup, question, versionedName, description, clarification, levelText, ...levelDescriptionsAndComment] = row;
        }
        else {
          [nr, lastQuestionGroup, question, description, clarification, levelText, ...levelDescriptionsAndComment] = row;
        }
      }
      else if (versioning) {
        [nr, question, versionedName, description, clarification, levelText, ...levelDescriptionsAndComment] = row;
      }
      else {
        [nr, question, description, clarification, levelText, ...levelDescriptionsAndComment] = row;
      }

      if (question === 'Technology fit' && lastQuestionGroup === 'Program fit') {
        continue;
      }
      // This is a rating for a question.
      if (nr.length > 0) {
        if (!lastLevelCount) {
          // Skip!
          continue;
        }
        if (!lastQuestionGroup) {
          throw Error('No Domain specified.');
        }

        if (!question) {
          throw Error('Invalid Criterium.');
        }
        if (!levelText) {
          throw Error('Invalid Level.');
        }
        let levelMatches;
        let level;
        if (NOT_APPLICABLE_FIELDS.includes(levelText)) {
          level = -1;
        }
        else if (DONT_KNOW_FIELDS.includes(levelText)) {
          level = -2;
        }
        else {
          levelMatches = levelText.match(/\d+/);
          if (levelMatches.length !== 1) {
            throw Error(`Invalid Level "${levelText}".`);
          }
          level = parseInt(levelMatches[0], 10);
        }

        if (level < -2 || lastLevelCount - 1 < level) {
          throw Error(`Invalid Level "${levelText}".`);
        }

        // Last one is a comment.
        const levelDescription = levelDescriptionsAndComment[level];
        const levels = [
          { level: -2, description: DONT_KNOW },
          { level: -1, description: NOT_APPLICABLE },
          { level: 0, description: levelDescriptionsAndComment[0] },
          { level: 1, description: levelDescriptionsAndComment[1] },
          { level: 2, description: levelDescriptionsAndComment[2] },
          { level: 3, description: levelDescriptionsAndComment[3] },
          { level: 4, description: levelDescriptionsAndComment[4] },
          { level: 5, description: levelDescriptionsAndComment[5] },
          { level: 6, description: levelDescriptionsAndComment[6] },
        ];
        const comments = levelDescriptionsAndComment[lastLevelCount];

        ratings.push({
          clarification,
          comments,
          question,
          description,
          questionGroup: lastQuestionGroup,
          level,
          levelDescription,
          levels,
          order: nr,
          versionedName,
        });
      }
      else {
        // This is a header row with domain as title.
        if (!question) {
          continue;
        }

        lastQuestionGroup = question;
        lastLevelCount = null;
        // After the Levels there is a comment. Count how many levels there are for this domain.
        for (let j = 0; j < levelDescriptionsAndComment.length; j++) {
          if (levelDescriptionsAndComment[j].indexOf('Level') === -1) {
            lastLevelCount = j;
            break;
          }
        }
      }
    }
    catch (error) {
      throw Error(`Error on row ${i}. ${error.message}`);
    }
  }
  return ratings;
}
