import { DxError } from '@innovatrix/utils/dx';
import { uuid, isString } from '@innovatrix/utils';

import { ErrorModal } from '../components/modals/ErrorModal';
import { DX_MODAL_ADD, DX_MODAL_CLEAR, DX_MODAL_REMOVE } from '../constants';

/**
 * Add a modal window.
 * @param {Function} component
 * @param {DxModalOptions|string} [options]
 * @param {string} [id] - Optional ID to use, useful if you later wish to remove the modal window in
 *   response to some event.
 * @return {{ type, component, id, options }}
 */
export const addModalAction = (component, options = {}, id) => {
  if (isString(options)) {
    id = options;
    options = {};
  }
  return { type: DX_MODAL_ADD, component, id: id || uuid(), options };
};

/**
 * Remove all modal windows.
 * @return {{ type }}
 */
export const clearModalsAction = () => ({ type: DX_MODAL_CLEAR });

/**
 * Remove the given modal window.
 * @param {string} id - The ID of the modal window to remove.
 * @return {{ type, id }}
 */
export const removeModalAction = (id) => ({ type: DX_MODAL_REMOVE, id });

/**
 * Shows an error modal and returns when the modal is closed.
 * @param {DxErrorArgument} error - See `@innovatrix/utils/dx/DxError`.
 * @param {object} [options] - Options injected in the props of the `ErrorModal` component.
 * @param {string} [options.id] - The modal ID to use, in case you need to close the modal
 *   programmatically
 * @param {string} [id] - Optional ID to use, useful if you later wish to remove the modal window in
 *   response to some event.
 * @return {{ type, component, id, options }}
 */
export const showErrorModalAction = (error, options = {}, id) => {
  if (id) { throw new Error('Pass the legacy in the options object instead.'); } // legacy
  const dxError = DxError.isDxError(error) ? error : new DxError(error);
  let size = 'medium';
  if (dxError.queryString) {
    size = Math.max(dxError.queryString.split('\n').map((l) => l.length)) > 40 ? 'large' : 'medium';
  }
  id = options.id || uuid();
  options = {
    closeOnOutsideClick: true,
    dxError,
    hideCloseButton: false,
    size,
    title: 'Error',
    ...options,
  };
  return addModalAction(ErrorModal, options, options.id || uuid());
};
