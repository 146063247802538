import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DxError } from '@innovatrix/utils/dx';
import { getDxAuthenticated, getDxAuthFailed } from '@innovatrix/selectors/authSelectors';

import { dxConfig } from '../dxConfig';
import { dxAuthenticateAction, dxAuthFailedAction } from '../actions/dxAuthActions';

import { AuthProvider, getProvider } from './providers/AuthProvider';

// -- Support --------------- --- --  -

const _getProvider = (fail) => {
  let providers = dxConfig.get('duxis.auth.identityProviders');
  providers = Object.keys(providers)
    .filter((id) => providers[id].enable)
    .map((id) => getProvider(id));
  if (providers.length > 1) {
    fail(new DxError('Multiple identity providers are enabled in the setup config. You need to' +
      ' specify which provider to use by passing it as `provider` prop to the `Authenticate` component.'));
  }
  else if (providers.length === 0) {
    fail(new DxError('No identity provider is enabled in the setup config. You need to specify' +
      ' which provider to use by passing it as `provider` prop to the `Authenticate` component.'));
  }
  else {
    return providers[0];
  }
};

// -- Component --------------- --- --  -

/**
 * See docs/dev/dxAuth-auth0.md for more information.
 */
class AuthenticateComponent extends React.Component {

  render() {
    if (this.props.authenticated) { return this.props.children; }
    if (!this.props.failed) {
      const provider = this.props.provider || _getProvider(this.props.fail);
      if (provider) {
        setTimeout(() => this.props.authenticate(provider.id, location.pathname), 50);
        const SignIn = provider.getSignInComponent();
        return SignIn ? React.createElement(SignIn, { provider }) : (null);
      }
    }
    return null;
  }

}

AuthenticateComponent.propTypes = {
  /** @private Provided in connect wrapper. */
  authenticate: PropTypes.func.isRequired,

  /** @private Provided in connect wrapper. */
  authenticated: PropTypes.bool.isRequired,

  /** Protected children. */
  children: PropTypes.node,

  /** @private Provided in connect wrapper. */
  fail: PropTypes.func.isRequired,

  /** @private Provided in connect wrapper. */
  failed: PropTypes.bool.isRequired,

  /**
   * The identity provider to authenticate with, such as {@link auth0Provider}.
   * @see react-frontend/auth/providers/auth0Provider
   * @see react-frontend/auth/providers/simplePasswordProvider
   */
  provider: PropTypes.instanceOf(AuthProvider),
};

// -- Container --------------- --- --  -

export const Authenticate = connect(
  (state) => ({
    authenticated: getDxAuthenticated(state),
    failed: getDxAuthFailed(state),
  }),
  {
    authenticate: dxAuthenticateAction,
    fail: dxAuthFailedAction,
  },
)(AuthenticateComponent);
