import { SELECT_EVALUATION, CLEAR_EVALUATIONS, SELECT_REVIEWER } from '../constants';

export const selectEvaluationAction = (evaluationId, value) => ({
  type: SELECT_EVALUATION,
  data: { evaluationId, value },
});

export const clearEvaluationsAction = () => ({
  type: CLEAR_EVALUATIONS,
});

export const selectReviewerAction = reviewerId => ({
  type: SELECT_REVIEWER,
  reviewerId,
});
