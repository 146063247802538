import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const ArrowIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 16 16" {...styles} {...props}>
    <path stroke="none" fill={color} d="M8 1.32L14.68 8 8 14.68 6.82 13.5l4.66-4.68H1.32V7.18h10.16L6.82 2.5z" />
  </svg>
);

ArrowIcon.propTypes = propTypes;
ArrowIcon.defaultProps = defaultProps;

export default ArrowIcon;
