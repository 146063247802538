import React from 'react';
import { Chapter, Module, Spinner } from '@innovatrix/components';

const LoadingScreen = () => (
  <Chapter>
    <Module>
      <Spinner />
    </Module>
  </Chapter>
);

export default LoadingScreen;
