import queryString from 'query-string';
import { createSelector } from 'reselect';

import { LOADING } from '../constants';

const DEFAULT_QUERY = { data: null, status: LOADING };
const DEFAULT_MUTATION = { status: null };

const getGeneric = (state) => state.generic;
const getItemId = (s, { namespace, variables, skip }) => ({ namespace, variables, skip });

export const getQueryDataFactorySelector = () => createSelector(
  [getGeneric, getItemId],
  (generic, { namespace, variables, skip }) => {
    if (!generic[namespace]) {
      return DEFAULT_QUERY;
    }
    if (skip) {
      return DEFAULT_MUTATION;
    }
    return generic[namespace][queryString.stringify(variables)] || DEFAULT_QUERY;
  },
);

export const getMutationDataFactorySelector = () => createSelector(
  [getGeneric, getItemId],
  (generic, { namespace, variables }) => {
    if (!generic[namespace]) {
      return DEFAULT_MUTATION;
    }
    return generic[namespace][queryString.stringify(variables)] || DEFAULT_MUTATION;
  },
);
