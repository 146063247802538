import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import { WHITE, PRIMARY } from '../../../../../styles';

const questionContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const questionOptionStyles = css({
  backgroundColor: WHITE,
  border: `1px dashed ${PRIMARY}`,
  fontSize: 16,
  padding: '4px 8px',
});

const EMPTY_OBJ = {};

const Questionpreview = ({ questionProps }) => {
  const dimensions = React.useRef(EMPTY_OBJ);
  React.useEffect(() => {
    dimensions.current = questionProps.getDimensions(questionProps.actualOrder) || {};
  }, []); // eslint-disable-line
  const { height, width } = dimensions.current;

  const style = React.useMemo(() => (questionProps.question.collapsed ?
    EMPTY_OBJ : { height: `${height || 25}px`, width: `${width || 200}px` }),
  [height, questionProps.question, width]);

  return (
    <div
      {...questionContainerStyles}
      style={style}
    >
      <div {...questionOptionStyles}>
        {questionProps.question.title || 'Question'}
      </div>
    </div>
  );
};

Questionpreview.propTypes = { questionProps: PropTypes.object.isRequired };

export default Questionpreview;
