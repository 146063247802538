import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Paragraph } from '@innovatrix/common/text';
import { PRIMARY, GREY_1, WHITE } from '@innovatrix/styles';
import formikField from '@innovatrix/components/fields/formikField';
import { prevent } from '@innovatrix/utils';
import Unknown from '@innovatrix/icons/Unknown';
import KeyIcon from '@innovatrix/icons/KeyIcon';
import LinkIcon from '@innovatrix/icons/LinkIcon';
import ValidationIcon from '@innovatrix/icons/ValidationIcon';
import DuplicateIcon from '@innovatrix/icons/DuplicateIcon';
import ArrowIcon from '@innovatrix/icons/ArrowIcon';

import CheckedSVG from './Completed';

const DUPLICATE = 'duplicate';
const KEY = 'key';
const NORMAL = 'normal';
const QUESTION = 'question';
const RELATION = 'relation';
const VALIDATION = 'validation';
const ARROW_LEFT = 'arrowLeft';

const increaseBrightness = (hex, percent) => {
  const num = parseInt(hex.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt; // eslint-disable-line no-bitwise
  const B = (num >> 8 & 0x00FF) + amt; // eslint-disable-line no-bitwise
  const G = (num & 0x0000FF) + amt; // eslint-disable-line no-bitwise
  return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)}`;
};

const ArrowLeftIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

const CheckFieldContainer = styled.div`
  align-items: center;
  display: flex;

  > .disabled {
    cursor: default;
    opacity: 0.75;
  }
`;

const Label = styled(Paragraph)`
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 1px;
  overflow: hidden;
  padding-left: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconContainer = styled.span`
  align-items: center;
  border-radius: ${({ theme }) => theme.border.radius};
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 16px;
  min-width: 16px;

  ${({ theme, flavor, checked, checkColor, disabled }) => {
    switch (flavor) {
      case QUESTION:
        return {
          border: `1px solid ${checked ? checkColor : theme.colors.grey1}`,
          backgroundColor: (checked ? checkColor : theme.colors.greyLight1),
          ':hover': {
            borderColor: (disabled ? 0 : checkColor),
            backgroundColor: (disabled ? 0 : (checked ? checkColor : increaseBrightness(checkColor, 70))),
            '> svg > path': {
              fill: (disabled ? 0 : (checked ? theme.colors.white : checkColor)),
            },
          },
        };
      case ARROW_LEFT:
        return {
          border: `1px solid ${checked ? checkColor : theme.colors.grey1}`,
          backgroundColor: (checked ? checkColor : theme.colors.white),
          '> svg > path': {
            fill: (checked ? theme.colors.white : 'none'),
          },
          ':hover': {
            borderColor: (disabled ? 0 : checkColor),
            backgroundColor: (disabled ? 0 : (checked ? checkColor : theme.colors.white)),
            '> svg > path': {
              fill: (disabled ? 0 : (checked ? theme.colors.white : checkColor)),
            },
          },
        };
      default:
        return {
          border: `1px solid ${checked ? checkColor : theme.colors.grey1}`,
          backgroundColor: (checked ? checkColor : theme.colors.white),
          ':hover': {
            borderColor: (disabled ? 0 : checkColor),
            backgroundColor: (disabled ? 0 : (checked ? checkColor : increaseBrightness(checkColor, 70))),
          },
        };
    }
  }}
`;

export const Check = ({
  checkColor, className, disabled, flavor, key, label, onChange, value,
}) => {
  const onChangeCb = React.useCallback((e) => {
    prevent(e);
    if (onChange) {
      onChange(!value);
    }
  }, [onChange, value]);

  const icon = React.useMemo(() => {
    const styles = { color: WHITE, width: 10, height: 10 };
    switch (flavor) {
      case NORMAL: return value ? <CheckedSVG color={WHITE} /> : null;
      case QUESTION: return value ? <Unknown color={WHITE} size="10px" /> : <Unknown color={GREY_1} size="10px" />;
      case RELATION: return value ? <LinkIcon {...styles} /> : null;
      case DUPLICATE: return value ? <DuplicateIcon {...styles} /> : null;
      case KEY: return value ? <KeyIcon {...styles} /> : null;
      case VALIDATION: return value ? <ValidationIcon {...styles} /> : null;
      case ARROW_LEFT: return <ArrowLeftIcon width={12} height={12} />;
      default: throw new Error(`Flavor: ${flavor} is not supported for the CheckField component.`);
    }
  }, [flavor, value]);

  return (
    <CheckFieldContainer
      className={`checkField ${disabled ? 'disabled ' : ' '} ${className}`}
      onClick={disabled ? undefined : onChangeCb}
    >
      <IconContainer
        flavor={flavor}
        checkColor={checkColor}
        className={`checkBox ${value ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        checked={value}
        disabled={disabled}
      >
        {icon}
      </IconContainer>
      {label ? <Label className="checkLabel" htmlFor={key} disabled={disabled} title={label}>{label}</Label> : null}
    </CheckFieldContainer>
  );
};

Check.propTypes = {
  checkColor: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  flavor: PropTypes.oneOf([ARROW_LEFT, KEY, QUESTION, NORMAL, DUPLICATE, VALIDATION, RELATION]),
  key: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

Check.defaultProps = {
  checkColor: PRIMARY,
  className: '',
  disabled: false,
  flavor: NORMAL,
  label: null,
};

export default formikField(Check);
