import { toast } from 'react-toastify';
import { PRIMARY, WARNING, WHITE } from '@innovatrix/styles';

const baseStyle = {
  fontFamily: 'Raleway',
  padding: '0 24px',
};

export function success(message) {
  toast.success(message, {
    style: { ...baseStyle, background: PRIMARY },
  });
}

export function error(message) {
  toast.error(message, {
    style: { ...baseStyle, background: WARNING },
  });
}

export function loading(message = 'Loading...') {
  toast.loading(message, {
    position: 'top-right',
    style: { ...baseStyle, background: PRIMARY, color: WHITE },
  });
}

export function dismiss(toastId) {
  toast.dismiss(toastId);
}
