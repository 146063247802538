/**
 * @function getClickHandler
 * @param {callback} onClick - Will be fired if there was only one click in delay time.
 * @param {callback} onDblClick - Will be fired if there were 2 clicks in the delay time.
 * @param {number} [delay = 250] - Time to wait between clicks.
 * @returns {function}
 */
export const getClickHandler = (onClick, onDblClick, delay = 250) => {
  let timeoutID = null;
  return (event) => {
    if (timeoutID) {
      timeoutID = clearTimeout(timeoutID);
      if (onDblClick) { onDblClick(event); }
    }
    else {
      timeoutID = setTimeout(() => {
        if (onClick) { onClick(event); }
        timeoutID = null;
      }, delay);
    }
  };
};

// Example: <p onClick={getClickHandler(() => console.log('single'), () => console.log('double'))}>Test</p>
