import * as React from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router';

// Helper component which will scroll to the top of the page when we navigate.
const ScrollToTop = ({ children }) => {
  const history = useHistory();
  React.useLayoutEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0));
    return () => { unlisten(); };
  }, [history]);
  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollToTop;
