import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IS_PRODUCTION } from '@innovatrix/constants';

const ALLOWED_SIZES = ['h1', 'h2', 'h3', 'h4'];
const ALLOWED_WEIGHTS = ['bold', 'normal', 'medium', 'light'];

const FONT_SIZE_FOR_TITLE_SIZE = {
  h1: '32px',
  h2: '24px',
  h3: '20px',
  h4: '18px',
};

const MARGIN_FOR_TITLE_SIZE = {
  h1: '16px',
  h2: '12px',
  h3: '10px',
  h4: '8px',
};

const FONT_WEIGHT_NUMBERS = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

const FakeTitle = styled.h1`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ size }) => FONT_SIZE_FOR_TITLE_SIZE[size]};
  font-weight: ${({ weight }) => FONT_WEIGHT_NUMBERS[weight]};
  line-height: normal;
  margin: 0;
  margin-bottom: ${({ size }) => MARGIN_FOR_TITLE_SIZE[size]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Title = ({ children, className, size, weight }) => {
  if (!IS_PRODUCTION) {
    if (!ALLOWED_SIZES.includes(size)) {
      throw new Error(
        `Invalid size passed to the <Title /> component.
        Got ${size} expected one of ${ALLOWED_SIZES.join(', ')}.`,
      );
    }
    if (!ALLOWED_WEIGHTS.includes(weight)) {
      throw new Error(
        `Invalid weight passed to the <Title /> component.
        Got ${weight} expected one of ${ALLOWED_WEIGHTS.join(', ')}.`,
      );
    }
  }
  const Component = React.useMemo(() => FakeTitle.withComponent(size), [size]);
  return (
    <Component className={className} size={size} title={children} weight={weight}>
      {children}
    </Component>
  );
};

Title.defaultProps = { size: 'h1', weight: 'normal' };
Title.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(ALLOWED_SIZES),
  weight: PropTypes.oneOf(ALLOWED_WEIGHTS),
};

export default React.memo(Title);
