import { mutationBuilder, queryBuilder } from '@innovatrix/utils/dx';

export const skipTourMutation = mutationBuilder({
  variables: {
    tourName: { type: 'String!' },
    userId: { type: 'String!' },
  },
  fields: {
    _skipTour: {
      varArgs: {
        tourName: 'tourName',
        userId: 'userId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const persistStepMutation = mutationBuilder({
  variables: {
    finished: { type: 'Boolean!' },
    tourName: { type: 'String!' },
    userId: { type: 'String!' },
  },
  fields: {
    _persistStep: {
      varArgs: {
        finished: 'finished',
        tourName: 'tourName',
        userId: 'userId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const getUserToursQuery = queryBuilder({
  variables: {
    userId: { type: 'String!' },
  },
  fields: {
    _getUserTours: {
      varArgs: {
        userId: 'userId',
      },
      fields: {
        error: true,
        result: true,
        success: true,
      },
    },
  },
});

export const acceptCookiesMutation = mutationBuilder({
  variables: {
    accepted: { type: 'Boolean!' },
  },
  fields: {
    _acceptCookies: {
      varArgs: { accepted: 'accepted' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const acceptLegalDocumentMutation = mutationBuilder({
  variables: {
    documentId: { type: 'String!' },
  },
  fields: {
    _acceptLegalDocument: {
      varArgs: {
        documentId: 'documentId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});
