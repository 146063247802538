import React from 'react';
import PropTypes from 'prop-types';
import {
  MULTIPLE_SELECTION_FIELD,
  TEXT_FIELD,
} from '@innovatrix/common/formBuilder/_constants';

import {
  DragDropHandlerDisabled,
  QuestionTypeWrapper,
  Side,
  QuestionIcon,
  QuestionTypeInformation,
  QuestionTypeTitle,
} from '../_styles';

import FieldData from './FieldData';

const SectionFieldDragPreview = ({ flexInline, label, icon, value, placeholder, fallbackText, type, description, showFallback }) => (
  <QuestionTypeWrapper
    disabled
    flexInline={flexInline}
    inverted
    isDragging={false}
  >
    <Side>
      <DragDropHandlerDisabled />
      <QuestionIcon flavor="quaternary" hide={false} icon={icon} />
      {showFallback ? (
        <FieldData
          description={description}
          fallbackText={fallbackText}
          label={label}
          type={type}
          value={value || placeholder}
        />
      ) : (
        <QuestionTypeInformation>
          {type === TEXT_FIELD || type === MULTIPLE_SELECTION_FIELD ? (
            <QuestionTypeTitle>{fallbackText}</QuestionTypeTitle>
          ) : (
            <QuestionTypeTitle>{value || label || fallbackText}</QuestionTypeTitle>
          )}
        </QuestionTypeInformation>
      )}
    </Side>
  </QuestionTypeWrapper>
);

SectionFieldDragPreview.propTypes = {
  description: PropTypes.string,
  fallbackText: PropTypes.string.isRequired,
  flexInline: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  showFallback: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};

export default SectionFieldDragPreview;
