import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IconButton } from '@innovatrix/common/buttons';
import { PRIMARY, REVIEW_GO, REVIEW_NOGO } from '@innovatrix/styles';
import CloseIcon from '@innovatrix/icons/CloseIcon';

const SUCCESS = 'success';
const DEFAULT = 'primary';
const WARNING = 'warning';

const TYPES = [SUCCESS, DEFAULT, WARNING];

const Container = styled.div`
  align-items: center;
  border-radius: ${({ theme }) => theme.border.radius};
  border: ${({ isOpen }) => (isOpen ? '1px solid' : 0)};
  display: flex;
  height: ${({ isOpen }) => (isOpen ? '46px' : 0)};
  justify-content: space-between;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  padding: ${({ isOpen }) => (isOpen ? '6px 16px 6px 24px' : 0)};

  &:hover {
    cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'default')};
  }
`;

const PrimaryContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.primaryLightest};
  border-color: ${({ theme }) => theme.colors.primary};
`;

const SuccessContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.reviewGoBackground};
  border-color: ${({ theme }) => theme.colors.reviewGo};
  color: ${({ theme }) => theme.colors.reviewGo};
`;

const WarningContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.reviewNoGoBackground};
  border-color: ${({ theme }) => theme.colors.reviewNoGo};
  color: ${({ theme }) => theme.colors.secondary};
`;

const StyledIcon = styled(IconButton)`
  > button:hover {
    background: none;
  }
`;

const MessageBar = ({ children, isOpen, onClose, iconColor, onClick, className, type, hasOnClick }) => {
  const color = iconColor || PRIMARY;

  if (type === SUCCESS) {
    return (
      <SuccessContainer isOpen={isOpen} onClick={onClick} className={className} hasOnClick={hasOnClick}>
        <React.Fragment>
          {children}
        </React.Fragment>
        {onClose && (
          <StyledIcon
            small
            icon={<CloseIcon color={REVIEW_GO} />}
            onClick={onClose}
          />
        )}
      </SuccessContainer>
    );
  }

  if (type === WARNING) {
    return (
      <WarningContainer isOpen={isOpen} onClick={onClick} className={className} hasOnClick={hasOnClick}>
        <React.Fragment>
          {children}
        </React.Fragment>
        {onClose && (
          <StyledIcon
            small
            icon={<CloseIcon color={REVIEW_NOGO} />}
            onClick={onClose}
          />
        )}
      </WarningContainer>
    );
  }

  return (
    <PrimaryContainer isOpen={isOpen} onClick={onClick} className={className} hasOnClick={hasOnClick}>
      <React.Fragment>
        {children}
      </React.Fragment>
      {onClose && (
        <StyledIcon
          small
          icon={<CloseIcon color={color} />}
          onClick={onClose}
        />
      )}
    </PrimaryContainer>
  );
};

MessageBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasOnClick: PropTypes.bool,
  iconColor: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(TYPES),
};

MessageBar.defaultProps = {
  className: '',
  hasOnClick: false,
  iconColor: null,
  onClick: () => {},
  onClose: undefined,
  type: DEFAULT,
};

export default MessageBar;
