import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const AddIcon = ({ color, size, styles, ...props }) => (
  <svg viewBox="0 0 20 20" height={size} width={size} {...styles} {...props}>
    <path fill={color} d="M11.5 8.5H20v3h-8.5V20h-3v-8.5H0v-3h8.5V0h3v8.5z" />
  </svg>
);

AddIcon.propTypes = propTypes;
AddIcon.defaultProps = defaultProps;

// TODOX '#414141'
export default AddIcon;
