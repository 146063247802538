import { FETCH_GEO_SUGGESTIONS, ERROR_GEO_SUGGESTIONS, RECEIVE_GEO_SUGGESTIONS } from '../constants';

const initialState = {
  error: null,
  isLoading: false,
  locations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_GEO_SUGGESTIONS: {
      const { error } = action;
      return {
        error,
        isLoading: false,
        locations: [],
      };
    }
    case FETCH_GEO_SUGGESTIONS: {
      return {
        isLoading: true,
        locations: [],
        error: null,
      };
    }
    case RECEIVE_GEO_SUGGESTIONS: {
      const { result } = action;
      return {
        isLoading: false,
        locations: result,
        error: null,
      };
    }
    default:
      return state;
  }
};
