
import { createSelector } from 'reselect';
import queryString from 'query-string';

import { DEFAULT_ENTITIES } from './utils';

const getDashboard = state => state.data.dashboard;

const getDashboardRelations = createSelector(
  getDashboard,
  (dashboard) => dashboard.relations,
);

const getProjectHasEvaluationMeanRatingsRelation = createSelector(
  getDashboardRelations,
  (relations) => relations.projectHasEvaluationMeanRatings,
);

const getProjectHasCompletedObjectivesDataRelation = createSelector(
  getDashboardRelations,
  (relations) => relations.projectHasCompletedObjectivesData,
);

const getProjectHasAssumptionsDataRelation = createSelector(
  getDashboardRelations,
  (relations) => relations.projectHasAssumptionsData,
);

export const getEvaluationMeanRatingsData = (state, { projectId }) => createSelector(
  getProjectHasEvaluationMeanRatingsRelation,
  (relations) => {
    const result = relations[queryString.stringify({ projectId })];
    if (!result) { return DEFAULT_ENTITIES; }
    if (result.data.length === 1) {
      const entry = result.data[0];
      result.data = [
        { ...entry, label: '' },
        { ...entry },
        { ...entry, label: ' ' },
      ];
    }
    return {
      status: result.status,
      data: [
        {
          id: 'Evaluations',
          data: result.data.map(({ label, value }) => ({ x: label, y: value })),
        },
      ],
    };
  },
)(state);

export const getCompletedObjectivesData = (state, { projectId }) => createSelector(
  getProjectHasCompletedObjectivesDataRelation,
  (relations) => relations[queryString.stringify({ projectId })] || DEFAULT_ENTITIES,
)(state);

export const getAssumptionsData = (state, { projectId }) => createSelector(
  getProjectHasAssumptionsDataRelation,
  (relations) => relations[queryString.stringify({ projectId })] || DEFAULT_ENTITIES,
)(state);
