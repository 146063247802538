import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ClickOutside from '@innovatrix/components/Clickoutside';
import CaretIcon from '@innovatrix/icons/CaretIcon';

import Button from './Button';
import { ALLOWED_FLAVORS, TERTIARY } from './_constants';

const Container = styled.div`
  position: relative;
`;

const Menu = styled(ClickOutside)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 12px 16px;
  position: absolute;
  top: ${({ small }) => (small ? 32 : 40)}px;
  z-index: 1;

  ${({ right }) => (right ? `
    right: 0;
  ` : `
    left: 0;
  `)}
`;

const StyledCaretIcon = styled(CaretIcon)`
  height: 6px;
  margin-left: 8px;
  transform: ${({ dropup }) => (dropup ? 'rotate(180deg)' : 'unset')};
  width: 6px;
  > path {
    height: 6px;
  }
`;

const MenuButton = ({ caret, children, className, color, disabled, flavor, icon, label, small, width, right }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = React.useCallback(() => setIsOpen(o => !o), []);

  return (
    <Container className={className}>
      <Button
        disabled={disabled}
        color={color}
        flavor={flavor}
        label={label}
        icon={icon}
        onClick={toggleOpen}
        small={small}
        width={width}
      >
        {caret && <StyledCaretIcon />}
      </Button>
      {isOpen && (
        <Menu
          clickOutside={toggleOpen}
          right={right}
          small={small}
        >
          {children}
        </Menu>
      )}
    </Container>
  );
};

MenuButton.defaultProps = { flavor: TERTIARY, label: 'Placeholder', right: false };
MenuButton.propTypes = {
  caret: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  flavor: PropTypes.oneOf(ALLOWED_FLAVORS),
  icon: PropTypes.node,
  label: PropTypes.string,
  right: PropTypes.bool,
  small: PropTypes.bool,
  width: PropTypes.string,
};

export default React.memo(MenuButton);
