
import { createSelector } from 'reselect';
import queryString from 'query-string';

import { DEFAULT_ENTITY } from './utils';

const getQuestionData = state => state.data.question;

// const getQuestionRelations = createSelector(
//   getQuestionData,
//   (question) => question.relations,
// );

const getQuestionEntities = createSelector(
  getQuestionData,
  (question) => question.entities,
);

export const getQuestion = (state, questionId) => createSelector(
  getQuestionEntities,
  (entities) => entities[queryString.stringify({ questionId })] || DEFAULT_ENTITY,
)(state);
