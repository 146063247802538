import React from 'react';
import PropTypes from 'prop-types';

const Unknown = ({ color, size, styles, ...props }) => (
  <svg {...styles} {...props} width={size} height={size} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 11 18">
    <path
      fill={color}
      d="M2.9,12.4c0-0.8,0.1-1.5,0.4-2.1c0.3-0.6,0.8-1.2,1.6-1.6c0.2-0.1,0.5-0.3,0.9-0.5s0.7-0.4,1-0.7S7.4,7,7.7,6.6C7.9,6.3,8,5.9,8,5.4C8,5.1,8,4.8,7.8,4.5S7.5,4.1,7.3,3.9C7.1,3.7,6.9,3.6,6.6,3.5C6.3,3.4,6,3.4,5.7,3.4c-0.4,0-0.7,0.1-1,0.2S4.2,3.8,4,4C3.8,4.2,3.6,4.4,3.4,4.6S3,5.1,2.9,5.3L0.8,3.8C1,3.3,1.3,2.9,1.6,2.5s0.7-0.7,1.2-1s0.9-0.5,1.4-0.6s1-0.2,1.6-0.2C6.4,0.7,7,0.8,7.6,1c0.6,0.2,1.1,0.5,1.6,0.8c0.5,0.4,0.9,0.9,1.2,1.5c0.3,0.6,0.4,1.3,0.4,2.1c0,0.5-0.1,1-0.2,1.4s-0.3,0.7-0.5,1.1S9.6,8.5,9.3,8.8C9,9,8.7,9.3,8.3,9.5c-0.3,0.2-0.6,0.4-1,0.6c-0.3,0.2-0.6,0.4-0.9,0.6S6,11.1,5.8,11.4c-0.2,0.3-0.3,0.6-0.3,1H2.9z M3,18v-3.3h2.7V18H3z"
    />
  </svg>);

Unknown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  styles: PropTypes.any,
};

Unknown.defaultProps = {
  color: '#FFFFFF',
  size: '14px',
};

export default Unknown;
