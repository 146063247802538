import styled from '@emotion/styled';

export const Container = styled.div`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.page};
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  font-size: 16px;
  height: inherit;
  overflow-x: visible;
  overflow-y: visible;
`;

// https://css-tricks.com/couple-takes-sticky-footer/#there-is-flexbox
export const Content = styled.div`
  flex: 1 0 auto;
`;
