import { DxError } from '@innovatrix/utils/dx';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../propTypes';
import { dxStyles } from '../styles';

import { DxErrorDetails } from './DxErrorDetails';
import { Section } from './Section';

// -- Styles --------------- --- --  -

const defaultStyles = css(dxStyles.contentSection, {
  background: 'rgb(242, 222, 222)',
  color: 'rgb(112, 45, 44)',
  minHeight: '32px',
});

const thinStyles = css(defaultStyles, { padding: '8px 32px' });

const dxErrorDetailsStyles = css({ '> div > pre': { color: 'rgb(169, 68, 66)' } });

// -- Component --------------- --- --  -

export const AlertSection = ({ error, message, styles, thin }) => {
  if (message) {
    throw new Error('The "message" prop for the "AlertSection" component is no longer supported.' +
      ' Use the "error" prop instead.');
  }
  return (
    <Section
      className="dx-alert-section"
      styles={css(thin ? thinStyles : defaultStyles, styles)}
    >
      <DxErrorDetails error={error} styles={dxErrorDetailsStyles} />
    </Section>
  );
};

AlertSection.propTypes = {
  /** Optional error object to show. */
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.instanceOf(DxError),
    PropTypes.object,
    PropTypes.string,
  ]),

  /** @deprecated */
  message: PropTypes.oneOf([]),

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,

  /** When true, then the alert is rendered as a thin section meant to shown above another section. */
  thin: PropTypes.bool,
};
