import React from 'react';
import Ranking from '@innovatrix/icons/RankingIcon';
import Info from '@innovatrix/icons/InfoIcon';
import TextEntry from '@innovatrix/icons/TextEntryIcon';
import MultipleSelection from '@innovatrix/icons/MultipleSelectionIcon';
import MultipleChoice from '@innovatrix/icons/MultipleChoiceIcon';

import multipleChoiceIcon from '../assets/icons/multipleChoice.svg';
import multipleSelectionIcon from '../assets/icons/multipleSelection.svg';
import textEntryIcon from '../assets/icons/textEntry.svg';
import infoIcon from '../assets/icons/infoGrey.svg';
import rankingIcon from '../assets/icons/ranking.svg';

// Don't touch this!
export const MULTI_SINGLE = 'MULTIPLE_CHOICE_SINGLE_SELECTION';
export const MULTI_SINGLE_QUANTIFIED = 'MULTIPLE_CHOICE_SINGLE_SELECTION_QUANTIFIED';
export const MULTI_MULTI = 'MULTIPLE_CHOICHE_MULTIPLE_SELECTION';
export const TEXT_ENTRY = 'TEXT_ENTRY';
export const OVERALL_ADVICE = 'OVERALL_ADVICE';
export const ADVICE_COMMENT = 'ADVICE_COMMENT';
export const MULTIPLE_TEXT_ENTRY = 'MULTIPLE_TEXT_ENTRY';
export const MATRIX = 'MATRIX';
export const RANKING = 'RANKING';
export const INTRODUCTION = 'INTRODUCTION';
export const ADVICE = 'ADVICE';

export const questionTypeOptions = [
  { icon: <MultipleChoice />, label: 'Multiple choice', value: [MULTI_SINGLE, MULTI_SINGLE_QUANTIFIED] },
  { icon: <MultipleSelection />, label: 'Multiple selection', value: MULTI_MULTI },
  { icon: <TextEntry />, label: 'Text entry', value: [TEXT_ENTRY, MULTIPLE_TEXT_ENTRY] },
  { icon: <Ranking />, label: 'Ranking', value: RANKING },
  { icon: <Info />, label: 'Introduction', value: INTRODUCTION },
];

export const questionIcons = {
  [INTRODUCTION]: infoIcon,
  [MULTI_SINGLE]: multipleChoiceIcon,
  [MULTI_SINGLE_QUANTIFIED]: multipleChoiceIcon,
  [MULTI_MULTI]: multipleSelectionIcon,
  [TEXT_ENTRY]: textEntryIcon,
  [MULTIPLE_TEXT_ENTRY]: textEntryIcon,
  [MATRIX]: textEntryIcon, // TODO
  [RANKING]: rankingIcon,
};
