import * as React from 'react';
import * as PropTypes from 'prop-types';
import { DragLayer as dragLayer } from 'react-dnd';
import styled from '@emotion/styled';
import SectionFieldDragPreview from '@innovatrix/common/formBuilder/builder/tabs/components/SectionFieldDragPreview';
import SectionDragPreview from '@innovatrix/common/formBuilder/builder/tabs/components/SectionDragPreview';
import SingleSelectionAnswerDragPreview from '@innovatrix/common/formBuilder/builder/tabs/components/SingleSelectionAnswerDragPreview';

import QuestionPreview from '../../modules/innovatrix/assessments/components/draggables/QuestionPreview';
import ThresholdBarPreview from '../../modules/innovatrix/assessments/components/draggables/ThresholdBarPreview';
import OptionPreview from '../../modules/innovatrix/assessments/components/draggables/OptionPreview';
import TextEntryOptionPreview from '../../modules/innovatrix/assessments/components/draggables/TextEntryOptionPreview';
import PreviewRankingOption from '../assessmentModal/question/rankingQuestion/Preview';
import WorkshopPreview from '../../modules/innovatrix/workshops/pages/persist/workshopTypes/components/Preview';
import { memoize } from '../../utils/memoize';
import ItemPreview from '../../modules/common/manage/Preview';
import { BoardAssumptionPreview, MultiBoardAssumptionPreview } from '../project/innoBoards/persist/AssumptionPreview';

import DocumentPreview from './DocumentPreview';

const DragLayer = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
`;

function getItemStylesFunction(props) {
  const { currentOffset, item: { leftOffset } } = props;
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  let transform;
  if (leftOffset) {
    transform = `translate(${leftOffset}px, ${y}px)`;
  }
  else {
    transform = `translate(${x}px, ${y}px)`;
  }
  return {
    transform,
    WebkitTransform: transform,
  };
}

const getItemStyles = memoize(getItemStylesFunction);

const CustomDragLayer = ({ isDragging, item, itemType, scroll, shouldScroll, currentOffset }) => {
  const element = React.useRef();

  React.useEffect(() => {
    if (isDragging && shouldScroll && currentOffset && element.current && item && item.shouldItemScroll) {
      const { x, y } = currentOffset;
      scroll(x, y);
    }
  }, [currentOffset, isDragging, item, scroll, shouldScroll]);

  const renderItem = React.useCallback((type, itm) => {
    switch (type) {
      case 'itemOption':
        return <ItemPreview title={itm && itm.title} />;
      case 'AssumptionCard':
        return <BoardAssumptionPreview status={itm.status} title={itm.title} />;
      case 'SmallAssumptionCard': // TODO: remove since this will be DEPRECATED
        if (itm.isSelected && itm.selectedAssumptionIds.length > 1) {
          return <BoardAssumptionPreview title={`${itm.selectedAssumptionIds.length} assumptions`} />;
        }
        return <BoardAssumptionPreview status={itm.assumption.status} title={itm.assumption.title} />;
      case 'BoardAssumptionCard':
        if (itm.isSelected && itm.selectedAssumptionIds.length > 1) {
          return <MultiBoardAssumptionPreview title={`${itm.selectedAssumptionIds.length} assumptions`} />;
        }
        return <BoardAssumptionPreview status={itm.assumption.status} title={itm.assumption.title} />;
      case 'PreviewRankingAnswerOption':
        return <PreviewRankingOption {...itm} />;
      case 'Question':
        return <QuestionPreview questionProps={itm} />;
      case 'TextEntryOption':
        return <TextEntryOptionPreview optionProps={itm} />;
      case 'RankingOption':
      case 'AnswerOption':
      case 'AdviceOption':
        return <OptionPreview optionProps={itm} />;
      case 'WorkshopType':
      case 'WorkshopMoment':
        return <WorkshopPreview {...itm} />;
      case 'ThresholdBar':
        return <ThresholdBarPreview {...itm} />;
      case 'Document':
        return <DocumentPreview text={itm.name} name={itm.name} type={itm.type} />;
      case 'FormBuilderQuestion':
      case 'FormBuilderSectionField':
        return <SectionFieldDragPreview {...itm.data} flexInline />;
      case 'FormBuilderSection':
        return <SectionDragPreview {...itm.data} />;
      case 'SingleSelectionAnswer':
      case 'MultipleSelectionAnswer':
        return <SingleSelectionAnswerDragPreview {...itm.data} />;
      default:
        return null;
    }
  }, []);

  if (!isDragging) {
    return null;
  }

  return (
    <DragLayer>
      <div ref={element} style={getItemStyles({ currentOffset, item })}>
        {renderItem(itemType, item)}
      </div>
    </DragLayer>
  );
};

CustomDragLayer.propTypes = {
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object,
  itemType: PropTypes.string,
  scroll: PropTypes.func,
  shouldScroll: PropTypes.bool,
};

export default dragLayer((monitor) => ({
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
}))(CustomDragLayer);
