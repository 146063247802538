import * as React from 'react';
import PropTypes from 'prop-types';

import { defaultProps, propTypes } from './defaultPropTypes';

const AssumptionStatusIcon = ({ className, newStatusColor, oldStatusColor, ...rest }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path className="back" fill={oldStatusColor} d="M3,0 L17,0 C18.6568542,0 20,1.34314575 20,3 L20,17 C20,18.6568542 18.6568542,20 17,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,3 C0,1.34314575 1.34314575,0 3,0 Z" />
      <path className="front" fill={newStatusColor} d="M12.5,0 L17,0 C18.6568542,0 20,1.34314575 20,3 L20,17 C20,18.6568542 18.6568542,20 17,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,12.5 L12.5,0 Z" />
    </g>
  </svg>
);

AssumptionStatusIcon.propTypes = {
  ...propTypes,
  newStatusColor: PropTypes.string.isRequired,
  oldStatusColor: PropTypes.string.isRequired,
};

AssumptionStatusIcon.defaultProps = defaultProps;

export default AssumptionStatusIcon;
