import * as React from 'react';
import CheckIcon from '@innovatrix/icons/CheckIcon';
import CloseIcon from '@innovatrix/icons/CloseIcon';
import ConditionsIcon from '@innovatrix/icons/ConditionsIcon';
import Maybe from '@innovatrix/icons/Maybe';
import { DECISION_CONDITIONS, DECISION_GO, DECISION_MAYBE, DECISION_NO_GO } from '@innovatrix/styles';

// FormBuilder states
export const OPEN_FORM = 'OPEN';
export const DRAFT_FORM = 'DRAFT';
export const SUBMITTED_FORM = 'SUBMITTED';

export const FIELD_TYPE_TAB = 'Field Types';
export const QUESTIONS_TAB = 'Questions';
export const INTEGRATIONS_TAB = 'Integrations';

export const FREE_TEXT_LABEL = 'Text';
export const INTRODUCTION_LABEL = 'Introduction';
export const PROJECT_RECORD_LABEL = 'Project Name';
export const SINGLE_SELECTION_LABEL = 'Single Selection';
export const MULTIPLE_SELECTION_LABEL = 'Multiple Selection';
export const LOCATION_OBJECT_LABEL = 'Location';
export const PROFILE_LABEL = 'Profile';

export const QUESTION_TYPE_OPTIONS = [
  INTRODUCTION_LABEL,
  PROJECT_RECORD_LABEL,
  FREE_TEXT_LABEL,
  SINGLE_SELECTION_LABEL,
  MULTIPLE_SELECTION_LABEL,
  LOCATION_OBJECT_LABEL,
  PROFILE_LABEL,
];

export const iconMapping = {
  Approved: <CheckIcon color={DECISION_GO} />,
  Rejected: <CloseIcon color={DECISION_NO_GO} height="14" width="14" />,
  Maybe: <Maybe color={DECISION_MAYBE} height="16" width="16" />,
  Conditions: <ConditionsIcon color={DECISION_CONDITIONS} height="16" width="16" />,
};

export const INTAKE_FORM = 'intakes';
export const REPORT_MODEL_FORM = 'reports';
export const ASSESSMENT_MODEL_FORM = 'assessments';

// Question Types
export const FORM_BUILDER_QUESTION_TYPE = 'FormBuilderQuestion';
export const FORM_BUILDER_SECTION_FIELD = 'FormBuilderSectionField';
export const FORM_BUILDER_SECTION = 'FormBuilderSection';

export const ADVICE_ANSWER = 'AdviceFieldAnswer';
export const MULTIPLE_SELECTION_ANSWER = 'MultipleSelectionAnswer';
export const OTHER_ANSWER = 'Other';
export const RANKING_ANSWER = 'RankingAnswer';
export const SCORED_ANSWER = 'ScoredAnswer';
export const SINGLE_SELECTION_ANSWER = 'SingleSelectionAnswer';

export const ADVICE_FIELD = 'ADVICE_FIELD';
export const DATE_FIELD = 'DATE_FIELD';
export const DOCUMENT_FIELD = 'DOCUMENT_FIELD';
export const INTEGRATION_FIELD = 'INTEGRATION_FIELD';
export const INTRODUCTION_FIELD = 'INTRODUCTION_FIELD';
export const LOCATION_OBJECT_FIELD = 'LOCATION_OBJECT_FIELD';
export const MULTIPLE_SELECTION_FIELD = 'MULTIPLE_SELECTION_FIELD';
export const NUMERIC_FIELD = 'NUMERIC_FIELD';
export const PROFILE_FIELD = 'PROFILE_FIELD';
export const PROJECT_RECORD_FIELD = 'PROJECT_RECORD_FIELD';
export const RANKING_FIELD = 'RANKING_FIELD';
export const SCORED_FIELD = 'SCORED_FIELD';
export const SINGLE_SELECTION_FIELD = 'SINGLE_SELECTION_FIELD';
export const TEXT_FIELD = 'TEXT_FIELD';

// Integrations
export const MONTHLY_REPORT = 'Salesforce Monthly Report';
export const QUARTERLY_REPORT = 'Salesforce Quarterly Report';

export const SALESFORCE_INTEGRATION = {
  ACCOUNT_NAME: 'Salesforce Account',
  ACCOUNT_VAT: 'Cloud Innovation VAT',
  MONTHLY_REPORT_FINANCE: `${MONTHLY_REPORT} Finance`,
  MONTHLY_REPORT_HIGHS_LOWS: `${MONTHLY_REPORT} Highs & Lows`,
  MONTHLY_REPORT_MARKET: `${MONTHLY_REPORT} Market`,
  MONTHLY_REPORT_PRODUCT: `${MONTHLY_REPORT} Product`,
  MONTHLY_REPORT_TEAM: `${MONTHLY_REPORT} Team`,
  MONTHLY_REPORT_ESG: `${MONTHLY_REPORT} ESG`,
  PROFILES: 'Salesforce Profile',
  PROJECT: 'Salesforce Project',
  PROJECT_DETAILS: 'Salesforce Project Details',
  QUARTERLY_REPORT_FINANCE: `${QUARTERLY_REPORT} Finance`,
  QUARTERLY_REPORT_HIGHS_LOWS: `${QUARTERLY_REPORT} Highs & Lows`,
  QUARTERLY_REPORT_MARKET: `${QUARTERLY_REPORT} Market`,
  QUARTERLY_REPORT_PRODUCT: `${QUARTERLY_REPORT} Product`,
  QUARTERLY_REPORT_TEAM: `${QUARTERLY_REPORT} Team`,
  QUARTERLY_REPORT_ESG: `${QUARTERLY_REPORT} ESG`,
  SUBMIT_PROJECT: 'Salesforce Submit Project',
};

export const CREATED_SECTION = 'CREATED';
export const STATIC_SECTION = 'STATIC';

// Field types and input types
export const SINGLE_LINE_FIELD_TYPE = 'SINGLE_LINE';
export const MULTI_LINE_FIELD_TYPE = 'MULTI_LINE';
export const FIELD_TYPES = [SINGLE_LINE_FIELD_TYPE, MULTI_LINE_FIELD_TYPE];

export const MAX_LENGTH_FIELD = 250;

export const DEFAULT_PLACEHOLDER = 'Answer';
export const BLANK = '-';
