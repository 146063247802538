import {
  ALL_SCORES,
  CRITERIA_ORDER,
  DOMAIN_ORDER,
  KICK_OFF,
  MULTI_SINGLE_QUANTIFIED,
} from '../constants';

export const getCollapseCriteria = state => state.projectInnovatrix.collapse;

export const getProjectQuestionGroups = (project, sortedQuestionGroups, phaseId) => {
  // Special sorting
  let questionsArray = [];
  let questionGroupsSorting = [];
  if (sortedQuestionGroups) {
    sortedQuestionGroups.forEach(({ questions }) => {
      questionsArray = [...questionsArray, ...Object.values(questions)];
    });
    questionGroupsSorting = questionsArray.sort((x1, x2) => x1.order - x2.order).reduce((acc, question) => {
      if (acc.indexOf(question.questionGroupId) === -1) {
        return [...acc, question.questionGroupId];
      }
      return acc;
    }, []);

    sortedQuestionGroups.sort((qg1, qg2) => questionGroupsSorting.indexOf(qg1.id) - questionGroupsSorting.indexOf(qg2.id));
  }

  return sortedQuestionGroups && sortedQuestionGroups
    .reduce((questionGroupAcc, { questions, id: questionGroupId, label }) => {
      let questionGroupAverage = project.averagePerQuestionGroup && project.averagePerQuestionGroup[questionGroupId];
      let prevMean;
      let lastEvaluation;
      let equalVersion;
      let previousReview;

      // Take the evaluation (in this case our phase will represent an Evaluation)
      const currentReview = project.reviews.find(review => review.id === phaseId);
      if (currentReview && !currentReview.ratings[questionGroupId]) {
        // This domain does not apply to the review that is our starting point of comparison (current)
        // So this is not relevant in our comparison.
        return questionGroupAcc;
      }

      if (currentReview && currentReview.order !== 0) {
        // So we can only edit kick off when he's the first
        if (currentReview.name === KICK_OFF && !project.reviews[1]) {
          equalVersion = true;
          lastEvaluation = true;
        }
        else {
          // First evaluation is 'Kick-off', then we have 'Evaluation 1', 'Evaluation 2', etc.
          const sortedEvaluations = project.evaluations.sort((val1, val2) => val1.order - val2.order);
          // Take the evaluation we need with the index.
          const currentEvaluationIndex = sortedEvaluations.findIndex(evaluation => evaluation.id === currentReview.id);
          // If it's kick off we have no previous so we can just go on with our life
          if (currentEvaluationIndex > 0) {
            const previousEvaluationId = sortedEvaluations[currentEvaluationIndex - 1].id;
            // Fetch it!
            previousReview = project.reviews.find(review => review.id === previousEvaluationId);
            // Fill the previous mean if the previousReview can be found
            prevMean = previousReview && previousReview.ratings[questionGroupId] && previousReview.ratings[questionGroupId].average;
            equalVersion = previousReview.version === currentReview.version;
            // Tempfix until migration
            if (previousReview.version === null || currentReview.version === null) {
              equalVersion = true;
            }
            lastEvaluation = currentEvaluationIndex === sortedEvaluations.length - 1;
          }
        }
      }

      // Same story as above
      const specificReview = currentReview && currentReview.ratings[questionGroupId];
      questionGroupAverage = specificReview ? specificReview.average : -1;
      const count = specificReview.questions && Object.values(specificReview.questions).reduce((acc, q) => (q && typeof q.rating === 'number' ? acc + 1 : acc), 0);
      const ratingGroupProps = {
        average: questionGroupAverage,
        id: questionGroupId,
        // Is for phases so we see the global average
        overallAverage: null,
        // prevMean is for Evaluations (for comparing in Evaluations View)
        prevMean,
        title: label,
        hasRatings: count > 0,
        amountOfRatings: count,
      };

      const sortedQuestions = Object.values(questions)
        .map(item => item)
        .sort((item1, item2) => item1.order - item2.order);
      ratingGroupProps.ratingBars = sortedQuestions.reduce((questionsAcc, { id: questionId, label: questionTitle }) => {
        let description;
        let question1;
        let question2;

        if (currentReview && currentReview.ratings[questionGroupId] && !currentReview.ratings[questionGroupId].questions[questionId]) {
          // This criterion does not apply to the review that is our starting point of comparison (current)
          // So this is not relevant in our comparison
          return questionsAcc;
        }

        if (equalVersion) {
          question1 = currentReview && currentReview.ratings[questionGroupId] && currentReview.ratings[questionGroupId].questions[questionId];
          question2 = previousReview && previousReview.ratings[questionGroupId] && previousReview.ratings[questionGroupId].questions[questionId];
        }
        else {
          question1 = currentReview && currentReview.ratings[questionGroupId] && currentReview.ratings[questionGroupId].questions[questionId];
          if (question1 && question1.versionedId) {
            question2 = previousReview && previousReview.ratings[questionGroupId] && previousReview.ratings[questionGroupId].questions[question1.versionedId];
          }
        }
        if (question1 && question1.rating === -1) {
          description = 'Not Applicable';
        }
        else if (question1 && question1.rating === -2) {
          description = "Don't Know";
        }

        const scoreResult = {
          adviceType: question1.adviceType,
          argumentation: question1.argumentation,
          // Current
          comment: {
            description: question1 && question1.comment,
          },
          comments: [],
          condition: question1.condition,
          deletedOn: question1.deletedOn,
          max: question1.max,
          mean: !question1 || question1.rating < 0 ? null : question1.rating,
          // description for current mean
          description: (question1 && question1.levelDescription) || description,
          clarification: question1 && question1.clarification,
          prevMean: !question2 || question2.rating < 0 ? null : question2.rating,
          // General
          title: questionTitle,
          key: questionId,
          type: question1 && question1.type,
          answer: question1 && question1.answer,
          ranking: question1 && question1.ranking,
          order: question1 && question1.order,
          options: question1 && question1.options || [],
          id: questionId,
          threshold: question1 && question1.threshold,
          ratingId: question1 && question1.id,
          isEditable: Boolean(lastEvaluation),
          isUnavailableInPreviousVersion: (!question2 && !equalVersion),
          onlyEvaluation: previousReview === undefined,
        };

        return [...questionsAcc, scoreResult];
      }, []);
      return [...questionGroupAcc, ratingGroupProps];
    }, []);
};

export const getProjectDomainGroups = (project, questionGroups, selectedReviewer, isEvaluation, phaseOrder, isLegacyReview) => {
  if (!questionGroups) {
    return [];
  }

  const hasOrder = questionGroups.filter(qg => qg.order).length > 0;

  let sortedQuestionGroups;

  if (hasOrder) {
    /**
     * We remove the advice and general comments to display only the scores.
     * Since the advice questiongroup is normally the first in the array
     * we just remove the first entry and keep the rest
     */
    const [, ...questionGroupsWithoutAdviceGroup] = questionGroups;
    sortedQuestionGroups = questionGroupsWithoutAdviceGroup;
  }
  else {
    sortedQuestionGroups = questionGroups
      .filter(({ hasRatings }) => hasRatings)
      .sort((item1, item2) => DOMAIN_ORDER.indexOf(item1.label) - DOMAIN_ORDER.indexOf(item2.label));
  }

  return sortedQuestionGroups
    .reduce((domainAcc, { average, criteria: _criteria, questions: newQuestions, id: questionGroupId, label }) => {
      const questions = _criteria || newQuestions;
      // When in evaluation our phase is the id of our evaluation
      const hasReviewerSelected = selectedReviewer !== ALL_SCORES;
      // Initial value
      let domainAverage = isLegacyReview
        ? (project.averagePerDomain && project.averagePerDomain[questionGroupId])
        : project.averagePerQuestionGroup && project.averagePerQuestionGroup[questionGroupId];
      // Initialize variables
      let specificReview;
      let currentReview;
      let prevMean;

      // When it's no ealuation and we have a reviewer
      if (!isEvaluation && hasReviewerSelected) {
        // Take review from that specific reviewer
        currentReview = project.reviews.filter(review => review.reviewer.id === selectedReviewer)[0];
        // Take all ratings for our current domain (we have the specificDomainReviews)
        specificReview = currentReview && currentReview.ratings[questionGroupId];
        // Get the average (if null take -1)
        domainAverage = specificReview ? specificReview.average : -1;
      }

      const ratingGroupProps = {
        average: domainAverage,
        id: questionGroupId,
        // Is for phases so we see the global average
        overallAverage: isEvaluation ? null : average,
        // prevMean is for Evaluations (for comparing in Evaluations View)
        prevMean,
        title: label,
      };

      const sortedQuestions = Object.keys(questions)
        .map(item => item)
        .sort((item1, item2) => CRITERIA_ORDER.indexOf(questions[item1].label) - CRITERIA_ORDER.indexOf(questions[item2].label));

      ratingGroupProps.ratingBars = sortedQuestions.reduce((acc, questionId) => {
        let result = -1;
        let comment = null;
        let comments = [];

        if (hasReviewerSelected) {
          if (specificReview) {
            const question = (specificReview.criteria || specificReview.questions)[questionId];
            if (question) {
              // When the criterion can be found in the review fill in the comment and rating
              comment = question.comment;
              result = question.rating;
            }
          }
        }
        else {
          // Get all comments for a single question
          // Structure: [{ comment: 'This is a comment', reviewer: { id: 'abc123', name: 'John Doe' }, result: 3 }, {...}, ...]
          const criteria = project.reviews
            .map(review => (review.ratings && review.ratings[questionGroupId] ? { ...review.ratings[questionGroupId], reviewerId: review.reviewer?.id } : null))
            .filter(x => x)
            .map(questionGroup => ((questionGroup.criteria || questionGroup.questions)[questionId]
              ? { ...(questionGroup.criteria || questionGroup.questions)[questionId], reviewerId: questionGroup.reviewerId }
              : null));
          comments = criteria
            .filter(c => c)
            .map(c => ({ comment: c.comment, reviewer: project.reviewers?.find(reviewer => reviewer.id === c.reviewerId), result: c.rating }));
          const meanPerQuestion = project.meanPerCriterion || project.meanPerQuestion;
          // When there's no reviewer selected just go for the global mean for the criterion
          result = meanPerQuestion && meanPerQuestion[questionGroupId][questionId];
        }

        const scoreResult = {
          comment,
          comments,
          mean: result,
          label: questions[questionId].label,
          order: questions[questionId].order,
          singleReviewer: hasReviewerSelected,
          key: questionId,
          type: MULTI_SINGLE_QUANTIFIED,
        };

        if (!isEvaluation) {
          const minPerQuestion = project.minPerCriterion || project.minPerQuestion;
          const maxPerQuestion = project.maxPerCriterion || project.maxPerQuestion;
          const maxScorePerQuestion = project.maxScorePerQuestion;

          // If we're looking at at a phase fill in more like minimum etc...
          scoreResult.min = minPerQuestion && minPerQuestion[questionGroupId][questionId];
          scoreResult.max = maxPerQuestion && maxPerQuestion[questionGroupId][questionId];
          scoreResult.overallAverage = questions[questionId].average;
          scoreResult.maxScoreQuestion = maxScorePerQuestion && maxScorePerQuestion[questionGroupId][questionId];

          // TODO
          scoreResult.minimumLevel = questions[questionId].minimumLevels
            ? questions[questionId].minimumLevels[phaseOrder]
            : questions[questionId].threshold;

          // When it's a specific reviewer we need his score and the criterium so we can find the description
          if (hasReviewerSelected) {
            const rating = currentReview.ratings[questionGroupId];
            const question = rating && (rating.criteria || rating.questions)[questionId];
            scoreResult.description = question && (question.description || question.levelDescription);
          }
          else {
            // ALL case
            const reviews = (project.reviews || []).filter(review => (
              review.ratings[questionGroupId] &&
                (review.ratings[questionGroupId].criteria || review.ratings[questionGroupId].questions)[questionId] &&
                (review.ratings[questionGroupId].criteria || review.ratings[questionGroupId].questions)[questionId].rating === Math.floor(result)
            ));
            const question = reviews[0] && (reviews[0].ratings[questionGroupId].criteria || reviews[0].ratings[questionGroupId].questions)[questionId];
            // Find the description closest to the mean
            scoreResult.description = question && (question.description || question.levelDescription);
          }
        }

        return [...acc, scoreResult];
      }, []);
      // Sort the ratingBars on their order value
      ratingGroupProps.ratingBars = ratingGroupProps.ratingBars.sort((a, b) => ((a.order > b.order) ? 1 : -1));
      return [...domainAcc, ratingGroupProps];
    }, []);
};
