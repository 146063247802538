import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MinusIcon from '@innovatrix/icons/MinusIcon';
import AddIcon from '@innovatrix/icons/AddIcon';
import { PRIMARY } from '@innovatrix/styles';
import formikField from '@innovatrix/components/fields/formikField';
import { IS_PRODUCTION } from '@innovatrix/constants';

import { Paragraph } from '../text';
import { IconButton } from '../buttons';

import { FormGroup, LabelGroup, InputGroup } from './_helpers';
import { defaultFieldPropTypes } from './_constants';

const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.border.radius};
  display: flex;
  height: 36px;
  justify-content: space-between;
  padding: 2px 4px;
  width: ${({ width }) => width};
`;

const InnerParagraph = styled(Paragraph)`
  margin-bottom: 0;
  margin-left: 2px;
  margin-right: 2px;
  padding: 4px;
  text-align: center;
  width: ${({ hasUnit }) => (hasUnit ? 'auto' : '32px')};
`;

export const AmountSelector = React.memo(({
  touched, required, fieldIcon, className, disabled,
  label, information, error, step, min, max, onChange, value, width,
  unit, decrementTooltip, incrementTooltip,
}) => {
  const hasMax = max !== null && max !== undefined; // would be so much easier with != null... Linter...
  const hasMin = min !== null && min !== undefined; // would be so much easier with != null... Linter...

  if (!IS_PRODUCTION) {
    if (hasMin && isNaN(Number(min))) {
      throw new Error('Expected a number for the min property in <AmountSelector />.');
    }

    if (hasMax && isNaN(Number(max))) {
      throw new Error('Expected a number for the max property in <AmountSelector />.');
    }

    if (hasMax && hasMin && !disabled && min >= max) {
      throw new Error(
        `Invalid min/max passed to the <AmountSelector /> component.
        Max should be larger than min.`,
      );
    }

    if (step === 0 || isNaN(Number(step))) {
      throw new Error(
        `Invalid step passed to the <AmountSelector /> component.
        Expected step to not be 0 and it to be a number.`,
      );
    }
  }

  const increment = React.useCallback(() => {
    if (disabled) { return; }
    if (!hasMax || value + step <= max) {
      onChange(value + step);
    }
    else if (hasMax && value + step > max && value !== max) {
      onChange(max);
    }
  }, [disabled, hasMax, max, onChange, step, value]);

  const decrement = React.useCallback(() => {
    if (disabled) { return; }
    if (!hasMin || value - step >= min) {
      onChange(value - step);
    }
    else if (hasMin && value - step < min && value !== min) {
      onChange(min);
    }
  }, [disabled, hasMin, min, onChange, step, value]);

  return (
    <FormGroup className={className}>
      {label && <LabelGroup touched={touched} error={error} label={label} information={information} required={required} />}
      <InputGroup icon={fieldIcon}>
        <Wrapper width={width}>
          {!disabled &&
          <IconButton disabled={value === min} small icon={<MinusIcon color={PRIMARY} />} onClick={decrement} tooltip={decrementTooltip} />}
          <InnerParagraph hasUnit={Boolean(unit)}>
            {value}{unit ? ` ${unit}` : ''}
          </InnerParagraph>
          {!disabled &&
          <IconButton disabled={value === max} small icon={<AddIcon color={PRIMARY} />} onClick={increment} tooltip={incrementTooltip} />}
        </Wrapper>
      </InputGroup>
    </FormGroup>

  );
});

AmountSelector.displayName = 'AmountSelector';
AmountSelector.propTypes = {
  ...defaultFieldPropTypes,
  decrementTooltip: PropTypes.string,
  fieldIcon: PropTypes.node,
  incrementTooltip: PropTypes.string,
  information: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  touched: PropTypes.bool,
  value: PropTypes.number.isRequired,
  width: PropTypes.string,
};
AmountSelector.defaultProps = {
  max: Infinity,
  min: -Infinity,
  step: 1,
  width: 'auto',
};

export default formikField(AmountSelector);
