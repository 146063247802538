import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { push } from 'connected-react-router';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { FETCH_OBJECTIVE_TYPES, FETCH_OBJECTIVES, MOVE_OBJECTIVE, DELETE_OBJECTIVE, COMPLETE_OBJECTIVE, OBJECTIVES_TAB, ALL_OBJECTIVES, ADD_OBJ_TYPE, COMPLETED_OBJECTIVES } from '../constants';
import { getObjectiveTypesQuery, createObjectiveTypeMutation, getObjectivesQuery, createObjectiveMutation, moveObjectiveMutation, deleteObjectiveMutation, completeObjectiveMutation, updateObjectiveMutation, persistObjectiveTypesQuery, updateObjectiveCommentMutation, createObjectiveCommentMutation } from '../queries';
import { completeObjectiveSuccessAction, receiveObjectiveTypesAction, createObjectiveTypeAction, fetchObjectiveTypesAction, receiveObjectivesAction, persistObjectiveAction, createObjectiveSuccessAction, fetchObjectivesAction, completeObjectiveErrorAction, deleteObjectiveErrorAction, errorMoveObjectiveAction, errorObjectivesAction, errorObjectiveTypesAction, completeObjectiveAnimationSuccessAction, persistObjectiveTypesAction, persistObjectiveCommentAction } from '../actions/objectiveActions';
import * as toast from '../utils/toast';

import * as api from './_api';
import { fetchEntitiesSaga } from './_utils';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const projectUrlPrefix = (campaignId, projectId) => (campaignId ? `/campaigns/${campaignId}/projects/${projectId}` : `/projects/${projectId}`);

/**
 * @name objectivesSaga
 */
export default function* () {
  yield takeEvery(FETCH_OBJECTIVE_TYPES, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorObjectiveTypesAction,
    errorMessage: 'Failed to fetch objective types',
    keyword: '_getObjectiveTypes',
    query: getObjectiveTypesQuery,
    receiveAction: receiveObjectiveTypesAction,
  }));

  yield takeEvery(FETCH_OBJECTIVES, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorObjectivesAction,
    errorMessage: 'Failed to fetch objectives',
    keyword: '_getObjectives',
    query: getObjectivesQuery,
    receiveAction: receiveObjectivesAction,
  }));

  yield takeEvery(persistObjectiveTypesAction.REQUEST, function* persistObjectiveTypes({ payload: { objectiveTypes, projectId, callback } }) {
    try {
      const variables = {
        objectiveTypes: objectiveTypes.map(({ id, title }) => ({ id, title })),
        projectId,
      };
      yield call(api.fetch, '_persistObjectiveTypes', persistObjectiveTypesQuery, { variables });
      yield put(fetchObjectiveTypesAction({ projectId }));
      callback();
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to save the objective types.' });
      callback(err);
    }
  });

  yield takeEvery(DELETE_OBJECTIVE, function* deleteObjective({ campaignId, objectiveTypeId, projectId, objectiveId }) {
    try {
      const variables = { objectiveId };
      yield call(api.fetch, '_deleteObjective', deleteObjectiveMutation, { variables });
      const currObjectiveTypeId = objectiveTypeId === ALL_OBJECTIVES || objectiveTypeId === ADD_OBJ_TYPE || objectiveTypeId === COMPLETED_OBJECTIVES ? undefined : objectiveTypeId;
      const currCompleted = objectiveTypeId === 'Completed' ? true : undefined;

      // Reroute to current objectiveTypeId
      yield put(fetchObjectiveTypesAction({ projectId }));
      yield put(fetchObjectivesAction({ projectId, objectiveTypeId: currObjectiveTypeId, completed: currCompleted }));
      yield put(push(`${projectUrlPrefix(campaignId, projectId)}/${OBJECTIVES_TAB}/${objectiveTypeId}`));
    }
    catch (error) {
      yield put(deleteObjectiveErrorAction({ error, objectiveId, objectiveTypeId, projectId }));
      if (error.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, error, { title: 'Failed to delete the objective.' });
    }
  });

  yield takeEvery(persistObjectiveAction.REQUEST, function* persistObjective({ payload: { campaignId, id, projectId, title, deadline, description, objectiveTypeId }, callback }) {
    try {
      // In case of edit.
      if (id) {
        const variables = { objective: { id, title, deadline, description } };
        yield call(api.fetch, '_updateObjective', updateObjectiveMutation, { variables });
        yield put(fetchObjectivesAction({ projectId, objectiveTypeId, completed: undefined }));
        callback();
      }
      else {
        const variables = { objective: { id, title, deadline, description }, objectiveTypeId, projectId };
        const objective = yield call(api.fetch, '_createObjective', createObjectiveMutation, { variables });
        callback();
        yield put(createObjectiveSuccessAction({ objectiveId: objective.id }));
        // A new one has been created so our types will be bigger and better
        yield put(fetchObjectivesAction({ projectId, objectiveTypeId, completed: undefined }));
        yield put(fetchObjectiveTypesAction({ projectId }));
        yield put(push(`${projectUrlPrefix(campaignId, projectId)}/${OBJECTIVES_TAB}/${objectiveTypeId || ALL_OBJECTIVES}/${objective.id}`));
      }
    }
    catch (err) {
      callback(err);
    }
  });

  yield takeEvery(createObjectiveTypeAction.REQUEST, function* createObjectType({ payload: { campaignId, projectId, typeName }, callback }) {
    try {
      const variables = { projectId, objectiveType: { title: typeName } };
      /* const newTypeId = */yield call(api.fetch, '_createObjectiveType', createObjectiveTypeMutation, { variables });
      yield put(fetchObjectiveTypesAction({ projectId }));
      yield put(push(`${projectUrlPrefix(campaignId, projectId)}/${OBJECTIVES_TAB}/${ALL_OBJECTIVES}`));
      callback();
    }
    catch (err) {
      callback(err);
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to create the type.' });
    }
  });

  yield takeLatest(COMPLETE_OBJECTIVE, function* completeObjective({ completed, objectiveId, objectiveTypeId, projectId }) {
    try {
      const variables = { completed, objectiveId };
      yield call(api.fetch, '_toggleCompleteObjective', completeObjectiveMutation, { variables });
      const fetchObjectiveTypeId = objectiveTypeId === ALL_OBJECTIVES || objectiveTypeId === COMPLETED_OBJECTIVES ? undefined : objectiveTypeId;
      const fetchCompleted = objectiveTypeId === COMPLETED_OBJECTIVES ? true : undefined;

      switch (completed) {
        // Wait until toast is closed.
        case true: yield toast.success('KPI will be set to completed!'); break;
        case false: yield toast.success('KPI will be set to incompleted!'); break;
        default: yield toast.success('KPI will be set to active!'); break;
      }

      yield put(completeObjectiveSuccessAction({ completed: fetchCompleted, objectiveTypeId, objectiveId, projectId, result: completed }));
      yield delay(300);
      yield put(fetchObjectiveTypesAction({ projectId }));
      yield put(completeObjectiveAnimationSuccessAction());
      yield put(fetchObjectivesAction({ completed: fetchCompleted, objectiveTypeId: fetchObjectiveTypeId, projectId }));
    }
    catch (error) {
      yield put(completeObjectiveErrorAction({ completed, error, objectiveId, objectiveTypeId, projectId }));
      if (error.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, error, { title: 'Failed to complete the objective.' });
    }
  });

  yield takeEvery(MOVE_OBJECTIVE, function* moveObjective({ campaignId, from, objectiveId, objectiveTypeId, projectId }) {
    try {
      const variables = { objectiveId, objectiveTypeId };
      yield call(api.fetch, '_moveObjective', moveObjectiveMutation, { variables });
      toast.success('Objective moved!');

      if (from === ALL_OBJECTIVES) {
        yield put(fetchObjectiveTypesAction({ projectId }));
        yield put(fetchObjectivesAction({ projectId, completed: undefined, objectiveTypeId: undefined }));
        yield put(push(`${projectUrlPrefix(campaignId, projectId)}/${OBJECTIVES_TAB}/${ALL_OBJECTIVES}/${objectiveId}`));
      }
      else {
        yield put(push(`${projectUrlPrefix(campaignId, projectId)}/${OBJECTIVES_TAB}/${objectiveTypeId}/${objectiveId}`));
      }
    }
    catch (err) {
      yield put(errorMoveObjectiveAction({ campaignId, objectiveId, objectiveTypeId, projectId }));
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to move the objective.' });
    }
  });

  yield takeEvery(persistObjectiveCommentAction.REQUEST, function* persistObjectiveComment({
    callback,
    payload: {
      comment, completed, id, objectiveId, objectiveTypeId, projectId,
    },
  }) {
    try {
      if (id) {
        yield call(api.fetch, '_updateObjectiveComment', updateObjectiveCommentMutation, {
          variables: {
            comment,
            id,
          },
        });
      }
      else {
        yield call(api.fetch, '_createObjectiveComment', createObjectiveCommentMutation, {
          variables: {
            comment,
            objectiveId,
          },
        });
      }
      callback();
      yield put(fetchObjectivesAction({ completed, projectId, objectiveTypeId }));
    }
    catch (err) {
      yield call(showErrorModal, err, { title: 'Failed to save the comment.' });
      callback(err);
    }
  });
}
