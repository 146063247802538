import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const AsterixIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 20 20" {...styles} {...props}>
    <path d="M2.5 17.7l4.4-6.6L0 8.8 1.1 5l7 2.7V0h3.8v7.7l7-2.7L20 8.8l-6.9 2.3 4.5 6.6-3.3 2.3-4.2-6.9L5.7 20z" />
  </svg>
);

AsterixIcon.propTypes = propTypes;
AsterixIcon.defaultProps = defaultProps;

export default AsterixIcon;
