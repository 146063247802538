import { FETCH_MANAGERS_AND_COACHES, RECEIVE_MANAGERS_AND_COACHES, ERROR_MANAGERS_AND_COACHES } from '../constants';

export const fetchManagersAndCoachesAction = (search) => ({
  search,
  type: FETCH_MANAGERS_AND_COACHES,
});

export const receiveManagersAndCoachesAction = ({ data, search }) => ({
  data,
  search,
  type: RECEIVE_MANAGERS_AND_COACHES,
});

export const errorManagersAndCoachesAction = ({ error, search }) => ({
  error,
  search,
  type: ERROR_MANAGERS_AND_COACHES,
});
