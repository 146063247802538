import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const EditIcon = ({ className, color, styles, ...props }) => (
  <svg className={className} fill={color} viewBox="0 0 40 40" {...styles} {...props}>
    <path d="M7.4 28.8l17.9-17.9 6.1 6.1-17.9 18H7.4v-6.2zM34 14.5l-6.1-6.1 2.9-2.9c.6-.6 1.8-.6 2.3 0l3.8 3.8c.6.6.6 1.8 0 2.3L34 14.5z" />
  </svg>
);

EditIcon.propTypes = propTypes;
EditIcon.defaultProps = defaultProps;

export default EditIcon;
