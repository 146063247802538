import styled from '@emotion/styled';

const Body = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  line-height: 1.25;
  margin-bottom: 8px;
  word-wrap: break-word;
`;

export default Body;
