import * as React from 'react';
import PropTypes from 'prop-types';

import {
  ADVICE_FIELD,
  DATE_FIELD,
  DOCUMENT_FIELD,
  INTRODUCTION_FIELD,
  LOCATION_OBJECT_FIELD,
  MULTIPLE_SELECTION_FIELD,
  NUMERIC_FIELD,
  PROFILE_FIELD,
  PROJECT_RECORD_FIELD,
  RANKING_FIELD,
  SCORED_FIELD,
  SINGLE_SELECTION_FIELD,
  TEXT_FIELD,
} from '../../../_constants';
import { QuestionTypeDescription, QuestionTypeInformation, QuestionTypeTitle } from '../_styles';

export const FieldData = ({
  type,
  fallbackText,
  label,
  value,
  description,
}) => {
  switch (type) {
    case INTRODUCTION_FIELD:
      // eslint-disable-next-line no-case-declarations
      const richText = value.getCurrentContent().getPlainText('\u0001');
      return (
        <QuestionTypeInformation>
          <QuestionTypeTitle isEmpty={!richText}>
            {richText || fallbackText}
          </QuestionTypeTitle>
        </QuestionTypeInformation>
      );
    case NUMERIC_FIELD:
    case DATE_FIELD:
    case PROJECT_RECORD_FIELD:
    case TEXT_FIELD:
    case LOCATION_OBJECT_FIELD:
    case PROFILE_FIELD:
    case SINGLE_SELECTION_FIELD:
    case MULTIPLE_SELECTION_FIELD:
    case DOCUMENT_FIELD:
    case RANKING_FIELD:
    case ADVICE_FIELD:
    case SCORED_FIELD:
      return (
        <QuestionTypeInformation>
          <QuestionTypeTitle>{label || fallbackText}</QuestionTypeTitle>
          {description ? (
            <QuestionTypeDescription>{description}</QuestionTypeDescription>
          ) : null}
        </QuestionTypeInformation>
      );
    default:
      return <p>Unsupported question</p>;
  }
};

FieldData.propTypes = {
  description: PropTypes.string,
  fallbackText: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

export default FieldData;
