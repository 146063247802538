import {
  TOGGLE_OBJECTIVES_CREATING,
  TOGGLE_SHOW_COMPLETED,
  SET_FIELD_EDIT_STATUS,
  COMPLETE_OBJECTIVE,
  COMPLETE_OBJECTIVE_SUCCESS,
  COMPLETE_OBJECTIVE_ANIMATION_SUCCESS,
} from '../constants';

const initialState = {
  animatedObjectiveId: null,
  completing: false,
  creating: false,
  fieldEditStatusses: {},
  showCompleted: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_OBJECTIVE_SUCCESS: {
      // Should move with an animation.
      return {
        ...state,
        animatedObjectiveId: action.objectiveId,
        completing: false,
      };
    }
    case COMPLETE_OBJECTIVE_ANIMATION_SUCCESS: {
      return {
        ...state,
        animatedObjectiveId: null,
      };
    }
    case SET_FIELD_EDIT_STATUS: {
      const { fieldId, status } = action;
      return {
        ...state,
        fieldEditStatusses: {
          ...state.fieldEditStatusses,
          [fieldId]: status,
        },
      };
    }
    case TOGGLE_OBJECTIVES_CREATING: {
      const { creating } = action;
      return {
        ...state,
        creating,
      };
    }
    case TOGGLE_SHOW_COMPLETED: {
      const { showCompleted } = state;
      return {
        ...state,
        showCompleted: !showCompleted,
      };
    }
    case COMPLETE_OBJECTIVE: {
      return {
        ...state,
        completing: true,
      };
    }
    default:
      return state;
  }
};

