import React from 'react';
import styled from '@emotion/styled';
import { Chapter, ChapterHeader, Module, Section } from '@innovatrix/components';

// -- Styles --------------- --- --  -

const StyledSection = styled(Section)`
  min-height: 200px;
  padding-top: 24px;
  padding: 32px;
`;

// -- Component --------------- --- --  -

const SigningIn = () => (
  <Chapter>
    <ChapterHeader title="Signing In" />
    <Module>
      <StyledSection>
        Signing In...
      </StyledSection>
    </Module>
  </Chapter>
);

export default SigningIn;
