import React, { PureComponent } from 'react';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import { WHITE, PRIMARY, GREY_1, GREY_LIGHT_1, GREY_BLUEISH } from '@innovatrix/styles';
import User from '@innovatrix/icons/User';
import Unknown from '@innovatrix/icons/Unknown';
import { prevent } from '@innovatrix/utils';

import formikField from '../formikField';

import CheckedSVG from './Completed';

const disabledStyles = css({
  backgroundColor: GREY_LIGHT_1,
  ':hover': {
    background: WHITE,
  },
});

const checkStyles = css({
  alignItems: 'center',
  display: 'flex',
  verticalAlign: 'center',
  '> .selected': {
    backgroundColor: PRIMARY,
  },
  '> .disabled': {
    cursor: 'default',
    opacity: 0.5,
  },
  '.disabled': disabledStyles,
});

const checkLabelStyles = css({
  cursor: 'pointer',
  display: 'inline-block',
  overflow: 'hidden',
  paddingLeft: 8,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const baseCheckBoxStyles = css({
  alignItems: 'center',
  backgroundColor: WHITE,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '16px',
  minWidth: '16px',
  borderRadius: 2,
});

const normalFlavorStyles = css({
  border: `1px solid ${GREY_1}`,
  ':hover': {
    borderColor: PRIMARY,
    backgroundColor: '#ABE6FF',
  },
  '.selected': {
    borderColor: PRIMARY,
    ':hover': {
      backgroundColor: PRIMARY,
    },
  },
});

const userFlavorStyles = css({
  border: 0,
  height: 24,
  width: 24,
  transition: 'all 0.3s',
  ':hover': {
    backgroundColor: GREY_BLUEISH,
    transition: 'all 0.3s',
    '> svg > path': {
      fill: PRIMARY,
    },
  },
  '.selected': {
    backgroundColor: WHITE,
    ':hover': {
      backgroundColor: GREY_BLUEISH,
    },
  },
});

const questionFlavorStyles = css({
  border: `1px solid ${GREY_1}`,
  backgroundColor: GREY_LIGHT_1,
  borderRadius: 2,
  '> svg': {
    width: 10,
    height: 10,
    '> path': {
      fill: GREY_1,
    },
  },
  ':hover': {
    borderColor: PRIMARY,
    backgroundColor: '#ABE6FF',
    '> svg > path': {
      fill: PRIMARY,
    },
  },
  '.selected': {
    borderColor: PRIMARY,
    ':hover': {
      backgroundColor: PRIMARY,
    },
  },
});

const flavors = {
  normal: css(baseCheckBoxStyles, normalFlavorStyles),
  user: css(baseCheckBoxStyles, userFlavorStyles),
  question: css(baseCheckBoxStyles, questionFlavorStyles),
};

export class Check extends PureComponent {

  onChange = (e) => {
    const { value, onChange } = this.props;
    prevent(e);
    if (onChange) {
      onChange(!value);
    }
  }

  getIcon(flavor, color, value) {
    switch (flavor) {
      case 'normal': return value ? <CheckedSVG color={color} /> : null;
      case 'user': return value ? <User color={PRIMARY} /> : <User color={GREY_1} />;
      case 'question': return value ? <Unknown color={PRIMARY} /> : <Unknown color={GREY_1} />;
      default: throw new Error('unknown flavor');
    }
  }

  render() {
    const { color, disabled, label, flavor, value, styles, className } = this.props;
    return (
      <div
        {...checkStyles}
        className={`checkField ${disabled ? 'disabled ' : ' '} ${styles ? styles.toString() : ''} ${className}`}
        onClick={disabled ? undefined : this.onChange}
      >
        <span
          {...flavors[flavor]}
          className={`checkBox ${value ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
          checked={value}
          disabled={disabled}
        >
          {this.getIcon(flavor, color, value)}
        </span>
        {label && <span {...checkLabelStyles} className="checkLabel" htmlFor={this.key} disabled={disabled} title={label}>{label}</span>}
      </div>
    );
  }

}

Check.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  flavor: PropTypes.string, // normal checkBox, userBox or questionBox
  getIcon: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func,
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.bool,
};

Check.defaultProps = {
  color: WHITE,
  disabled: false,
  flavor: 'normal',
  label: null,
};

export default formikField(Check);
