import { createBrowserHistory as createHistory } from 'history';
import { applyMiddleware, combineReducers, createStore as createReduxStore } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { dxAuthReducer } from '@innovatrix/react-frontend/reducers/dxAuthReducer';
import { dxQueryReducer } from '@innovatrix/react-frontend/reducers/dxQueryReducer';
import { dxModalReducer } from '@innovatrix/react-frontend/reducers/dxModalReducer';
import { dxAuthSagas } from '@innovatrix/react-frontend/sagas/dxAuth';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import {
  DX_QUERY_CANCELLED,
  DX_QUERY_CONTEXT_COMPLETE,
  DX_QUERY_CONTEXT_PENDING,
  DX_QUERY_FAILED,
  DX_QUERY_POSTED,
  DX_QUERY_SUCCESS,
  IS_PRODUCTION,
} from '../constants';

const dxSagas = function* () {
  yield spawn(dxAuthSagas);
};

const dxReducers = {
  dxAuth: dxAuthReducer,
  dxModals: dxModalReducer,
  dxQueries: dxQueryReducer,
};

const actionsLogBlacklist = [
  '@@router/LOCATION_CHANGE',
  DX_QUERY_CANCELLED,
  DX_QUERY_CONTEXT_COMPLETE,
  DX_QUERY_CONTEXT_PENDING,
  DX_QUERY_FAILED,
  DX_QUERY_POSTED,
  DX_QUERY_SUCCESS,
];

const defaultReduxLoggerOptions = {
  collapsed: true,
  predicate: (getState, action) => !actionsLogBlacklist.includes(action.type),
};

export const sagaMiddleware = createSagaMiddleware();

/**
 * @name createStore
 * Creates a standard fully-featured Redux store.
 * @param {object} [reducers] - An object with project-specific Redux reducers (reducing functions),
 *   indexed by the keys to use in the resulting combined reducer.
 * @param {Function} [saga] - A generator function to be run by the redux-saga middleware.
 * @param {object} [history] - A custom history tied to both router and redux. Defaults to a
 *   browser-history.
 * @param {object} [reduxLoggerOptions] - An optional object with options for the `redux-logger`
 *   middleware. See `https://github.com/evgenyrodionov/redux-logger` for more details on the
 *   possible options.
 * @returns {object} A Redux store.
 */
export default function createStore(reducers, saga, history, reduxLoggerOptions) {
  // Use browser-history as default history:
  if (!history) { history = createHistory(); }

  // Apply all default middleware:
  const middleware = applyMiddleware(
    ...[
      routerMiddleware(history),
      sagaMiddleware,
      IS_PRODUCTION ? undefined : createLogger({ ...defaultReduxLoggerOptions, ...reduxLoggerOptions }),
    ].filter(Boolean),
  );

  // The generic Duxis Foundation reducers:
  const duxisReducers = {
    ...dxReducers,
  };

  const staticReducers = {
    ...duxisReducers,
    ...reducers,
    router: connectRouter(history),
  };

  // Create the store:
  const store = createReduxStore(
    combineReducers(staticReducers),
    middleware,
  );

  store.createReducer = () => combineReducers({
    ...store.staticReducers,
    ...store.asyncReducers,
  });

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};
  store.staticReducers = staticReducers;

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    if (!store.asyncReducers[key]) {
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(store.createReducer());
    }
  };

  store.removeReducer = (key) => {
    if (store.asyncReducers[key]) {
      delete store.asyncReducers[key];
      delete store.getState()[key];
      store.replaceReducer(store.createReducer());
    }
  };

  // Run the main saga (when given):
  sagaMiddleware.run(function* () {
    yield spawn(dxSagas);
    if (saga) { yield spawn(saga, store); }
  });

  return store;
}
