import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, select, takeEvery } from 'redux-saga/effects';

import { getDxAuthToken } from '../selectors/authSelectors';
import {
  CREATE_DOCUMENT, DOWNLOAD_DOCUMENT, CREATE_NEW_DOCUMENT_VERSION, ATTACH_DOCUMENT_TEMPLATE, ATTACH_WORKSHOP_DOCUMENT, ATTACH_WORKSHOP_DOCUMENT_FOR_REVISION,
  ATTACH_REVISED_DOCUMENT, DOWNLOAD_ALL_REVISED_DOCUMENTS,
  CREATE_NEW_WORKSHOP_DOCUMENT_VERSION, ATTACH_FORM_DOCUMENT, ATTACH_LEGAL_DOCUMENT, DOWNLOAD_INNOVATRIX_LEGAL_DOCUMENT,
} from '../constants';
import { config } from '../config';
import { sanitizeFile } from '../utils';

import * as api from './_api';

const REST_BASE_URL = config.get('innovatrix.hosts.dxApi').replace('/graphql', '');
// Remove ".api" from REST URL to retrieve the HOST_URL
const HOST_URL = REST_BASE_URL.replace('api.', '');

const mapPropsToFormData = (props) => {
  const formData = new FormData();
  Object.keys(props).forEach(key => {
    // Only add value to formData if it's not undefined
    if (props[key]) {
      formData.append(key, props[key]);
    }
  });
  return formData;
};

function* downloadFile({ isPreview, url, name, skipAuth = false, requestType }) {
  const authToken = skipAuth ? undefined : yield select(getDxAuthToken);
  const formData = mapPropsToFormData({ name });
  if (isPreview) {
    yield api.previewFile(authToken, url, formData, requestType);
  }
  else {
    yield api.downloadFile(authToken, url, formData, requestType);
  }
}

export default function* () {
  yield takeEvery(CREATE_DOCUMENT, function* createDocument({ callback, file, folderId, projectId }) {
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({ file: sanitizedFile, folderId, projectId });
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/documents`, formData);
      callback(null, { projectId });
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to upload your file.' });
    }
  });

  yield takeEvery(DOWNLOAD_DOCUMENT, function* downloadDocument(params) {
    try {
      const { isPreview, skipAuth } = params;
      const authToken = skipAuth ? null : yield select(getDxAuthToken);
      const apiCall = isPreview ? api.previewFile : api.downloadFile;
      const formData = mapPropsToFormData(params);
      yield call(apiCall, authToken, `${REST_BASE_URL}/download-document`, formData);
    }
    catch (e) {
      yield call(showErrorModal, e, { title: 'Failed to download document.' });
    }
  });

  yield takeEvery(DOWNLOAD_ALL_REVISED_DOCUMENTS, function* downloadAllRevisedDocuments({ workshopMomentId, name }) {
    try {
      const authToken = yield select(getDxAuthToken);
      const formData = new FormData();
      formData.append('name', name);
      yield api.downloadFile(authToken, `${REST_BASE_URL}/projects/workshops/${workshopMomentId}/download-all-revised-documents`, formData);
    }
    catch (e) {
      yield call(showErrorModal, e, { title: 'Failed to download the revised documents zip.' });
    }
  });

  yield takeEvery(CREATE_NEW_DOCUMENT_VERSION, function* createNewDocumentVersion({ callback, ...variables }) {
    const { file, projectId } = variables;
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({
        ...variables,
        file: sanitizedFile,
      });
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/new-document-version`, formData);
      callback(null, { projectId });
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to upload the new version' });
    }
  });

  yield takeEvery(CREATE_NEW_WORKSHOP_DOCUMENT_VERSION, function* attachNewWorkshopDocumentVersion({ file, workshopMomentId, documentId, callback }) {
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({ documentId, file: sanitizedFile, workshopMomentId });
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/attach-new-version-workshop-document`, formData);
      callback(null, { workshopMomentId });
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to upload a new version for the current workshop document' });
    }
  });

  yield takeEvery(ATTACH_DOCUMENT_TEMPLATE, function* createDocumentTemplate({
    file,
    formId,
    formType,
    questionId,
    sectionId,
    sectionOrder,
    order,
    callback,
    label,
    downloadOnly,
  }) {
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({
        downloadOnly,
        file: sanitizedFile,
        formId,
        formType,
        label,
        order,
        questionId,
        sectionId,
        sectionOrder,
      });
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/attach-document-template`, formData);
      callback(null, { questionId });
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to upload the workshop document' });
    }
  });

  yield takeEvery(ATTACH_FORM_DOCUMENT, function* attachFormDocument({ file, answerId, questionId, callback, formType, isPreview }) {
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({
        answerId,
        file: sanitizedFile,
        formType,
        isPreview: isPreview ? 'IS_PREVIEW' : 'NO_PREVIEW',
        questionId,
      });
      const authToken = yield select(getDxAuthToken);
      const { data } = yield call(api.uploadFile, authToken, `${REST_BASE_URL}/attach-form-document`, formData);
      callback(null, { questionId, document: data.document });
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: `Failed to upload the ${formType} document` });
    }
  });

  yield takeEvery(ATTACH_WORKSHOP_DOCUMENT, function* attachWorkshopDocument({ file, workshopMomentId, callback }) {
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({ file: sanitizedFile, workshopMomentId });
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/attach-workshop-document`, formData);
      callback(null, { workshopMomentId });
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to upload the workshop document' });
    }
  });

  yield takeEvery(ATTACH_WORKSHOP_DOCUMENT_FOR_REVISION, function* attachWorkshopDocumentForRevision({ file, callback, type, workshopMomentId, ...rest }) {
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({ file: sanitizedFile, workshopMomentId, ...rest });
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/attach-workshop-document-for-revision`, formData);
      callback(null, { workshopMomentId });
    }
    catch (err) {
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to upload the workshop document for revision' });
    }
  });

  yield takeEvery(ATTACH_REVISED_DOCUMENT, function* attachRevisedDocument({
    file, workshopMomentId, projectId, callback, documentId,
  }) {
    try {
      const sanitizedFile = sanitizeFile(file);
      const formData = mapPropsToFormData({
        documentId,
        file: sanitizedFile,
        projectId,
        workshopMomentId,
      });
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/attach-revised-document`, formData);
      callback(null, { workshopMomentId });
    }
    catch (err) {
      callback(err);
      const isInvalidType = err && err.response && err.response.data.message.includes('should be of the same type');
      if (!isInvalidType) {
        yield call(showErrorModal, err, { title: 'Failed to upload the workshop document for revision' });
      }
    }
  });

  yield takeEvery(ATTACH_LEGAL_DOCUMENT, function* attachLegalDocument({ callback, type, ...rest }) {
    try {
      const formData = mapPropsToFormData(rest);
      const authToken = yield select(getDxAuthToken);
      yield call(api.uploadFile, authToken, `${REST_BASE_URL}/attach-legal-document`, formData);
      callback(null, { ...rest });
    }
    catch (err) {
      callback(err);
    }
  });

  yield takeEvery(DOWNLOAD_INNOVATRIX_LEGAL_DOCUMENT, function* downloadLegalDocument({ isPreview, name }) {
    try {
      const url = `${HOST_URL}/static/innovatrix-privacy-policy.pdf`;
      yield downloadFile({ isPreview, name, url, requestType: 'GET' });
    }
    catch (e) {
      console.error(e);
    }
  });
}
