import { createSelector } from 'reselect';
import queryString from 'query-string';

import { DEFAULT_ENTITIES } from './utils';

const getManagersAndCoachesData = state => state.data.managersAndCoaches;

const getManagersAndCoachesRelations = createSelector(
  getManagersAndCoachesData,
  (users) => users.relations,
);

const getManagersAndCoachesHasUsers = createSelector(
  getManagersAndCoachesRelations,
  (relations) => relations.hasUsers,
);

export const getManagersAndCoaches = (state, { search }) => createSelector(
  getManagersAndCoachesHasUsers,
  (relations) => relations[queryString.stringify({ search })] || DEFAULT_ENTITIES,
)(state);
