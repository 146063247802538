import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { GREY_1, GREY_2, SECONDARY } from '@innovatrix/styles';

const FONT = 'Raleway';

const titleH1Styles = css({
  color: SECONDARY,
  fontFamily: FONT,
  fontSize: 32,
  fontWeight: 'normal',
  marginTop: 0,
  marginBottom: 16,
  wordWrap: 'break-word',
});

const titleH2Styles = css(titleH1Styles, {
  fontSize: 24,
  marginBottom: 12,
});

const titleH3Styles = css(titleH1Styles, {
  fontSize: 16,
  marginBottom: 8,
});

export const Title = ({ children, size = 'h1', styles, title, className }) => {
  switch (size) {
    case 'h1':
      return <h1 className={`${titleH1Styles.toString()} ${className}`} {...(styles || {})} title={title}>{children}</h1>;
    case 'h2':
      return <h2 className={`${titleH2Styles.toString()} ${className}`} {...(styles || {})} title={title}>{children}</h2>;
    case 'h3':
      return <h3 className={`${titleH3Styles.toString()} ${className}`} {...(styles || {})} title={title}>{children}</h3>;
    default:
      throw Error(`Title size ${size} does not exist!`);
  }
};

Title.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
  styles: PropTypes.object,
  title: PropTypes.string,
};

export const titleH1DescriptionStyles = css(titleH1Styles, {
  color: GREY_2,
  fontSize: 20,
  fontWeight: 'normal',
});

export const titleH2DescriptionStyles = css(titleH2Styles, {
  color: GREY_2,
  fontSize: 18,
});

export const titleH3DescriptionStyles = css(titleH3Styles, {
  color: GREY_2,
  fontSize: 16,
});

export const TitleDescription = ({ children, size = 'h1', styles, title }) => {
  switch (size) {
    case 'h1':
      return <h1 {...css(titleH1DescriptionStyles, styles)} title={title}>{children}</h1>;
    case 'h2':
      return <h2 {...css(titleH2DescriptionStyles, styles)} title={title}>{children}</h2>;
    case 'h3':
      return <h3 {...css(titleH3DescriptionStyles, styles)} title={title}>{children}</h3>;
    default:
      throw Error(`Title size ${size} does not exist!`);
  }
};

TitleDescription.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  styles: PropTypes.object,
  title: PropTypes.string,
};

export const titleH1PassiveStyles = css(titleH1Styles, {
  fontWeight: 'normal',
  opacity: '60%',
});

export const titleH2PassiveStyles = css(titleH2Styles, {
  fontWeight: 'normal',
  opacity: '60%',
});

export const titleH3PassiveStyles = css(titleH3Styles, {
  fontWeight: 'normal',
  opacity: '60%',
});

export const TitlePassive = ({ children, size = 'h1', styles }) => {
  switch (size) {
    case 'h1':
      return <h1 {...css(titleH1PassiveStyles, styles)}>{children}</h1>;
    case 'h2':
      return <h2 {...css(titleH2PassiveStyles, styles)}>{children}</h2>;
    case 'h3':
      return <h3 {...css(titleH3PassiveStyles, styles)}>{children}</h3>;
    default:
      throw Error(`Title size ${size} does not exist!`);
  }
};

TitlePassive.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  styles: PropTypes.object,
};

export const titleH1PassiveDescriptionStyles = css(titleH1DescriptionStyles, {
  color: GREY_1,
});

export const titleH2PassiveDescriptionStyles = css(titleH2DescriptionStyles, {
  color: GREY_1,
  fontWeight: 'normal',
});

export const titleH3PassiveDescriptionStyles = css(titleH3DescriptionStyles, {
  color: GREY_1,
  fontWeight: 'normal',
});

export const TitlePassiveDescription = ({ children, size = 'h1', styles }) => {
  switch (size) {
    case 'h1':
      return <h1 {...css(titleH1PassiveDescriptionStyles, styles)}>{children}</h1>;
    case 'h2':
      return <h2 {...css(titleH2PassiveDescriptionStyles, styles)}>{children}</h2>;
    case 'h3':
      return <h3 {...css(titleH3PassiveDescriptionStyles, styles)}>{children}</h3>;
    default:
      throw Error(`Title size ${size} does not exist!`);
  }
};

TitlePassiveDescription.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  styles: PropTypes.object,
};

export const titleH1BoldStyles = css(titleH1Styles, {
  fontWeight: 'bold',
});

export const titleH2BoldStyles = css(titleH2Styles, {
  fontWeight: 'bold',
});

export const titleH3BoldStyles = css(titleH3Styles, {
  fontWeight: 'bold',
});

export const TitleBold = ({ children, size = 'h1', styles }) => {
  switch (size) {
    case 'h1':
      return <h1 {...css(titleH1BoldStyles, styles)}>{children}</h1>;
    case 'h2':
      return <h2 {...css(titleH2BoldStyles, styles)}>{children}</h2>;
    case 'h3':
      return <h3 {...css(titleH3BoldStyles, styles)}>{children}</h3>;
    default:
      throw Error(`Title size ${size} does not exist!`);
  }
};

TitleBold.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  styles: PropTypes.object,
};
