import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Body } from '@innovatrix/common/text';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.greyLight1};
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid ${({ theme }) => theme.colors.greyLight3};
  cursor: pointer;
  display: flex;
  height: auto;
  opacity: 1;
  padding: 8px 24px;
  position: relative;
  transition: 0.3s;
  width: 600px;
`;

const OptionTitle = styled(Body)`
  margin-bottom: 0;
`;

const RankingOptionPreview = ({ title }) => <Container><OptionTitle>{title}</OptionTitle></Container>;

RankingOptionPreview.propTypes = {
  title: PropTypes.string.isRequired,
};

export default React.memo(RankingOptionPreview);
