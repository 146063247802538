import queryString from 'query-string';

import { ERROR, LOADING, LOADED, RELOADING } from '../../constants';

export const serialize = queryString.stringify;

export function errorEntity(state, { error, ...keyProps }) {
  const key = serialize(keyProps);
  return {
    ...state,
    entities: {
      ...state.entities,
      [key]: { _status: ERROR, _error: error },
    },
  };
}

export function fetchEntity(state, { type, ...keyProps }) {
  const key = serialize(keyProps);
  const entity = state.entities[key];
  const prevStatus = entity && entity._status;
  return {
    ...state,
    entities: {
      ...state.entities,
      [key]: (prevStatus === LOADED || prevStatus === RELOADING) ? { ...entity, _status: RELOADING } : { _status: LOADING },
    },
  };
}

export function receiveEntity(state, { data: entity, type, ...keyProps }) {
  const key = serialize(keyProps);
  return {
    ...state,
    entities: {
      ...state.entities,
      [key]: { ...entity, _status: LOADED },
    },
  };
}

export function errorEntities(path, state, { error, type, ...keyProps }) {
  const key = serialize(keyProps);
  return {
    ...state,
    relations: {
      ...state.relations,
      [path]: {
        ...state.relations[path],
        [key]: { data: [], error, status: ERROR },
      },
    },
  };
}

export function fetchEntities(path, state, { type, ...keyProps }) {
  const key = serialize(keyProps);
  const relations = state.relations[path][key];
  const prevStatus = relations && relations.status;
  return {
    ...state,
    relations: {
      ...state.relations,
      [path]: {
        ...state.relations[path],
        [key]: prevStatus === LOADED ? { ...relations, status: RELOADING } : { data: [], status: LOADING },
      },
    },
  };
}

export function receiveEntities(path, state, { data: entities, type, ...keyProps }) {
  const key = serialize(keyProps);
  return {
    ...state,
    relations: {
      ...state.relations,
      [path]: {
        ...state.relations[path],
        [key]: { data: entities, status: LOADED },
      },
    },
  };
}
