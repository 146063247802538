import PropTypes from 'prop-types';

import { projectPropType } from './evaluationResultsPropType';

export const cardPropTypes = {
  project: projectPropType.isRequired,
  showDetails: PropTypes.bool,
};

export const cardPropType = PropTypes.shape(cardPropTypes);
