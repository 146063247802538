/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import { WHITE, GREY_LIGHT_2, GREY_LIGHT_1 } from '../../styles';
import CloseIcon from '../../assets/icons/CloseIcon';
import { TAB_KEY, ESCAPE_KEY, ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from '../../constants';

import ClickOutside from './Clickoutside';
import Label from './fields/Label';

import { Spinner } from '.';

// -- Styles --------------- --- --  -

const wrapperStyles = css({
  display: 'inline-block',
  float: 'left',
  height: 24,
  margin: '0 14px',
  position: 'relative',
});

const spinnerStyles = css({
  height: 20,
  margin: 0,
  position: 'absolute',
  right: 5,
  top: 3,
  width: 40,
  '> span': {
    margin: 0,
  },
});

const inputStyles = css({
  background: GREY_LIGHT_2,
  borderColor: GREY_LIGHT_2,
  opacity: 0.5,
  borderRadius: 4,
  borderStyle: 'solid',
  fontSize: '14px',
  borderWidth: 1,
  paddingLeft: 5,
  paddingRight: 5,
  position: 'relative',
  ':hover': {
    opacity: 0.8,
    transition: 'opacity 0.3s',
  },
  ':focus': {
    opacity: 1,
    background: WHITE,
  },
});

const optionContainerStyles = css({
  background: WHITE,
  border: '1px solid rgba(0, 0, 0, 0.15)',
  height: 100,
  maxWidth: '100%',
  overflow: 'scroll',
  position: 'absolute',
  width: '100%',
  zIndex: 999,
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

const optionStyles = css({
  cursor: 'pointer',
  margin: 0,
  maxWidth: '100%',
  padding: 5,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
  '.selected': {
    backgroundColor: GREY_LIGHT_1,
  },
});

const crossStyles = css({
  cursor: 'pointer',
  position: 'absolute',
  left: -16,
  top: 16,
  width: 14,
  height: 14,
  opacity: 0.4,
  transition: 'all 0.3s ease-in',
  ':hover': {
    opacity: 0.7,
    transition: 'all 0.3s ease-in',
  },
});

// -- Component --------------- --- --  -

/**
 * Generic AutoComplete Component.
 */
class AutoComplete extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // Index for our keyboard controls to highlight
      highlightedIndex: -1,
      // Indicates if this container is open or closed
      isOpen: false,
      // Contains the text entered to search on
      value: '',
    };
  }

  componentDidMount() {
    const { focus, selectedValue } = this.props;
    if (selectedValue) {
      this.findSelectedValue();
    }
    if (focus) {
      this.inputNode.focus();
    }
  }

  // Check if we need to scroll down or up to visualize our selection.
  componentDidUpdate(prevProps) {
    const { selectedValue, options } = this.props;
    if (this.selection) {
      this.container.scrollTop = this.state.highlightedIndex * this.selection.clientHeight;
    }

    // Because sometimes our value gets set before new options will be updated, this is a pre-caution when using
    // this in combination with redux-form.
    if (selectedValue) {
      const option = options.find(({ value, id }) => (value || id) === selectedValue);
      if (option && this.state.value !== option.title && prevProps.selectedValue !== selectedValue) {
        this.findSelectedValue();
      }
    }
  }

  findSelectedValue = () => {
    const { selectedValue, options, searchField } = this.props;
    const selected = options.find(({ value, id }) => (value || id) === selectedValue) || { title: '' };
    this.setState({ value: selected.title || selected[searchField] });
  }

  /**
   * @function
   * @return {Object} The wrapper ref
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  /**
   * @function
   * @return {function} The debounce function
   * Will debounce given function for given wait
   */
  debounce = (func, wait = 250) => {
    let timeout;
    return function debounceFunc(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  }

  /**
   * @function
   * Will close our container and clear our inputted value onClickOutside
   */
  handleClickOutside = () => {
    const { clearOnBlur, onBlur, selectedValue } = this.props;
    if (onBlur) { onBlur(this.state.value); }
    if (selectedValue) {
      this.setState({ isOpen: false });
    }
    else if (clearOnBlur) {
      this.setState({ value: '', isOpen: false });
    }
    else {
      this.setState({ isOpen: false });
    }
  }

  /**
   * @function
   * @param {Object} event the current event to be handled
   * Will alter the value from our input when changed
   */
  onChange = (e) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e.target.value);
    }
    this.setState({ isOpen: true, value: e.target.value });
  }

  /**
   * @function
   * @returns The options for current filter on given searchField
   */
  getFilteredValues = () => {
    const { options, searchField } = this.props;
    const { value } = this.state;

    let filteredValues = options;
    if (value && searchField) {
      filteredValues = options.filter(option => option[searchField].toLowerCase().indexOf(value.toLowerCase()) > -1);
    }

    return filteredValues;
  }

  /**
   * @function
   * @param {Object} event the current event to be handled
   * will prevent the default and stop it from propagating
   */
  prevent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  /**
   * @function
   * @param {Object} event the current event to be handled
   * Will correctly handle the keyInput when needed
   */
  keyHandler = (e) => {
    const { keyCode } = e;
    const { clearOnBlur, onBlur, clear, onClick, selectedValue } = this.props;
    const { highlightedIndex, isOpen } = this.state;

    switch (keyCode) {
      case TAB_KEY: {
        if (onBlur) {
          onBlur(this.state.value);
        }
        if (selectedValue) {
          this.setState({ isOpen: false });
        }
        else if (clearOnBlur) {
          this.setState({ value: '', isOpen: false });
        }
        else {
          this.setState({ isOpen: false });
        }
        break;
      }
      case ENTER_KEY: {
        this.prevent(e);
        const values = this.getFilteredValues();
        if (highlightedIndex > -1 && highlightedIndex < values.length) {
          this.setState({ value: '', isOpen: false });
          this.inputNode.blur();
          onClick((values[highlightedIndex].value || values[highlightedIndex].id));
        }
        else if (highlightedIndex === -1 && onBlur) {
          onBlur(this.state.value);
        }
        break;
      }
      case ESCAPE_KEY: {
        this.prevent(e);
        this.inputNode.blur();
        if (clear) {
          clear();
        }
        this.setState({ value: '', isOpen: false });
        break;
      }
      case ARROW_DOWN_KEY: {
        this.prevent(e);
        const values = this.getFilteredValues();
        if (highlightedIndex + 1 < values.length) {
          this.setState({ highlightedIndex: highlightedIndex + 1 });
        }
        break;
      }
      case ARROW_UP_KEY: {
        this.prevent(e);
        if (highlightedIndex - 1 >= 0) {
          this.setState({ highlightedIndex: highlightedIndex - 1 });
        }
        break;
      }
      default: {
        if (isOpen) {
          this.setState({ highlightedIndex: 0 });
        }
        else {
          this.setState({ highlightedIndex: 0, isOpen: true });
        }
        break;
      }
    }
  }

  /**
   * @function
   * @param {String} value the clicked option' value
   * Will trigger the parent's onClick with clicked value
   */
  onClick = (value, e) => {
    const { onClick, selectedValue } = this.props;
    if (selectedValue) {
      this.setState({ isOpen: false });
    }
    else {
      this.setState({ value: '', isOpen: false });
    }
    this.inputNode.blur();
    onClick(value, e);
  }

  clearInput = (e) => {
    const { clear } = this.props;
    this.prevent(e);
    this.inputNode.blur();
    clear();
    this.setState({ value: '', isOpen: false });
  }

  render() {
    const { alwaysOpen, label, clear, disabled, isLoading, placeholder, searchField, selectedValue, styles } = this.props;
    const { highlightedIndex, isOpen, value: stateValue } = this.state;
    const filteredValues = this.getFilteredValues();
    return (
      <div
        {...wrapperStyles}
        className={`${disabled ? 'disabled' : ''} ${styles ? styles.toString() : ''}`}
        onKeyDown={this.keyHandler}
        ref={this.setWrapperRef}
      >
        <div>
          {label && <Label label={label} />}
          <input
            {...inputStyles}
            disabled={disabled}
            className={disabled ? 'searchField disabled' : 'searchField'}
            ref={(inputNode) => { this.inputNode = inputNode; }}
            placeholder={placeholder}
            value={stateValue}
            type="text"
            onChange={this.onChange}
          />
        </div>
        {clear && !disabled && Boolean(selectedValue) && <CloseIcon height={12} onClick={this.clearInput} styles={crossStyles} width={12} />}
        {isLoading && <Spinner size={10} styles={spinnerStyles} />}
        {((!isLoading && stateValue && stateValue.length > 0 && isOpen) || (!isLoading && alwaysOpen)) &&
          <ClickOutside
            clickOutside={this.handleClickOutside}
            wrapperRef={this.wrapperRef}
          >
            <div {...optionContainerStyles} ref={(ref) => { this.container = ref; }}>
              {filteredValues.map((v, i) => (
                <p
                  {...optionStyles}
                  ref={ref => { this.selection = ref; }}
                  className={highlightedIndex === i ? 'selected' : ''}
                  key={v.value || v.id}
                  title={v[searchField]}
                  onClick={this.onClick.bind(this, v.value || v.id)}
                >
                  {v[searchField]}
                </p>
              ))}
            </div>
          </ClickOutside>}
      </div>
    );
  }

}

AutoComplete.propTypes = {
  alwaysOpen: PropTypes.bool,
  clear: PropTypes.func,
  clearOnBlur: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  // Indicates a isLoading state in case we fetch suggestions
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // Indicates what happens when the user clicks a suggestion
  onClick: PropTypes.func.isRequired,

  /**
   * All of our suggestions
   * These need to contain these fields:
   *  value
   *  [searchField]
  * */
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  // The field the text will search on.
  searchField: PropTypes.string.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Optional styling
  styles: PropTypes.any,
  value: PropTypes.string,
};

AutoComplete.defaultProps = { clearOnBlur: true };

export default AutoComplete;
