import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const OtherIcon = ({ color, size, styles, ...props }) => (
  <svg version="1.1" viewBox="0 0 28 36" height={size} width={size} {...styles} {...props}>
    <defs>
      <linearGradient id="OtherIconb" x1="22.918%" x2="78.303%" y1="24.185%" y2="75.784%">
        <stop stopColor="#CDCEDE" offset="0" />
        <stop stopColor="#AEAFC2" offset="1" />
      </linearGradient>
      <path id="OtherIcona" d="m17.5 0 10.5 10.5h-6.5c-2.2091 0-4-1.7909-4-4v-6.5z" />
      <filter id="OtherIconc" x="-171.4%" y="-95.2%" width="366.7%" height="366.7%">
        <feOffset dx="-4" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.533998652   0 0 0 0 0.538753332   0 0 0 0 0.629092262  0 0 0 1 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="m3 0h14.5l10.5 10.5v22.5c0 1.6569-1.3431 3-3 3h-22c-1.6569 0-3-1.3431-3-3v-30c-2.0291e-16 -1.6569 1.3431-3 3-3z" fill="url(#OtherIconb)" />
      <path d="m12.183 14 1.8173 1.7671h7.226c0.49039 0 0.90865 0.1753 1.2548 0.52592 0.34616 0.35061 0.51923 0.76433 0.51923 1.2412v8.6988c0 0.47683-0.17308 0.89055-0.51923 1.2412-0.34616 0.35061-0.76442 0.52592-1.2548 0.52592h-14.452c-0.49039 0-0.90865-0.1753-1.2548-0.52592-0.34616-0.35061-0.51923-0.76433-0.51923-1.2412v-10.466c0-0.47683 0.17308-0.89055 0.51923-1.2412s0.76442-0.52592 1.2548-0.52592h5.4087z" fill="#fff" />
      <use fill="black" filter="url(#OtherIconc)" xlinkHref="#OtherIcona" />
      <use fill="#E6E6EA" fillRule="evenodd" xlinkHref="#OtherIcona" />
    </g>
  </svg>
);

OtherIcon.propTypes = propTypes;
OtherIcon.defaultProps = defaultProps;

export default OtherIcon;
