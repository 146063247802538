import {
  ACCEPTED_WITH_CONDITIONS,
  ACCEPTED,
  ACTIVE,
  ALL,
  ARCHIVED,
  CAMPAIGNS_ENABLED,
  COLLAPSE_ALL,
  CONFIGURATION_TAB,
  EXPAND_ALL,
  HIDE_PREDICTIONS,
  INNOBOARDS_ENABLED,
  INNOBOARDS_MODEL_NAME,
  INTAKES_ENABLED,
  MAILS_ENABLED,
  MODELS_ENABLED,
  ON_HOLD,
  ORDER_ALPHABETICALLY,
  ORDER_DECISIONS,
  ORDER_RATINGS_ASC,
  ORDER_RATINGS_DESC,
  PHASES_ENABLED,
  REJECTED,
  REPORTS_ENABLED,
  REPORTS_TAB,
  SALESFORCE_ENABLED,
  SHOW_PREDICTIONS,
  UNDECIDED,
  WORKSHOPS_ENABLED,
  WORKSHOPS_TAB,
  ASSESSMENTS_V2_ENABLED,
} from './misc';

const orderOptions = [
  ORDER_ALPHABETICALLY,
  ORDER_RATINGS_ASC,
  ORDER_RATINGS_DESC,
];

export const ONCE = 'ONCE';
export const LIMITED = 'LIMITED';
export const UNLIMITED = 'UNLIMITED';

export const PLANNED = 'PLANNED';
export const COMPLETED = 'COMPLETED';

if (SALESFORCE_ENABLED) {
  orderOptions.push(ORDER_DECISIONS);
}

export const ORDER_OPTIONS = orderOptions;

export const PROPOSALS_TAB = 'Proposals';
export const PROJECT_TAB = 'Projects';
export const ASSESSMENT_TAB_V2 = 'Assessments v2';
export const ASSESSMENT_TAB = 'Assessments';
export const MODEL_TAB = INNOBOARDS_MODEL_NAME;
export const PROJECT_INTAKE_TAB = 'Creation';
export const PROJECT_DASHBOARD_TAB = 'Dashboard';
export const INTAKE_TAB = 'Intake';
export const STATUS_TAB = 'Status';
export const CALLS_TAB = 'Calls';
export const MAILS_TAB = 'Mails';

export const INNOVATRIX_OPTIONS = [];

INNOVATRIX_OPTIONS.push({ key: CONFIGURATION_TAB, value: CONFIGURATION_TAB });

// Proposals tab should come first (if applicable)
if ((SALESFORCE_ENABLED || CAMPAIGNS_ENABLED) && PHASES_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: PROPOSALS_TAB, value: PROPOSALS_TAB });
}

INNOVATRIX_OPTIONS.push({ key: PROJECT_TAB, value: PROJECT_TAB });

if (INNOBOARDS_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: MODEL_TAB, value: MODEL_TAB });
}

if (MODELS_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: ASSESSMENT_TAB, value: ASSESSMENT_TAB });
}

if (ASSESSMENTS_V2_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: ASSESSMENT_TAB_V2, value: ASSESSMENT_TAB_V2 });
}

if (WORKSHOPS_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: WORKSHOPS_TAB, value: WORKSHOPS_TAB });
}

if (INTAKES_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: CALLS_TAB, value: CALLS_TAB });
}

if (MAILS_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: MAILS_TAB, value: MAILS_TAB });
}

if (REPORTS_ENABLED) {
  INNOVATRIX_OPTIONS.push({ key: REPORTS_TAB, value: REPORTS_TAB });
}

export const PROJECTS_OPTIONS = [];

if (MODELS_ENABLED) {
  PROJECTS_OPTIONS.push({ key: ASSESSMENT_TAB, value: ASSESSMENT_TAB });
}

if (INTAKES_ENABLED) {
  PROJECTS_OPTIONS.push({ key: INTAKE_TAB, value: INTAKE_TAB });
}

if (!SALESFORCE_ENABLED) {
  PROJECTS_OPTIONS.push({ key: PROJECT_INTAKE_TAB, value: PROJECT_INTAKE_TAB });
}

PROJECTS_OPTIONS.push({ key: PROJECT_DASHBOARD_TAB, value: PROJECT_DASHBOARD_TAB });

// -- Cards Filter --------------- --- --  -

export const STATUS_OPTIONS = [
  { label: 'Show All', value: ALL },
  { label: 'Show To Be Decided', value: UNDECIDED },
  { label: 'Show Accepted', value: ACCEPTED },
  { label: 'Show Accepted with Conditions', value: ACCEPTED_WITH_CONDITIONS },
  { label: 'Show Rejected', value: REJECTED },
];

export const DOMAIN_ORDER = [
  'Startup maturity',
  'Customer',
  'Solution',
  'Value capture',
  'Ecosystem',
  'Competitive environment',
  'Team',
  'Financial',
  'Financials', // In evaluations Financial is called Financials
  'Legal', // Used for future evaluations (upload evaluation)
  'Business operations',
  'Program fit',
];

export const CRITERIA_ORDER = [
  'Startup lifecycle',
  'Product maturity',
  'Customer segment: understanding',
  'Customer segment: validation',
  'Needs: understanding',
  'Needs: validation',
  'Problem statement',
  'Barriers: understanding',
  'Barriers: validation',
  'Value proposition',
  'Performance of (digital) solution',
  'Solution: validation',
  'Revenue potential',
  'Value capture: understanding',
  'Value capture: validation',
  'Ecosystem: validation',
  'Eco-system: understanding',
  'Value chain: understanding',
  'Current practices: understanding',
  'Current practices: validation',
  'Sustainable advantage',
  'Domain and market knowledge',
  'Technical knowledge/skills',
  'Business skills',
  'Governance',
  'Commitment',
  'Coachability',
  'International business orientation',
  'Financing need understanding',
  'Funding level and sophistication',
  'Acquisition of funding',
  'Revenues',
  'Recurring revenues',
  'Operational cash flow',
  'Financial scalability',
  'Chances of bankruptcy',
  'Formalization of relationships',
  'PETS',
  'Intellectual property',
  'QMS',
  'Scalability',
  'Marketing and sales processes',
  'Communication processes and branding',
  'Operations',
  'Management processes',
  'KPI definition and follow-up',
  'Technology fit',
];

export const DECISION_ORDER = [
  'Go',
  'Conditions',
  'Maybe',
  'No go',
];

// When the label says "SHOW PREDICTIONS", we're currently hiding them.
export const PREDICTION_OPTIONS = [{
  label: 'HIDE PREDICTIONS',
  value: SHOW_PREDICTIONS,
}, {
  label: 'SHOW PREDICTIONS',
  value: HIDE_PREDICTIONS,
}];

export const COLLAPSE_CRITERIA_OPTIONS = [{
  label: 'COLLAPSE ALL',
  value: COLLAPSE_ALL,
}, {
  label: 'EXPAND ALL',
  value: EXPAND_ALL,
}];

export const STATE_OPTIONS = [{
  label: 'Active',
  value: ACTIVE,
}, {
  label: 'On hold',
  value: ON_HOLD,
}, {
  label: 'Archived',
  value: ARCHIVED,
}];

export const PROJECT_STATUS = {
  CLOSED: 'Closed',
  LIQUIDATION: 'Liquidation',
  ON_HOLD: 'On Hold',
  WITHDRAWN: 'Withdrawn',
};
