import { LOADING } from '../../constants';

export const DEFAULT_ENTITY = {
  _status: LOADING,
};

export const DEFAULT_ENTITIES = {
  data: [],
  status: LOADING,
};
