import {
  SELECT_EVALUATION,
  ALL_SCORES,
  CLEAR_EVALUATIONS,
  SELECT_REVIEWER,
} from '../constants';

const initialState = {
  selectedReviewer: ALL_SCORES,
  selectedEvaluations: [],
};

// Currently only one modal can be opened!
export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_EVALUATION: {
      // Value implies checking or unchecking the CheckBox
      // EvaluationId is  the id off the evaluation we're altering
      const { evaluationId, value } = action.data;
      let evaluations;
      if (value) {
        // We can't have more than 2 selectedEvaluations so this implies we need to reset our choiche
        if (state.selectedEvaluations.length === 2) {
          evaluations = [evaluationId];
        }
        else {
          // We no mutaters
          evaluations = [...state.selectedEvaluations, evaluationId];
        }
      }
      else {
        // If value is false than we filter out the id
        evaluations = state.selectedEvaluations.filter(id => id !== evaluationId);
      }

      return {
        ...state,
        selectedEvaluations: evaluations,
      };
    }
    case SELECT_REVIEWER: {
      const { reviewerId } = action;
      return {
        ...state,
        selectedReviewer: reviewerId,
      };
    }
    case CLEAR_EVALUATIONS: {
      return {
        ...state,
        selectedEvaluations: [],
      };
    }
    default:
      return state;
  }
};
