import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@innovatrix/common/buttons';

import formikField from '../formikField';

export class ToggleButton extends PureComponent {

  onClick = (e) => {
    const { onChange, onClick, value } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onChange(!value);
    if (onClick) {
      onClick(!value);
    }
  }

  render() {
    const { activeIcon, activeTooltip, className, disabled, inactiveIcon, inactiveTooltip, value } = this.props;
    return (
      <div className={className}>
        <IconButton
          disabled={disabled}
          icon={value ? activeIcon : inactiveIcon}
          onClick={disabled ? undefined : this.onClick}
          tooltip={value ? activeTooltip : inactiveTooltip}
        />
      </div>
    );
  }

}

ToggleButton.propTypes = {
  activeIcon: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]).isRequired,
  activeTooltip: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inactiveIcon: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]).isRequired,
  inactiveTooltip: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.bool,
};

export default formikField(ToggleButton);
