/* global LOG_DX_QUERY */
/* eslint-disable no-use-before-define */

import axios from 'axios';
import { DxError } from '@innovatrix/utils/dx';
import invariant from 'invariant';
import { isString } from '@innovatrix/utils';
import { getDxAuthExpiresAt, getDxAuthenticated, getDxAuthUser } from '@innovatrix/selectors/authSelectors';

import {
  DX_CONTEXT_DEFAULT,
  DX_QUERY_CANCELLED,
  DX_QUERY_CONTEXT_COMPLETE,
  DX_QUERY_CONTEXT_PENDING,
  DX_QUERY_FAILED,
  DX_QUERY_POSTED,
  DX_QUERY_SUCCESS,
} from '../constants';

// -- Constants & Properties --------------- --- --  -

let _initialized = false;
let _store;
let _defaultUrl;
// let _cancelLock = false;

const logQuery = LOG_DX_QUERY ? ((...args) => console.log('[dxQuery.fetchQuery]', ...args)) : (() => null);

// -- Initialization --------------- --- --  -

/**
 * @param {object} store - The Redux store.
 * @param {object} [defaultUrl] - The default url.
 */
export const initializeQueryFetcher = ({ store, defaultUrl }) => {
  if (_initialized) { throw new Error('The queryManager is already initialized.'); }
  if (!store) { throw new Error('The `store` parameter is missing.'); }
  _store = store;
  _defaultUrl = defaultUrl;
  _initialized = true;
};

// -- Submitting Queries --------------- --- --  -

/**
 * Returns a promise that resolves when the query succeeded, failed, or was cancelled. It resolves
 * to a {@link QueryResult} object.
 *
 * Queries can be grouped in _contexts_. Queries for which no context is specified, are assigned the
 * default context. These contexts play a role in the management of concurrent queries, and can be
 * used as a scope delimiter when selecting query state details from the Redux store.
 *
 * Queries are either mutations or immutations. Immutations are immediately executed, but if an
 * immutation with same query and the same context is currently busy then this older immutation is
 * cancelled.
 *
 * Mutations with the same context are never executed concurrently. When a new mutation is submitted
 * while a previous mutation is busy in the same context, then the new mutation is deferred till the
 * previous one completes or fails. Note that mutations cannot be cancelled.
 *
 * The query manager needs to be initialized (once) with the Redux store. This enables it to
 * dispatch two sets of query state actions, the first to mark the start and end of the execution of
 * the queries, the second to mark the periods dusring which uueries are being executed within the
 * context.
 *
 * ### Terminology:
 *
 * - Query : A DxQuery object. Represents a query declaration.
 * - Instance: Represents a concrete instantiation of a given query, including a query, a context,
 *   the query variables, the context, etc.
 * - Mutation: A query that mutates the data in the store.
 * - Immutation: A query that does not mutate the data in the store. In GraphQL, this is referred
 *   to as a _query_, but to avoid ambiguity due to the concurrent use of _query_ as denoting both
 *   mutations and (immutating) queries, we here use _immutations_.
 *
 * @param {DxQuery} query
 * @param {object} [options] - Options.
 * @param {boolean} [options.authenticate] - When true (default) the query request will include the
 *   dxToken.
 * @param {string} [options.context] - Optional query context.
 * @param {string} [options.dxToken] - Optional dxToken to use instead of the one from the current user.
 * @param {object} [options.variables] - The GraphQL variables.
 * @param {string} [options.url] - The GraphQL API URL. When not provided, the default URL provided
 *   with `initializeQueryFetcher` will be used.
 * @returns {Promise.<QueryResult>}
 */
export const fetchQuery = async (query, options = {}) => {
  const { authenticate = true, context, variables, url = _defaultUrl } = options;
  let dxToken;

  if (!_initialized) {
    // console.error(query);
    throw new Error('You need to call `initializeQueryFetcher` with the store and the default API url as arguments.');
  }

  if (authenticate) {
    dxToken = options.dxToken;
    // Use the dxToken of the current authenticated user, except when it is expired, or is about to.
    if (!dxToken) {
      const state = _store.getState();
      if (getDxAuthenticated(state, false)) {
        // check if token has expired or will expire in less then 10 seconds:
        if (getDxAuthExpiresAt(state) < (new Date().getTime() / 1000 - 10)) {
          console.warn('The dxToken has expired. Cancelling query.');
          return Promise.resolve({ cancelled: true, tokenExpired: true });
          // logQuery('The dxToken has expired. Sending query without dxToken.');
        }
      }
      else {
        logQuery('There is no authenticated user, dxToken remain undefined.');
      }
    }
  }

  // if (_cancelLock) { throw new Error('Do not submit a query in response to a query cancellation.'); }
  if (!query) { throw new Error('The `query` argument is missing.'); }
  if (!query.isDxQuery) { throw new Error(`The "query" argument should be dxQuery, instead got "${query}".`); }

  logQuery(`Called with..\n  - query: "${query.queryString}"\n  - context: ${context}`);

  // TODO: disabled pending scoped auth support in dxQuery
  // Assert that the user is authorized to execute this query:
  // try {
  //   authorize(_store.getState(), query.authRule);
  // }
  // catch (error) {
  //   const dxError = new DxError(error, {
  //     errorCode: DxError.ERROR_CODE_NOT_AUTHORIZED,
  //     queryString: query.queryString,
  //     variables,
  //   });
  //   return new QueryResult({ error: dxError });
  // }

  const contextObj = Context.get(context || DX_CONTEXT_DEFAULT); // Get context object
  const instance = new Query(contextObj, query, url, variables, authenticate, dxToken);

  // Add the query in the context and return the promise:
  contextObj.add(instance);
  return instance.promise;
};

// -- Context Class --------------- --- --  -

class Context {

  constructor(key) {
    this.isContext = true;
    this._key = key;
    this._currImmutations = [];
    this._currMutation = null;
    this._queuedMutations = [];
  }

  get key() { return this._key; }

  get hasCurrentMutation() { return Boolean(this._currMutation); }

  add(instance) {
    if (instance.isMutation) {
      if (this._currMutation) {
        // Enqueue new mutation when a current mutation is busy:
        this._queuedMutations.push(instance);
      }
      else {
        this._currMutation = instance;
        instance.post(); // Fetch the data
        _store.dispatch({ type: DX_QUERY_CONTEXT_PENDING, context: this._key });
      }
    }
    else if (this._currImmutations.length === 0) {
      this._currImmutations.push(instance);
      instance.post(); // Fetch the data
      _store.dispatch({ type: DX_QUERY_CONTEXT_PENDING, context: this._key });
    }
    else {
      // Cancel current immutation that has the same query as the new immutation:
      let i = 0;
      while (i < this._currImmutations.length) {
        const queuedInst = this._currImmutations[i];
        if (!queuedInst.isMutation && queuedInst.query === instance.query) {
          queuedInst.cancel(); // this will remove the instance from the queue, so no i++
        }
        else { i++; }
      }
      this._currImmutations.push(instance);
      instance.post(); // Fetch the data
    }
  }

  /**
   * Clears the complete instance, and executes the next mutation when needed.
   * @param instance
   */
  complete(instance) {
    if (instance.isMutation) {
      if (this._queuedMutations.length > 0) {
        this._currMutation = this._queuedMutations.shift();
        this._currMutation.post();
      }
      else {
        this._currMutation = null;
      }
    }
    else {
      this._currImmutations = this._currImmutations.filter((immut) => immut !== instance);
    }

    if (!this._currMutation && this._currImmutations.length === 0) {
      _store.dispatch({ type: DX_QUERY_CONTEXT_COMPLETE, context: this._key });
    }
  }

}

/**
 * The active contexts.
 * @type {Context[]}
 */
Context._contexts = [];

/**
 * Get the existing context with the given key, or return a new context.
 * @param {string} key
 * @returns {Context}
 */
Context.get = (key) => {
  const context = Context._contexts[key] || (Context._contexts[key] = new Context(key));
  return context;
};

Context.isContext = (val) => val && val.isContext;

// -- Query Class --------------- --- --  -

/**
 * Represents a query (mutation or query).
 */
class Query {

  constructor(context, query, url, variables, authenticate = true, dxToken) {
    // Check arguments:
    invariant(Context.isContext(context),
      `The "context" argument should be a Context object, instead got "${context}".`);
    invariant(query, 'The `query` argument is missing.');
    invariant(query.isDxQuery, `The "query" argument should be DxQuery, instead got "${query}".`);

    // Initialize properties:
    this.authenticate = authenticate;
    this.context = context;
    this.cancelled = false;
    this.dxToken = dxToken;
    this.query = query;
    this.queryId = nextQueryId(); // a unique id for this query instance
    this.url = url;
    this.variables = variables;

    // Initialize promise:
    const instance = this;
    this.promise = new Promise((resolve) => { instance.resolve = resolve; });
  }

  get isMutation() { return this.query.isMutation; }

  _config(auth) {
    let token;
    if (isString(auth)) { token = auth; }
    else if (auth) {
      const user = getDxAuthUser(_store.getState());
      if (user) { token = user.dxToken; }
    }
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
      };
    }
    return null;
  }

  async post() {
    invariant(!this.cancelled, 'The instance was cancelled.');

    this.dispatch(DX_QUERY_POSTED);

    const errMsg = 'The GraphQL query failed.';
    let response;
    try {
      const body = { query: this.query.queryString, variables: this.variables };
      const headers = this._config(this.authenticate && (this.dxToken || true));
      response = await axios.post(this.url, body, headers);
    }
    catch (error) {
      if (this.cancelled) { return null; }
      // console.error('The query failed [in fetchQuery.post].', error);
      return this.fail({
        reason: errMsg,
        error,
        errorCode: DxError.ERROR_CODE_REQUEST_FAILED,
        url: this.url,
      });
    }

    if (this.cancelled) { return null; }

    if (!response) {
      return this.fail(errMsg, 'The response is empty.');
    }
    if (!response.data) {
      return this.fail(errMsg, 'The response.data property is empty.');
    }

    // Check for errors reported by Axios:
    if (response.data.errors) {
      return this.fail(errMsg, {
        errorCode: DxError.ERROR_CODE_QUERY_FAILED,
        dxResponse: response,
      });
    }

    if (!response.data.data) {
      return this.fail(errMsg, 'The response.data.data property is empty.');
    }
    let data = response.data.data;

    // Deserialize the query response:
    try {
      data = this.query.deserializer(data);
    }
    catch (error) {
      return this.fail({
        reason: `${errMsg} Failed to deserialize the query results.`,
        error,
        errorCode: DxError.ERROR_CODE_DESERIALIZATION_FAILED,
        url: this.url,
      });
    }

    this.dispatch(DX_QUERY_SUCCESS, { data });
    this.resolve(new QueryResult({ complete: true, data }));
    return this.context.complete(this);
  }

  fail(...errArgs) {
    const dxError = new DxError(errArgs, {
      queryString: this.query.queryString,
      variables: this.variables,
    });
    this.dispatch(DX_QUERY_FAILED, { dxError });
    this.resolve(new QueryResult({ error: dxError }));
    return this.context.complete(this);
  }

  cancel() {
    invariant(!this.isMutation, 'Mutations cannot be cancelled.');
    this.cancelled = true;
    this.dispatch(DX_QUERY_CANCELLED);
    this.resolve(QueryResult.cancelled);
    return this.context.complete(this);
  }

  dispatch(type, props = {}) {
    _store.dispatch({
      type,
      context: this.context.key,
      query: this.query,
      queryId: this.queryId,
      url: this.url,
      variables: this.variables,
      ...props,
    });
  }

  toString() {
    return `Query { context: ${this.context.key} queryId: ${this.queryId} }`;
  }

}

Query._queryIdCounter = 0;

const nextQueryId = () => {
  Query._queryIdCounter++;
  if (Query._queryIdCounter > Number.MAX_SAFE_INTEGER) {
    Query._queryIdCounter = 1;
  }
  return `query_${Query._queryIdCounter}`;
};

// -- QueryResult Class --------------- --- --  -

/**
 * Represents the result of a query/mutation.
 */
class QueryResult {

  constructor({ cancelled, complete, data, error }) {
    // check arguments:
    invariant(!error || DxError.isDxError(error),
      `The "error" argument must be a DxError, instead got "${error}".`);

    // initialize properties:
    this.cancelled = cancelled || false;
    this.complete = complete || false;
    this.data = data;
    this.error = error;
  }

}

QueryResult.cancelled = new QueryResult({ cancelled: true });
