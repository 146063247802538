// Generic
// ///////////

export const FETCH_QUERY = 'innovatrix/FETCH_QUERY';
export const RECEIVE_QUERY = 'innovatrix/RECEIVE_QUERY';
export const COMPLETE_MUTATION = 'innovatrix/COMPLETE_MUTATION';
export const SEND_MUTATION = 'innovatrix/SEND_MUTATION';

// Application
// ///////////

export const ERROR_APPLICATION_META_DATA = 'innovatrix/ERROR_APPLICATION_META_DATA';
export const FETCH_APPLICATION_META_DATA = 'innovatrix/FETCH_APPLICATION_META_DATA';
export const RECEIVE_APPLICATION_META_DATA = 'innovatrix/RECEIVE_APPLICATION_META_DATA';

export const ERROR_PROJECT_TYPES = 'innovatrix/ERROR_PROJECT_TYPES';
export const FETCH_PROJECT_TYPES = 'innovatrix/FETCH_PROJECT_TYPES';
export const RECEIVE_PROJECT_TYPES = 'innovatrix/RECEIVE_PROJECT_TYPES';

export const FETCH_USER_PROFILES = 'innovatrix/FETCH_USER_PROFILES';
export const RECEIVE_USER_PROFILES = 'innovatrix/RECEIVE_USER_PROFILES';
export const ERROR_USER_PROFILES = 'innovatrix/ERROR_USER_PROFILES';

export const ACCEPTED_COOKIES = 'innovatrix/ACCEPTED_COOKIES';
export const ACCEPTED_LEGAL_DOCUMENT = 'innovatrix/ACCEPTED_LEGAL_DOCUMENT';
export const UPDATE_APPLICATION_CONFIGURATION = 'innovatrix/UPDATE_APPLICATION_CONFIGURATION';

// Users
/// /////

export const FETCH_MANAGERS_AND_COACHES = 'innovatrix/FETCH_MANAGERS_AND_COACHES';
export const ERROR_MANAGERS_AND_COACHES = 'innovatrix/ERROR_MANAGERS_AND_COACHES';
export const RECEIVE_MANAGERS_AND_COACHES = 'innovatrix/RECEIVE_MANAGERS_AND_COACHES';

// Campaigns
/// /////////

export const ERROR_CAMPAIGNS = 'innovatrix/ERROR_CAMPAIGNS';
export const FETCH_CAMPAIGNS = 'innovatrix/FETCH_CAMPAIGNS';
export const RECEIVE_CAMPAIGNS = 'innovatrix/RECEIVE_CAMPAIGNS';

export const ERROR_CAMPAIGN = 'innovatrix/ERROR_CAMPAIGN';
export const FETCH_CAMPAIGN = 'innovatrix/FETCH_CAMPAIGN';
export const RECEIVE_CAMPAIGN = 'innovatrix/RECEIVE_CAMPAIGN';

export const ERROR_UPDATE_CAMPAIGN = 'innovatrix/ERROR_UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'innovatrix/UPDATE_CAMPAIGN';

export const DOWNLOAD_CAMPAIGNS_EXPORT = 'innovatrix/DOWNLOAD_CAMPAIGNS_EXPORT';
export const DOWNLOAD_WORKSHOPS_EXPORT = 'innovatrix/DOWNLOAD_WORKSHOPS_EXPORT';

// Assessments
/// ///////////

export const PERSIST_SURVEY = 'innovatrix/PERSIST_SURVEY';
export const PERSIST_SURVEY_2 = 'innovatrix/PERSIST_SURVEY_2';
export const PERSIST_ASSESSMENT_VERSION = 'innovatrix/PERSIST_ASSESSMENT_VERSION';
export const FETCH_ASSESSMENT_VERSION = 'innovatrix/FETCH_ASSESSMENT_VERSION';
export const RECEIVE_ASSESSMENT_VERSION = 'innovatrix/RECEIVE_ASSESSMENT_VERSION';
export const ERROR_ASSESSMENT_VERSION = 'innovatrix/ERROR_ASSESSMENT_VERSION';
export const FETCH_ACTIVE_ASSESSMENT_VERSION = 'innovatrix/FETCH_ACTIVE_ASSESSMENT_VERSION';
export const RECEIVE_ACTIVE_ASSESSMENT_VERSION = 'innovatrix/RECEIVE_ACTIVE_ASSESSMENT_VERSION';
export const ERROR_ACTIVE_ASSESSMENT_VERSION = 'innovatrix/ERROR_ACTIVE_ASSESSMENT_VERSION';
export const FETCH_ASSESSMENT_VERSIONS = 'innovatrix/FETCH_ASSESSMENT_VERSIONS';
export const RECEIVE_ASSESSMENT_VERSIONS = 'innovatrix/RECEIVE_ASSESSMENT_VERSIONS';
export const ERROR_ASSESSMENT_VERSIONS = 'innovatrix/ERROR_ASSESSMENT_VERSIONS';

// Projects
/// ////////

export const ERROR_PROJECT_BOARDS = 'innovatrix/ERROR_PROJECT_BOARDS';
export const FETCH_PROJECT_BOARDS = 'innovatrix/FETCH_PROJECT_BOARDS';
export const RECEIVE_PROJECT_BOARDS = 'innovatrix/RECEIVE_PROJECT_BOARDS';

export const ERROR_PROJECT_OVERVIEW = 'innovatrix/ERROR_PROJECT_OVERVIEW';
export const FETCH_PROJECT_OVERVIEW = 'innovatrix/FETCH_PROJECT_OVERVIEW';
export const RECEIVE_PROJECT_OVERVIEW = 'innovatrix/RECEIVE_PROJECT_OVERVIEW';

export const ERROR_PROJECTS = 'innovatrix/ERROR_PROJECTS';
export const FETCH_PROJECTS = 'innovatrix/FETCH_PROJECTS';
export const RECEIVE_PROJECTS = 'innovatrix/RECEIVE_PROJECTS';

export const ERROR_PROJECT_BY_EVALUATION = 'innovatrix/ERROR_PROJECT_BY_EVALUATION';
export const FETCH_PROJECT_BY_EVALUATION = 'innovatrix/FETCH_PROJECT_BY_EVALUATION';
export const RECEIVE_PROJECT_BY_EVALUATION = 'innovatrix/RECEIVE_PROJECT_BY_EVALUATION';

export const ERROR_PROJECT_BY_PHASE = 'innovatrix/ERROR_PROJECT_BY_PHASE';
export const FETCH_PROJECT_BY_PHASE = 'innovatrix/FETCH_PROJECT_BY_PHASE';
export const RECEIVE_PROJECT_BY_PHASE = 'innovatrix/RECEIVE_PROJECT_BY_PHASE';

export const ERROR_PROJECT_REVIEWS_BY_PHASE = 'innovatrix/ERROR_PROJECT_REVIEWS_BY_PHASE';
export const FETCH_PROJECT_REVIEWS_BY_PHASE = 'innovatrix/FETCH_PROJECT_REVIEWS_BY_PHASE';
export const RECEIVE_PROJECT_REVIEWS_BY_PHASE = 'innovatrix/RECEIVE_PROJECT_REVIEWS_BY_PHASE';

export const ERROR_PROJECT_WORKSHOPS = 'innovatrix/ERROR_PROJECT_WORKSHOPS';
export const FETCH_PROJECT_WORKSHOPS = 'innovatrix/FETCH_PROJECT_WORKSHOPS';
export const RECEIVE_PROJECT_WORKSHOPS = 'innovatrix/RECEIVE_PROJECT_WORKSHOPS';

export const FETCH_PROJECT_REVIEWERS = 'innovatrix/FETCH_PROJECT_REVIEWERS';
export const RECEIVE_PROJECT_REVIEWERS = 'innovatrix/RECEIVE_PROJECT_REVIEWERS';

export const COMPARE_PROJECT = 'innovatrix/COMPARE_PROJECT';
export const RECEIVE_COMPARE_PROJECT = 'innovatrix/RECEIVE_COMPARE_PROJECT';

export const FETCH_BRIEF_PROJECTS = 'innovatrix/FETCH_BRIEF_PROJECTS';
export const RECEIVE_BRIEF_PROJECTS = 'innovatrix/RECEIVE_BRIEF_PROJECTS';
export const SHOW_DECISION_DETAILS = 'innovatrix/SHOW_DECISION_DETAILS';

// Objectives
/// //////////

export const ERROR_OBJECTIVE_TYPES = 'innovatrix/ERROR_OBJECTIVE_TYPES';
export const FETCH_OBJECTIVE_TYPES = 'innovatrix/FETCH_OBJECTIVE_TYPES';
export const RECEIVE_OBJECTIVE_TYPES = 'innovatrix/RECEIVE_OBJECTIVE_TYPES';

export const PERSIST_OBJECTIVE_COMMENT = 'innovatrix/PERSIST_OBJECTIVE_COMMENT';

export const ERROR_OBJECTIVES = 'innovatrix/ERROR_OBJECTIVES';
export const FETCH_OBJECTIVES = 'innovatrix/FETCH_OBJECTIVES';
export const RECEIVE_OBJECTIVES = 'innovatrix/RECEIVE_OBJECTIVES';

export const TOGGLE_OBJECTIVES_CREATING = 'innovatrix/TOGGLE_OBJECTIVES_CREATING';
export const CREATE_OBJECTIVE_SUCCESS = 'innovatrix/CREATE_OBJECTIVE_SUCCESS';
export const TOGGLE_SHOW_COMPLETED = 'innovatrix/TOGGLE_SHOW_COMPLETED';
// export const DELETE_OBJECTIVE = 'innovatrix/DELETE_OBJECTIVE';
export const COMPLETE_OBJECTIVE = 'innovatrix/COMPLETE_OBJECTIVE';
export const COMPLETE_OBJECTIVE_SUCCESS = 'innovatrix/COMPLETE_OBJECTIVE_SUCCESS';
export const COMPLETE_OBJECTIVE_ANIMATION_SUCCESS = 'innovatrix/COMPLETE_OBJECTIVE_ANIMATION_SUCCESS';
export const DELETE_OBJECTIVE_ERROR = 'innovatrix/DELETE_OBJECTIVE_ERROR';
export const COMPLETE_OBJECTIVE_ERROR = 'innovatrix/COMPLETE_OBJECTIVE_ERROR';
export const SET_FIELD_EDIT_STATUS = 'innovatrix/SET_FIELD_EDIT_STATUS';

// Objective types
/// ///////////////

export const MOVE_OBJECTIVE = 'innovatrix/MOVE_OBJECTIVE';
export const ERROR_MOVE_OBJECTIVE = 'innovatrix/ERROR_MOVE_OBJECTIVE';

// Criteria
/// ////////

export const RECEIVE_CRITERION_LEVELS = 'innovatrix/RECEIVE_CRITERION_LEVELS';

// Innoboards
/// //////////

export const ERROR_INNOBOARDS = 'innovatrix/ERROR_INNOBOARDS';
export const FETCH_INNOBOARDS = 'innovatrix/FETCH_INNOBOARDS';
export const RECEIVE_INNOBOARDS = 'innovatrix/RECEIVE_INNOBOARDS';
export const TOGGLE_COLLAPSE_CRITERIA = 'innovatrix/TOGGLE_COLLAPSE_CRITERIA';
export const ADD_RELATED_ASSUMPTION = 'innovatrix/ADD_RELATED_ASSUMPTION';
export const DELETE_RELATED_ASSUMPTION = 'innovatrix/DELETE_RELATED_ASSUMPTION';
export const UPDATE_ASSUMPTION = 'innovatrix/UPDATE_ASSUMPTION';
export const UPDATE_ASSUMPTION_ERROR = 'innovatrix/UPDATE_ASSUMPTION_ERROR';
export const UPDATE_ASSUMPTION_SUCCESS = 'innovatrix/UPDATE_ASSUMPTION_SUCCESS';
export const COPY_ASSUMPTIONS = 'innovatrix/COPY_ASSUMPTIONS';
export const COPY_ASSUMPTIONS_ERROR = 'innovatrix/COPY_ASSUMPTIONS_ERROR';
export const COPY_ASSUMPTIONS_SUCCESS = 'innovatrix/COPY_ASSUMPTIONS_SUCCESS';
export const MOVE_ASSUMPTIONS = 'innovatrix/MOVE_ASSUMPTIONS';
export const MOVE_ASSUMPTIONS_ERROR = 'innovatrix/MOVE_ASSUMPTIONS_ERROR';
export const MOVE_ASSUMPTIONS_SUCCESS = 'innovatrix/MOVE_ASSUMPTIONS_SUCCESS';
export const FETCH_RELATED_ASSUMPTIONS = 'innovatrix/FETCH_RELATED_ASSUMPTIONS';
export const RECEIVE_RELATED_ASSUMPTIONS = 'innovatrix/RECEIVE_RELATED_ASSUMPTIONS';
export const ERROR_RELATED_ASSUMPTIONS = 'innovatrix/ERROR_RELATED_ASSUMPTIONS';
export const DOWNLOAD_INNOBOARD_PDF = 'innovatrix/DOWNLOAD_INNOBOARD_PDF';

export const DELETE_SEGMENT = 'innovatrix/DELETE_SEGMENT';

export const SCROLL_TO_LAST_CRITERION = 'innovatrix/SCROLL_TO_LAST_CRITERION';
export const SCROLL_TO_LAST_CRITERION_COMPLETED = 'innovatrix/SCROLL_TO_LAST_CRITERION_COMPLETED';

export const FOCUS_ASSUMPTION = 'innovatrix/FOCUS_ASSUMPTION';
export const FOCUS_ASSUMPTION_COMPLETED = 'innovatrix/FOCUS_ASSUMPTION_COMPLETED';

// InnoBoard

export const CHANGE_INNOBOARD_COLUMNS = 'innovatrix/CHANGE_INNOBOARD_COLUMNS';
export const CHANGE_INNOBOARD_CRITERIA = 'innovatrix/CHANGE_INNOBOARD_CRITERIA';
export const CHANGE_INNOBOARD_FILTER = 'innovatrix/CHANGE_INNOBOARD_FILTER';
export const CHANGE_INNOBOARD_SEGMENT = 'innovatrix/CHANGE_INNOBOARD_SEGMENT';
export const CLOSE_ASSUMPTION_ACTION_MODAL = 'innovatrix/CLOSE_ASSUMPTION_ACTION_MODAL';
export const DELETE_ASSUMPTION_ERROR = 'innovatrix/DELETE_ASSUMPTION_ERROR';
export const DELETE_ASSUMPTION_SUCCESS = 'innovatrix/DELETE_ASSUMPTION_SUCCESS';
export const DELETE_ASSUMPTION = 'innovatrix/DELETE_ASSUMPTION';
export const ERROR_ASSUMPTION_COMMENTS = 'innovatrix/ERROR_ASSUMPTION_COMMENTS';
export const ERROR_CRITERION_COMMENTS = 'innovatrix/ERROR_CRITERION_COMMENTS';
export const FETCH_ASSUMPTION_COMMENTS = 'innovatrix/FETCH_ASSUMPTION_COMMENTS';
export const FETCH_CRITERION_COMMENTS = 'innovatrix/FETCH_CRITERION_COMMENTS';
export const OPEN_ASSUMPTION_ACTION_MODAL = 'innovatrix/OPEN_ASSUMPTION_ACTION_MODAL';
export const RECEIVE_ASSUMPTION_COMMENTS = 'innovatrix/RECEIVE_ASSUMPTION_COMMENTS';
export const RECEIVE_CRITERION_COMMENTS = 'innovatrix/RECEIVE_CRITERION_COMMENTS';
export const RESET_INNOBOARD_FILTERS = 'innovatrix/RESET_INNOBOARD_FILTERS';
export const SELECT_FILTER_SEGMENT = 'innovatrix/SELECT_FILTER_SEGMENT';
export const SELECT_SEGMENT = 'innovatrix/SELECT_SEGMENT';
export const UNDO_ARCHIVE_ASSUMPTION_ERROR = 'innovatrix/UNDO_ARCHIVE_ASSUMPTION_ERROR';
export const UNDO_ARCHIVE_ASSUMPTION_SUCCESS = 'innovatrix/UNDO_ARCHIVE_ASSUMPTION_SUCCESS';
export const UNDO_ARCHIVE_ASSUMPTION = 'innovatrix/UNDO_ARCHIVE_ASSUMPTION';

export const DELETE_SEGMENT_SUCCESS = 'innovatrix/DELETE_SEGMENT_SUCCESS';
export const DELETE_SEGMENT_ERROR = 'innovatrix/DELETE_SEGMENT_ERROR';
export const UNDO_ARCHIVE_SEGMENT = 'innovatrix/UNDO_ARCHIVE_SEGMENT';
export const UNDO_ARCHIVE_SEGMENT_SUCCESS = 'innovatrix/UNDO_ARCHIVE_SEGMENT_SUCCESS';
export const UNDO_ARCHIVE_SEGMENT_ERROR = 'innovatrix/UNDO_ARCHIVE_SEGMENT_ERROR';

// Projects
/// ////////

export const FETCH_MANAGERS = 'innovatrix/FETCH_MANAGERS';
export const ERROR_MANAGERS = 'innovatrix/ERROR_MANAGERS';
export const RECEIVE_MANAGERS = 'innovatrix/RECEIVE_MANAGERS';
export const ACCEPT_PROJECT = 'innovatrix/ACCEPT_PROJECT';
export const REJECT_PROJECT = 'innovatrix/REJECT_PROJECT';
export const ACCEPT_WITH_CONDITIONS_PROJECT = 'innovatrix/ACCEPT_WITH_CONDITIONS_PROJECT';
export const UNDO_DECISION = 'innovatrix/UNDO_DECISION';
export const UPDATE_DECISION = 'innovatrix/UPDATE_DECISION';
export const RECEIVE_UPDATE_DECISION = 'innovatrix/RECEIVE_UPDATE_DECISION';
export const RECEIVE_UPDATED_PROJECT = 'innovatrix/RECEIVE_UPDATED_PROJECT';
export const DELETE_PROJECT = 'innovatrix/DELETE_PROJECT';
export const TOGGLE_PREDICTIONS = 'innovatrix/TOGGLE_PREDICTIONS';
export const INVITE_MEMBER_TO_PLATFORM = 'innovatrix/INVITE_MEMBER_TO_PLATFORM';
export const ERROR_ONE_ON_ONE_FOR_PROJECT = 'innovatrix/ERROR_ONE_ON_ONE_FOR_PROJECT';
export const FETCH_ONE_ON_ONE_FOR_PROJECT = 'innovatrix/FETCH_ONE_ON_ONE_FOR_PROJECT';
export const RECEIVE_ONE_ON_ONE_FOR_PROJECT = 'innovatrix/RECEIVE_ONE_ON_ONE_FOR_PROJECT';
export const RESERVE_TIMESLOT = 'innovatrix/RESERVE_TIMESLOT';

// WORKSHOPS
// /////////

export const ANIMATE_WORKSHOP = 'innovatrix/ANIMATE_WORKSHOP';
export const COMPLETE_ANIMATE_WORKSHOP = 'innovatrix/COMPLETE_ANIMATE_WORKSHOP';
export const DELETE_TIMESLOT_TIME_RANGE = 'innovatrix/DELETE_TIMESLOT_TIME_RANGE';
export const DOWNLOAD_ICAL_EVENT = 'innovatrix/DOWNLOAD_ICAL_EVENT';
export const ERROR_TIMESLOT_TIME_RANGES = 'innovatrix/ERROR_TIMESLOT_TIME_RANGES';
export const ERROR_TOGGLE_ATTENDED_WORKSHOP = 'innovatrix/ERROR_TOGGLE_ATTENDED_WORKSHOP';
export const FETCH_TIMESLOT_TIME_RANGES = 'innovatrix/FETCH_TIMESLOT_TIME_RANGES';
export const MARK_ATTENDED_WORKSHOP = 'innovatrix/MARK_ATTENDED_WORKSHOP';
export const MARK_ATTENDING_WORKSHOP = 'innovatrix/MARK_ATTENDING_WORKSHOP';
export const RECEIVE_TIMESLOT_TIME_RANGES = 'innovatrix/RECEIVE_TIMESLOT_TIME_RANGES';
export const REMOVE_EXTERNAL_FROM_WORKSHOP = 'innovatrix/REMOVE_EXTERNAL_FROM_WORKSHOP';
export const TOGGLE_ATTENDED_WORKSHOP = 'innovatrix/TOGGLE_ATTENDED_WORKSHOP';

// Evaluations
/// ///////////

export const CLONE_EVALUATION = 'innovatrix/CLONE_EVALUATION';
export const DELETE_EVALUATION_ERROR = 'innovatrix/DELETE_EVALUATION_ERROR';
export const DELETE_EVALUATION_SUCCESS = 'innovatrix/DELETE_EVALUATION_SUCCESS';
export const ERROR_EVALUATION = 'innovatrix/ERROR_EVALUATION';
export const ERROR_QUESTION = 'innovatrix/ERROR_QUESTION';
export const FETCH_QUESTION = 'innovatrix/FETCH_QUESTION';
export const POST_EVALUATION = 'innovatrix/POST_EVALUATION';
export const RECEIVE_EVALUATION = 'innovatrix/RECEIVE_EVALUATION';
export const RECEIVE_QUESTION = 'innovatrix/RECEIVE_QUESTION';
export const SAVE_ANSWER = 'innovatrix/SAVE_ANSWER';
export const SUBMIT_EVALUATION = 'innovatrix/SUBMIT_EVALUATION';

// Settings
/// ////////

export const RECEIVE_SETTINGS = 'innovatrix/RECEIVE_SETTINGS';
export const CHANGE_DECISION_SETTING = 'innovatrix/CHANGE_DECISION_SETTING';

// Modals
/// //////

export const CLOSE_MODAL = 'innovatrix/CLOSE_MODAL';
export const OPEN_MODAL = 'innovatrix/OPEN_MODAL';

// Geo
/// ///

export const ERROR_GEO_SUGGESTIONS = 'innovatrix/ERROR_GEO_SUGGESTIONS';
export const FETCH_GEO_SUGGESTIONS = 'innovatrix/FETCH_GEO_SUGGESTIONS';
export const RECEIVE_GEO_SUGGESTIONS = 'innovatrix/RECEIVE_GEO_SUGGESTIONS';

// Scores
/// //////

export const SELECT_EVALUATION = 'innovatrix/SELECT_EVALUATION';
export const CLEAR_EVALUATIONS = 'innovatrix/CLEAR_EVALUATIONS';
export const SELECT_REVIEWER = 'innovatrix/SELECT_REVIEWER';

// Salesforce
/// //////////

export const CREATE_SALESFORCE_SUBSCRIPTION = 'innovatrix/CREATE_SALESFORCE_SUBSCRIPTION';
export const REMOVE_SALESFORCE_SUBSCRIPTION = 'innovatrix/REMOVE_SALESFORCE_SUBSCRIPTION';
export const UPDATE_SALESFORCE_SYNC_STATUS = 'innovatrix/UPDATE_SALESFORCE_SYNC_STATUS';

export const OPEN_DECISION_SUBSCRIPTION = 'innovatrix/OPEN_DECISION_SUBSCRIPTION';
export const CLOSE_DECISION_SUBSCRIPTION = 'innovatrix/CLOSE_DECISION_SUBSCRIPTION';

// Documents
/// /////////

export const CREATE_DOCUMENT = 'innovatrix/CREATE_DOCUMENT';
export const CREATE_NEW_DOCUMENT_VERSION = 'innovatrix/CREATE_NEW_DOCUMENT_VERSION';
export const CREATE_NEW_WORKSHOP_DOCUMENT_VERSION = 'innovatrix/CREATE_NEW_WORKSHOP_DOCUMENT_VERSION';

export const DOWNLOAD_DOCUMENT = 'innovatrix/DOWNLOAD_DOCUMENT';
export const DOWNLOAD_ALL_REVISED_DOCUMENTS = 'innovatrix/DOWNLOAD_ALL_REVISED_DOCUMENTS';
export const DOWNLOAD_INNOVATRIX_LEGAL_DOCUMENT = 'innovatrix/DOWNLOAD_INNOVATRIX_LEGAL_DOCUMENT';

export const ATTACH_WORKSHOP_DOCUMENT = 'innovatrix/ATTACH_WORKSHOP_DOCUMENT';
export const ATTACH_WORKSHOP_DOCUMENT_FOR_REVISION = 'innovatrix/ATTACH_WORKSHOP_DOCUMENT_FOR_REVISION';
export const ATTACH_REVISED_DOCUMENT = 'innovatrix/ATTACH_REVISED_DOCUMENT';
export const ATTACH_DOCUMENT_TEMPLATE = 'innovatrix/ATTACH_DOCUMENT_TEMPLATE';
export const ATTACH_FORM_DOCUMENT = 'innovatrix/ATTACH_FORM_DOCUMENT';
export const ATTACH_LEGAL_DOCUMENT = 'innovatrix/ATTACH_LEGAL_DOCUMENT';

export const EXPORT_CSV = 'innovatrix/EXPORT_CSV';
export const EXPORT_DEALS_CSV = 'innovatrix/EXPORT_DEALS_CSV';
