import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import { PRIMARY, WHITE } from '../../../../../styles';

const answerOptionStyles = css({
  backgroundColor: WHITE,
  border: `1px dashed ${PRIMARY}`,
  fontSize: 16,
  padding: '4px 8px',
  width: 250,
});

const OptionPreview = ({ optionProps }) => (
  <div {...answerOptionStyles}>
    {optionProps.answer.title || 'Answer option'}
  </div>
);

OptionPreview.propTypes = { optionProps: PropTypes.object.isRequired };

export default OptionPreview;
