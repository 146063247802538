import { css } from 'glamor';
import React from 'react';
import PropTypes from 'prop-types';

// Styles
const descriptionStyles = css({
  fontSize: 16,
  fontStyle: 'italic',
  marginBottom: 11,
});

const subTitleStyles = css({
  marginTop: 0,
});

// Components
export const Description = ({ children, styles }) => <p {...css(descriptionStyles, styles)}>{children}</p>;

export const SubTitle = ({ children, styles, className = '' }) => <h5 {...styles} className={`${subTitleStyles} ${className}`}>{children}</h5>;

Description.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  styles: PropTypes.any,
};

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  styles: PropTypes.any,
};
