import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import en from 'i18next-icu/locale-data/en';
import { IS_PRODUCTION } from '@innovatrix/constants';

import { config } from './config';

const clientHost = config.get('duxis.auth.identityProviders.auth0.webAuth.clientHost', 'http://localhost');

i18n
  .use(
    new ICU({
      localeData: [en], // array of localeData
    }),
  )
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Note: this should become an ENV variable instance-based
    lng: 'en', // Note: perhaps we could store this in the user's profile data
    preload: ['en'], // preload the english local (for now)
    // We should split namespaces via a feature-based approach
    ns: ['main', 'about', 'reports', 'intakes', 'mails', 'errors', 'innoBoardModels', 'innoBoards', 'dashboard', 'assessments'],
    defaultNS: 'main',
    fallbackNS: 'main',
    keySeparator: false,
    debug: !IS_PRODUCTION, // only show debug logging on dev environment
    backend: {
      // The path on the server from where we want to load the translation files
      loadPath: `${clientHost}/locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
