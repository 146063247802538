import { takeEvery, select, take, call, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { SubscriptionClient } from 'subscriptions-transport-ws/dist/client';
import * as toast from '@innovatrix/utils/toast';

import { receiveUpdatedProjectAction } from '../modules/projects/cards/_actions';
import { getDxAuthToken, getDxAuthUser } from '../selectors/authSelectors';
import { CLOSE_DECISION_SUBSCRIPTION, CREATE_SALESFORCE_SUBSCRIPTION, OPEN_DECISION_SUBSCRIPTION, REMOVE_SALESFORCE_SUBSCRIPTION } from '../constants';
import { updateSalesforceSyncStatusAction } from '../actions';
import { config } from '../config';
import { getSalesforceStatusQuery } from '../queries';

import * as api from './_api';

const GRAPHQL_ENDPOINT = config.get('duxis.webSockets.salesforce');
const salesforceSyncSubscriptionQuery = `
  subscription {
    _salesforce
  }`;
const decisionSubscriptionQuery = `
  subscription {
    _decisionsSubscription
  }`;
const INITIAL_STATUS = 'INITIAL';
const INITIAL_CONNECTION = 'INITIAL_CONNECTION';

const createSalesforceChannel = (params) => eventChannel(emitter => {
  const salesforceClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
    connectionParams: { ...params },
    inactivityTimeout: 60000,
    minTimeout: 2000,
    reconnect: true,
    reconnectionAttempts: 10,
  });
  // On initial connection
  salesforceClient
    .onConnected(() => {
      console.info('[SalesforceSync] Successfully connected to websocket.');
      emitter({ payload: INITIAL_CONNECTION });
    });

  salesforceClient
    .request({ query: salesforceSyncSubscriptionQuery })
    .subscribe({
      next(events) {
        const { data: { _salesforce: salesforceSyncStatus } } = events;
        emitter({ payload: salesforceSyncStatus });
      },
    });

  return () => {
    salesforceClient.close();
  };
});

const createDecisionChannel = (params) => eventChannel(emitter => {
  const salesforceClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
    connectionParams: { ...params },
    inactivityTimeout: 60000,
    lazy: true,
    minTimeout: 2000,
    reconnect: true,
    reconnectionAttempts: 10,
  });

  salesforceClient.onConnected(() => {
    console.info('[DecisionPersist] Successfully connected to websocket.');
  });

  salesforceClient
    .request({ query: decisionSubscriptionQuery })
    .subscribe({
      next(events) {
        const { data: { _decisionsSubscription: data } } = events;
        emitter(data);
      },
    });

  return () => {
    salesforceClient.close();
  };
});

// Store the subscription instances here
export default function* () {
  let salesforceChannel;
  let decisionChannel;

  yield takeEvery(CREATE_SALESFORCE_SUBSCRIPTION, function* () {
    try {
      const dxToken = yield select(getDxAuthToken);
      const user = yield (select(getDxAuthUser));
      salesforceChannel = yield call(createSalesforceChannel, { dxToken, userId: user._id });
      while (salesforceChannel) {
        const data = yield take(salesforceChannel);
        if (data && data.payload === INITIAL_CONNECTION) {
          const status = yield call(api.fetch, '_getSalesforceStatus', getSalesforceStatusQuery);
          yield put(updateSalesforceSyncStatusAction({ status }));
        }
        else if (data) {
          yield put(updateSalesforceSyncStatusAction({ status: data.payload }));
        }
      }
    }
    catch (err) {
      console.error(err);
    }
  });

  yield takeEvery(REMOVE_SALESFORCE_SUBSCRIPTION, function* () {
    if (salesforceChannel) {
      salesforceChannel.close();
      salesforceChannel = null;
      yield put(updateSalesforceSyncStatusAction({ status: INITIAL_STATUS }));
    }
  });

  yield takeEvery(OPEN_DECISION_SUBSCRIPTION, function* () {
    try {
      const dxToken = yield select(getDxAuthToken);
      const user = yield (select(getDxAuthUser));
      decisionChannel = yield call(createDecisionChannel, { dxToken, userId: user._id });
      while (decisionChannel) {
        const result = yield take(decisionChannel);
        if (result) {
          const { data, error } = result;
          if (error) { toast.error(`Decision update failed. Reset project: ${data.name} to ${data.status}.`); }
          yield put(receiveUpdatedProjectAction(data));
        }
      }
    }
    catch (err) {
      console.error(err);
    }
  });

  yield takeEvery(CLOSE_DECISION_SUBSCRIPTION, function* () {
    if (decisionChannel) {
      decisionChannel.close();
      decisionChannel = null;
      yield;
    }
  });
}
