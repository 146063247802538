/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileIcon from '@innovatrix/icons/ProfileIcon';
import { Body, Paragraph } from '@innovatrix/common/text';
import { getCurrentUser } from '@innovatrix/selectors/data/applicationSelectors';
import { openModalAction, closeModalAction, updateProfileAction } from '@innovatrix/actions';
import { getOpenedModals } from '@innovatrix/selectors';
import { prevent } from '@innovatrix/utils';
import graphql from '@innovatrix/common/graphql';
import { INNOVATRIX_INTRODUCTION_TOUR, INTAKES_ENABLED } from '@innovatrix/constants';
import useClickOutside from 'react-cool-onclickoutside';
import { useHistory } from 'react-router';

import EditProfileModal from '../persist';
import { resetUserTourMutation } from '../_queries';
import { ApplicationContext } from '../../context';

// -- Styles --------------- --- --  -

const Wrapper = styled.div`
  display: flex;
  margin-top: -6px;
  position: relative;
`;

const userIconWrapperBaseStyle = `
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
`;

const OuterUserIconWrapper = styled.div`
  ${userIconWrapperBaseStyle};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const InnerUserIconWrapper = styled.div`
  ${userIconWrapperBaseStyle};
  background-color: ${({ theme }) => theme.colors.greyLight3};
  margin-bottom: 12px;
`;

const MyProfileDrawer = styled.div`
  align-items: center;
  ${({ theme }) => theme.block};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
  cursor: default;
  display: flex;
  flex-direction: column;
  padding: 16px 12px 12px;
  position: absolute;
  right: 0;
  top: 36px;
  width: 200px;
  z-index: 99;
`;

const Name = styled(Body)`
  margin-bottom: 0;
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.greyLight3};
  margin: 12px -12px;
  width: calc(100% + 24px);
`;

const linkStyles = `
  cursor: pointer;
  margin-bottom: 0;
`;

const MyProfile = styled(Body)`
  ${linkStyles};
  color: ${({ theme }) => theme.colors.primary};
`;

const Link = styled(Paragraph)`${linkStyles};`;

const ResetTourLink = ({ resetTour, close }) => {
  const { refetchTours: refetch, user } = React.useContext(ApplicationContext);
  const onResetIntroTour = React.useCallback(() => {
    resetTour({
      userId: user.id,
      tourName: INNOVATRIX_INTRODUCTION_TOUR,
      callback: (err) => {
        if (err) {
          console.error(err);
        }
        else {
          refetch();
        }
        close();
      },
    });
  }, [close, refetch, resetTour, user.id]);

  return (
    <ViewIntroTour onClick={onResetIntroTour}>View intro tour</ViewIntroTour>
  );
};

ResetTourLink.propTypes = {
  close: PropTypes.func.isRequired,
  resetTour: PropTypes.func.isRequired,
};

const ViewIntroTour = styled(Paragraph)`
  ${linkStyles}
`;

// -- Component --------------- --- --  -

const UserMenu = ({
  openModals, signOut, user, updateProfile,
  openModal, closeModal, resetTour, isApplicant,
  hideApplicationsLink,
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const closeEditProfileModal = React.useCallback(() => closeModal('editProfile'), [closeModal]);

  const onClick = React.useCallback(() => {
    if (openModals.editProfile) { return; }
    setIsOpen(true);
  }, [openModals.editProfile]);

  const onKeyDown = React.useCallback((e) => {
    if (e.keyCode === 32) { e.stopPropagation(); }
  }, []);

  const openEditProfileModal = React.useCallback((e) => {
    prevent(e);
    openModal('editProfile');
    setIsOpen(() => false);
  }, [openModal]);

  const goToApplications = React.useCallback(() => {
    history.push('/apply');
  }, [history]);

  const ref = useClickOutside(() => {
    close();
  });

  return (
    <Wrapper onClick={onClick} ref={ref}>
      <OuterUserIconWrapper>
        <ProfileIcon />
      </OuterUserIconWrapper>
      {isOpen &&
        <MyProfileDrawer>
          <InnerUserIconWrapper><ProfileIcon /></InnerUserIconWrapper>
          {user && user.firstName && user.lastName ?
            <Name>{user.firstName} {user.lastName}</Name> : <span />}
          <Divider />
          <MyProfile onClick={openEditProfileModal}>My Profile</MyProfile>
          {(user && user.id && !isApplicant) ? <Divider /> : null}
          {(user && user.id && !isApplicant) && (<ResetTourLink resetTour={resetTour} close={close} />)}
          {isApplicant || !INTAKES_ENABLED || hideApplicationsLink ? null : <Divider />}
          {isApplicant || !INTAKES_ENABLED || hideApplicationsLink ? null : (
            <Link onClick={goToApplications}>Applications</Link>
          )}
          <Divider />
          <Link onClick={signOut}>Sign out</Link>
        </MyProfileDrawer>}
      <EditProfileModal
        onClose={closeEditProfileModal}
        isOpen={Boolean(openModals.editProfile)}
        user={user}
        updateProfile={updateProfile}
        keyPress={onKeyDown}
      />
    </Wrapper>
  );
};

UserMenu.propTypes = {
  closeModal: PropTypes.func.isRequired,
  hideApplicationsLink: PropTypes.bool,
  isApplicant: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  openModals: PropTypes.object,
  resetTour: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  user: PropTypes.object,
};

UserMenu.defaultProps = {
  isApplicant: false,
  hideApplicationsLink: false,
};

export default graphql({
  mutation: resetUserTourMutation,
  name: 'resetTour',
  namespace: '_resetUserTour',
  type: 'Mutation',
})(connect((state) => ({
  openModals: getOpenedModals(state),
  user: getCurrentUser(state),
}), {
  closeModal: closeModalAction,
  openModal: openModalAction,
  updateProfile: updateProfileAction,
})(React.memo(UserMenu)));
