import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Paragraph, Title } from '@innovatrix/common/text';

import Badge from './Badge';

const Container = styled.div`
  ${({ theme }) => theme.block};
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
  margin-right: 16px;
  display: inline-block;
  max-width: 100%;
`;

const HeaderLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  margin-right: 32px;
`;

const StyledHeader = styled.div`
  min-width: 0;
`;

const LeftHeaderWithTitle = styled(StyledHeader)`
  display: flex;
  flex-direction: column;
`;

const LeftHeader = styled(StyledHeader)`
  align-items: center;
  display: flex;
`;

const LeftHeaderTitle = styled(StyledTitle)`
  margin-left: ${({ hasBadge }) => (hasBadge ? '16px' : 0)};
  margin-right: ${({ hasSubtitle }) => (hasSubtitle ? '16px' : 0)};
`;

const RightHeader = styled(StyledHeader)``;

const Body = styled.div`
  padding: 16px 32px;
`;

const SubTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey2};
  margin-bottom: 0;
  margin-top: 6px;
  overflow: hidden;
  white-space: nowrap;
`;

const Block = ({ badgeLabel, className, children, headerTopRight, link, size, subTitle, title }) => (
  <Container className={className}>
    {title &&
      <Header>
        {link ?
          <LeftHeaderWithTitle>
            <Link to={link}>
              <StyledTitle size={size}>{title}</StyledTitle>
              {badgeLabel ? <Badge text={badgeLabel} /> : null}
            </Link>
            {subTitle ? <SubTitle title={subTitle}>{subTitle}</SubTitle> : null}
          </LeftHeaderWithTitle>
          :
          <LeftHeader>
            {badgeLabel ? <Badge text={badgeLabel} /> : null}
            <LeftHeaderTitle
              hasBadge={badgeLabel}
              hasSubtitle={subTitle}
              size={size}
            >
              {title}
            </LeftHeaderTitle>
            {subTitle ? <SubTitle title={subTitle}>{subTitle}</SubTitle> : null}
          </LeftHeader>}
        <RightHeader>
          {headerTopRight}
        </RightHeader>
      </Header>}
    {title ? <HeaderLine /> : null}
    <Body>
      {children}
    </Body>
  </Container>
);

Block.propTypes = {
  badgeLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  headerTopRight: PropTypes.node,
  link: PropTypes.node,
  size: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

Block.defaultProps = {
  size: 'h2',
  subTitle: '',
};

export default Block;
