import {
  CHANGE_INNOBOARD_COLUMNS,
  CHANGE_INNOBOARD_CRITERIA,
  CHANGE_INNOBOARD_FILTER,
  CHANGE_INNOBOARD_SEGMENT,
  CLOSE_ASSUMPTION_ACTION_MODAL,
  DELETE_ASSUMPTION_ERROR,
  DELETE_ASSUMPTION,
  DELETE_SEGMENT_ERROR,
  ERROR_ASSUMPTION_COMMENTS,
  ERROR_CRITERION_COMMENTS,
  FETCH_ASSUMPTION_COMMENTS,
  FETCH_CRITERION_COMMENTS,
  OPEN_ASSUMPTION_ACTION_MODAL,
  RECEIVE_ASSUMPTION_COMMENTS,
  RECEIVE_CRITERION_COMMENTS,
  RESET_INNOBOARD_FILTERS,
  SELECT_FILTER_SEGMENT,
  SELECT_SEGMENT,
  UNDO_ARCHIVE_ASSUMPTION_ERROR,
  UNDO_ARCHIVE_ASSUMPTION,
  UNDO_ARCHIVE_SEGMENT_ERROR,
  UNDO_ARCHIVE_SEGMENT,
} from '../constants';

import { createFormikAction } from './utils';

export const persistInnoBoardAction = createFormikAction('PERSIST_INNOBOARD');
export const persistCriterionAction = createFormikAction('PERSIST_CRITERION');
export const persistAssumptionAction = createFormikAction('PERSIST_ASSUMPTION');
export const persistAssumptionCommentAction = createFormikAction('PERSIST_ASSUMPTION_COMMENT');
export const persistInnoboardCriterionCommentAction = createFormikAction('PERSIST_CRITERION_COMMENT');

export const selectSegmentAction = segmentId => ({
  segmentId,
  type: SELECT_SEGMENT,
});

export const deleteSegmentErrorAction = ({ error, innoBoardId, segmentId }) => ({
  error,
  innoBoardId,
  segmentId,
  type: DELETE_SEGMENT_ERROR,
});

export const undoArchiveSegmentAction = ({ innoBoardId, projectId, innoBoardPhaseId, segmentId }) => ({
  innoBoardId,
  projectId,
  innoBoardPhaseId,
  segmentId,
  type: UNDO_ARCHIVE_SEGMENT,
});

export const undoArchiveSegmentErrorAction = ({ error, innoBoardId, segmentId }) => ({
  error,
  innoBoardId,
  segmentId,
  type: UNDO_ARCHIVE_SEGMENT_ERROR,
});

export const deleteAssumptionAction = ({ assumptionId, isSoftDelete, projectId }) => ({
  assumptionId,
  isSoftDelete,
  projectId,
  type: DELETE_ASSUMPTION,
});

export const deleteAssumptionErrorAction = ({ assumptionId, error }) => ({
  assumptionId,
  error,
  type: DELETE_ASSUMPTION_ERROR,
});

export const undoArchiveAssumptionAction = ({ assumptionId, projectId }) => ({
  assumptionId,
  projectId,
  type: UNDO_ARCHIVE_ASSUMPTION,
});

export const undoArchiveAssumptionErrorAction = ({ assumptionId, error }) => ({
  assumptionId,
  error,
  type: UNDO_ARCHIVE_ASSUMPTION_ERROR,
});

export const openAssumptionActionModalAction = (args) => ({
  ...args,
  type: OPEN_ASSUMPTION_ACTION_MODAL,
});

export const closeAssumptionActionModalAction = () => ({
  type: CLOSE_ASSUMPTION_ACTION_MODAL,
});

export const changeInnoboardSegmentAction = ({ segment, boardColumns }) => ({
  segment,
  boardColumns,
  type: CHANGE_INNOBOARD_SEGMENT,
});

export const selectFilterSegmentAction = segment => ({
  segment,
  type: SELECT_FILTER_SEGMENT,
});

export const changeInnoboardFilterAction = (filter) => ({
  filter,
  type: CHANGE_INNOBOARD_FILTER,
});

export const changeInnoboardCriteriaAction = ({ value }) => ({
  value,
  type: CHANGE_INNOBOARD_CRITERIA,
});

export const resetInnboardFiltersAction = () => ({
  type: RESET_INNOBOARD_FILTERS,
});

export const fetchAssumptionCommentsAction = ({ assumptionId }) => ({
  assumptionId,
  type: FETCH_ASSUMPTION_COMMENTS,
});

export const receiveAssumptionCommentsAction = ({ assumptionId, data }) => ({
  assumptionId,
  data,
  type: RECEIVE_ASSUMPTION_COMMENTS,
});

export const errorAssumptionCommentsAction = ({ assumptionId, error }) => ({
  assumptionId,
  error,
  type: ERROR_ASSUMPTION_COMMENTS,
});

export const fetchCriterionCommentsAction = ({ innoboardCriterionId }) => ({
  innoboardCriterionId,
  type: FETCH_CRITERION_COMMENTS,
});

export const receiveCriterionCommentsAction = ({ innoboardCriterionId, data }) => ({
  innoboardCriterionId,
  data,
  type: RECEIVE_CRITERION_COMMENTS,
});

export const errorCriterionCommentsAction = ({ innoboardCriterionId, error }) => ({
  innoboardCriterionId,
  error,
  type: ERROR_CRITERION_COMMENTS,
});

export const changeInnoboardColumnsAction = ({
  boardColumns,
}) => ({
  boardColumns,
  type: CHANGE_INNOBOARD_COLUMNS,
});
