import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import formikField from '@innovatrix/components/fields/formikField';
import { prevent } from '@innovatrix/utils';
import { ENTER_KEY, ESCAPE_KEY } from '@innovatrix/constants';
import { PreBody } from '@innovatrix/common/text';

import { FormGroup, LabelGroup, InputGroup, Clarification } from './_helpers';
import {
  ALLOWED_SIZES,
  SIZE_MAPPING,
  TEXT_FIELD_HEIGHT_MAPPING,
  defaultFieldPropTypes,
} from './_constants';

const Input = styled.textarea`
  border: 0;
  border-bottom: 1px solid ${({ error, theme, touched }) => (error && touched ? theme.colors.warning : theme.colors.grey1)};
  color: ${({ theme }) => theme.colors.secondary};
  cursor: text;
  font-size: ${({ size }) => SIZE_MAPPING[size]};
  line-height: ${({ size }) => SIZE_MAPPING[size]};
  outline: 0;
  overflow-y: hidden;
  padding: 10px 8px 2px;
  resize: none;
  transition: border-bottom 0.3s;
  width: 100%;

  &:placeholder {
    color: ${({ theme }) => theme.colors.grey2};
  }
  &:disabled {
    border-bottom: 0;
    color: ${({ theme, readMode }) => (readMode ? theme.colors.secondary : theme.colors.grey2)};
    /* background-color: ${({ theme, readMode }) => (readMode ? theme.colors.white : 'default')}; */
    cursor: default;
  }
  &:hover, &:focus, &:active {
    border-bottom-color: ${({ theme }) => theme.colors.focus};
  }
`;

const TextFieldComponent = ({
  clarification,
  className,
  clearable,
  description,
  disabled,
  error,
  fieldIcon,
  fieldId,
  information,
  label,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  onPressEnter,
  onPressEscape,
  placeholder,
  readMode,
  required,
  size,
  shouldAutoResize,
  shouldFocus,
  touched,
  value,
}) => {
  if (!ALLOWED_SIZES.includes(size)) {
    throw new Error(
      `Invalid size passed to the <TextField /> component.
      Got ${size} expected one of ${ALLOWED_SIZES.join(', ')}.`,
    );
  }

  const handleKeyDown = (e) => {
    // Do nothing on "Shift + Enter" to allow carriage return.
    if (e.keyCode === ENTER_KEY && !e.nativeEvent.shiftKey && onPressEnter) {
      prevent(e);
      onPressEnter();
    }
    if (e.keyCode === ESCAPE_KEY && onPressEscape) {
      prevent(e);
      onPressEscape();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const textInputRef = React.useRef();
  const animationFrame = React.useRef();

  React.useLayoutEffect(() => {
    if (shouldFocus) {
      setTimeout(() => {
        requestAnimationFrame(
          () => textInputRef.current && textInputRef.current.focus(),
        );
      });
    }
  }, [shouldFocus]);

  const onChangeCb = React.useCallback(
    (e) => {
      const { value: newValue } = e.currentTarget;
      onChange(newValue);
    },
    [onChange],
  );

  const autosize = React.useCallback(() => {
    if (shouldAutoResize) {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
      // The animationFrame caused a bug in the Application form
      // see: IVT-1305, so I have removed it until I can think of a fix with animation frames enabled (if at all possible)
      // Read frame
      // animationFrame.current = requestAnimationFrame(() => {
      if (!textInputRef.current) {
        return;
      }
      textInputRef.current.style.height = '0px';
      const currentScrollHeight = textInputRef.current.scrollHeight;
      // Write frame
      textInputRef.current.style.height = `${
        currentScrollHeight + TEXT_FIELD_HEIGHT_MAPPING[size] || 34
      }px`;
      // animationFrame.current = null;
      // });
    }
  }, [shouldAutoResize, size]);

  // On mount try to autosize.
  React.useLayoutEffect(() => {
    autosize(value);
  }, [autosize, value]);

  const clear = React.useCallback(() => onChange(''), [onChange]);

  const onClickCb = React.useCallback(
    (e) => {
      prevent(e);
      if (onClick) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <FormGroup className={className}>
      {label && (
        <LabelGroup
          touched={touched}
          error={error}
          label={label}
          information={information}
          required={required}
        />
      )}
      {description ? <PreBody>{description}</PreBody> : null}
      {clarification ? <Clarification>{clarification}</Clarification> : null}
      <InputGroup
        icon={fieldIcon}
        clear={disabled || !clearable ? undefined : clear}
      >
        <Input
          autocomplete="off"
          ref={textInputRef}
          disabled={disabled}
          error={error}
          onBlur={onBlur}
          onChange={onChangeCb}
          onClick={onClickCb}
          onKeyDown={handleKeyDown}
          name={fieldId}
          onFocus={onFocus}
          size={size}
          readMode={readMode}
          placeholder={placeholder || 'placeholder'}
          touched={touched}
          value={value}
        />
      </InputGroup>
    </FormGroup>
  );
};

TextFieldComponent.propTypes = {
  ...defaultFieldPropTypes,
  clearable: PropTypes.bool,
  description: PropTypes.string,
  fieldIcon: PropTypes.node,
  information: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPressEnter: PropTypes.func,
  onPressEscape: PropTypes.func,
  placeholder: PropTypes.string,
  readMode: PropTypes.bool,
  shouldAutoResize: PropTypes.bool,
  shouldFocus: PropTypes.bool,
  size: PropTypes.oneOf(ALLOWED_SIZES),
  touched: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

TextFieldComponent.defaultProps = { shouldAutoResize: true, size: 'body' };

export const TextField = React.memo(TextFieldComponent);

export default formikField(TextField);
