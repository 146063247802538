export const BUTTON = 'button';
export const ICON_BUTTON = 'iconButton';

export const PRIMARY = 'primary';
export const QUATERNARY = 'quaternary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const TEXT_BUTTON = 'textButton';
export const OUTLINE = 'outline';
export const WARNING = 'warning';

export const ALLOWED_FLAVORS = [PRIMARY, SECONDARY, TERTIARY, QUATERNARY, TEXT_BUTTON, OUTLINE, WARNING];
export const TOGGLE_TYPES = [ICON_BUTTON, BUTTON];
