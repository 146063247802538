import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { TextField } from '@innovatrix/common/fields';
import Comment from '@innovatrix/icons/CommentIcon';
import { GREY_2 } from '@innovatrix/styles';

// -- Styles --------------- --- --  -

const CommentContainer = styled.div`
  align-items: center;
  display: flex;
  padding-top: 24px;
`;

const CommentIcon = styled(Comment)`
  margin-right: 12px;
`;

const CommentField = styled(TextField)`
  > div > textarea {
    background-color: ${({ theme }) => theme.colors.greyLight1};
    border-radius: ${({ theme }) => theme.border.radius};
    border: 1px solid ${({ theme }) => theme.colors.greyLight3};
    padding: 14px 16px 8px;

    &:focus, &:hover {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }
  }
`;

// -- Component --------------- --- --  -

const CommentInput = ({ className, disabled, fieldId, onPressEnter, ...props }) => (
  <CommentContainer className={className}>
    <CommentIcon color={GREY_2} />
    <CommentField
      fieldId={fieldId}
      name={fieldId}
      editMode={!disabled}
      placeholder="Write a comment"
      onPressEnter={onPressEnter}
      {...props}
    />
  </CommentContainer>
);

CommentInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  onPressEnter: PropTypes.func,
};

export default React.memo(CommentInput);
