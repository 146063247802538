// -- dxQuery --------------- --- --  -

export const DX_QUERY_POSTED = 'DX_QUERY_POSTED';
export const DX_QUERY_CANCELLED = 'DX_QUERY_CANCELLED';
export const DX_QUERY_FAILED = 'DX_QUERY_FAILED';
export const DX_QUERY_SUCCESS = 'DX_QUERY_SUCCESS';

export const DX_QUERY_CONTEXT_PENDING = 'DX_QUERY_CONTEXT_PENDING';
export const DX_QUERY_CONTEXT_COMPLETE = 'DX_QUERY_CONTEXT_COMPLETE';

// -- dxAuth --------------- --- --  -

export const DX_AUTH_AUTHENTICATE = 'DX_AUTH_AUTHENTICATE';
export const DX_AUTH_AUTHENTICATED = 'DX_AUTH_AUTHENTICATED';
export const DX_AUTH_FAILED = 'DX_AUTH_FAILED';
export const DX_AUTH_SET_PROVIDER_STATE = 'DX_AUTH_SET_PROVIDER_STATE';
export const DX_AUTH_SIGN_OUT = 'DX_AUTH_SIGN_OUT';
export const DX_AUTH_SIGNED_OUT = 'DX_AUTH_SIGNED_OUT';

// -- dxModals --------------- --- --  -

export const DX_MODAL_ADD = 'DX_MODAL_ADD';
export const DX_MODAL_CLEAR = 'DX_MODAL_CLEAR';
export const DX_MODAL_CONFIRM = 'DX_MODAL_CONFIRM';
export const DX_MODAL_REMOVE = 'DX_MODAL_REMOVE';
