import {
  DX_QUERY_CANCELLED,
  DX_QUERY_CONTEXT_PENDING,
  DX_QUERY_CONTEXT_COMPLETE,
  DX_QUERY_FAILED,
  DX_QUERY_POSTED,
  DX_QUERY_SUCCESS,
} from '../constants';

// -- Initial State --------------- --- --  -

export const initialDxQueryState = {
  cancelled: false,
  complete: false,
  context: null,
  data: null,
  dxError: null,
  failed: false,
  fetching: false,
  pending: false,
};

// -- Reducer --------------- --- --  -

const reduceContext = (state, action, queryReducer) => {
  const { context } = action;
  const queryState = state[context] || { ...initialDxQueryState, context };
  const nxtQueryState = queryReducer(queryState, action);
  nxtQueryState.context = context; // re-assign to be sure...
  return nxtQueryState === state[context] ? state : { ...state, [context]: nxtQueryState };
};

/**
 * The main reducer.
 * @param {object} state - The current state.
 * @param {object} action - The action.
 * @returns {object} The updated state.
 */
export const dxQueryReducer = (state = {}, action) => {
  switch (action.type) {
    case DX_QUERY_POSTED:
      return reduceContext(state, action, (_, { query, queryId, url, variables }) => ({
        ...initialDxQueryState,
        fetching: true,
        query,
        queryId,
        url,
        variables,
      }));

    case DX_QUERY_CANCELLED:
      return reduceContext(state, action, (_, { query, queryId, url, variables }) => ({
        ...initialDxQueryState,
        cancelled: true,
        query,
        queryId,
        url,
        variables,
      }));

    case DX_QUERY_FAILED:
      return reduceContext(state, action, (_, { dxError, query, queryId, url, variables }) => ({
        ...initialDxQueryState,
        dxError,
        failed: true,
        query,
        queryId,
        url,
        variables,
      }));

    case DX_QUERY_SUCCESS:
      return reduceContext(state, action, (_, { data, query, queryId, url, variables }) => ({
        ...initialDxQueryState,
        complete: true,
        data,
        query,
        queryId,
        url,
        variables,
      }));

    case DX_QUERY_CONTEXT_PENDING:
      return reduceContext(state, action, (queryState) => ({
        ...queryState,
        pending: true,
      }));

    case DX_QUERY_CONTEXT_COMPLETE:
      return reduceContext(state, action, (queryState) => ({
        ...queryState,
        pending: false,
      }));

    default:
      return state;
  }
};
