import axios from 'axios';
import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, takeEvery } from 'redux-saga/effects';

import { config } from '../config';
import { FETCH_GEO_SUGGESTIONS } from '../constants';
import { receiveGeoSuggestionsAction, errorGeoSuggestionsAction } from '../actions/geoActions';

const BASE_URL = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places';
const MAPBOX_TOKEN = config.get('innovatrix.mapbox.token');

export default function* () {
  yield takeEvery(FETCH_GEO_SUGGESTIONS, function* fetchGeoSuggesitons({ search }) {
    try {
      const url = `${BASE_URL}/${search}.json?limit=10&country=be,nl&type=poi,place,address&language=en&access_token=${MAPBOX_TOKEN}`;
      const { data: { features } } = yield call(axios.get, url, false);
      yield put(receiveGeoSuggestionsAction(features));
    }
    catch (error) {
      yield put(errorGeoSuggestionsAction(error));
      yield call(showErrorModal, error, { title: 'Failed to fetch the evaluations.' });
    }
  });
}
