import { PropTypes } from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { Title } from '@innovatrix/common/text';

// -- Styles --------------- --- --  -

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  box-sizing: border-box;
  padding: 16px 64px 0 !important;
  position: relative;

  @media screen and (min-width: 1700px) {
    padding-left: calc(64px + 1vw) !important;
    padding-right: calc(64px + 1vw) !important;
  }

  @media screen and (min-width: 680px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: left;
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  line-height: 1.25;
  margin: 0px 8px 6px 0px;
  max-width: 160px;
  overflow-wrap: break-word;
  overflow: visible;
  text-overflow: ellipsis;
  text-transform: uppercase;
  width: auto;
`;

const ChapterHeaderTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`;

// -- PropTypes --------------- --- --  -

const propTypes = {
  children: PropTypes.node, // an optional ModuleToolbar
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  className: '',
  children: null,
  subtitle: null,
  title: 'Loading',
};

// -- Component --------------- --- --  -

const ChapterHeader = ({ children, className, subtitle, title }) => (
  <Header className={`dx-chapter-header${className ? ` ${className}` : ''}`}>
    <TitleBar>
      {subtitle ? <SubTitle>{subtitle}</SubTitle> : null}
      <ChapterHeaderTitle size="h1">{title}</ChapterHeaderTitle>
    </TitleBar>
    {children}
  </Header>
);

ChapterHeader.propTypes = propTypes;
ChapterHeader.defaultProps = defaultProps;

export default ChapterHeader;
