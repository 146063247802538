import * as React from 'react';
import PropTypes from 'prop-types';

const ValidationIcon = ({ className, color, height, size, width }) => (
  <svg className={className} color={color} height={height || size} width={width || size} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20">
    <path
      fill={color}
      id="Validation"
      d="M12.2,0c0.7,0,1.2,0.5,1.2,1.1s-0.6,1.1-1.2,1.1H12v5.3l5.1,7.8c0.6,0.9,0.8,1.9,0.6,2.9
	c-0.2,1-1.1,1.7-2.2,1.7H4.6c-1.1,0-2-0.7-2.2-1.7c-0.2-1,0-2,0.5-2.9l4.2-7.8V2.3H6.8c-0.7,0-1.2-0.5-1.2-1.1S6.1,0,6.8,0H12.2z
	M12.2,0.9H6.8C6.7,0.9,6.6,1,6.6,1.1s0.1,0.2,0.2,0.2H8v6.5l-4.3,8c-0.4,0.7-0.5,1.5-0.4,2.3c0.1,0.6,0.6,1,1.3,1h10.8
	c0.6,0,1.2-0.4,1.3-1c0.1-0.8,0-1.6-0.5-2.2l-5.2-8V1.4h1.2c0.1,0,0.2-0.1,0.2-0.2S12.4,0.9,12.2,0.9z M12.2,10.5l3.7,5.7
	c0.3,0.5,0.4,1.1,0.3,1.7l0,0.2c-0.1,0.4-0.5,0.7-1,0.7H4.8c-0.5,0-0.8-0.3-1-0.7l0-0.2c-0.2-0.6-0.1-1.2,0.2-1.7l2.7-5
	c0.5,0.3,1,0.4,1.3,0.3c0.7-0.1,1.4-0.8,2.5-0.9C11.3,10.4,11.8,10.4,12.2,10.5z M6.9,15c-0.7,0-1.2,0.6-1.2,1.2
	c0,0.7,0.6,1.2,1.2,1.2s1.3-0.6,1.3-1.2C8.2,15.6,7.6,15,6.9,15z M9.7,14.1c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5
	s0.5-0.2,0.5-0.5C10.1,14.3,9.9,14.1,9.7,14.1z M7.9,13.2c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5
	S8.1,13.2,7.9,13.2z M11,12.3c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9S11.5,12.3,11,12.3z"
    />
  </svg>
);

ValidationIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ValidationIcon.defaultProps = {
  className: '',
  color: '#222222',
  size: '14px',
};

export default ValidationIcon;
