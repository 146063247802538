import {
  ERROR_CAMPAIGN,
  ERROR_CAMPAIGNS,
  FETCH_CAMPAIGN,
  FETCH_CAMPAIGNS,
  RECEIVE_CAMPAIGN,
  RECEIVE_CAMPAIGNS,
  ERROR_UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DOWNLOAD_CAMPAIGNS_EXPORT,
} from '../constants';

// Fetch campaign
/// //////////////

export const errorCampaignAction = ({ campaignId, error }) => ({
  campaignId,
  error,
  type: ERROR_CAMPAIGN,
});

export const fetchCampaignAction = ({ campaignId }) => ({
  campaignId,
  type: FETCH_CAMPAIGN,
});

export const receiveCampaignAction = ({ campaignId, data }) => ({
  campaignId,
  data,
  type: RECEIVE_CAMPAIGN,
});

// Update campaign
/// ///////////////

export const errorUpdateCampaignAction = ({ campaignId, error }) => ({
  campaignId,
  error,
  type: ERROR_UPDATE_CAMPAIGN,
});

export const updateCampaignAction = (payload) => ({
  ...payload,
  type: UPDATE_CAMPAIGN,
});

// Fetch campaigns
/// ///////////////

export const errorCampaignsAction = ({ error }) => ({
  error,
  type: ERROR_CAMPAIGNS,
});

export const fetchCampaignsAction = () => ({
  type: FETCH_CAMPAIGNS,
});

export const receiveCampaignsAction = ({ data }) => ({
  data,
  type: RECEIVE_CAMPAIGNS,
});

export const downloadCampaignsExportAction = ({ id, name }) => ({
  id,
  name,
  type: DOWNLOAD_CAMPAIGNS_EXPORT,
});

