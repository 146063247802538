import { queryBuilder } from '@innovatrix/utils/dx';

// -- Queries --------------- --- --  -

export const getActivitiesForModelVersionQuery = queryBuilder(
  {
    variables: { filter: { type: 'JSON' } },
    fields: {
      filterActivities: {
        varArgs: { filter: 'filter' },
        fields: {
          items: {
            fields: {
              id: true,
              title: true,
              order: true,
              is_other: true,
            },
          },
          filteredCount: true,
          reason: true,
          success: true,
        },
      },
    },
  },
);
