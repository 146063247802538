import PropTypes from 'prop-types';

export const dealPropType = PropTypes.shape({
  category: PropTypes.string,
  contact: PropTypes.string,
  homepage: PropTypes.string,
  id: PropTypes.string,
  instructions: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
  offer: PropTypes.string,
  pictureUrl: PropTypes.string,
  remarks: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
});
