import { queryBuilder, mutationBuilder } from '@innovatrix/utils/dx';

// -- Queries --------------- --- --  -

export const getObjectiveTypesQuery = queryBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
    },
    fields: {
      _getObjectiveTypes: {
        varArgs: { projectId: 'projectId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getObjectivesQuery = queryBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
      completed: { type: 'Boolean' },
      objectiveTypeId: { type: 'String' },
    },
    fields: {
      _getObjectives: {
        varArgs: { projectId: 'projectId', completed: 'completed', objectiveTypeId: 'objectiveTypeId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createObjectiveTypeMutation = mutationBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
      objectiveType: { type: 'JSON!' },
    },
    fields: {
      _createObjectiveType: {
        varArgs: { projectId: 'projectId', objectiveType: 'objectiveType' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const persistObjectiveTypesQuery = mutationBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
      objectiveTypes: { type: 'JSON!' },
    },
    fields: {
      _persistObjectiveTypes: {
        varArgs: { projectId: 'projectId', objectiveTypes: 'objectiveTypes' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createObjectiveMutation = mutationBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
      objectiveTypeId: { type: 'String' },
      objective: { type: 'JSON!' },
    },
    fields: {
      _createObjective: {
        varArgs: { projectId: 'projectId', objectiveTypeId: 'objectiveTypeId', objective: 'objective' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const updateObjectiveMutation = mutationBuilder(
  {
    variables: {
      objective: { type: 'JSON!' },
    },
    fields: {
      _updateObjective: {
        varArgs: { objective: 'objective' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const moveObjectiveMutation = mutationBuilder(
  {
    variables: {
      objectiveId: { type: 'String!' },
      objectiveTypeId: { type: 'String!' },
    },
    fields: {
      _moveObjective: {
        varArgs: { objectiveId: 'objectiveId', objectiveTypeId: 'objectiveTypeId' },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const deleteObjectiveMutation = mutationBuilder(
  {
    variables: {
      objectiveId: { type: 'String!' },
    },
    fields: {
      _deleteObjective: {
        varArgs: { objectiveId: 'objectiveId' },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const completeObjectiveMutation = mutationBuilder(
  {
    variables: {
      objectiveId: { type: 'String!' },
      completed: { type: 'Boolean' },
    },
    fields: {
      _toggleCompleteObjective: {
        varArgs: { objectiveId: 'objectiveId', completed: 'completed' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createObjectiveCommentMutation = mutationBuilder({
  variables: {
    comment: { type: 'String!' },
    objectiveId: { type: 'String!' },
  },
  fields: {
    _createObjectiveComment: {
      varArgs: { comment: 'comment', objectiveId: 'objectiveId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateObjectiveCommentMutation = mutationBuilder({
  variables: {
    comment: { type: 'String!' },
    id: { type: 'String!' },
  },
  fields: {
    _updateObjectiveComment: {
      varArgs: { comment: 'comment', id: 'id' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});
