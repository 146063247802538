import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import ModalFooter from '@innovatrix/common/ModalFooter';
import { FormModal } from '@innovatrix/modals';
import { StringField, SelectField } from '@innovatrix/common/fields';
import { SALESFORCE_ENABLED } from '@innovatrix/constants';
import { Paragraph } from '@innovatrix/common/text';

const salutations = [{
  label: 'Mr',
  value: 'Mr',
}, {
  label: 'Ms',
  value: 'Ms',
}, {
  label: 'Mrs',
  value: 'Mrs',
}];

const RowContainer = styled.div`
  ${({ theme }) => theme.rows(2)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Label = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey2};
  display: inline-block;
  font-size: 12px;
  position: relative;
  text-transform: uppercase;
`;

const EditProfileModal = (
  { handleSubmit, onClose, isOpen, user: { firstName, lastName }, submitForm, keyPress },
) => {
  const buttons = React.useMemo(() => [
    { flavor: 'tertiary', type: 'button', onClick: onClose, label: 'Close' },
    ...[{ flavor: 'primary', type: 'submit', onClick: submitForm, label: 'Save' }],
  ], [onClose, submitForm]);

  return (
    <FormModal
      isOpen={isOpen}
      subTitle="My Profile"
      title="Edit Profile"
      onSubmit={handleSubmit}
      footerContent={<ModalFooter buttons={buttons} />}
      onClose={onClose}
    >
      {/* tempfix because <NavBar> eats spaces */}
      <Wrapper onKeyDown={keyPress}>
        <RowContainer>
          <div>
            <Label>
              Salutation
            </Label>
            <SelectField
              fieldId="salutation"
              label="Salutation"
              options={salutations}
              placeholder="None"
              required
              width="100px"
            />
          </div>
        </RowContainer>
        <RowContainer>
          <StringField size="h3" fieldId="firstName" label="First Name" value={firstName} placeholder="First Name" />
          <StringField size="h3" fieldId="lastName" label="Last Name" value={lastName} placeholder="Last Name" />
        </RowContainer>
        <StringField disabled={SALESFORCE_ENABLED} fieldId="email" label="Email" />
        <StringField fieldId="phoneNumber" label="Phone" placeholder="No phone number provided." />
        <StringField fieldId="linkedIn" label="LinkedIn Profile" placeholder="No LinkedIn profile link provided." />
      </Wrapper>
    </FormModal>
  );
};

EditProfileModal.propTypes = {
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  keyPress: PropTypes.func,
  onClose: PropTypes.func,
  submitForm: PropTypes.func,
  user: PropTypes.object,
};

export default withFormik({
  mapPropsToValues: ({ user }) => ({
    email: user.email || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    linkedIn: user.linkedIn || '',
    phoneNumber: user.phoneNumber || '',
    salutation: user.salutation || '',
  }),
  handleSubmit: (values, { props: { updateProfile, onClose }, setSubmitting }) => {
    setSubmitting(true);
    updateProfile(values, () => {
      setSubmitting(false);
      onClose();
    });
  },
})(React.memo(EditProfileModal));
