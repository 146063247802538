import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@innovatrix/common/buttons';
import { Body } from '@innovatrix/common/text';
import graphql from '@innovatrix/common/graphql';
import { acceptedCookiesAction } from '@innovatrix/actions';
import * as toast from '@innovatrix/utils/toast';
import { WARNING } from '@innovatrix/styles';

import { acceptCookiesMutation } from '../_queries';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  bottom: 32px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 24px 32px;
  position: fixed;
  transform: translateX(-50%);
  z-index: 1;
`;

const CTAs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Text = styled(Body)`
  margin-bottom: 16px;
`;

const CookieStatement = ({ acceptCookies, persist }) => {
  const { t } = useTranslation('main');
  const [loading, setLoading] = React.useState(false);

  const acceptCookiesCb = React.useCallback(() => {
    setLoading(true);
    acceptCookies({
      accepted: true,
      callback: (err) => {
        if (err) {
          console.error(err);
          setLoading(false);
          toast.error(t('ui.cookies.error'));
        }
        else {
          setLoading(false);
          persist();
        }
      },
    });
  }, [acceptCookies, persist, t]);

  const onRejectCookiesCb = React.useCallback(() => {
    setLoading(true);
    acceptCookies({
      accepted: false,
      callback: (err) => {
        if (err) {
          console.error(err);
          setLoading(false);
          toast.error(t('ui.cookies.error'));
        }
        else {
          setLoading(false);
          persist();
        }
      },
    });
  }, [acceptCookies, persist, t]);

  return (
    <Wrapper>
      <Text>{t('ui.cookies.statement')}</Text>
      <CTAs>
        <Button
          color={WARNING}
          disabled={loading}
          flavor="outline"
          label={t('ui.reject')}
          onClick={onRejectCookiesCb}
        />
        <Button
          disabled={loading}
          label={t('ui.accept')}
          onClick={acceptCookiesCb}
        />
      </CTAs>
    </Wrapper>
  );
};

CookieStatement.propTypes = {
  acceptCookies: PropTypes.func.isRequired,
  persist: PropTypes.func.isRequired,
};

export default connect(() => ({}), {
  persist: acceptedCookiesAction,
})(graphql({
  mutation: acceptCookiesMutation,
  name: 'acceptCookies',
  namespace: '_acceptCookies',
})(React.memo(CookieStatement)));
