import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const DURATION = '200ms';
const EASING = 'ease-out';

const SMALL = 'small';
const LARGE = 'large';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.danger};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.danger};
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all ${DURATION} ${EASING};

  ${({ flavor }) => (flavor === LARGE ? `
    height: 44px;
    transition: all 0.3s ease-out;
    width: 44px;

    > ._disabled {
      &::after {
        top: 17px; right: 6px; width: 30px; height: 6px;
      }
    }

    > ._icon {
      &::after {
        top: 0; right: 0; width: 20px; height: 3px;
      }
      &::before {
        top: 0; width: 20px; height: 3px;
      }
    }

    > ._selected {
      &::after {
        right: -5px;
      }
      &::before {
        width: 11px; top: 4px;
      }
    }
  ` : '')}

  ${({ flavor }) => (flavor === SMALL ? `
    height: 20px;
    width: 20px;

    > ._disabled {
      &::after {
        height: 3px;
        right: 2px;
        top: 7px;
        width: 14px;
      }
    }

    > ._icon {
      &::after {
        height: 2px;
        right: 4px;
        top: 0;
        width: 13px;
      }
      &::before {
        width: 13px; height: 2px; top: 0; left: 3px;
      }
    }

    > ._selected {
      &::after {
        width: 11px; right: 3px;
      }
      &::before {
        width: 7px; top: 2px; left: 2px;
      }
    }
  ` : '')}

  &.disabled {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.danger};
    cursor: default;
  }

  &.empty {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
  }

  &.selected {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &.crossed {
    border: 1px solid ${({ theme }) => theme.colors.danger};
  }
`;

const alignMiddle = `
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
`;

const Inner = styled.span`
  ${alignMiddle}
  border-radius: 50%;
  display: block;
  height: 100%;
  transform: scale(0.6);
  transition: all ${DURATION} ${EASING};
  width: 100%;

  &.selected {
    background: ${({ theme }) => theme.colors.primary};
    transform: scale(1);
  }
`;

const Icon = styled.span`
  ${alignMiddle}
  display: block;
  height: 3px;
  width: 20px;

  &::after {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    content: "";
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    transition: all ${DURATION} ${EASING};
  }

  &::before {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    content: "";
    display: block;
    position: absolute;
    transform: rotate(45deg);
    transition: all ${DURATION} ${EASING};
  }
`;

const DisabledIcon = styled.span`
  ${alignMiddle}
  display: block;

  &::after {
    background-color: ${({ theme }) => theme.colors.danger};
    border-radius: 3px;
    content: "";
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    transition: all ${DURATION} ${EASING};
  }
`;

const Dot = styled.div`
  ${alignMiddle}
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 25%;
  border: 1px solid transparent;
  display: block;
  height: 12px;
  transition: all ${DURATION} ${EASING};
  width: 12px;
`;

const RadioCircle = ({ className, type, disabled, flavor = LARGE, selected, onClick: onClickCb }) => {
  const isEmpty = typeof selected !== 'boolean';
  const onClick = React.useMemo(() => (disabled ? undefined : onClickCb), [disabled, onClickCb]);
  return (
    <Container
      className={`${className} ${selected ? 'selected' : ''} ${isEmpty && disabled ? 'disabled' : ''} ${isEmpty && !disabled ? 'empty' : ''} ${flavor}`}
      flavor={flavor}
      onClick={onClick}
    >
      {disabled && type === 'icon' && isEmpty && <DisabledIcon className="_disabled" />}
      {!isEmpty && <Inner className={`_inner ${selected ? 'selected' : ''}`} />}
      {!isEmpty && type === 'icon' && <Icon className={selected ? '_icon _selected ' : '_icon'} />}
      {!isEmpty && type === 'dot' && <Dot className={selected ? '_dot _selected' : '_dot'} />}
    </Container>
  );
};

RadioCircle.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  flavor: PropTypes.oneOf([SMALL, LARGE]),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  type: PropTypes.oneOf(['icon', 'dot']),
};

RadioCircle.defaultProps = {
  className: '',
  type: 'icon',
};

export default React.memo(RadioCircle);
