import * as React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Button, IconButton } from './buttons';
import { ALLOWED_FLAVORS } from './buttons/_constants';

const FooterContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.greyLight3};
  display: flex;
  justify-content: ${({ buttonsLeft }) => (buttonsLeft ? 'space-between' : 'flex-end')} ;
  width: 100%;
  padding: 16px 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const RightIconButton = styled(IconButton)`
  margin-left: 12px;
`;

const RightButton = styled(Button)`
  margin-left: 12px;
`;

const LeftIconButton = styled(IconButton)`
  margin-right: 12px;
`;

const LeftButton = styled(Button)`
  margin-right: 12px;
`;

const ModalFooter = ({ buttons, className }) => {
  const array = React.useMemo(() => (Array.isArray(buttons) ? buttons : []), [buttons]);
  const buttonsLeft = React.useMemo(() => Boolean(array.find(x => x.left)), [array]);
  return (
    <FooterContainer className={className} buttonsLeft={buttonsLeft}>
      {/* validate if buttonsLeft is true, if so, assign buttons to left and right */}
      {buttonsLeft ?
        <React.Fragment>
          <ButtonWrapper>
            {array.filter(button => button.left).map(btn => (
              btn.iconButton
                ? <LeftIconButton {...btn} key={btn.label || btn.tooltip} />
                : <LeftButton {...btn} key={btn.label || btn.tooltip} />
            ))}
          </ButtonWrapper>
          <ButtonWrapper>
            {array.filter(button => !button.left).map(btn => (
              btn.iconButton
                ? <RightIconButton {...btn} key={btn.label || btn.tooltip} />
                : <RightButton {...btn} key={btn.label || btn.tooltip} />
            ))}
          </ButtonWrapper>
        </React.Fragment>
        : array.map(btn => (
          btn.iconButton
            ? <RightIconButton {...btn} key={btn.label || btn.tooltip} />
            : <RightButton {...btn} key={btn.label || btn.tooltip} />))}
    </FooterContainer>
  );
};

ModalFooter.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    flavor: PropTypes.oneOf(ALLOWED_FLAVORS),
    icon: PropTypes.node,
    iconButton: PropTypes.bool,
    label: PropTypes.string,
    left: PropTypes.bool,
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
    type: PropTypes.string,
  })),
  className: PropTypes.string,
};

ModalFooter.defaultProps = {
  buttons: [],
  className: '',
};

export default React.memo(ModalFooter);
