import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@innovatrix/common/buttons';
import { Body, Title } from '@innovatrix/common/text';
import graphql from '@innovatrix/common/graphql';
import { acceptedLegalDocumentAction, downloadDocumentAction } from '@innovatrix/actions';
import * as toast from '@innovatrix/utils/toast';
import { PlainModal } from '@innovatrix/modals';
import { DOCUMENT_VARIANTS } from '@innovatrix/constants';

import { acceptLegalDocumentMutation } from '../_queries';

const AcceptLegalDocumentCTA = styled(Button)`
  margin-top: 8px;
`;

const Text = styled(Body)`
  margin-top: 32px;
  margin-bottom: 24px;
`;

const ModalTitle = styled(Title)`
  white-space: initial;
  position: relative;
  padding-bottom: 32px;
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 500;
  &:after {
    display: block;
    width: 24px;
    height: 4px;
    background-color: black;
    position: absolute;
    bottom: 0;
    content: '';
    overflow: visible;
  }
`;

const StyledLink = styled.a`
  align-self: flex-end;
  font-style: italic;
`;

const Modal = styled(PlainModal)`
  background-color: ${({ theme }) => theme.colors.greyBlueish};
  position: relative;

  > form {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div.body {
      height: 100%;
      max-height: initial;
      padding: 0;
      padding: 32px;
    }
  }
`;

const TermsStatement = ({ acceptLegalDocument, document, downloadDocument, persist, shouldShow, title, documentTitle }) => {
  const { t } = useTranslation('main');
  const [loading, setLoading] = React.useState(false);

  const acceptLegalDocumentCb = React.useCallback(() => {
    setLoading(true);
    acceptLegalDocument({
      documentId: document.id,
      callback: (err) => {
        if (err) {
          console.error(err);
          setLoading(false);
          toast.error(t('ui.terms.error'));
        }
        else {
          setLoading(false);
          persist({ documentId: document.id });
          shouldShow(false);
        }
      },
    });
  }, [acceptLegalDocument, document.id, persist, shouldShow, t]);

  const onDownload = React.useCallback(() => {
    downloadDocument({
      documentId: document.id,
      variant: DOCUMENT_VARIANTS.LEGAL_DOCUMENT,
      ...document,
    });
  }, [document, downloadDocument]);

  return (
    <Modal
      width="320px"
      minWidth="320px"
    >
      <ModalTitle>{t('ui.legaldocuments.update')}{t(title)}</ModalTitle>
      <Text>
        {t('ui.legaldocuments.statement.text1')}
        <StyledLink onClick={() => onDownload()}>{t(documentTitle)}</StyledLink>
        {t('ui.legaldocuments.statement.text2')}
      </Text>
      <Text>
        {t('ui.legaldocuments.accepts.text')}
      </Text>
      <AcceptLegalDocumentCTA
        disabled={loading}
        loading={loading}
        label={t('ui.accept')}
        onClick={acceptLegalDocumentCb}
      />
    </Modal>
  );
};

TermsStatement.propTypes = {
  acceptLegalDocument: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  documentTitle: PropTypes.string.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  persist: PropTypes.func.isRequired,
  shouldShow: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default connect(() => ({}), {
  persist: acceptedLegalDocumentAction,
  downloadDocument: downloadDocumentAction,
})(graphql({
  mutation: acceptLegalDocumentMutation,
  name: 'acceptLegalDocument',
  namespace: '_acceptLegalDocument',
})(React.memo(TermsStatement)));
