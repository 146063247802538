import { DxError } from '@innovatrix/utils/dx';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { addModalAction, clearModalsAction, removeModalAction } from '../../actions';
import { getDxModals } from '../../selectors';
import { AlertSection } from '../AlertSection';

import { Modal } from './Modal';
import { modalPropType } from './modalPropTypes';

// -- Styles --------------- --- --  -

const managerStyles = css({ boxSizing: 'content-box' });

const bodyModalOpenStyles = css({
  overflow: 'hidden',
});

// -- Component --------------- --- --  -

/**
 * Derived from https://github.com/ErrorPro/react-redux-modal
 *
 * Note: this component has a side-effect: when showing a modal dialog, it needs to set overflow:hidden on the body element.
 * This is handled through lifecycle methods
 */
class ModalManagerComponent extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  componentDidMount() {
    this.toggleModalClass();
  }

  componentDidUpdate(prevProps) {
    const { modals } = this.props;
    if (prevProps.modals !== modals) {
      this.toggleModalClass();
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: new DxError(error, info) });
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyModalOpenStyles);
  }

  toggleModalClass = () => {
    const { modals } = this.props;
    const hasModals = Object.keys(modals).length > 0;
    if (hasModals) {
      document.body.classList.add(bodyModalOpenStyles);
    }
    else {
      document.body.classList.remove(bodyModalOpenStyles);
    }
  }

  render() {
    if (this.state.hasError) { return <AlertSection error={this.state.error} />; }
    const { modals, removeModal } = this.props;
    return (
      <div className="dx-modal-manager" {...managerStyles}>
        {modals.map((modal, i) => (
          <Modal
            index={i}
            key={modal.id}
            removeModal={removeModal}
            {...modal}
          />
        ))}
      </div>
    );
  }

}

ModalManagerComponent.propTypes = {
  addModal: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  modals: PropTypes.arrayOf(modalPropType).isRequired,
  removeModal: PropTypes.func.isRequired,
};

// -- Container --------------- --- --  -

export const ModalManager = connect(
  (state) => ({
    modals: getDxModals(state),
  }),
  {
    addModal: addModalAction,
    clearModals: clearModalsAction,
    removeModal: removeModalAction,
  },
  undefined, // use default mergeProps implementation
  {
    areStatesEqual: (next, prev) => prev.dxModals === next.dxModals,
  },
)(ModalManagerComponent);
