export const ALL = 'All';
export const FILTER_ALL = 'Filter on All';
export const FILTER_CARDS = 'innovatrix/FILTER_CARDS';
export const FILTER_DECISIONS = 'innovatrix/FILTER_DECISIONS';
export const ORDER_ALPHABETICALLY = 'Order Alphabetically';
export const ORDER_CARDS = 'innovatrix/ORDER_CARDS';
export const RECEIVE_PROJECTS = 'innovatrix/RECEIVE_PROJECTS';
export const RECEIVE_UPDATED_PROJECT = 'innovatrix/RECEIVE_UPDATED_PROJECT';
export const SHOW_DECISION_DETAILS = 'innovatrix/SHOW_DECISION_DETAILS';
export const DELETE_EVALUATION_SUCCESS = 'innovatrix/DELETE_EVALUATION_SUCCESS';
export const HIDE_PREDICTIONS = 'innovatrix/HIDE_PREDICTIONS';
export const TOGGLE_PREDICTIONS = 'innovatrix/TOGGLE_PREDICTIONS';
export const FILTER_CARDS_BY_MANAGER = 'innovatrix/FILTER_CARDS_BY_MANAGER';
export const FILTER_PROJECT_TYPE = 'innovatrix/FILTER_PROJECT_TYPE';
export const FETCH_PROJECTS = 'innovatrix/FETCH_PROJECTS';
export const ERROR_PROJECTS = 'innovatrix/ERROR_PROJECTS';
export const UPDATE_DECISION = 'innovatrix/UPDATE_DECISION';
export const RECEIVE_UPDATE_DECISION = 'innovatrix/RECEIVE_UPDATE_DECISION';
export const FETCH_REVIEWERS = 'innovatrix/FETCH_REVIEWERS';
export const RECEIVE_REVIEWERS = 'innovatrix/RECEIVE_REVIEWERS';
export const ERROR_REVIEWERS = 'innovatrix/ERROR_REVIEWERS';
