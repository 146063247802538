import queryString from 'query-string';
import moment from 'moment';
import { createSelector } from 'reselect';

import { LOADING } from '../../constants';

import { DEFAULT_ENTITIES } from './utils';

const getWorkshopTypeState = state => (state.workshops || state.data.workshops);
const PROJECT_PHASE_STATUS = {
  WITHDRAWN: 'Withdrawn',
};

const getWorkshopTypesRelations = createSelector(
  getWorkshopTypeState,
  (workshopType) => workshopType.relations,
);

export const getInnovatrixHasWorkshopTypes = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.innovatrixHasWorkshopTypes,
);

export const getWorkshopHasMoments = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.workshopHasMoments,
);

export const getWorkshopHasProjects = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.workshopHasProjects,
);

export const getWorkshopMomentHasDates = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.workshopMomentHasDates,
);

export const getWorkshopTypeHasWorkshops = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.workshopTypeHasWorkshops,
);

export const getWorkshopMomentHasTimeslotDates = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.workshopMomentHasTimeslotDates,
);

export const getTimeslotDateHasTimeslotTimeRanges = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.timeslotDateHasTimeslotTimeRanges,
);

export const getTimeslotTimeRangeHasProjects = createSelector(
  getWorkshopTypesRelations,
  (relations) => relations.timeslotTimeRangeHasProjects,
);

export const getWorkshopTypes = createSelector(
  getInnovatrixHasWorkshopTypes,
  (relations) => {
    if (!relations['']) {
      return DEFAULT_ENTITIES;
    }
    const data = relations[''].data.sort((x1, x2) => x1.order - x2.order);
    return {
      _status: relations['']._status,
      data,
    };
  },
);

export const getInvitedProjectsForWorkshopDate = (state, { workshopMomentId }) => {
  const projectState = getWorkshopHasProjects(state)[queryString.stringify({ workshopMomentId })] || DEFAULT_ENTITIES;
  if (!projectState) { return []; }
  const invitedProjects = projectState.data.filter(({ invited }) => invited);
  return invitedProjects.map((project) => ({
    ...project,
    id: project.projectId,
  }));
};

export const getProjectsForWorkshop = (state, { tab, workshopMomentId }) => {
  const projectState = getWorkshopHasProjects(state)[queryString.stringify({ workshopMomentId })] || DEFAULT_ENTITIES;
  if (!projectState) { return DEFAULT_ENTITIES; }
  const { data, status } = projectState;

  const { attendedTotalCount, invitedTotalCount, projects } = data.reduce((acc, project) => {
    if (project.projectStatus === PROJECT_PHASE_STATUS.WITHDRAWN) {
      return acc;
    }

    return {
      ...acc,
      attendedTotalCount: (acc.attendedTotalCount || 0) + (project.attended ? 1 : 0),
      invitedTotalCount: (acc.invitedTotalCount || 0) + (project.invited ? 1 : 0),
      projects: [
        ...(acc.projects || []),
        project,
      ],
    };
  }, {});

  if (tab === 'Attended') {
    const onlyInvitedProjects = projects.filter(p => p.invited);
    return { attendedTotalCount, invitedTotalCount, projects: onlyInvitedProjects, status };
  }

  return { attendedTotalCount, invitedTotalCount, projects, status };
};

export const getWorkshopType = (state, typeId) => getWorkshopTypes(state).data.find(({ id }) => id === typeId);

export const getWorkshopMoments = (state, { workshopId }) => getWorkshopHasMoments(state)[queryString.stringify({ workshopId })] || DEFAULT_ENTITIES;

export const getWorkshops = (state, { workshopTypeId }) => getWorkshopTypeHasWorkshops(state)[queryString.stringify({ workshopTypeId })] || DEFAULT_ENTITIES;

export const getWorkshopDates = (state, { workshopMomentId }) => getWorkshopMomentHasDates(state)[queryString.stringify({ workshopMomentId })] || DEFAULT_ENTITIES;

export const getTimeslotDates = (state, { workshopMomentId }) => getWorkshopMomentHasTimeslotDates(state)[queryString.stringify({ workshopMomentId })] || DEFAULT_ENTITIES;

export const getTimeslotTimeRanges = (state, { timeslotDateId }) => getTimeslotDateHasTimeslotTimeRanges(state)[queryString.stringify({ timeslotDateId })] || DEFAULT_ENTITIES;

export const getTimeRangeProjects = (state, { timeslotTimeRangeId }) => getTimeslotTimeRangeHasProjects(state)[queryString.stringify({ timeslotTimeRangeId })] || DEFAULT_ENTITIES;

export const getAssignedProjectsForDate = (state, { timeslotDateId, workshopMomentId }) => {
  let amountOfSlots = 0;
  let amountOfProjects = 0;
  const { data: dates, status } = getTimeslotDates(state, { workshopMomentId });
  if (status === LOADING) {
    return {
      amountOfSlots: 0,
      amountOfProjects: 0,
    };
  }
  const { interval } = dates.find(({ id }) => id === timeslotDateId);
  const { data: ranges } = getTimeslotTimeRanges(state, { timeslotDateId });
  ranges.forEach(({ endDatetime, startDatetime, id: timeslotRangeId }) => {
    const diff = moment(endDatetime).diff(moment(startDatetime), 'minutes');
    const amount = Math.floor(diff / interval);
    amountOfSlots += amount;
    const { data: rangeProjects } = getTimeRangeProjects(state, { timeslotTimeRangeId: timeslotRangeId });
    rangeProjects.forEach(() => {
      amountOfProjects += 1;
    });
  });
  return { amountOfProjects, amountOfSlots };
};

export const getAssignedProjects = (state, { workshopMomentId }) => {
  const dates = getTimeslotDates(state, { workshopMomentId });
  if (dates.status === LOADING) {
    return {
      isLoading: true,
      amountOfSlots: 0,
      projects: [],
    };
  }
  const { data } = dates;
  let amountOfSlots = 0;
  const assignedProjects = [];
  data.forEach(({ id: timeslotDateId, interval }) => {
    const { data: ranges } = getTimeslotTimeRanges(state, { timeslotDateId });
    ranges.forEach(({ endDatetime, startDatetime, id: timeslotRangeId }) => {
      const diff = moment(endDatetime).diff(moment(startDatetime), 'minutes');
      const amount = Math.floor(diff / interval);
      amountOfSlots += amount;
      const { data: rangeProjects } = getTimeRangeProjects(state, { timeslotTimeRangeId: timeslotRangeId });
      rangeProjects.forEach(({ projectId }) => {
        assignedProjects.push(projectId);
      });
    });
  });
  return { isLoading: false, amountOfSlots, projects: assignedProjects };
};

