import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';

import { fetchProjectWorkshopsAction, addExternalToWorkshopAction, errorTimeslotTimeRangesAction, receiveTimeslotTimeRangesAction, fetchTimeslotTimeRangesAction, animateWorkshopAction, completeAnimateWorkshopAction } from '../actions';
import { TOGGLE_ATTENDED_WORKSHOP, DOWNLOAD_ICAL_EVENT, MARK_ATTENDING_WORKSHOP, MARK_ATTENDED_WORKSHOP, FETCH_TIMESLOT_TIME_RANGES, DELETE_TIMESLOT_TIME_RANGE, REMOVE_EXTERNAL_FROM_WORKSHOP, DOWNLOAD_WORKSHOPS_EXPORT } from '../constants';
import { toggleAttendedWorkshopMutation, markMemberAttendingMutation, markMemberAttendedMutation, addExternalAttenderMutation, getTimeslotTimeRangesQuery, deleteTimeslotTimeRangeMutation, removeExternalAttenderMutation } from '../queries';
import * as toast from '../utils/toast';
import { getDxAuthToken } from '../selectors/authSelectors';
import { config } from '../config';
import { fetchProjectsForWorkshopAction, getProjectsTimeslotsAction } from '../modules/innovatrix/workshops/_actions';
import { deleteProjectTimeslotMutation } from '../modules/innovatrix/workshops/queries';
import { REMOVE_PROJECT_FROM_TIMESLOT } from '../modules/innovatrix/workshops/_actionTypes';

import * as api from './_api';
import { fetchEntitiesSaga, formMutationSaga, mutationSaga } from './_utils';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const BASE_URL = config.get('innovatrix.hosts.dxApi');

export default function* () {
  yield takeEvery(FETCH_TIMESLOT_TIME_RANGES, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorTimeslotTimeRangesAction,
    errorMessage: 'Failed to fetch timeslot time ranges.',
    keyword: '_getTimeslotTimeRanges',
    query: getTimeslotTimeRangesQuery,
    receiveAction: receiveTimeslotTimeRangesAction,
  }));

  yield takeEvery(DELETE_TIMESLOT_TIME_RANGE, mutationSaga.bind(undefined, {
    * after({ timeslotDateId, callback }) {
      yield put(fetchTimeslotTimeRangesAction({ timeslotDateId }));
      if (callback && typeof callback === 'function') { callback(); }
    },
    errorMessage: 'Failed to delete the timeslot date range.',
    keyword: '_deleteTimeslotTimeRange',
    mutation: deleteTimeslotTimeRangeMutation,
  }));

  yield takeEvery(MARK_ATTENDING_WORKSHOP, mutationSaga.bind(undefined, {
    * after({ callback }) {
      if (callback && typeof callback === 'function') {
        callback();
      }
      yield;
    },
    errorMessage: 'Failed to update attending.',
    keyword: '_markMemberAttending',
    mutation: markMemberAttendingMutation,
    preparePayload: ({ projectId, workshopMomentId, memberId, attending, isExternal }) => ({
      projectId, data: { workshopMomentId, memberId, attending, isExternal },
    }),
  }));

  yield takeEvery(MARK_ATTENDED_WORKSHOP, mutationSaga.bind(undefined, {
    * after({ isInnovatrixScreen, workshopMomentId, campaignId, projectId, status }) {
      if (isInnovatrixScreen) {
        yield put(fetchProjectsForWorkshopAction({ campaignId, workshopMomentId }));
      }
      else {
        yield put(fetchProjectWorkshopsAction({ projectId, status }));
      }
    },
    errorMessage: 'Failed to update attended.',
    keyword: '_markMemberAttended',
    mutation: markMemberAttendedMutation,
    preparePayload: ({ projectId, workshopMomentId, memberId, attended, name }) => ({
      projectId, data: { workshopMomentId, memberId, attended, name },
    }),
  }));

  yield takeEvery(addExternalToWorkshopAction.REQUEST, formMutationSaga.bind(undefined, {
    * after({ callback }) {
      yield;
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
    errorMessage: 'Failed to invite the external',
    keyword: '_addExternalAttender',
    mutation: addExternalAttenderMutation,
    preparePayload: ({ projectId, ...rest }) => ({ projectId, data: rest }),
  }));

  yield takeEvery(REMOVE_EXTERNAL_FROM_WORKSHOP, mutationSaga.bind(undefined, {
    * after({ callback }) {
      toast.success('Successfully removed the external member.');
      if (callback && typeof callback === 'function') {
        callback();
      }
      yield;
    },
    errorMessage: 'Failed to remove the external member.',
    keyword: '_removeExternalAttender',
    mutation: removeExternalAttenderMutation,
    preparePayload: ({ projectId, workshopMomentId, memberId }) => ({
      projectId, workshopMomentId, memberId,
    }),
  }));

  yield takeEvery(TOGGLE_ATTENDED_WORKSHOP, mutationSaga.bind(undefined, {
    * after({ shouldAnimate, campaignId, workshopMomentId, projectId, status }) {
      if (campaignId) {
        // Innovatrix-interface
        yield put(fetchProjectsForWorkshopAction({ campaignId, workshopMomentId }));
      }
      else {
        // Project-interface
        if (shouldAnimate) {
          yield toast.success('Workshop will be moved to "passed"!');
          yield put(animateWorkshopAction({ workshopMomentId }));
          yield delay(300);
          yield put(completeAnimateWorkshopAction({ workshopMomentId }));
        }
        yield put(fetchProjectWorkshopsAction({ projectId, status }));
      }
    },
    errorMessage: 'Failed to attend workshop.',
    keyword: '_toggleAttendedWorkshop',
    mutation: toggleAttendedWorkshopMutation,
  }));

  yield takeEvery(DOWNLOAD_ICAL_EVENT, function* downloadCalendarEvent({ projectId, workshopMomentId, title }) {
    try {
      const baseUrl = BASE_URL.replace('/graphql', '');
      const authToken = yield select(getDxAuthToken);
      const formData = new FormData();
      formData.append('name', `${title}.ics`);
      yield api.downloadFile(authToken, `${baseUrl}/workshops/calendar/${workshopMomentId}/${projectId}`, formData, 'GET');
    }
    catch (e) {
      yield call(showErrorModal, e, { title: 'Failed to make the iCal.' });
    }
  });

  yield takeEvery(REMOVE_PROJECT_FROM_TIMESLOT, function* removeProjectFromTimeslot({
    projectTimeslotId,
    timeslotTimeRangeId,
    callback,
  }) {
    try {
      const variables = {
        id: projectTimeslotId,
      };
      yield call(api.fetch, '_deleteProjectTimeslot', deleteProjectTimeslotMutation, { variables });
      if (callback) {
        callback();
      }
      else {
        yield put(getProjectsTimeslotsAction({ timeslotTimeRangeId }));
      }
    }
    catch (err) {
      yield call(showErrorModal, err, 'Failed to remove project from timeslot.');
      callback(err);
    }
  });

  yield takeEvery(DOWNLOAD_WORKSHOPS_EXPORT, function* downloadWorkshopsExport() {
    let toastId;
    try {
      const baseUrl = BASE_URL.replace('/graphql', '');
      const authToken = yield select(getDxAuthToken);
      const fileName = 'workshops-export.csv';
      toastId = toast.loading('Downloading...');
      const { data } = yield axios({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'GET',
        responseType: 'json',
        url: `${baseUrl}/future-workshops/export`,
      });
      yield api.downloadCsvFile(data, fileName);
      toast.dismiss(toastId);
      toast.success('Download successful!');
    }
    catch (e) {
      toast.dismiss(toastId);
      toast.error('Failed to download the workshop export.');
      console.error(e);
    }
  });
}
