// Primary
export const PRIMARY = '#3F98BD';
export const PRIMARY_LIGHT = '#6FB1CD';
export const SECONDARY = '#3C3C3B';
export const MAIN_BORDER = '#E6E6EA';
export const WHITE = '#FFFFFF';
export const MAIN_PAGE = '#EBEBEA';
export const MAIN_BG = '#D7D7D4';
export const PRIMARY_DARK = '#3E8EAF';

// Secondary
export const GREY_BLUEISH = '#F7F8FD';
export const GREY_LIGHT_1 = '#F3F3F3';
export const GREY_LIGHT_2 = '#E6E6EA';
export const GREY_LIGHT_3 = '#CDCEDE';
export const GREY_1 = '#AEAFC2';
export const GREY_2 = '#8889A0';
export const DANGER = '#FF3F56';
export const PURPLE = '#90298D';
export const PURPLE_LIGHT = '#C894C6';
export const WARNING = DANGER;
export const WARNING_DARK = '#F82E46';
export const WARNING_LIGHT = '#FF6275';

// Focus color
export const FOCUS = '#35BBEE';
export const FOCUS_TRANSPARENT = 'rgba(53,187,238,0.35)';

// Decisions
export const DECISION_CONDITIONS = '#F1CB23';
export const DECISION_GO = '#3DC459';
export const DECISION_MAYBE = '#FB8228';
export const DECISION_NO_GO = '#D0041B';

export const MARKER_GREEN = DECISION_GO;
export const MARKER_RED = DECISION_NO_GO;

export const DECISION_GO_LIGHT = '#7EDF92';
export const DECISION_NO_GO_LIGHT = '#F994A0';

// Toggle
export const TOGGLE_ACTIVE = DECISION_GO;
export const TOGGLE_ACTIVE_BORDER = '#4C8B18';

export const GREEN = TOGGLE_ACTIVE_BORDER;

// Innoboard
export const ASSUMPTION_YELLOW = '#FFC014';

// Comments
export const COMMENTS_CONDITIONS = '#FFF6DE';
export const COMMENTS_GO = '#F4FFF2';
export const COMMENTS_MAYBE = '#FFF8F2';
export const COMMENTS_NO_GO = '#FFF8F9';

// VIZ
export const BAR_BG = '#E1E1DF';
export const GRADIENT_INACTIVE = 'linear-gradient(-90deg, #AEAFC2 0%, #CDCEDE 100%)';
export const GRADIENT_POSITIVE = 'linear-gradient(-90deg, #3F98BD 0%, #90D5F2 100%)';
export const GRADIENT_POSITIVE_REVERSE = 'linear-gradient(-90deg, #90D5F2 0%, #3F98BD 100%)';
export const GRADIENT_NEGATIVE = 'linear-gradient(90deg, #E5828B 4%, #B03D4E 100%)';
export const GRADIENT_PROGRESS = 'linear-gradient(-90deg, #84FAB0 0%, #90D5F2 100%)';
export const GRADIENT_REGRESS = 'linear-gradient(90deg, #E5828B 0%, #3F98BD 100%)';
export const SPREAD_LINE_POSITIVE = '#36337D';
export const SPREAD_POSITIVE = 'rgba(54,51,125,0.5)';
export const SPREAD_LINE_NEGATIVE = '#7E0110';
export const SPREAD_NEGATIVE = 'rgba(208,2,27,0.5)';
export const THRESHOLD = '#B13D4F';

export const TURQUOISE = '#52BDC2';
export const TURQUOISE_2 = '#36959a';
export const TURQUOISE_LIGHT = '#78D8DD';
export const TURQUOISE_DARK = '#4AACB0';
export const PROGRESS = TURQUOISE;

// Reviews
export const REVIEW_CONDITIONS = '#F5C023';
export const REVIEW_CONDITIONS_BG = '#FFF6DD';
export const REVIEW_GO = '#5CB000';
export const REVIEW_GO_BG = '#F4FFF2';
export const REVIEW_MAYBE = '#FB6627';
export const REVIEW_MAYBE_BG = '#FFF8F2';
export const REVIEW_NOGO = '#D0021B';
export const REVIEW_NOGO_BG = '#FFF8F9';

// Rating bar
export const RATINGBAR_GREEN = '#21D0A9';

// Categories
// Background Material Design 100
export const RED_100 = '#FFCDD2';
export const PINK_100 = '#F8BBD0';
export const PURPLE_100 = '#E1BEE7';
export const DEEP_PURPLE_100 = '#D1C4E9';
export const INDIGO_100 = '#C5CAE9';
export const BLUE_100 = '#BBDEFB';
export const LIGHT_BLUE_100 = '#B3E5FC';
export const CYAN_100 = '#B2EBF2';
export const TEAL_100 = '#B2DFDB';
export const GREEN_100 = '#C8E6C9';
export const LIGHT_GREEN_100 = '#DCEDC8';
export const LIME_100 = '#F0F4C3';
export const YELLOW_100 = '#FFF9C4';
export const AMBER_100 = '#FFECB3';
export const ORANGE_100 = '#FFE0B2';
export const DEEP_ORANGE_100 = '#FFCCBC';

export const CATEGORIES_BG = [
  RED_100,
  PINK_100,
  PURPLE_100,
  DEEP_PURPLE_100,
  INDIGO_100,
  BLUE_100,
  LIGHT_BLUE_100,
  CYAN_100,
  TEAL_100,
  GREEN_100,
  LIGHT_GREEN_100,
  LIME_100,
  YELLOW_100,
  AMBER_100,
  ORANGE_100,
  DEEP_ORANGE_100,
];

// Background Material Design 200
export const RED_200 = '#EF9A9A';
export const PINK_200 = '#F48FB1';
export const PURPLE_200 = '#CE93D8';
export const DEEP_PURPLE_200 = '#B39DDB';
export const INDIGO_200 = '#9FA8DA';
export const BLUE_200 = '#90CAF9';
export const LIGHT_BLUE_200 = '#81D4FA';
export const CYAN_200 = '#80DEEA';
export const TEAL_200 = '#80CBC4';
export const GREEN_200 = '#A5D6A7';
export const LIGHT_GREEN_200 = '#C5E1A5';
export const LIME_200 = '#E6EE9C';
export const YELLOW_200 = '#FFF59D';
export const AMBER_200 = '#FFE082';
export const ORANGE_200 = '#FFCC80';
export const DEEP_ORANGE_200 = '#FFAB91';

export const CATEGORIES_OUTLINE = [
  RED_200,
  PINK_200,
  PURPLE_200,
  DEEP_PURPLE_200,
  INDIGO_200,
  BLUE_200,
  LIGHT_BLUE_200,
  CYAN_200,
  TEAL_200,
  GREEN_200,
  LIGHT_GREEN_200,
  LIME_200,
  YELLOW_200,
  AMBER_200,
  ORANGE_200,
  DEEP_ORANGE_200,
];
