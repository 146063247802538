const isArray = Array.isArray;
const isNumber = (n) => typeof n === 'number';

// -- Aggregators --------------- --- --  -
export function sum(vals) {
  return vals.reduce((a, b) => (b < 0 ? a + 0 : a + b), 0);
}

export function average(vals) {
  if (isArray(vals) && vals.length > 0 && vals.every(isNumber)) {
    const filteredValues = vals.filter(val => val >= 0);
    if (filteredValues.length < 1) {
      return -1;
    }
    return sum(filteredValues) / filteredValues.length;
  }
  return -1;
}

export function median(vals) {
  if (isArray(vals) && vals.length > 0 && vals.every(isNumber)) {
    const filteredValues = vals.filter(val => val >= 0);
    if (filteredValues.length < 1) {
      return -1;
    }
    const sortedValues = filteredValues.sort();
    if (filteredValues.length % 2 !== 0) {
      return sortedValues[Math.floor(filteredValues.length / 2)];
    }
    const firstNumber = sortedValues[Math.floor(filteredValues.length / 2)];
    const secondNumber = sortedValues[Math.ceil(filteredValues.length / 2) - 1];
    return ((firstNumber + secondNumber) / 2);
  }
  return -1;
}
