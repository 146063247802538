import React from 'react';
import PropTypes from 'prop-types';

import { defaultProps } from './defaultPropTypes';

const MultipleChoiceIcon = ({ color, ...props }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 15 15" {...props}>
    <g id="multiple_choice">
      <circle id="Oval-3" stroke={color} fill="#FFF" className="st0" cx="2" cy="2" r="1.5" />
      <path id="Line-5" fill="none" stroke={color} strokeLinecap="square" className="st1" d="M6.5 2h8" />
      <path id="Line-5_1_" fill="none" stroke={color} strokeLinecap="square" className="st1" d="M6.5 7.5h8" />
      <circle id="Oval-3_1_" cx="2" cy="7.5" r="1.5" fill={color} stroke={color} />
      <path id="Line-5_2_" fill="none" stroke={color} strokeLinecap="square" className="st1" d="M6.5 13h8" />
      <circle id="Oval-3_2_" stroke={color} fill="#FFF" className="st0" cx="2" cy="13" r="1.5" />
    </g>
  </svg>
);

MultipleChoiceIcon.propTypes = {
  color: PropTypes.string,
};

MultipleChoiceIcon.defaultProps = {
  color: '#3c3c3b',
  ...defaultProps,
};

export default MultipleChoiceIcon;
