import { push } from 'connected-react-router';
import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  errorProjectByEvaluationAction,
  receiveProjectByPhaseAction,
  fetchProjectByPhaseAction,
  errorProjectByPhaseAction,
  fetchProjectByEvaluationAction,
  fetchProjectOverviewAction,
  fetchProjectsAction,
  persistProjectSummaryAction,
  persistTeamMembersAction,
  receiveBriefProjectsAction,
  receiveProjectByEvaluationAction,
  receiveProjectOverviewAction,
  receiveProjectBoardsAction,
  errorProjectBoardsAction,
  fetchBriefProjectsAction,
  errorProjectOverviewAction,
  errorProjectWorkshopsAction,
  receiveManagersAction,
  errorManagersAction,
  receiveProjectWorkshopsAction,
  createTeamMemberAction,
  addManagersAction,
  persistManagersAction,
  receiveOneOnOneForProjectAction,
  errorOneOnOneForProjectAction,
  fetchOneOnOneForProjectAction,
  receiveProjectReviewsByPhaseAction,
  errorProjectReviewsByPhaseAction,
} from '../actions';
import {
  DELETE_PROJECT,
  PROJECTS_CONTEXT,
  FETCH_PROJECT_BY_EVALUATION,
  FETCH_BRIEF_PROJECTS,
  FETCH_MANAGERS,
  FETCH_PROJECT_REVIEWERS,
  FETCH_PROJECT_BY_PHASE,
  FETCH_PROJECT_BOARDS,
  FETCH_PROJECT_OVERVIEW,
  FETCH_PROJECT_WORKSHOPS,
  ACTIVE,
  INVITE_MEMBER_TO_PLATFORM,
  FETCH_ONE_ON_ONE_FOR_PROJECT,
  RESERVE_TIMESLOT,
  FETCH_PROJECT_REVIEWS_BY_PHASE,
} from '../constants';
import {
  updateProjectMutation,
  deleteProjectQuery,
  getBriefProjectsQuery,
  getProjectOverviewQuery,
  getProjectInnoboardsQuery,
  getProjectByEvaluationQuery,
  inviteMemberToProjectMutation,
  getProjectByPhaseQuery,
  persistTeamMembersMutation,
  getProjectWorkshopsQuery,
  createTeamMemberMutation,
  addManagerMutation,
  persistManagersMutation,
  getOneOnOnesForProjectQuery,
  createProjectTimeslotMutation,
  deleteProjectTimeslotMutation,
  getManagersQuery,
  getProjectReviewsByPhaseQuery,
} from '../queries';
import * as toast from '../utils/toast';

import * as api from './_api';
import { fetchEntitiesSaga } from './_utils';

/**
 * @name fetchProjectsSaga
 */
export default function* () {
  yield takeEvery(FETCH_PROJECT_BY_EVALUATION, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorProjectByEvaluationAction,
    errorMessage: 'Failed to fetch project scores',
    keyword: '_getProjectByEvaluation',
    query: getProjectByEvaluationQuery,
    receiveAction: receiveProjectByEvaluationAction,
  }));

  yield takeEvery(FETCH_PROJECT_BOARDS, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorProjectBoardsAction,
    errorMessage: 'Failed to fetch project boards',
    keyword: '_getProjectInnoBoards',
    query: getProjectInnoboardsQuery,
    receiveAction: receiveProjectBoardsAction,
  }));

  yield takeEvery(FETCH_PROJECT_WORKSHOPS, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorProjectWorkshopsAction,
    errorMessage: 'Failed to fetch project workshops',
    keyword: '_getProjectWorkshops',
    query: getProjectWorkshopsQuery,
    receiveAction: receiveProjectWorkshopsAction,
  }));

  yield takeEvery(FETCH_MANAGERS, fetchEntitiesSaga.bind(undefined, {
    errorAction: errorManagersAction,
    errorMessage: 'Failed to fetch managers',
    keyword: '_getManagers',
    query: getManagersQuery,
    receiveAction: receiveManagersAction,
  }));

  yield takeEvery(INVITE_MEMBER_TO_PLATFORM, function* inviteMemberToProject({ id, projectId, callback }) {
    try {
      yield call(api.fetch, '_inviteMemberToProject', inviteMemberToProjectMutation, {
        variables: { id, projectId },
      });
      callback();
      yield put(fetchProjectOverviewAction({ projectId }));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      callback(err);
      yield call(showErrorModal, err, { title: 'Failed to invite the member.' });
    }
  });

  yield takeEvery(DELETE_PROJECT, function* deleteProject({ projectId, campaignId, phaseId, callback }) {
    try {
      yield call(api.fetch, '_deleteProject', deleteProjectQuery, {
        context: PROJECTS_CONTEXT,
        variables: { projectId },
      });
      toast.success('Project removed!');
      if (callback) { callback(); }
      if (campaignId && phaseId !== 'NO_PHASE') {
        // Redirect to campaigns view
        yield put(push(`/campaigns/${campaignId}/cards/${phaseId}`));
      }
      else {
        // Redirect back to the cards view (when for example deleting from a specific project)
        yield put(push(`/cards/${ACTIVE}`));
      }
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to remove project.' });
    }
    // LL doesn't have campaigns or phases.
    yield put(fetchProjectsAction({}));
  });

  yield takeEvery(FETCH_BRIEF_PROJECTS, function* fetchBriefProjects() {
    try {
      const briefProjects = yield call(api.fetch, '_getBriefProjects', getBriefProjectsQuery, {
        constext: PROJECTS_CONTEXT, // TODO: CONSTEXT ? Can we change that ?
      });
      yield put(receiveBriefProjectsAction(briefProjects));
    }
    catch (err) {
      yield call(showErrorModal, err, { title: 'Failed to fetch briefProjects.' });
    }
  });

  yield takeEvery(FETCH_PROJECT_BY_PHASE, function* fetchProjectByPhase({ campaignId, isCompare, phaseId, projectId }) {
    try {
      const project = yield call(api.fetch, '_getProjectByPhase', getProjectByPhaseQuery, {
        variables: { campaignId, isCompare, phaseId, projectId },
      });
      yield put(receiveProjectByPhaseAction({ campaignId, data: project, isCompare, phaseId, projectId }));
    }
    catch (error) {
      yield put(errorProjectByPhaseAction({ campaignId, error, isCompare, projectId, phaseId }));
      if (error.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, error, { title: 'Failed to fetch the projects.' });
    }
  });

  yield takeEvery(FETCH_PROJECT_REVIEWS_BY_PHASE, function* fetchProjectReviewsByPhase({ campaignId, phaseId, projectId }) {
    try {
      const project = yield call(api.fetch, '_getProjectReviewsByPhase', getProjectReviewsByPhaseQuery, {
        variables: { campaignId, phaseId, projectId },
      });
      yield put(receiveProjectReviewsByPhaseAction({ campaignId, data: project, phaseId, projectId }));
    }
    catch (error) {
      yield put(errorProjectReviewsByPhaseAction({ campaignId, error, projectId, phaseId }));
      if (error.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, error, { title: 'Failed to fetch the project reviews.' });
    }
  });

  yield takeEvery(FETCH_PROJECT_OVERVIEW, function* fetchProjectOverview({ projectId }) {
    try {
      const variables = {
        projectId,
      };
      const projectOverview = yield call(api.fetch, '_getProjectOverview', getProjectOverviewQuery, {
        context: PROJECTS_CONTEXT,
        variables,
      });
      yield put(receiveProjectOverviewAction({ data: projectOverview, projectId }));
    }
    catch (err) {
      console.error(err);
      if (err.code === 'NOT_COMPLETED') { return; }
      yield put(errorProjectOverviewAction({ error: err, projectId }));
      yield call(showErrorModal, err, { title: 'Failed to fetch project overview.' });
    }
  });

  yield takeEvery(FETCH_PROJECT_REVIEWERS, function* compareProject({ campaignId, projectId, scoreType }) {
    const convertedType = scoreType === 'Selection' ? 'CompareSelection' : 'CompareEvaluations';
    // To make sure our isLoading flag works we are working with the convertedType as a phase call
    if (scoreType === 'Selection') {
      yield put(fetchProjectByPhaseAction({ campaignId, phaseId: convertedType, projectId, isCompare: true }));
    }
    else {
      yield put(fetchProjectByEvaluationAction({ campaignId, evaluationId: convertedType, projectId, isCompare: true }));
    }
  });

  yield takeEvery(persistProjectSummaryAction.REQUEST, function* persistProjectSummary({
    callback, payload: { projectId, name, description, majorDomain, pictureUrl, projectTypeId },
  }) {
    try {
      const context = PROJECTS_CONTEXT;
      const variables = { projectId,
        project: {
          description,
          majorDomain,
          name,
          pictureUrl,
          projectTypeId,
        } };
      yield call(api.fetch, '_updateProject', updateProjectMutation, {
        context,
        variables,
      });
      callback();
      yield put(fetchBriefProjectsAction());
      yield put(fetchProjectOverviewAction({ projectId }));
    }
    catch (err) {
      console.error(err);
    }
  });

  yield takeEvery(addManagersAction.REQUEST, function* addManagers({ callback, payload: { projectId, ...manager } }) {
    try {
      const context = PROJECTS_CONTEXT;
      const variables = {
        manager,
        projectId,
      };
      yield call(api.fetch, 'addManager', addManagerMutation, {
        context,
        variables,
      });
      yield put(fetchProjectOverviewAction({ projectId }));
      callback();
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, 'Failed to add managers.');
      callback(err);
    }
  });

  yield takeEvery(persistManagersAction.REQUEST, function* persistManagers({ callback, payload: { coaches, managers, projectId } }) {
    try {
      const context = PROJECTS_CONTEXT;
      const variables = {
        coachIds: coaches.map(({ id }) => id),
        managerIds: managers.map(({ id }) => id),
        projectId,
      };
      yield call(api.fetch, 'persistManagers', persistManagersMutation, {
        context,
        variables,
      });
      yield put(fetchProjectOverviewAction({ projectId }));
      callback();
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to add managers.' });
      callback(err);
    }
  });

  yield takeEvery(createTeamMemberAction.REQUEST, function* createTeamMember({ callback, payload: {
    projectId, ...teamMember
  } }) {
    try {
      const variables = {
        projectId,
        teamMember,
      };
      yield call(api.fetch, 'createTeamMember', createTeamMemberMutation, {
        variables,
      });
      yield put(fetchProjectOverviewAction({ projectId }));
      callback();
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to persist team member.' });
      callback(err);
    }
  });

  yield takeEvery(persistTeamMembersAction.REQUEST, function* persistTeamMembers({ callback, payload: { primaryTeamMembers, projectId, secondaryTeamMembers } }) {
    try {
      const context = PROJECTS_CONTEXT;
      const teamMembers = primaryTeamMembers
        .filter(teamMember => teamMember.name)
        .map(teamMember => ({ ...teamMember, type: 'PRIMARY' }))
        .concat(
          secondaryTeamMembers.filter(teamMember => teamMember.name)
            .map(teamMember => ({ ...teamMember, type: 'SECONDARY' })),
        );
      const variables = { projectId, teamMembers };
      yield call(api.fetch, 'persistTeamMembers', persistTeamMembersMutation, {
        context,
        variables,
      });
      yield put(fetchProjectOverviewAction({ projectId }));
      callback();
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, { title: 'Failed to persist team members.' });
      callback(err);
    }
  });

  yield takeEvery(FETCH_ONE_ON_ONE_FOR_PROJECT, function* fetchOneOnOnes({ projectId, workshopMomentId, callback }) {
    try {
      const data = yield call(api.fetch, '_getOneOnOnes', getOneOnOnesForProjectQuery, {
        variables: {
          projectId,
          workshopMomentId,
        },
      });
      yield put(receiveOneOnOneForProjectAction({ data, projectId, workshopMomentId }));
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, 'Failed to fetch all one on ones.');
      yield put(errorOneOnOneForProjectAction({ error: err, projectId, workshopMomentId }));
    }
  });

  yield takeEvery(RESERVE_TIMESLOT, function* reservetimeslot({
    projectId, start, end, timeslotRangeId, workshopMomentId, id, callback,
  }) {
    try {
      if (id) {
        yield call(api.fetch, '_deleteProjectTimeslot', deleteProjectTimeslotMutation, {
          variables: { id },
        });
      }
      yield call(api.fetch, '_createProjectTimeslot', createProjectTimeslotMutation, {
        variables: {
          projectId,
          startDatetime: start,
          endDatetime: end,
          timeslotTimeRangeId: timeslotRangeId,
        },
      });
      yield put(fetchOneOnOneForProjectAction({ projectId, workshopMomentId }));
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield call(showErrorModal, err, 'Failed to reserve the timeslot.');
      if (callback && typeof callback === 'function') {
        callback(err);
      }
    }
  });
}
