import { mutationBuilder } from '@innovatrix/utils/dx';

export const resetUserTourMutation = mutationBuilder({
  variables: {
    userId: { type: 'String!' },
    tourName: { type: 'String!' },
  },
  fields: {
    _resetUserTour: {
      varArgs: {
        userId: 'userId',
        tourName: 'tourName',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});
