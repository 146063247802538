import {
  ERROR_PROJECT_OVERVIEW,
  FETCH_PROJECT_OVERVIEW,
  RECEIVE_PROJECT_OVERVIEW,
} from '../../constants';

import {
  errorEntity,
  fetchEntity,
  receiveEntity,
} from './utils';

const initialState = {
  entities: {},
  relations: {
    campaignHasProjects: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_PROJECT_OVERVIEW: return errorEntity(state, action);
    case FETCH_PROJECT_OVERVIEW: return fetchEntity(state, action);
    case RECEIVE_PROJECT_OVERVIEW: return receiveEntity(state, action);

    default: return state;
  }
};

