import { RECEIVE_UPDATE_DECISION, TOGGLE_COLLAPSE_CRITERIA, UPDATE_DECISION } from '../constants';

const initialState = {
  collapse: false,
  isDecisionLoading: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSE_CRITERIA: {
      return {
        ...state,
        collapse: !state.collapse,
      };
    }
    case UPDATE_DECISION: {
      const { data } = action;
      return {
        ...state,
        isDecisionLoading: [
          ...state.isDecisionLoading,
          data.projectId,
        ],
      };
    }
    case RECEIVE_UPDATE_DECISION: {
      const { data } = action;
      const isDecisionLoading = state.isDecisionLoading.filter(e => e !== data.projectId);
      return {
        ...state,
        isDecisionLoading,
      };
    }
    default:
      return state;
  }
};

