import { call, put, takeEvery } from 'redux-saga/effects';
import qs from 'query-string';
import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';

import { FETCH_QUERY, SEND_MUTATION } from '../constants';
import { fetchQueryAction, receiveQueryAction, completeMutationAction } from '../actions';

import * as api from './_api';

const NOT_COMPLETED = 'NOT_COMPLETED';
const INTEGRATION_IS_ALREADY_IN_PROGRESS = 'INTEGRATION_IS_ALREADY_IN_PROGRESS';

function* fetchEntitiesSaga({ variables, namespace, query, url, isDxApi }) {
  try {
    const context = `GENERIC_${namespace}_${qs.stringify(variables)}`;
    const data = yield call(api.fetch, namespace, query, { variables, context, url }, isDxApi);
    yield put(receiveQueryAction({ data, variables, namespace, query, url }));
  }
  catch (error) {
    if (error.code === NOT_COMPLETED) { return; }
    yield put(receiveQueryAction({ error, variables, namespace, query, url }));
    yield call(showErrorModal, error, { title: namespace });
  }
}

function* mutateSaga({ variables: mutationVars, namespace, mutation, refetchQueries = [], isDxApi }) {
  const { callback, ...variables } = mutationVars;
  try {
    const context = `GENERIC_${namespace}_${qs.stringify(variables)}`;
    const data = yield call(api.fetch, namespace, mutation, { variables, context }, isDxApi);

    for (let i = 0; i < refetchQueries.length; i += 1) {
      const refetchQuery = refetchQueries[i];
      if (refetchQuery.namespace) {
        yield put(fetchQueryAction({ ...refetchQuery }));
      }
      else if (refetchQuery.action) {
        const { action, variables: vars = {} } = refetchQuery;
        yield put(action({ ...vars }));
      }
      else {
        console.warn(
          `Invalid refetchQuery passed to generic Mutation.\n${
            JSON.stringify(refetchQuery)}`,
        );
      }
    }

    yield put(completeMutationAction({ data, variables, namespace, mutation }));
    if (callback) { callback(undefined, data); }
  }
  catch (error) {
    console.error(error);
    if (error.code === NOT_COMPLETED || error === INTEGRATION_IS_ALREADY_IN_PROGRESS) { return; }
    yield put(completeMutationAction({ error, variables, namespace, mutation }));
    if (callback) { callback(error); }
    yield call(showErrorModal, error, { title: namespace });
  }
}

export default function* () {
  yield takeEvery(FETCH_QUERY, fetchEntitiesSaga.bind(undefined));
  yield takeEvery(SEND_MUTATION, mutateSaga.bind(undefined));
}
