import React from 'react';
import PropTypes from 'prop-types';

import { defaultProps } from './defaultPropTypes';

const MultipleSelectionIcon = ({ color, ...props }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 15 15" {...props}>
    <g id="multiple_selection" transform="translate(0 31)">
      <path fill="none" stroke={color} strokeWidth="1.0351" strokeLinecap="square" id="Line-5" d="M6.4-29.4h8" />
      <path fill="none" stroke={color} strokeWidth="1.0351" strokeLinecap="square" id="Line-5_1_" d="M6.4-23.5h8" />
      <path id="Rectangle-20" fill="none" stroke={color} strokeWidth="1.071" d="M.5-25.1h3.2v3.2H.5z" />
      <path id="Combined-Shape" fill={color} d="M4.3-30.6l-2.4 2.4-1.1-1.1-.4.4 1.5 1.5 2.4-2.4v3.1H0V-31h4.3v.4z" />
      <path id="Combined-Shape_1_" fill={color} d="M4.3-19.9l-2.4 2.4-1.1-1.1-.4.4 1.5 1.5 2.4-2.4v3.1H0v-4.3h4.3v.4z" />
      <path id="Line-5_2_" stroke={color} fill="none" strokeWidth="1.0351" strokeLinecap="square" d="M6.4-17.6h8" />
    </g>
  </svg>
);

MultipleSelectionIcon.propTypes = {
  color: PropTypes.string,
};

MultipleSelectionIcon.defaultProps = {
  color: '#3c3c3b',
  ...defaultProps,
};

export default MultipleSelectionIcon;
