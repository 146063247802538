import React from 'react';
import PropTypes from 'prop-types';

import { defaultProps } from './defaultPropTypes';

const RankingIcon = ({ color, ...props }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 15 15" {...props}>
    <g id="ranking" transform="translate(0 93)">
      <path fill={color} id="Rectangle-7" className="st0" d="M0-87.5h3.5v9.5H0z" />
      <path fill={color} id="Rectangle-7_1_" className="st0" d="M5.8-93h3.5v15H5.8z" />
      <path fill={color} id="Rectangle-7_2_" className="st0" d="M11.5-90.3H15V-78h-3.5z" />
    </g>
  </svg>
);

RankingIcon.propTypes = {
  color: PropTypes.string,
};

RankingIcon.defaultProps = {
  color: '#3c3c3b',
  ...defaultProps,
};

export default RankingIcon;
