import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WithToolTip from '@innovatrix/components/WithToolTip';
import { IS_PRODUCTION } from '@innovatrix/constants';

import { PRIMARY, SECONDARY } from '../_constants';

import Item from './Item';

const ALLOWED_FLAVORS = [PRIMARY, SECONDARY];

const DropdownMenu = styled.div`
  background: ${({ theme }) => (theme.colors.white)};
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 4px 0;
  position: absolute;
  right: 0;
  z-index: 5;
  ${({ fixed }) => (fixed ? 'bottom: 0;' : 'top: 48px;')}
`;

const Wrapper = styled.div`
  position: relative;
  ${({ fixed }) => (fixed ? `
    bottom: 64px;
    position: fixed;
    right: 32px;
  ` : '')}
`;

const Button = styled.button`
  align-items: center;
  background: ${({ isPrimary, theme }) => (isPrimary ? theme.colors.primary : theme.colors.white)};
  border: 1px solid ${({ fixed, theme }) => (fixed ? theme.colors.greyLight3 : 'transparent')};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: ${({ isPrimary }) => (isPrimary ? '50px' : '40px')};
  justify-content: center;
  transition: transform .33s ease-in-out, box-shadow .33s ease-in-out;
  width: ${({ isPrimary }) => (isPrimary ? '50px' : '40px')};
  &:hover {
    background: ${({ isPrimary, theme }) => (isPrimary ? theme.colors.primaryDark : theme.colors.greyBlueish)};
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
    transform: scale(1.1);
  }
  &:active, &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.focus};
    outline: none;
  }
  &:after {
    background: ${({ isPrimary, theme }) => (isPrimary ? theme.colors.primary : theme.colors.white)};
  }
  ${({ theme }) => theme.effect.ripple}
`;

const InnerContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${({ isPrimary }) => (isPrimary ? '18px' : '14px')};
  justify-content: center;
  width: ${({ isPrimary }) => (isPrimary ? '18px' : '14px')};
  > * {
    cursor: pointer;
  }
`;

const FloatingActionButton = ({
  className, children, disabled, flavor,
  onClick, options, tooltip, type, fixed,
}) => {
  if (!IS_PRODUCTION) {
    if (!ALLOWED_FLAVORS.includes(flavor)) {
      throw new Error(
        `Invalid size passed to the <FloatingActionButton /> component.
        Got ${flavor} expected one of ${ALLOWED_FLAVORS.join(', ')}.`,
      );
    }
  }
  const isPrimary = React.useMemo(() => flavor === 'primary', [flavor]);
  const timeout = React.useRef();

  const [isOpen, setIsOpen] = React.useState(false);

  const leave = React.useCallback(() => {
    timeout.current = setTimeout(() => setIsOpen(() => false), 250);
  }, []);
  const enter = React.useCallback(() => {
    if (timeout.current) { clearTimeout(timeout.current); }
  }, []);

  const toggleDropdown = React.useCallback(() => setIsOpen(o => !o), []);

  return (
    <WithToolTip className={className} label={tooltip}>
      <Wrapper
        fixed={fixed}
        onMouseEnter={enter}
        onMouseLeave={leave}
      >
        <Button
          disabled={disabled}
          fixed={fixed}
          isPrimary={isPrimary}
          onClick={options ? toggleDropdown : onClick}
          type={type}
        >
          <InnerContainer isPrimary={isPrimary}>
            {children}
          </InnerContainer>
        </Button>
        {options && isOpen && (
          <DropdownMenu fixed={fixed}>
            {options.map(({
              label: itemLabel,
              value: itemValue,
              action,
              options: itemOptions,
            }, i) => (
              <Item
                key={`${itemValue}${i}`}
                label={itemLabel}
                options={itemOptions}
                action={action}
              />
            ))}
          </DropdownMenu>
        )}
      </Wrapper>
    </WithToolTip>
  );
};

FloatingActionButton.defaultProps = { fixed: false, flavor: PRIMARY, type: 'button', tooltip: undefined };
FloatingActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fixed: PropTypes.bool,
  flavor: PropTypes.oneOf(ALLOWED_FLAVORS),
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func,
    label: PropTypes.string,
    options: PropTypes.array,
  })),
  tooltip: PropTypes.string,
  type: PropTypes.string,
};

export default React.memo(FloatingActionButton);
