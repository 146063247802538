const monthNames = [
  'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni',
  'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December',
];

// Function to format our date to DD/MM - HH:MM
export const formatDateTime = inputDate => {
  let date = inputDate;
  // Check if our inputDate has to be converted to a Date
  if (!(date instanceof Date)) {
    date = new Date(inputDate);
  }

  // Format fields with a 0 in front when needed
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  // Return the formatted Date
  return `${day}/${month} - ${hour}:${minutes}`;
};

export const formatDateMonthName = inputDate => {
  let date = inputDate;
  // Check if our inputDate has to be converted to a Date
  if (!(date instanceof Date)) {
    date = new Date(inputDate);
  }

  const day = date.getDate();
  const year = date.getFullYear();

  // Return the formatted Date
  return `${day} ${monthNames[date.getMonth()]} ${year}`;
};

export const formatDate = inputDate => {
  let date = inputDate;
  // Check if our inputDate has to be converted to a Date
  if (!(date instanceof Date)) {
    date = new Date(inputDate);
  }

  // Format fields with a 0 in front when needed
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();

  // Return the formatted Date
  return `${day}/${month}/${year}`;
};
