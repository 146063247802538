import { queryBuilder } from '@innovatrix/utils/dx';

export const getSalesforceStatusQuery = queryBuilder(
  {
    fields: {
      _getSalesforceStatus: {
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);
