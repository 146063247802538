import { put, takeEvery } from 'redux-saga/effects';

import { receiveSettingsAction } from '../actions';
import { CHANGE_DECISION_SETTING } from '../constants';
import { decisonSettingMutation } from '../queries';

import { mutationSaga } from './_utils';

export default function* () {
  yield takeEvery(CHANGE_DECISION_SETTING, mutationSaga.bind(undefined, {
    * after(payload, syncDecisions) { yield put(receiveSettingsAction({ syncDecisions })); },
    keyword: 'syncDecisions',
    mutation: decisonSettingMutation,
    preparePayload: ({ data: willSync }) => ({ willSync }),
  }));
}
