import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { put, call, takeEvery } from 'redux-saga/effects';

import { FETCH_MANAGERS_AND_COACHES } from '../constants';
import { filterUsersQuery } from '../queries/users';
import { receiveManagersAndCoachesAction, errorManagersAndCoachesAction } from '../actions';

import * as api from './_api';

export default function* () {
  yield takeEvery(FETCH_MANAGERS_AND_COACHES, function* fetchManagersAndCoaches({ search }) {
    try {
      const result = yield call(api.fetch, '_getManagersAndCoaches', filterUsersQuery, { variables: {
        searchString: search,
      } });
      const data = result.items.map(({ roles, ...rest }) => ({ ...rest, isManager: roles.includes('innovatrix/role/manager') }));
      yield put(receiveManagersAndCoachesAction({ data, search }));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield put(errorManagersAndCoachesAction({ error: err, search }));
      yield call(showErrorModal, err, { title: 'Failed to fetch the coaches and managers.' });
    }
  });
}
