import * as React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { dxAuthSignOutAction } from '@innovatrix/react-frontend/actions/dxAuthActions';
import { Authenticate } from '@innovatrix/react-frontend/auth';
import { isAuthorized } from '@innovatrix/selectors/authSelectors';
import { getUrlPrefix } from '@innovatrix/utils/urlPrefix';
import { getApplicationMetaData } from '@innovatrix/selectors/data/applicationSelectors';
import {
  CAMPAIGNS_ENABLED,
  DASHBOARD_TAB,
  DEALS_ENABLED,
  ENABLE_MICROSOFT_CLARITY,
  INNOBOARD_TAB,
  INNOBOARDS_ENABLED,
  INNOVATRIX_OPTIONS,
  INTAKES_ENABLED,
  IS_OPEN_ENVIRONMENT,
  IS_PRODUCTION,
  LEGAL_DOCUMENTS,
  MICROSOFT_CLARITY_KEY,
  PHASES_ENABLED,
  PUBLIC_DEALS_ENABLED,
  SALESFORCE_ENABLED,
  WORKSHOPS_ENABLED,
} from '@innovatrix/constants';
import Spinner from '@innovatrix/components/Spinner';

import Cards from '../../modules/projects/cards';
import MyProjects from '../../modules/projects/myProjects';
import SigningIn from '../../modules/signingIn';
import CreateProjectModalOnRegistration from '../../modules/projects/create';

import NotFound from './NotFound';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import AppContainer from './AppContainer';
import { Container, Content } from './Container';
import InnovatrixIntroductionTour from './InnovatrixIntroductionTour';
import CookieStatement from './CookieStatement';
import LegalDocumentStatement from './LegalDocumentStatement';

// -- Async Routes ----------- --- -- -

const Campaigns = loadable(() => import('../../modules/campaigns'), {
  fallback: <Spinner />,
});

const ComponentsDemo = loadable(() => import('../../modules/demo/components'), {
  fallback: <Spinner />,
});

const Project = loadable(() => import('../project'), {
  fallback: <Spinner />,
});

const Welcome = loadable(() => import('../../modules/welcome'), {
  fallback: <Spinner />,
});

const Proposals = loadable(() => import('../../modules/proposals'), {
  fallback: <Spinner />,
});

const About = loadable(() => import('../../modules/about'), {
  fallback: <Spinner />,
});

const Deals = loadable(() => import('../../modules/deals'), {
  fallback: <Spinner />,
});

const Innovatrix = loadable(() => import('../../modules/innovatrix'), {
  fallback: <Spinner />,
});

const PublicDeals = loadable(() => import('../../modules/public/deals'), {
  fallback: <Spinner />,
});

const PublicInnoBoard = loadable(() => import('../../modules/public/innoboard'), {
  fallback: <Spinner />,
});

const Intakes = loadable(() => import('../../modules/intakes'), {
  fallback: <Spinner />,
});

const PublicIntakeRoutes = loadable(() => import('../../modules/public/intakes'), {
  fallback: <Spinner />,
});

const GenericWorkshopURL = loadable(() => import('../../modules/workshops'), {
  fallback: <Spinner />,
});

// -- Nested routes --------------- --- --  -
const PublicRoutes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      {/* Make sure that we hide these routes on environments that do not need them enabled */}
      {PUBLIC_DEALS_ENABLED ? <Route exact path={`${match.path}/deals`} component={PublicDeals} key="public-deals" /> : null}
      {INTAKES_ENABLED ? (<Route exact path={`${match.path}/apply`} component={PublicIntakeRoutes} key="public-intakes-root" />) : null}
      {INTAKES_ENABLED ? (<Route path={`${match.path}/apply/:campaignName`} component={PublicIntakeRoutes} key="public-intakes" />) : null}
      {INNOBOARDS_ENABLED ? (<Route path={`${match.path}/:projectId/${INNOBOARD_TAB}/:innoBoardId/:innoBoardPhaseId/:viewMode/:validationId?`} component={PublicInnoBoard} key="public-innoboards" />) : null}
      <Redirect path="/public" to="/" />
    </Switch>
  );
};

const projectsUrl = SALESFORCE_ENABLED ? '/projects/Active' : '/projects/ACTIVE';

const initialRedirect = ({ canAccessCampaigns, canAccessProjects, canAccessProposals, myProjects, unfinishedRegistrations }) => {
  if (unfinishedRegistrations && unfinishedRegistrations.length > 0) {
    return <Redirect exact from="/" key="redirect-unfinished-registration" to="/projects/create" />;
  }

  const landingPage = IS_OPEN_ENVIRONMENT ? INNOBOARD_TAB : DASHBOARD_TAB;

  if (SALESFORCE_ENABLED || CAMPAIGNS_ENABLED) {
    if (canAccessCampaigns) {
      return <Redirect exact from="/" key="redirect-campaigns" to="/campaigns" />;
    }

    if (canAccessProjects) {
      return <Redirect exact from="/" key="redirect-projects" to={projectsUrl} />;
    }

    if (canAccessProposals) {
      return <Redirect exact from="/" key="redirect-proposals" to="/proposals/Open" />;
    }

    return (
      <Redirect
        exact from="/" key="redirect-client" to={
          myProjects && myProjects.length > 0 ?
            `${getUrlPrefix(myProjects[0].campaignId, myProjects[0].id)}/${landingPage}`
            : '/about'
        }
      />);
  }

  if (canAccessProjects) {
    return <Redirect exact from="/" key="redirect-projects" to="/cards/ACTIVE" />;
  }

  return (
    <Redirect
      exact from="/" key="redirect-client"
      to={
        myProjects && myProjects.length > 0 ?
          `${getUrlPrefix(undefined, myProjects[0].id)}/${landingPage}` :
          '/about'
      }
    />
  );
};

// -- Component --------------- --- --  -
const App = ({
  applicationMetaData,
  canAccessApplications,
  canAccessCampaigns,
  canAccessDemo,
  canAccessManage,
  canAccessProjects,
  canAccessProposals,
  canEditCampaigns,
  isApplicant,
  signOut,
}) => {
  const { configuration, isEvaluator, myProjects, unfinishedRegistrations, user } = applicationMetaData;

  const hasLoggedIn = user && user.hasLoggedIn;

  const termsDocument = configuration && configuration.legalDocuments && configuration.legalDocuments.find(d => d.id === LEGAL_DOCUMENTS.TERMS_AND_CONDITIONS);
  const privacyDocument = configuration && configuration.legalDocuments && configuration.legalDocuments.find(d => d.id === LEGAL_DOCUMENTS.PRIVACY_POLICY);

  const termsVersion = termsDocument && termsDocument.version;
  const privacyVersion = privacyDocument && privacyDocument.version;

  const diffTermsVersion = termsVersion && user && (user.acceptedTermsVersion !== termsVersion);
  const diffPrivacyVersion = privacyVersion && user && (user.acceptedPrivacyPolicyVersion !== privacyVersion);

  /**
   * Initially the acceptedCookies value for a user is NULL
   * so only when the value is NULL we should show the CookieStatement.
   */
  const shouldShowCookies = user && user.acceptedCookies === null;

  const [shouldShowTerms, setShouldShowTerms] = React.useState(diffTermsVersion);
  const [shouldShowPrivacy, setShouldShowPrivacy] = React.useState(diffPrivacyVersion);

  /**
   * Inject the Microsoft Clarity script into the HTML head
   * when enabled.
   */
  React.useEffect(() => {
    if ((user && user.acceptedCookies) && ENABLE_MICROSOFT_CLARITY) {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
       })(window, document, "clarity", "script", "${MICROSOFT_CLARITY_KEY}");
      `;
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [user]);

  /**
   * Here we check if the user has logged already to show the terms update,
   * otherwise it means he just came from the welcome page and obviously accepted there
   * We can't check on the Loading status as it's already loaded after the welcome page
   */
  React.useEffect(() => {
    if (diffTermsVersion && hasLoggedIn) {
      setShouldShowTerms(true);
    }
  }, [applicationMetaData, diffTermsVersion, hasLoggedIn]);
  React.useEffect(() => {
    if (!shouldShowTerms && diffPrivacyVersion && hasLoggedIn) {
      setShouldShowPrivacy(true);
    }
  }, [shouldShowTerms, diffPrivacyVersion, hasLoggedIn]);

  /**
   * Enable MS Clarity data collecting session.
   * Consent is off by default, so we have to explicitly
   * give consent to MS Clarity when the user has accepted the cookie-statement.
   */
  React.useEffect(() => {
    if ((user && user.acceptedCookies) && ENABLE_MICROSOFT_CLARITY && window.clarity) {
      window.clarity('consent');
    }
  }, [user]);

  return (
    <Container>
      <Switch>
        <Route path="/public">
          <PublicRoutes />
        </Route>
        <Authenticate>
          <AppContainer>
            <ScrollToTop>
              <Switch>
                {/* Intakes do not need navbar and footer */}
                {(INTAKES_ENABLED && canAccessApplications) ? (
                  <Route key="intakes" path="/apply">
                    <Intakes />
                  </Route>
                ) : null}
                {/* Redirect applicants on-login to the /apply route */}
                {INTAKES_ENABLED && isApplicant ? <Redirect to="/apply" key="redirect-applicant" /> : null}
                {/* Routes that do need the navbar and footer */}
                <Route path="*">
                  <Content>
                    {(SALESFORCE_ENABLED || hasLoggedIn) ? <NavigationBar signOut={signOut} /> : null}
                    <Switch>
                      {(SALESFORCE_ENABLED || CAMPAIGNS_ENABLED)
                        ? [
                          initialRedirect({ canAccessCampaigns, canAccessProjects, canAccessProposals, myProjects, unfinishedRegistrations }),
                          <Route component={CreateProjectModalOnRegistration} key="project-creation" path="/projects/create" />,
                          (canAccessProposals && (SALESFORCE_ENABLED || CAMPAIGNS_ENABLED) && PHASES_ENABLED) && <Redirect exact from="/proposals" to="/proposals/Open" key="redirect-proposals" />,
                          (canAccessProposals && (SALESFORCE_ENABLED || CAMPAIGNS_ENABLED) && PHASES_ENABLED) && <Route component={Proposals} path="/proposals/:tab" key="proposals" />,
                          canAccessCampaigns && <Route component={Campaigns} exact path="/campaigns" key="campaigns" />,
                          <Route component={Project} key="projectCompare" path="/campaigns/:campaignId/projects/:projectId/:tab/:phaseId?" />,
                          canAccessProjects && <Route component={Cards} key="cards" path="/campaigns/:campaignId/cards/:phaseId/:tab?" />,
                          canAccessProjects && <Route component={MyProjects} key="projects" path="/projects/:projectStatus" />,
                          <Redirect exact from="/projects" key="redirect-projects" to={projectsUrl} />,
                        ].filter(Boolean)
                        : [
                          initialRedirect({ canAccessCampaigns, canAccessProjects, canAccessProposals, myProjects, unfinishedRegistrations }),
                          canAccessProjects && <Route component={Cards} key="cards" path="/cards/:state" />,
                          <Route component={Project} key="project" path="/projects/:projectId/:tab/:phaseId?" />,
                        ].filter(Boolean)}
                      {/* Hide the manage page for anyone with insufficient rights, e.g. normal users and campaign managers */}
                      {(canAccessManage && canEditCampaigns) && <Redirect exact from="/manage" key="redirect-manage" to={`/manage/${INNOVATRIX_OPTIONS[0] ? `${INNOVATRIX_OPTIONS[0].value}` : '/not-found'}`} />}
                      {(canAccessManage && canEditCampaigns) && <Route component={Innovatrix} key="manage" path="/manage/:tab" />}
                      {/* Only enable the deals page on environments with DEALS_ENABLED set to true */}
                      {DEALS_ENABLED ? <Route component={Deals} key="deals" path="/deals" /> : null}
                      {/* Generic workshop URL */}
                      {WORKSHOPS_ENABLED ? (
                        <Route component={GenericWorkshopURL} key="generic-workshops" path="/workshops/:workshopMomentId" />
                      ) : null}
                      <Route path="/auth0/auth-redirect" component={SigningIn} />
                      {(user && !user.hasLoggedIn) ? (
                        <Route path="/welcome" component={Welcome} />
                      ) : null}
                      <Route path="/about/:tab?" key="about" component={About} />
                      {(canAccessDemo && !IS_PRODUCTION) ? (<Route path="/components/:component?" component={ComponentsDemo} />) : null}
                      <Route path="*" component={NotFound} />
                    </Switch>
                    {/* Tour modal should only be visible to logged in users */}
                    {/* This prevents from the Tour modal showing on the Welcome page */}
                    {/* Note: applicants and evaluators should not see this! */}
                    {(hasLoggedIn && !isApplicant && !isEvaluator) ? (
                      <InnovatrixIntroductionTour />
                    ) : null}
                    {hasLoggedIn ? (
                      <React.Fragment>
                        {shouldShowTerms ? <LegalDocumentStatement document={termsDocument} shouldShow={setShouldShowTerms} title="ui.legaldocuments.update.terms" documentTitle="about.termsAndConditions" /> : null}
                        {!shouldShowTerms && shouldShowPrivacy ? <LegalDocumentStatement document={privacyDocument} shouldShow={setShouldShowPrivacy} title="ui.legaldocuments.update.privacyPolicy" documentTitle="about.privacyPolicy" /> : null}
                        {shouldShowCookies ? <CookieStatement /> : null}
                      </React.Fragment>
                    ) : null}
                  </Content>
                  {(SALESFORCE_ENABLED || hasLoggedIn) ? <Footer /> : null}
                </Route>
              </Switch>
            </ScrollToTop>
          </AppContainer>
        </Authenticate>
      </Switch>
    </Container>
  );
};

App.propTypes = {
  applicationMetaData: PropTypes.object,
  canAccessApplications: PropTypes.bool.isRequired,
  canAccessCampaigns: PropTypes.bool.isRequired,
  canAccessDemo: PropTypes.bool.isRequired,
  canAccessManage: PropTypes.bool.isRequired,
  canAccessProjects: PropTypes.bool.isRequired,
  canAccessProposals: PropTypes.bool.isRequired,
  canEditCampaigns: PropTypes.bool.isRequired,
  isApplicant: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
};

// -- Wrappers --------------- --- --  -

export default connect((state) => {
  // Applicants only have a few select roles,
  // so for now we'll just assume that a user has the applicant role if he is authorized for applications/view
  // but not for the deals/view activities (all but the applicant role have deals/view activity assigned to them).
  const isApplicant = isAuthorized(state, 'applications/view') && !isAuthorized(state, 'deals/view');
  return {
    applicationMetaData: getApplicationMetaData(state),
    canAccessApplications: isAuthorized(state, 'applications/view'),
    canAccessCampaigns: isAuthorized(state, 'access/campaigns'),
    canAccessDemo: isAuthorized(state, 'access/ums'),
    canAccessManage: isAuthorized(state, 'access/manage'),
    canAccessProjects: isAuthorized(state, 'access/projects'),
    canAccessProposals: isAuthorized(state, 'access/proposals'),
    canEditCampaigns: isAuthorized(state, 'campaigns/update'),
    isApplicant,
  };
}, {
  signOut: dxAuthSignOutAction,
})(React.memo(App));
