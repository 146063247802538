import { createFormikAction } from '../../../actions/utils';

import {
  FILTER_CARDS,
  FILTER_DECISIONS,
  ORDER_CARDS,
  SHOW_DECISION_DETAILS,
  TOGGLE_PREDICTIONS,
  FILTER_PROJECT_TYPE,
  UPDATE_DECISION,
  RECEIVE_UPDATE_DECISION,
  FILTER_CARDS_BY_MANAGER,
  RECEIVE_UPDATED_PROJECT,
  FETCH_REVIEWERS,
  RECEIVE_REVIEWERS,
  ERROR_REVIEWERS,
} from './_actionTypes';

export const filterProjectTypeAction = typeId => ({
  typeId,
  type: FILTER_PROJECT_TYPE,
});

export const showDecisionDetailsCardAction = projectId => ({
  type: SHOW_DECISION_DETAILS,
  projectId,
});

export const filterCardsByManagerAction = managerId => ({
  managerId,
  type: FILTER_CARDS_BY_MANAGER,
});

export const filterCardsByStatusAction = filter => ({
  filter,
  type: FILTER_CARDS,
});

export const filterDecisionsAction = filter => ({
  type: FILTER_DECISIONS,
  filter,
});

export const orderCardsAction = order => ({
  type: ORDER_CARDS,
  order,
});

export const togglePredictionsAction = (value) => ({
  type: TOGGLE_PREDICTIONS,
  value,
});

export const receiveUpdatedProjectAction = (project) => ({
  type: RECEIVE_UPDATED_PROJECT,
  project,
});

export const updateDecisionAction = (campaignId, projectId, phaseId, decision, conditions) => ({
  type: UPDATE_DECISION,
  data: {
    campaignId,
    conditions,
    decision,
    phaseId,
    projectId,
  },
});

export const receiveDecisionAction = (projectId) => ({
  type: RECEIVE_UPDATE_DECISION,
  data: {
    projectId,
  },
});

export const createProjectAction = createFormikAction('CREATE_PROJECT');

export const fetchReviewersAction = () => ({
  type: FETCH_REVIEWERS,
});

export const receiveReviewersAction = ({ data }) => ({
  data,
  type: RECEIVE_REVIEWERS,
});

export const errorReviewersAction = ({ error }) => ({
  error,
  type: ERROR_REVIEWERS,
});
