import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendMutationAction } from '@innovatrix/actions/genericActions';
import { getMutationDataFactorySelector } from '@innovatrix/selectors/genericSelectors';

/**
 * This component has the sole purpose of executing a Mutation
 * to achieve this it will go through some steps
 *
 * - Render: It will bind a function according to the passed parameters.
 *   Secondly it will memoize the render function to prevent friction.
 * - Execution: Will indicate current namespace with hashed vars as in-flight.
 * - Processing: Will use api.fetch to postprocess the response.
 * - Post: Will execute the refetchQueries to achieve the new state.
 *   Optionally when it's a form it will also execute the passed callback.
 *
 * @param object children React component children
 * @param object mutation (also possibly a function) A graphQL mutation query
 *                        if a function then that function should return a mutation query object
 * @param object data
 * @param string namespace (also possibly a function)
 *                         typically this would be a string that specifies the key of the redux state,
 *                         it will get stored under `generic.[_namespace]`.
 *                         I guess it also allows passing a function that can generate the namespace based on the variables
 * @param function refetchQueries refetch data after mutation
 * @param function sendMutation actionCreator (but this is always the same, so don't see why its a param)
 * @param boolean isDxApi *shrug* die duxis die
 * @returns function It passes the mutate function to the children? Not sure what the base case is that actually calls the function
 */
const Mutation = ({ children, mutation, data, namespace, refetchQueries, sendMutation, isDxApi }) => {
  const mutate = React.useCallback((variables) => {
    sendMutation({
      mutation: typeof mutation === 'function' ? mutation(variables) : mutation,
      namespace: typeof namespace === 'function' ? namespace(variables) : namespace,
      variables,
      refetchQueries,
      isDxApi,
    });
  }, [isDxApi, mutation, namespace, refetchQueries, sendMutation]);
  return React.useMemo(() => children(mutate, { data }), [children, mutate, data]);
};

Mutation.propTypes = {
  children: PropTypes.any,
  data: PropTypes.any,
  isDxApi: PropTypes.bool,
  mutation: PropTypes.any,
  namespace: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  refetchQueries: PropTypes.array,
  sendMutation: PropTypes.func,
};

/**
 * Why do we do this?
 * To prevent people from abusing this component in
 * such a fashion that a consistent state can't be guaranteed
 *
 * Example:
 * <Mutation> --> Memoizes X
 *  <Mutation> --> Memoized Y
 *    ...
 *  </Mutation>
 * </Mutation>
 *
 * On next iteration we bounce on Mutation #1 with a memoized Y implying a perf
 * nett loss.
 */
const makeMapState = () => {
  const selectItemForThisComponent = getMutationDataFactorySelector();
  return (state, { namespace, variables }) => ({
    data: selectItemForThisComponent(state, { namespace, variables }),
  });
};

// One could wonder why we use an additional memo when our redux state has already
// achieved a state of individual memoization.
// Well when a parent rerenders it will invoke children
// In this case we have:
// <Mutation>{children}</Mutation>
// Implying that when a parent rerenders our Mutation and render prop
// can be skipped on equality --> only the childComponent will rerender
// With equal Mutation context.
export default connect(makeMapState, {
  sendMutation: sendMutationAction,
})(Mutation);
