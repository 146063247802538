import {
  ERROR_ACTIVE_ASSESSMENT_VERSION,
  ERROR_ASSESSMENT_VERSION,
  ERROR_ASSESSMENT_VERSIONS,
  FETCH_ACTIVE_ASSESSMENT_VERSION,
  FETCH_ASSESSMENT_VERSION,
  FETCH_ASSESSMENT_VERSIONS,
  RECEIVE_ACTIVE_ASSESSMENT_VERSION,
  RECEIVE_ASSESSMENT_VERSION,
  RECEIVE_ASSESSMENT_VERSIONS,
} from '../../constants';

import { fetchEntities, receiveEntities, errorEntities, errorEntity, fetchEntity, receiveEntity } from './utils';

const initialState = {
  entities: {},
  relations: {
    assessmentHasVersions: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_ASSESSMENT_VERSIONS: return errorEntities('assessmentHasVersions', state, action);
    case FETCH_ASSESSMENT_VERSIONS: return fetchEntities('assessmentHasVersions', state, action);
    case RECEIVE_ASSESSMENT_VERSIONS: return receiveEntities('assessmentHasVersions', state, action);

    case ERROR_ASSESSMENT_VERSION: return errorEntity(state, action);
    case FETCH_ASSESSMENT_VERSION: return fetchEntity(state, action);
    case RECEIVE_ASSESSMENT_VERSION: return receiveEntity(state, action);

    case ERROR_ACTIVE_ASSESSMENT_VERSION: return errorEntity(state, { id: 'activeAssessmentVersion' });
    case FETCH_ACTIVE_ASSESSMENT_VERSION: return fetchEntity(state, { id: 'activeAssessmentVersion' });
    case RECEIVE_ACTIVE_ASSESSMENT_VERSION: return receiveEntity(state, { data: action.data, id: 'activeAssessmentVersion' });

    default: return state;
  }
};

