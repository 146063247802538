import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Paragraph from '../../text/Paragraph';

const ITEM_HEIGHT = 32;

const DropdownMenuItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  height: ${ITEM_HEIGHT}px;
  padding: 8px 12px;
  position: relative;
  &:hover {
    background: ${({ theme }) => theme.colors.greyBlueish};
  }
`;

const ItemParagraph = styled(Paragraph)`
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Item = ({ label, action }) => (
  <DropdownMenuItem onClick={action}>
    <ItemParagraph>{label}</ItemParagraph>
  </DropdownMenuItem>
);

Item.propTypes = {
  action: PropTypes.func,
  label: PropTypes.string,
};

export default React.memo(Item);
