import React from 'react';
import styled from '@emotion/styled';
import { Paragraph } from '@innovatrix/common/text';
import { connect } from 'react-redux';
import { getApplicationMetaData } from '@innovatrix/selectors';

import { config } from '../../config';
// -- Styles --------------- --- --  -

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 32px 32px 24px 32px;
`;

const Copyright = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey2};
  margin-bottom: 0;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  > * {
    margin-left: 12px;
  }
`;

const Logo = styled.img`
  max-width: 150px;
`;

const extraLogos = config.get('innovatrix.instance.logos', []);

// -- Component --------------- --- --  -

const Footer = connect((state) => {
  const applicationMetaData = getApplicationMetaData(state);
  const { configuration: { applicationImageUrl } } = applicationMetaData;
  return ({
    companyLogo: applicationImageUrl,
  });
})(({ companyLogo }) => (
  <Container>
    <Copyright>&copy;{new Date().getFullYear()}, imec vzw</Copyright>
    <LogoContainer>
      {companyLogo ? (
        <Logo src={companyLogo} alt="company-logo" />
      ) : null}
      {extraLogos ?
        Array.isArray(extraLogos) ?
          extraLogos.map(({ logo, height }, i) => <Logo key={i} height={height} src={logo} alt="company-logo" />) :
          <Logo src={extraLogos.logo} height={extraLogos.height} alt="company-logo" />
        : null}
    </LogoContainer>
  </Container>
));

export default Footer;
