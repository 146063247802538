import React from 'react';

import { defaultProps, propTypes } from './defaultPropTypes';

const AsterixIcon = ({ color, styles, ...props }) => (
  <svg fill={color} viewBox="0 0 20 20" {...styles} {...props}>
    <path d="M10.9 12.4V8.6H9.1v3.8h1.8zm0 3.8v-1.9H9.1v1.9h1.8zM0 19L10 1l10 18H0z" />
  </svg>
);

AsterixIcon.propTypes = propTypes;
AsterixIcon.defaultProps = defaultProps;

export default AsterixIcon;
