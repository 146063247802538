import { showErrorModal } from '@innovatrix/react-frontend/sagas/dxModalSagas';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getDxAuthUser } from '../../../selectors/authSelectors'; // TODO: move later.
import * as api from '../../../sagas/_api'; // TODO: move later.

import { FETCH_MY_PROJECTS } from './_actionTypes';
import { receiveMyProjectsAction, errorMyProjectsAction } from './_actions';
import { getMyProjectsQuery } from './_queries';

export default function* () {
  yield takeEvery(FETCH_MY_PROJECTS, function* fetchMyProjects() {
    try {
      const { id } = yield select(getDxAuthUser);
      const projects = yield call(api.fetch, '_getMyProjects', getMyProjectsQuery, {
        context: 'PROJECTS',
        variables: { id },
      });
      yield put(receiveMyProjectsAction({ data: projects }));
    }
    catch (err) {
      if (err.code === 'NOT_COMPLETED') { return; }
      yield put(errorMyProjectsAction({ error: err }));
      yield call(showErrorModal, err, { title: 'Failed to fetch the projects.' });
    }
  });
}
