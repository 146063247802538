import {
  DELETE_OBJECTIVE,
  FETCH_OBJECTIVES,
  RECEIVE_OBJECTIVES,
  ERROR_OBJECTIVES,
  ERROR_OBJECTIVE_TYPES,
  COMPLETE_OBJECTIVE_ERROR,
  COMPLETE_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE_ERROR,
  FETCH_OBJECTIVE_TYPES,
  RECEIVE_OBJECTIVE_TYPES,
  TOGGLE_OBJECTIVES_CREATING,
  CREATE_OBJECTIVE_SUCCESS,
  TOGGLE_SHOW_COMPLETED,
  COMPLETE_OBJECTIVE,
  MOVE_OBJECTIVE,
  SET_FIELD_EDIT_STATUS,
  ERROR_MOVE_OBJECTIVE,
  COMPLETE_OBJECTIVE_ANIMATION_SUCCESS,
  PERSIST_OBJECTIVE_COMMENT,
} from '../constants';

import { createFormikAction } from './utils';

// OBJECTIVE TYPES

export const createObjectiveTypeAction = createFormikAction('CREATE_OBJECTIVE_TYPE');

export const persistObjectiveTypesAction = createFormikAction('PERSIST_OBJECTIVE_TYPES');

export const persistObjectiveCommentAction = createFormikAction(PERSIST_OBJECTIVE_COMMENT);

export const fetchObjectiveTypesAction = ({ projectId }) => ({
  type: FETCH_OBJECTIVE_TYPES,
  projectId,
});

export const receiveObjectiveTypesAction = ({ projectId, data }) => ({
  projectId,
  type: RECEIVE_OBJECTIVE_TYPES,
  data,
});

export const errorObjectiveTypesAction = ({ error }) => ({
  type: ERROR_OBJECTIVE_TYPES,
  error,
});

// OBJECTIVES
export const persistObjectiveAction = createFormikAction('PERSIST_OBJECTIVE');
export const createObjectiveSuccessAction = ({ objectiveId }) => ({
  objectiveId,
  type: CREATE_OBJECTIVE_SUCCESS,
});

export const toggleShowCompletedAction = () => ({
  type: TOGGLE_SHOW_COMPLETED,
});

export const fetchObjectivesAction = ({ completed, projectId, objectiveTypeId }) => ({
  completed,
  objectiveTypeId,
  projectId,
  type: FETCH_OBJECTIVES,
});

export const receiveObjectivesAction = ({ completed, data, objectiveTypeId, projectId }) => ({
  completed,
  data,
  objectiveTypeId,
  projectId,
  type: RECEIVE_OBJECTIVES,
});

export const errorObjectivesAction = ({ completed, error, objectiveTypeId, projectId }) => ({
  completed,
  error,
  objectiveTypeId,
  projectId,
  type: ERROR_OBJECTIVES,
});

export const toggleObjectivesCreatingAction = ({ creating }) => ({
  creating,
  type: TOGGLE_OBJECTIVES_CREATING,
});

export const completeObjectiveAction = ({ completed, objectiveId, objectiveTypeId, projectId }) => ({
  completed,
  objectiveId,
  objectiveTypeId,
  projectId,
  type: COMPLETE_OBJECTIVE,
});

export const completeObjectiveErrorAction = ({ completed, error, objectiveId, objectiveTypeId, projectId }) => ({
  completed,
  error,
  objectiveId,
  objectiveTypeId,
  projectId,
  type: COMPLETE_OBJECTIVE_ERROR,
});

export const moveObjectiveAction = ({ campaignId, objectiveId, from, objectiveTypeId, projectId }) => ({
  campaignId,
  from,
  objectiveId,
  objectiveTypeId,
  projectId,
  type: MOVE_OBJECTIVE,
});

export const completeObjectiveSuccessAction = ({ completed, objectiveId, objectiveTypeId, projectId, result }) => ({
  completed,
  objectiveId,
  objectiveTypeId,
  projectId,
  type: COMPLETE_OBJECTIVE_SUCCESS,
  result,
});

export const completeObjectiveAnimationSuccessAction = () => ({
  type: COMPLETE_OBJECTIVE_ANIMATION_SUCCESS,
});

export const deleteObjectiveErrorAction = ({ campaignId, error, objectiveId, objectiveTypeId, projectId }) => ({
  campaignId,
  error,
  objectiveId,
  objectiveTypeId,
  projectId,
  type: DELETE_OBJECTIVE_ERROR,
});

export const deleteObjectiveAction = ({ campaignId, objectiveId, objectiveTypeId, projectId }) => ({
  campaignId,
  objectiveId,
  objectiveTypeId,
  projectId,
  type: DELETE_OBJECTIVE,
});

export const setFieldEditStatusAction = ({ fieldId, status }) => ({
  fieldId,
  status,
  type: SET_FIELD_EDIT_STATUS,
});

export const errorMoveObjectiveAction = ({ campaignId, error, objectiveId, objectiveTypeId, projectId }) => ({
  campaignId,
  error,
  objectiveId,
  objectiveTypeId,
  projectId,
  type: ERROR_MOVE_OBJECTIVE,
});

