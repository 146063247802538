import * as React from 'react';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';

const style = { display: 'inline-flex' };

/**
 * Wrapper that renders on tooltip around the children's hover effect.
 *
 * @param {React.node} children - React children.
 * @param {string} [placement = 'top'] - Where the tooltip needs to float.
 * @param {string} label - The tooltip's text.
 */
const ToolTip = ({
  children, label, placement, className,
}) => {
  if (!label) {
    return (
      <div style={style} className={className}>
        {children}
      </div>
    );
  }
  return (
    <div style={style}>
      <Tippy content={label} placement={placement}>
        <div className={className}>
          {children}
        </div>
      </Tippy>
    </div>
  );
};

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.oneOf(['bottom', 'left', 'right', 'top', 'auto']),
};

ToolTip.defaultProps = {
  label: null,
  placement: 'top',
};

export default React.memo(ToolTip);
